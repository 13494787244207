import React from 'react'
import { Route, useRouteMatch } from 'react-router-dom'
import { Text } from '@rimauswap-libs/uikit'
import styled from 'styled-components'
import Page from 'components/Layout/Page'
import FlexLayout from 'components/Layout/Flex'
import SearchInput from 'components/SearchInput'
import InfoTabButtons from './components/InfoTabButtons'
import Overview from './Overview'
import Tokens from './Tokens'
import Pools from './Pools'


const ControlContainer = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  position: relative;

  justify-content: space-between;
  flex-direction: column;

  ${({ theme }) => theme.mediaQueries.sm} {
    flex-direction: row;
    flex-wrap: wrap;
    padding: 16px 0px;
    margin-bottom: 0;
  }
`
const LabelWrapper = styled.div`
  display: flex;
  align-items: center;
  > ${Text} {
    font-size: 12px;
  }
`
const FilterContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  padding: 8px 0px;

  ${({ theme }) => theme.mediaQueries.sm} {
    width: auto;
    padding: 0;
  }
`
const ViewControls = styled.div`
  flex-wrap: wrap;
  justify-content: space-between;
  display: flex;
  align-items: center;
  width: 100%;

  > div {
    padding: 8px 0px;
  }

  ${({ theme }) => theme.mediaQueries.sm} {
    justify-content: flex-start;
    width: auto;

    > div {
      padding: 0;
    }
  }
`

const Info: React.FC = () => {
  const { path } = useRouteMatch();
  // event: React.ChangeEvent<HTMLInputElement>
  const handleChangeQuery = () => {
    // console.log(event.target.value);
  }



  
  const renderContent = (): JSX.Element => {
  
    return (
      <FlexLayout>
        <Route exact path={`${path}`}>
          <Overview />
        </Route>
        <Route exact path={`${path}/pools`}>
          <Pools />
        </Route>
        <Route exact path={`${path}/tokens`}>
          <Tokens />
        </Route>
      </FlexLayout>
    )
  }



  return (
    <>
      <Page>
        <ControlContainer>
          <ViewControls>
            <InfoTabButtons />
          </ViewControls>
          <FilterContainer>
           <LabelWrapper style={{ marginLeft: 16 }}>
              {/* <Text textTransform="uppercase">{t('Search')}</Text> */}
              <SearchInput onChange={handleChangeQuery} placeholder="Search Pools and Tokens" />
            </LabelWrapper>
          </FilterContainer>
        </ControlContainer>
        {renderContent()}
        {/* {account && !userDataLoaded && stakedOnly && (
          <Flex justifyContent="center">
            <Loading />
          </Flex>
        )}
        <div ref={loadMoreRef} /> */}
        {/* <StyledImage src="/images/decorations/3dpan.png" alt="Rimauswap illustration" width={120} height={103} /> */}
      </Page>
    </>
  )
}

export default Info
