import React from 'react'
// import styled from 'styled-components'

const Tokens: React.FC = () => {
 

  return (
    <>
     <h1>Tokens</h1>
    </>
  )
}

export default Tokens
