export const Pools = {
	"updated_at": 1629973816724,
	"data": {
		"0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c_0xCc88c8769e86D8E7B8726DEc92ca2Dbda9175787": {
			"pair_address": "0x6977D622c79903D630c1470F21128C247064c8a0",
			"base_name": "Wrapped BNB",
			"base_symbol": "WBNB",
			"base_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"quote_name": "FOMOMOON",
			"quote_symbol": "FOMOMOON",
			"quote_address": "0xCc88c8769e86D8E7B8726DEc92ca2Dbda9175787",
			"price": "1.0000000000000000000330985048376e+49",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "243463656904602710465090.7742758",
			"liquidity_BNB": "457166761194673720727.6958759436"
		},
		"0x34D5C5D5fD78Da13BA0aC15854C9405565ECF907_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0xeCAfB14f259e1E46Fb2E0b35bd5928eD26eb1176",
			"base_name": "Marlboro",
			"base_symbol": "Marlboro",
			"base_address": "0x34D5C5D5fD78Da13BA0aC15854C9405565ECF907",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "0",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "12349210313193778012310.0691725",
			"liquidity_BNB": "42626519333910613005.5504086947"
		},
		"0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c_0xf3F219A4285771D00657C8fd06B69Af8c89EDF2A": {
			"pair_address": "0x6fbd6D0E53138eCe61F0F1e757CD7aE1A91F6086",
			"base_name": "Wrapped BNB",
			"base_symbol": "WBNB",
			"base_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"quote_name": "TankArmy",
			"quote_symbol": "TANK",
			"quote_address": "0xf3F219A4285771D00657C8fd06B69Af8c89EDF2A",
			"price": "1.03885310617078753178818283419488884271763972574277997091211e+39",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "6320100142534248577097.57436607",
			"liquidity_BNB": "14655143541537127963.08242639413"
		},
		"0xBA537b27fD23908420a8b1169705A5FF9d77Abb6_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0x2735A21d8C4B8E55ce7e9B7603Ca5b654bf9dF7e",
			"base_name": "🙏 Help Addiction - Token for Charity 🙏",
			"base_symbol": "ADDICTED",
			"base_address": "0xBA537b27fD23908420a8b1169705A5FF9d77Abb6",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "0",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "2920947091445036040984.30756548",
			"liquidity_BNB": "5021293082225524925.34268668477"
		},
		"0xBb075aC9c55F4901c071d3352D00B21990976542_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0xF5E00Cfec58c9D0E78A96945C5468FF90444Aeb7",
			"base_name": "Boost",
			"base_symbol": "Boost",
			"base_address": "0xBb075aC9c55F4901c071d3352D00B21990976542",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "0",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "1493206108600469361835.911243865",
			"liquidity_BNB": "4350237921697709318.037363640222"
		},
		"0xb92ae8D7a63170CdA049e079e2b3419f2496C29c_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0x7D56b1C5bEdc890124D98Fc97DA16bb7e85593b6",
			"base_name": "HotFood",
			"base_symbol": "HD",
			"base_address": "0xb92ae8D7a63170CdA049e079e2b3419f2496C29c",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "0",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "661239995320287456594.9447272625",
			"liquidity_BNB": "1956414769574896135.741968372733"
		},
		"0x5aD8aB066CD086d0184d658FACa2A0522c57386e_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0x898055BFB2eFCD598BaFb18dBdA90292D7f9f0A5",
			"base_name": "maidcoin.org",
			"base_symbol": "MAID",
			"base_address": "0x5aD8aB066CD086d0184d658FACa2A0522c57386e",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "0",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "17260971128667343865.7576136161",
			"liquidity_BNB": "40443649162574750.43962047161625"
		},
		"0x35674807881aff2920D6D553cD45346Fdc1CfA90_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0x189150d3E84897dCEc3ABeFd117acABB4De479Dc",
			"base_name": "Polka Dogelon",
			"base_symbol": "PDOGELON",
			"base_address": "0x35674807881aff2920D6D553cD45346Fdc1CfA90",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "0",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "16730955688178509758.02077894444",
			"liquidity_BNB": "38999445020545304.76689829989584"
		},
		"0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c_0xd590D6D4f173885a9A3db2E97Ab9f58F80fa6631": {
			"pair_address": "0xe6925d832085fB0E9A25c2C2E65003c8d32D3791",
			"base_name": "Wrapped BNB",
			"base_symbol": "WBNB",
			"base_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"quote_name": "GAMEOLOGYv2",
			"quote_symbol": "GMYX",
			"quote_address": "0xd590D6D4f173885a9A3db2E97Ab9f58F80fa6631",
			"price": "5.67083737853069071182602378860706087303675609955268434758e+36",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "13053344822252830808.57571663159",
			"liquidity_BNB": "38740817401240900.3630310282081"
		},
		"0x7B74C77AA1E7F70cb8C1d01c2FEC66336995deD4_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0x26Ed0E959CaBA2416916dBd9407e227c064EaBdf",
			"base_name": "CONNECTX",
			"base_symbol": "CX",
			"base_address": "0x7B74C77AA1E7F70cb8C1d01c2FEC66336995deD4",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "0",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "6262619343082153528.79439732211",
			"liquidity_BNB": "17983624749502009.34726882708748"
		},
		"0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c_0xdA3155A436c964dA63D1e943593801AE9b513A75": {
			"pair_address": "0x2d28812622B43DbAf9e8bC4E76EC26F258DdcD10",
			"base_name": "Wrapped BNB",
			"base_symbol": "WBNB",
			"base_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"quote_name": "Tiger Shiba Doge",
			"quote_symbol": "TGSDOGE",
			"quote_address": "0xdA3155A436c964dA63D1e943593801AE9b513A75",
			"price": "1.65475135375212792652426844471575012195517477152848058745e+36",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "1188294671504634718.042067658578",
			"liquidity_BNB": "4017767689550441.607193183164902"
		},
		"0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c_0xF0a8D2eB72De9F0980e6D64A1a9EA86abC296d3D": {
			"pair_address": "0xE8aB303B93a41F814cE4ccf8F6F37a0d0598cda0",
			"base_name": "Wrapped BNB",
			"base_symbol": "WBNB",
			"base_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"quote_name": "PlanChain.io",
			"quote_symbol": "PLAN",
			"quote_address": "0xF0a8D2eB72De9F0980e6D64A1a9EA86abC296d3D",
			"price": "9.7446853460594945460716182519905468756394949758351292788e+35",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "1051978089936093806.143123865125",
			"liquidity_BNB": "3345642416334535.500620488644607"
		},
		"0x8b5A1aE5309299a634702b46B7790240Bf7931fB_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0xC82E34AF5b5bBa367b02f8F509e61059B2ed2e34",
			"base_name": "Yoshix NFT Game",
			"base_symbol": "YSX",
			"base_address": "0x8b5A1aE5309299a634702b46B7790240Bf7931fB",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "0",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "344202450835636896.605699894744",
			"liquidity_BNB": "895641893095203.153267442293198"
		},
		"0xA6A0567Ae25283E9fC4C42a063340424b794d7CE_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0x077113FA7CB361574FCC35aB613AD9736eD0fcd2",
			"base_name": "CryptoPunk NFT BSC",
			"base_symbol": "👨‍🎤CRPK",
			"base_address": "0xA6A0567Ae25283E9fC4C42a063340424b794d7CE",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "0",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "228500389804163639.8924851870875",
			"liquidity_BNB": "605599525800631.404546081703426"
		},
		"0x47f2EE75Dd5bDEa39bf2a1B2495b59A1Dca619Df_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0x296968CA7c171b5e420CB9b9F47139Ce8dFCcFcE",
			"base_name": "Sappchat.com",
			"base_symbol": "APP",
			"base_address": "0x47f2EE75Dd5bDEa39bf2a1B2495b59A1Dca619Df",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "0",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "130680530487114952.7066838683448",
			"liquidity_BNB": "388207757189862.7701436237723143"
		},
		"0x55d398326f99059fF775485246999027B3197955_0x58472700522605db6505ED6AEa866F57d9873c44": {
			"pair_address": "0x32E4e82d6447A8711b52C0c51c94CA45B13feD0C",
			"base_name": "Tether USD",
			"base_symbol": "USDT",
			"base_address": "0x55d398326f99059fF775485246999027B3197955",
			"quote_name": "SweetDOGE",
			"quote_symbol": "SweetDOGE",
			"quote_address": "0x58472700522605db6505ED6AEa866F57d9873c44",
			"price": "4.3355285437141269134579620775630002964719833975689297361399e+38",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "68466468163595024.1569511701239",
			"liquidity_BNB": "145359829710647.6042411460336379"
		},
		"0x5cB25d67e574D730d98dcF66D11e00eEAC2408d9_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0xaFE6aa3c484087Ce55F900bFDe134E4F1DD518C6",
			"base_name": "Cosmic",
			"base_symbol": "COS",
			"base_address": "0x5cB25d67e574D730d98dcF66D11e00eEAC2408d9",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "0",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "62416793265940693.7128569570369",
			"liquidity_BNB": "200777974331315.4822261124390608"
		},
		"0x8143dA62F4A06311eEa8854E6C5965a167ECBDDD_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0x7AbD2d20Ff79Ca5e262dAB30C63Af740db7Fb828",
			"base_name": "Mecha Doge",
			"base_symbol": "MECHADOGE",
			"base_address": "0x8143dA62F4A06311eEa8854E6C5965a167ECBDDD",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "0",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "38343039047050212.73278420395576",
			"liquidity_BNB": "137325424292840.2939482337709626"
		},
		"0x28c96d92AB0C3B991aFFA45E3Af0111937f79ef0_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0xF0e8a2eC75d53F0baf12243b7972234A05802911",
			"base_name": "Green Akita Inu",
			"base_symbol": "GAKITA",
			"base_address": "0x28c96d92AB0C3B991aFFA45E3Af0111937f79ef0",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "0",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "38130938810794480.3613712228991",
			"liquidity_BNB": "122824112056820.3791495865517788"
		},
		"0x66b6468486FeADf0e1A56885b953C6778d1f9f20_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0x77b9a8d655Ba155DaB0c253582f2c12A0557Cb05",
			"base_name": "Blue Akita Inu",
			"base_symbol": "BLUEAKITA",
			"base_address": "0x66b6468486FeADf0e1A56885b953C6778d1f9f20",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "0",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "36914584897773647.5180768633319",
			"liquidity_BNB": "121814804190783.7444434703652677"
		},
		"0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c_0xDc0A57625567115f890F424B451970AaDfE17Fc7": {
			"pair_address": "0xd6e07BCdCD60b2A9e201E303eCa7c09e48C169E3",
			"base_name": "Wrapped BNB",
			"base_symbol": "WBNB",
			"base_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"quote_name": "TopDoge",
			"quote_symbol": "TopDoge",
			"quote_address": "0xDc0A57625567115f890F424B451970AaDfE17Fc7",
			"price": "9.973967943667986071608996943677003022112286931109803e+31",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "31018245325147802.84011728177433",
			"liquidity_BNB": "108627767475261.5356064077073115"
		},
		"0xa4bdB640038b9eb264d7bcC3bF47DBf12192f9f8_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0xa94075451e5104ed31015847D7963f68BBbd9aD5",
			"base_name": "Ryoshis DogeCoin",
			"base_symbol": "RYOSHIDOGE",
			"base_address": "0xa4bdB640038b9eb264d7bcC3bF47DBf12192f9f8",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "0",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "28968940913365882.74413633462993",
			"liquidity_BNB": "69623627969212.31323960497492676"
		},
		"0x653f1e96130Bda6e2a82ECc640D6F6742D1C3119_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0x258401B28C96229Cbf0e398E72907401B21f9a33",
			"base_name": "Waifu Labs",
			"base_symbol": "WaifuLabs",
			"base_address": "0x653f1e96130Bda6e2a82ECc640D6F6742D1C3119",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "0",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "24335455791658639.31472491539705",
			"liquidity_BNB": "82590485061619.5687680641739895"
		},
		"0xa1231c9354733C04A433890A32146fF4EEbFe14C_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0x560A71489C650AF00C5d0DFbd2a6CfCF1B46A802",
			"base_name": "Polka DogeMoon",
			"base_symbol": "PDOGEMOON",
			"base_address": "0xa1231c9354733C04A433890A32146fF4EEbFe14C",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "0",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "22608010373408335.51852981954602",
			"liquidity_BNB": "52835009386944.14787341002151555"
		},
		"0xA399009E2993DA85A63bc63C093a5dD555291f6B_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0xc1B0A020FECe3e21C8c5f2BdD6BF0b4339005Eb3",
			"base_name": "Orica Doge",
			"base_symbol": "ORIDOGE",
			"base_address": "0xA399009E2993DA85A63bc63C093a5dD555291f6B",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "0",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "22353577311877045.54600679981482",
			"liquidity_BNB": "52991515547420.7768201332932187"
		},
		"0x18e00741463D23671286F84ad19b0452a17688C8_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0x99e7c07F5C61C6B4b258440FC7ee6419325bEf2e",
			"base_name": "Blue Doge Coin",
			"base_symbol": "BLUEDOGE",
			"base_address": "0x18e00741463D23671286F84ad19b0452a17688C8",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "0",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "21979726216545090.8276892506266",
			"liquidity_BNB": "72487890947104.8745459505463874"
		},
		"0x30fA6EeE6314028CfCd129302774C14972697c1E_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0xce1f566743389932A8E721c02cFc005cBf5754EB",
			"base_name": "Doge Monkey NFT",
			"base_symbol": "DOGEMONKEY",
			"base_address": "0x30fA6EeE6314028CfCd129302774C14972697c1E",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "0",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "21025631670368040.84946051331667",
			"liquidity_BNB": "52956101051694.0251734971556135"
		},
		"0x703b859625b7eED45f93a558eE9E1eFb8D43bF94_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0xDe21F1df123A62fbE463fB424A4100DEa8127E4C",
			"base_name": "BiragonNFT",
			"base_symbol": "BIRA",
			"base_address": "0x703b859625b7eED45f93a558eE9E1eFb8D43bF94",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "0",
			"base_volume": "5.00000000001893372763551385917e+24",
			"quote_volume": "337.583014524391936918",
			"liquidity": "20990377322033413.67606475030064",
			"liquidity_BNB": "41324994834936.809454975738427"
		},
		"0x441477e0f1f61521dEce2462Af808A9c689c579C_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0xa6eba0c35014D5fc86CD08edF6a9261DfD458952",
			"base_name": "Shiba Monster",
			"base_symbol": "SHIBMON",
			"base_address": "0x441477e0f1f61521dEce2462Af808A9c689c579C",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "0",
			"base_volume": "5.00000000001872908140363955514e+24",
			"quote_volume": "337.051510422875128632",
			"liquidity": "20858658384729272.76669211499407",
			"liquidity_BNB": "41513832673514.8364603286504345"
		},
		"0x6D9B3ef219273EFD20219F662cf3F22Ff06cdb29_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0xf820f14aDC5738d905057ceF992AD4F2829641d2",
			"base_name": "Bina DogeMoon",
			"base_symbol": "BINADOGE",
			"base_address": "0x6D9B3ef219273EFD20219F662cf3F22Ff06cdb29",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "0",
			"base_volume": "5.00000000001800560038180715437e+24",
			"quote_volume": "333.6706582096984785950000000003",
			"liquidity": "20330199013257285.1604638960145",
			"liquidity_BNB": "41346553607528.5421709077102299"
		},
		"0xa0A5aFf1Faa5eE9bc491cC411afBe7A850B5EEa2_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0xEfeFCdd85Bdf06aaFba11E62961cbcF4f6159249",
			"base_name": "MelodyMuse Token",
			"base_symbol": "MELOMUSE",
			"base_address": "0xa0A5aFf1Faa5eE9bc491cC411afBe7A850B5EEa2",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "0",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "20243017205815633.1330927113264",
			"liquidity_BNB": "55542232384587.82493941168265167"
		},
		"0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c_0xf45570923787Cca30e3b23f4256c82c67e7481b9": {
			"pair_address": "0x8aD4C21790DfBE4B8BF1D574a74C818c1D4404E3",
			"base_name": "Wrapped BNB",
			"base_symbol": "WBNB",
			"base_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"quote_name": "Floki Doge Elon",
			"quote_symbol": "FLOKIDOGE",
			"quote_address": "0xf45570923787Cca30e3b23f4256c82c67e7481b9",
			"price": "3.54796577494360177366616958339457329225520319984448216e+33",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "20031698377936551.63592515254652",
			"liquidity_BNB": "40963498980855.3545946992578668"
		},
		"0x71BA32EB1f88c40652a1f8f14C0880f2CB86528F_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0x645528AdCb7D50d7B86DAe8ba98f656fFe65e2aC",
			"base_name": "Corgi Shiba Musk",
			"base_symbol": "SHIBMUSK",
			"base_address": "0x71BA32EB1f88c40652a1f8f14C0880f2CB86528F",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "0",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "19897857840125948.4623297286165",
			"liquidity_BNB": "41066243957841.62604769327890436"
		},
		"0xb58fCa9B6757B811bbc9eae6216CB26De1871f21_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0x674325DEE3de3F953A2e26d4ab78e091BA8C04C6",
			"base_name": "Floki Akita NFT",
			"base_symbol": "FLAIKITA",
			"base_address": "0xb58fCa9B6757B811bbc9eae6216CB26De1871f21",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "0",
			"base_volume": "5.000000000018010002061980100876e+24",
			"quote_volume": "330.2221263610266024700000000003",
			"liquidity": "19574058325788327.2214351238131",
			"liquidity_BNB": "40387845645145.42688133241149046"
		},
		"0x9d2738fa3d3D6621218cA1254bcbC8020A0B23Dd_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0x16Ad1FF2C538a43EfA2797736982A478A99af6cD",
			"base_name": "Alice Doge NFT",
			"base_symbol": "ALDOGE",
			"base_address": "0x9d2738fa3d3D6621218cA1254bcbC8020A0B23Dd",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "0",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "18580397386032450.03032234670957",
			"liquidity_BNB": "41272851450504.29352686447474735"
		},
		"0x6d4D626db4fD6FeD0dc0D8ea8a1B6DbdFdAf9B30_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0xB91223bB3028Fd761427140B75f3ce78e6a08859",
			"base_name": "COBRA",
			"base_symbol": "COBRA",
			"base_address": "0x6d4D626db4fD6FeD0dc0D8ea8a1B6DbdFdAf9B30",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "0",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "18326153306250023.12019784797295",
			"liquidity_BNB": "30152107246732.66983414283122628"
		},
		"0x5c2321aEE02b8D380202DcAF368097FDEAe46F2A_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0x9b6749B76523bC6e7aCFA6c06E61159BE355A41F",
			"base_name": "Rise Doge NFT",
			"base_symbol": "RDOGE",
			"base_address": "0x5c2321aEE02b8D380202DcAF368097FDEAe46F2A",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "0",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "17340830004559650.49113139933793",
			"liquidity_BNB": "38757064088253.1294777014027727"
		},
		"0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c_0xeB109f8a504Db88a6a1C2141dC2F97DdE86823b8": {
			"pair_address": "0x9ea35d0999aA8C58254a222556C7e9e569c5EefD",
			"base_name": "Wrapped BNB",
			"base_symbol": "WBNB",
			"base_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"quote_name": "Polka Doge Token",
			"quote_symbol": "PKDOGET",
			"quote_address": "0xeB109f8a504Db88a6a1C2141dC2F97DdE86823b8",
			"price": "5.35371016528533229663858986729531616061270977132025345e+33",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "17059479206260451.00735860492375",
			"liquidity_BNB": "38793036820891.19116596257642726"
		},
		"0x43BC90EAC5877E84490df228420B85446705C35A_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0x2dEaf1cFE95cF1Bf03d6c6df1e2437FB0584DCB7",
			"base_name": "Axie Infinity BSC",
			"base_symbol": "BAXS",
			"base_address": "0x43BC90EAC5877E84490df228420B85446705C35A",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "0",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "16697026993176069.56132121903615",
			"liquidity_BNB": "43683144414557.308052382299465"
		},
		"0x57B62cEb9FE91794B794AA62E494C7c12Ac0Ed9b_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0x47440f8fc91d66867Fa35901bE2882C7FF791F11",
			"base_name": "StepHero",
			"base_symbol": "HERO",
			"base_address": "0x57B62cEb9FE91794B794AA62E494C7c12Ac0Ed9b",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "0",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "15617561813964134.19198481165823",
			"liquidity_BNB": "38804909274705.5195762085295779"
		},
		"0x47e10659F54fb21E7e3E0Ef387aB6872Fb46316B_0x89Cf8aC9c202AF7D19630Ec7Eb384F25985fA4d7": {
			"pair_address": "0xEa52E531Aa91415Ee7bF05E37a54142Aa43DAc88",
			"base_name": "Floki",
			"base_symbol": "FLOKI",
			"base_address": "0x47e10659F54fb21E7e3E0Ef387aB6872Fb46316B",
			"quote_name": "Polytopia",
			"quote_symbol": "POLY",
			"quote_address": "0x89Cf8aC9c202AF7D19630Ec7Eb384F25985fA4d7",
			"price": "5e-19",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "15442696094497925.24233326056823",
			"liquidity_BNB": "50874480082672.70440035227213926"
		},
		"0xB0ec269433F719564724d7AbEB83cBfDaa1F19Ea_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0x3389d4A04955Eb50A20Bdaf36f06E36640AcF9DB",
			"base_name": "Alien BSC Token",
			"base_symbol": "AlienBSC",
			"base_address": "0xB0ec269433F719564724d7AbEB83cBfDaa1F19Ea",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "0",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "14739272204005108.20510626362407",
			"liquidity_BNB": "38060235579977.33159605817111576"
		},
		"0x8B6B65195C67C536b6FB331433e1488E83f32460_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0x61f1b308A7aEC46198588c5cECc545683aE22a1f",
			"base_name": "Polka SuShi Moon",
			"base_symbol": "PKSUSHI",
			"base_address": "0x8B6B65195C67C536b6FB331433e1488E83f32460",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "0",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "14526443465377638.81734810699604",
			"liquidity_BNB": "38755049799357.783214280973415"
		},
		"0xb568A9BdC9f026244b9cebE2B331C144dA33E68F_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0xc879CAb291F8a847B1B9c2a32Db497ddf00e2117",
			"base_name": "Nexo DogeCoin",
			"base_symbol": "NEXODOGE",
			"base_address": "0xb568A9BdC9f026244b9cebE2B331C144dA33E68F",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "0",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "14308545103973020.11659338261953",
			"liquidity_BNB": "38942574275381.10703587338072346"
		},
		"0x6F6d6CC9e6f46E525Aaf2D35a58f454c4A4a8856_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0x6e93cD45906609554751543d2FdAEad3458ba074",
			"base_name": "Yoshi Doge",
			"base_symbol": "YOSHIDOGE",
			"base_address": "0x6F6d6CC9e6f46E525Aaf2D35a58f454c4A4a8856",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "0",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "14027175343215354.05314227191671",
			"liquidity_BNB": "43440308788253.6894271689891115"
		},
		"0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c_0xC7dC5f0015936134bcE40D1e9Be61b6e8E6c0325": {
			"pair_address": "0xd8049A67BD27A512E2cB843D175572048811E4c7",
			"base_name": "Wrapped BNB",
			"base_symbol": "WBNB",
			"base_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"quote_name": "Dogecoin 3.0",
			"quote_symbol": "DOGE3",
			"quote_address": "0xC7dC5f0015936134bcE40D1e9Be61b6e8E6c0325",
			"price": "5.3534398770702949000131509359834899314606694426672184e+33",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "13631512822696972.24186465374055",
			"liquidity_BNB": "38845534928238.12337088899818205"
		},
		"0xB2bC20374FaC51259a271b7769958CE09B2B2C07_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0xf47c5cd0F9a7c8E8EFe4d4241C282040a501F2BE",
			"base_name": "Super CateMoon",
			"base_symbol": "CATEMOON",
			"base_address": "0xB2bC20374FaC51259a271b7769958CE09B2B2C07",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "0",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "13623597851511684.02691152950723",
			"liquidity_BNB": "39078299574748.3883527603451896"
		},
		"0x24BC8C85D29eE2C69991A692b87dd396B31796Ff_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0x5D8Af381aF54e6dCF9Bd61B3266E07F7DDf2418A",
			"base_name": "NeoDOGE Coin",
			"base_symbol": "NeoDOGE",
			"base_address": "0x24BC8C85D29eE2C69991A692b87dd396B31796Ff",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "0",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "13612511663848020.78571787350181",
			"liquidity_BNB": "38782928800712.4088604263058587"
		},
		"0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c_0xD5Fe3d1392d359653D951a3999769B905E03F2EF": {
			"pair_address": "0x9F3b4551E41a77f0F39a0215ec1a9998b502E050",
			"base_name": "Wrapped BNB",
			"base_symbol": "WBNB",
			"base_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"quote_name": "Germ Network",
			"quote_symbol": "GERM",
			"quote_address": "0xD5Fe3d1392d359653D951a3999769B905E03F2EF",
			"price": "5.24118329589915374964723862044110018747765061170452982e+33",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "13609507478712593.94421993227682",
			"liquidity_BNB": "38032252728768.1360727975813737"
		},
		"0x6C4d7d614534b7Ec4c3F9A4d4613403187037D97_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0xDc501b231202610991DDaF4717aC4dD7a29868ED",
			"base_name": "Ape Akita Token",
			"base_symbol": "AKITA",
			"base_address": "0x6C4d7d614534b7Ec4c3F9A4d4613403187037D97",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "0",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "13171186227436903.41544364227005",
			"liquidity_BNB": "38068212127849.08683054007008284"
		},
		"0x182E5ba6Df3e6769Ff3C95dAaa618Ee2b205Cc6E_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0xB8D51AC77cb2FF9f388A99aD4837F3D6a925eCcD",
			"base_name": "Bobby Finke",
			"base_symbol": "FINKE",
			"base_address": "0x182E5ba6Df3e6769Ff3C95dAaa618Ee2b205Cc6E",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "0",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "12533333209405204.22648923551895",
			"liquidity_BNB": "39863580992741.72754722472645583"
		},
		"0x91D15Ab3B5f47f157C7F543230e42bde3e38351A_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0x31653E9C26bB649c2b1Fbd9541dC50088E97BbF3",
			"base_name": "Dino Shiba Inu",
			"base_symbol": "DINOSHIB",
			"base_address": "0x91D15Ab3B5f47f157C7F543230e42bde3e38351A",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "0",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "11401950383936077.91974814939825",
			"liquidity_BNB": "39255371269805.489500409121239"
		},
		"0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c_0xD9C535cf9ab165Ed8cA37fd345787Ac2BF819Bf9": {
			"pair_address": "0x741fD85883B241FFe8f6e9Acf1A08702a5Bb8A5B",
			"base_name": "Wrapped BNB",
			"base_symbol": "WBNB",
			"base_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"quote_name": "Truebit Chain",
			"quote_symbol": "TRU-CHAIN",
			"quote_address": "0xD9C535cf9ab165Ed8cA37fd345787Ac2BF819Bf9",
			"price": "5.35468925058624186037029057165529386715595560929198026e+33",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "11355278941477671.08378110031244",
			"liquidity_BNB": "34345815659991.10959496666286073"
		},
		"0xB340D5C82a9de96a0b841d5653549dc2a104e3bc_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0x30509989CdA5130Dc70bbCCA19FB16e73B83995D",
			"base_name": "Crypto Emory",
			"base_symbol": "EMORY",
			"base_address": "0xB340D5C82a9de96a0b841d5653549dc2a104e3bc",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "0",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "10425214400307803.16293847564077",
			"liquidity_BNB": "33798922226883.63464466238030953"
		},
		"0x9bB7722756daBe175F3A70b4a7e569274636e38a_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0x3C923991032Ff9a01C57EE8e8e84677F9164E978",
			"base_name": "Nikita Mars Inu",
			"base_symbol": "NIKITA",
			"base_address": "0x9bB7722756daBe175F3A70b4a7e569274636e38a",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "0",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "10414629708149022.89893770153482",
			"liquidity_BNB": "32331904576223.32342178635299287"
		},
		"0xa582CB9d6033aEC03A0eC959FF91AbD29DcADE56_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0x09fad44e0c510d5E7a7B611354e19C67d9aea8aC",
			"base_name": "Baby Rune",
			"base_symbol": "BabyRUNE",
			"base_address": "0xa582CB9d6033aEC03A0eC959FF91AbD29DcADE56",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "0",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "10370270801027038.1348325917456",
			"liquidity_BNB": "33226877566149.0088657505519841"
		},
		"0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c_0xd6371ea36f68A90b757453763690c0895c4fCfe1": {
			"pair_address": "0x3Bf0B6830D2B26D2ea92BbeD0CF47707AC6D3624",
			"base_name": "Wrapped BNB",
			"base_symbol": "WBNB",
			"base_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"quote_name": "Luna Doge",
			"quote_symbol": "LUNADOGE",
			"quote_address": "0xd6371ea36f68A90b757453763690c0895c4fCfe1",
			"price": "7.30090029478314473436739534604052303965110481034330109e+33",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "10365038514534634.32341008856214",
			"liquidity_BNB": "32952546967686.5891816562270414"
		},
		"0x46E267Bc62FFad6552AB613E21ad56fa1fc0F96F_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0xf3b642cc1c131D6A10b5E62b685F43fE66Fe2381",
			"base_name": "Crypto Cake BSC",
			"base_symbol": "CCAKEBSC",
			"base_address": "0x46E267Bc62FFad6552AB613E21ad56fa1fc0F96F",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "0",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "8680320868451120.40905701937878",
			"liquidity_BNB": "26899350224613.12471242836987026"
		},
		"0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c_0xE640ab4c80d289eED4434A96B0DEC8c2CF7906D8": {
			"pair_address": "0x8B6D937f3AeEE67104FAeC7FB26F788cD43d19F1",
			"base_name": "Wrapped BNB",
			"base_symbol": "WBNB",
			"base_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"quote_name": "BinaCake BSC",
			"quote_symbol": "BinaCAKEBSC",
			"quote_address": "0xE640ab4c80d289eED4434A96B0DEC8c2CF7906D8",
			"price": "5.24384604072394777201780556278137147228424902598207936e+33",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "8621028733453764.32128550100741",
			"liquidity_BNB": "27010852007321.9899164905195369"
		},
		"0x5b41395467d484B377143408D7BBbEb14B1937BC_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0x26Bc6243ad23B88F2ec3E4B20086320217442A49",
			"base_name": "Bella Kishu Doge",
			"base_symbol": "KISHUDOGE",
			"base_address": "0x5b41395467d484B377143408D7BBbEb14B1937BC",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "0",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "8439625395598287.116421398269374",
			"liquidity_BNB": "27054634499789.47930688950423125"
		},
		"0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c_0xF0700d3EEaa5D5e33E9D6573ed5a237687500b19": {
			"pair_address": "0xe8675BA878C86734f5e379DfF3c0F94677aF923A",
			"base_name": "Wrapped BNB",
			"base_symbol": "WBNB",
			"base_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"quote_name": "Blue Shiba Token",
			"quote_symbol": "BLSHIB",
			"quote_address": "0xF0700d3EEaa5D5e33E9D6573ed5a237687500b19",
			"price": "8.92267155104961639580767466834898285158000481770727725e+33",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "7712528300915188.06524124334904",
			"liquidity_BNB": "26266984288304.50211266548680755"
		},
		"0x66651364c5435e73a962da59Ec259c249b56A3EB_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0x3caEF515C76eEE7b3eDe6aAE6eF184a17238C209",
			"base_name": "Corgi Rise",
			"base_symbol": "CORGIRISE",
			"base_address": "0x66651364c5435e73a962da59Ec259c249b56A3EB",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "0",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "7160785809156303.89787544162715",
			"liquidity_BNB": "25416626396043.4323467529471879"
		},
		"0x3E1E7119a413E64571b0A4BB74Cca6AC8f916D05_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0xdAA3f0a548a9f6B0694eE797Da988f1d6420624A",
			"base_name": "POLYMER",
			"base_symbol": "POLY",
			"base_address": "0x3E1E7119a413E64571b0A4BB74Cca6AC8f916D05",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "0",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "7006865156602423.8923773436433",
			"liquidity_BNB": "20466591301953.01618168646743165"
		},
		"0xaeBE7F94deB8e4C87D4822100445C02299061bC1_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0x11f5840Ec5E601AAc19d2b6d8be7d587622288C2",
			"base_name": "StealthElonSafeCommunityMoon",
			"base_symbol": "StealthElonSafeCommunityMoon",
			"base_address": "0xaeBE7F94deB8e4C87D4822100445C02299061bC1",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "0",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "6289280583344124.10643125599839",
			"liquidity_BNB": "10003994796572.4365494491701876"
		},
		"0x5CFBFF1BE0abE5C6942AF67C2f31348dF1C0f08c_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0x313b6535cA12c11F3aC4277436721782127953BC",
			"base_name": "Anon Akita Inu",
			"base_symbol": "AnonAKITA",
			"base_address": "0x5CFBFF1BE0abE5C6942AF67C2f31348dF1C0f08c",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "0",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "4916490428242588.25826019566419",
			"liquidity_BNB": "18596965206240.08030850559336364"
		},
		"0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c_0xd34ee06A6E3773059ca5B91107094f9f9d8A040e": {
			"pair_address": "0xE07BdFe7a49EDff8e25FA07CB082dA6b5E2d90AB",
			"base_name": "Wrapped BNB",
			"base_symbol": "WBNB",
			"base_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"quote_name": "YAY.games",
			"quote_symbol": "YAY",
			"quote_address": "0xd34ee06A6E3773059ca5B91107094f9f9d8A040e",
			"price": "1.42208994890573929087278382896979253414153549308978052e+34",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "4634577832564112.87865057542627",
			"liquidity_BNB": "9406270316492.96537215194901464"
		},
		"0x45CDcE2375f988DC1A358D5f763463daC8E435A9_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0x565c6069315B644dc9B3293f72FF3769C77d762d",
			"base_name": "Poly Akita",
			"base_symbol": "POLYAKITA",
			"base_address": "0x45CDcE2375f988DC1A358D5f763463daC8E435A9",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "0",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "4448263050998223.93953295857787",
			"liquidity_BNB": "14225457754796.61207640139340595"
		},
		"0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c_0xd83bd1715FF628a797E85B8AFc5AD51f2Ed00Cdd": {
			"pair_address": "0xc72Edb943488086472Ceb0c2686480ba9cDa2cDd",
			"base_name": "Wrapped BNB",
			"base_symbol": "WBNB",
			"base_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"quote_name": "Shiba Rise Mars",
			"quote_symbol": "SHIBRISE",
			"quote_address": "0xd83bd1715FF628a797E85B8AFc5AD51f2Ed00Cdd",
			"price": "5.85628584814981872930374905917743000177418522282665768e+33",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "4005219670425681.020610148805996",
			"liquidity_BNB": "14236355421343.86792005930459029"
		},
		"0x461192C88DAACBc5958bc52617Bdaecc1FdD80bc_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0x051d0e899361E5261b8EBdd9A06812632497DDFC",
			"base_name": "CoffeCat",
			"base_symbol": "COFC",
			"base_address": "0x461192C88DAACBc5958bc52617Bdaecc1FdD80bc",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "0",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "3378341966709023.737893134949523",
			"liquidity_BNB": "10431642589775.6386171960903094"
		},
		"0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c_0xfF79649cAdE0AF00a9577C3dB2B77E8BB18b1f99": {
			"pair_address": "0x33E7C5AAD510E433090671a3cd9d83f362fb78B9",
			"base_name": "Wrapped BNB",
			"base_symbol": "WBNB",
			"base_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"quote_name": "SargeCoin",
			"quote_symbol": "SARGE",
			"quote_address": "0xfF79649cAdE0AF00a9577C3dB2B77E8BB18b1f99",
			"price": "4.87937533273411339972168826699181418623611870659072274e+33",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "3207297475844358.140101844124146",
			"liquidity_BNB": "12079573554611.58886811225883076"
		},
		"0x3E83EaB545e5B41c7B9BB169Af7eba034F1c4f60_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0x99654F471f235D64f2D963bf63F49F6c5058e598",
			"base_name": "Fuck Israel",
			"base_symbol": "Fuck Israel",
			"base_address": "0x3E83EaB545e5B41c7B9BB169Af7eba034F1c4f60",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "0",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "3014283992546783.33809980353394",
			"liquidity_BNB": "10011492131334.16681596674085168"
		},
		"0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c_0xd3c84Fe12279cdf461a4121aCAAD1E3deFd13e4b": {
			"pair_address": "0xf2337a76df358951e76c61BF5d9800A95099df28",
			"base_name": "Wrapped BNB",
			"base_symbol": "WBNB",
			"base_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"quote_name": "DAKURA",
			"quote_symbol": "DKR",
			"quote_address": "0xd3c84Fe12279cdf461a4121aCAAD1E3deFd13e4b",
			"price": "2.63829041103585127009990395881544243088068270935435e+30",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "2983924159817005.962537523382164",
			"liquidity_BNB": "9571833312998.28189724023866691"
		},
		"0x64e130d7c40fa8C1B65Fbdf49F22df2cB1a5555F_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0x10641F46aaBC57e38d790fc901d87A92887589Be",
			"base_name": "Ever Akita Inu",
			"base_symbol": "EverAKITA",
			"base_address": "0x64e130d7c40fa8C1B65Fbdf49F22df2cB1a5555F",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "0",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "2939737294811299.776572745391388",
			"liquidity_BNB": "11266791506387.76528229982014082"
		},
		"0x59F276a70490422dD555eeeb0C16F2493fF8eC00_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0xa0bE6910a344F5e0312179701dCEA46E0A33AefE",
			"base_name": "BestBuy.finance",
			"base_symbol": "BEST",
			"base_address": "0x59F276a70490422dD555eeeb0C16F2493fF8eC00",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "0",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "2575765363263233.349947615294326",
			"liquidity_BNB": "6879357744368.27696399002789772"
		},
		"0x45e6ad1444e63a88535D32fDF0AAf26809EA935D_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0xFD248bbF10a81193A3719D979b8b3cc29944bea4",
			"base_name": "Dogcake Finance",
			"base_symbol": "DCAKE",
			"base_address": "0x45e6ad1444e63a88535D32fDF0AAf26809EA935D",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "0",
			"base_volume": "5.00000000007569260443578623474e+24",
			"quote_volume": "98.0324140935632406949999999999",
			"liquidity": "2412014948078178.262520622867175",
			"liquidity_BNB": "4787198882180.645678758276202365"
		},
		"0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c_0xBBd64F409ed768BEa0D85c7B52a54269b91f4041": {
			"pair_address": "0x2A2d43872998baCdC64433bD2D355C9A6fBe37Cb",
			"base_name": "Wrapped BNB",
			"base_symbol": "WBNB",
			"base_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"quote_name": "QueenDex Token",
			"quote_symbol": "QUEENDEX",
			"quote_address": "0xBBd64F409ed768BEa0D85c7B52a54269b91f4041",
			"price": "7.31719048145261855494813424581566974526919415479760565e+33",
			"base_volume": "55.71673433931667762199999999994",
			"quote_volume": "5.000000000004000400000000000001101e+24",
			"liquidity": "2174669910940428.711719368532702",
			"liquidity_BNB": "4554416663541.474787292451554474"
		},
		"0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c_0xe56f999669DE1DD507dBDe6FaC1334f66F96670D": {
			"pair_address": "0xd6772CE142FF34b12f4B5aB599FC5908c258Eb4b",
			"base_name": "Wrapped BNB",
			"base_symbol": "WBNB",
			"base_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"quote_name": "NFT LaunchPad",
			"quote_symbol": "NFTPAD",
			"quote_address": "0xe56f999669DE1DD507dBDe6FaC1334f66F96670D",
			"price": "7.11166414455270535122368654915290251719656572276706056e+33",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "2141334501328676.440590477152124",
			"liquidity_BNB": "4484941869784.43615399785186275"
		},
		"0x41F22f8b5E6b8d685b391b1ceeDE36F793a96Ab6_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0xCA4C9a52D8e9Aa347a0151D3B48cCC6379903F8b",
			"base_name": "101Doge",
			"base_symbol": "101DOGE",
			"base_address": "0x41F22f8b5E6b8d685b391b1ceeDE36F793a96Ab6",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "0",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "2105707583624328.179830982412161",
			"liquidity_BNB": "4363256277007.566808506099988032"
		},
		"0x2889a76bCa61B72B63Ce318E75c58fF4F658a647_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0x34b5F7721EaA68DBAFaB63F013b839AcC2dFe78d",
			"base_name": "Doge Roblox NFT",
			"base_symbol": "DOGERBL",
			"base_address": "0x2889a76bCa61B72B63Ce318E75c58fF4F658a647",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "0",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "2104671259701410.633148641867257",
			"liquidity_BNB": "5266709608899.229626730432498385"
		},
		"0x519Ac0E8cad648D667a74c34d0505E338bE9B008_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0xbfE1521aeFdCd1957c20a4cF280A7DB6c0f1553d",
			"base_name": "Ryoshis DogeCoin",
			"base_symbol": "RYOSHIDOGE",
			"base_address": "0x519Ac0E8cad648D667a74c34d0505E338bE9B008",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "0",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "2092223055695649.495881003758882",
			"liquidity_BNB": "5136163927582.678267887814263656"
		},
		"0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c_0xe04C2E062D5FBe184F929D7dd3E03D2BCdBf1e47": {
			"pair_address": "0x05aD4EA402D2a3118c23e104D93B135e5dAB2327",
			"base_name": "Wrapped BNB",
			"base_symbol": "WBNB",
			"base_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"quote_name": "Doge Guild Games",
			"quote_symbol": "DOGEGG",
			"quote_address": "0xe04C2E062D5FBe184F929D7dd3E03D2BCdBf1e47",
			"price": "3.5693040609032846553849789859182973126914105200836523e+32",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "2091907503549939.291164539068681",
			"liquidity_BNB": "5214277806548.059369547168405416"
		},
		"0x9082A354b1E9cA61c93e843DF2Ef36Ea53a076E5_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0xB8FEA838186000b3fBb3fDa97477cf7fBB342fbd",
			"base_name": "DogeWin Token",
			"base_symbol": "DOGEWIN",
			"base_address": "0x9082A354b1E9cA61c93e843DF2Ef36Ea53a076E5",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "0",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "2045955345829456.156087263028386",
			"liquidity_BNB": "5103686352359.418564054195458564"
		},
		"0x36cD824F403C94CBF0Bc773EB16ec44bBe2388Ca_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0x7e648e04642Ed3B2665e8491c84654C48b162d83",
			"base_name": "Multigame Rewards Token",
			"base_symbol": "MULTI",
			"base_address": "0x36cD824F403C94CBF0Bc773EB16ec44bBe2388Ca",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "0",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "2006185092100820.660507291580783",
			"liquidity_BNB": "4468082916047.3807365001065898"
		},
		"0x7157489406d24a41466219B42f1B9cbd98943905_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0x103aa6898f2ce392752630541AA7cC0F8aaBAd55",
			"base_name": "Corgi Doge NFT",
			"base_symbol": "CORGIDOGE",
			"base_address": "0x7157489406d24a41466219B42f1B9cbd98943905",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "0",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "2000949178591835.545795372549499",
			"liquidity_BNB": "4108994611808.573252196766593954"
		},
		"0x9bd547Cd5382aB3fAC467bfAdAf0fDbA67Ff73e0_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0x28CB951D9D7De9837951A542A03bDC5391B8A6F7",
			"base_name": "Doge MetaVerse 1Layer",
			"base_symbol": "METADOGE",
			"base_address": "0x9bd547Cd5382aB3fAC467bfAdAf0fDbA67Ff73e0",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "0",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "1981586635113183.513513466518265",
			"liquidity_BNB": "4382247977616.395954737082325092"
		},
		"0x0c5cE2b5793a1836A716B98863CFB63e6381505b_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0x18A436179529F817F243e9e4a7EEAbE49e71C610",
			"base_name": "Chocolate Cake",
			"base_symbol": "COLACAKE",
			"base_address": "0x0c5cE2b5793a1836A716B98863CFB63e6381505b",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "0",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "1947090347722756.001904272374068",
			"liquidity_BNB": "4595540927963.96743351384226311"
		},
		"0x41d514f9748c264782aaF041D80B6b68172BF234_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0x14c429B0b11258Fde5c2191240d68EF1E84024C4",
			"base_name": "PolkaFantasy Token",
			"base_symbol": "POLKAFAN",
			"base_address": "0x41d514f9748c264782aaF041D80B6b68172BF234",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "0",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "1913987718638929.315376870163234",
			"liquidity_BNB": "4302512040502.145871908608781953"
		},
		"0x5D153bE4e8aBd9Ac1d4ae01b0De338335B2Ed162_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0x77d874b5F80F977490726A9141C15f80c5C27A98",
			"base_name": "Rangers PUBG Token",
			"base_symbol": "RPG",
			"base_address": "0x5D153bE4e8aBd9Ac1d4ae01b0De338335B2Ed162",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "0",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "1906366505891142.784996991218083",
			"liquidity_BNB": "3960537540830.953078296582317748"
		},
		"0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c_0xEdf01772b17316cad4Ab83E451606220E01da7a2": {
			"pair_address": "0x5269412754A3340038237eb1028359a0816BdA0f",
			"base_name": "Wrapped BNB",
			"base_symbol": "WBNB",
			"base_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"quote_name": "DogeVault Token",
			"quote_symbol": "DOGEVAULT",
			"quote_address": "0xEdf01772b17316cad4Ab83E451606220E01da7a2",
			"price": "9.56387844011806893155943476738940481603062109041201963e+33",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "1886983999090702.499004600132915",
			"liquidity_BNB": "4402594097523.93809728464132685"
		},
		"0x3b5D9E529BD8A25aFe6AF706AbB18ef5d3f5c87F_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0xb959be5a6B718A4C0204155b03376Fe2bE1d6998",
			"base_name": "BinaDoge",
			"base_symbol": "BINADOGE",
			"base_address": "0x3b5D9E529BD8A25aFe6AF706AbB18ef5d3f5c87F",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "0",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "1878356626202153.972137947536156",
			"liquidity_BNB": "6180137687979.312794094651813265"
		},
		"0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c_0xcE56278568005185eafcd87118945272A0CF2558": {
			"pair_address": "0xf2C46fB91c581f3357034e9a3e1218886c750167",
			"base_name": "Wrapped BNB",
			"base_symbol": "WBNB",
			"base_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"quote_name": "Rise Cake",
			"quote_symbol": "RiseCAKE",
			"quote_address": "0xcE56278568005185eafcd87118945272A0CF2558",
			"price": "1.08286658552439458332335858986898991459297831050455366e+33",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "1868193942467793.326972958881576",
			"liquidity_BNB": "5611218874642.64383724908571351"
		},
		"0x7eC2edA8B38489fD6cdA7F9eFA826AC4c9CbF588_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0xBb566361e6C8a6480909623c40b82da31eba72CB",
			"base_name": "DogeToys Token",
			"base_symbol": "DOGETOY",
			"base_address": "0x7eC2edA8B38489fD6cdA7F9eFA826AC4c9CbF588",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "0",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "1867969025833894.594156075857466",
			"liquidity_BNB": "4671983860415.462434614303707435"
		},
		"0x6bBe1B6fF989b040856120A66D0AbCeF001E66fe_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0x6EF563D382b3B07B3Ed363F50B71D01852A37B5D",
			"base_name": "DaikokutenSama 7LuckyGods",
			"base_symbol": "DKKS",
			"base_address": "0x6bBe1B6fF989b040856120A66D0AbCeF001E66fe",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "0",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "1835021307366841.351227036978026",
			"liquidity_BNB": "4603192349301.96771850812610529"
		},
		"0x7250EC91121bEe74CA0E3CF58Fbd61F3b72bD417_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0x118F2f2D460F42575aB73d5EF06E5E0Ec8DA504a",
			"base_name": "DeRaceNFT",
			"base_symbol": "DERC",
			"base_address": "0x7250EC91121bEe74CA0E3CF58Fbd61F3b72bD417",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "0",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "1833176065495426.63341708416458",
			"liquidity_BNB": "4137779836489.467165098967292455"
		},
		"0xb18947ca2724840EB181E17a3E79662d859a986b_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0xCFa2F7A0C98Cf6C0C24aC4A6689c5754C7C5584F",
			"base_name": "Volka Doge",
			"base_symbol": "VOLKADOGE",
			"base_address": "0xb18947ca2724840EB181E17a3E79662d859a986b",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "0",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "1778793315410992.30245229263504",
			"liquidity_BNB": "4428606116921.29680778659227913"
		},
		"0x909601FB5D9e021F559f0AcDD90D2738a49F6cEB_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0x8158bb94519f77C792403F1fB28dB458d7EcC2C1",
			"base_name": "HalfPizza Cake",
			"base_symbol": "HALFCAKE",
			"base_address": "0x909601FB5D9e021F559f0AcDD90D2738a49F6cEB",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "0",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "1737297628729667.549767925211341",
			"liquidity_BNB": "4384815512162.654668911173214647"
		},
		"0x486fFeef815A14804CcE346F5c60a4cC7369eD5e_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0x0aa4702425fa6512Bd15dbd1939Edf29bBeF1dbE",
			"base_name": "PORNHUB TOKEN",
			"base_symbol": "PORNHUB",
			"base_address": "0x486fFeef815A14804CcE346F5c60a4cC7369eD5e",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "0",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "1632303639584918.87802928050912",
			"liquidity_BNB": "5277967165330.564129212703892394"
		},
		"0x4aBdcC312A858Ae0a918ab461DC5802612Cc471A_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0x309D27938Eb6eD242d58D4b6aE016B92c382639a",
			"base_name": "Decubate",
			"base_symbol": "DCB",
			"base_address": "0x4aBdcC312A858Ae0a918ab461DC5802612Cc471A",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "0",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "1618502435372562.564937577111412",
			"liquidity_BNB": "5000199801996.99332482294212799"
		},
		"0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c_0xC666bB69965f934B86a57cCb4123e8530BA12BC3": {
			"pair_address": "0x05Dd7C7a62FF92512569dd0aEE010a2537E3c01d",
			"base_name": "Wrapped BNB",
			"base_symbol": "WBNB",
			"base_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"quote_name": "Virtual Rise",
			"quote_symbol": "VRISE",
			"quote_address": "0xC666bB69965f934B86a57cCb4123e8530BA12BC3",
			"price": "2.21642878280207669803571898051067130889105024187909471e+33",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "1602220700589569.564779860689796",
			"liquidity_BNB": "5881543101881.38289357473708103"
		},
		"0x04aF6bb8a1A4377BEb2419E927fbb66477836a68_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0x8Dae07186c4Ed1c4915c5B6492eFeB6da78979d6",
			"base_name": "Povo.finance",
			"base_symbol": "POVO",
			"base_address": "0x04aF6bb8a1A4377BEb2419E927fbb66477836a68",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "0",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "1568533370699939.996136056393635",
			"liquidity_BNB": "3497313746666.00129265303181442"
		},
		"0x4DDBdA62516E323573041bc31bf42e52B8832d57_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0x5079b234e4aB4FE21e3E2626a12b7bf39419d199",
			"base_name": "Energy Monster NFT",
			"base_symbol": "EGMON",
			"base_address": "0x4DDBdA62516E323573041bc31bf42e52B8832d57",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "0",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "1481633330342035.024931274182306",
			"liquidity_BNB": "3878273411120.08173811931145474"
		},
		"0x72F8BaaF423A9f40a4Dff933939eF74feF3d5372_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0x68e2c547f46dDea0c85B542885DA070a075633a0",
			"base_name": "BinaShiba",
			"base_symbol": "BINASHIB",
			"base_address": "0x72F8BaaF423A9f40a4Dff933939eF74feF3d5372",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "0",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "1446386768206698.39549807146325",
			"liquidity_BNB": "4773868253875.10873455983010076"
		},
		"0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c_0xc61C804F792dde3bD20a137731FF57Fe79592D2A": {
			"pair_address": "0xf2bFe5f7CE563Eb3458dEC751e673dad7dfbF41d",
			"base_name": "Wrapped BNB",
			"base_symbol": "WBNB",
			"base_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"quote_name": "AliceFarming Play2Earn",
			"quote_symbol": "ALICEFARM",
			"quote_address": "0xc61C804F792dde3bD20a137731FF57Fe79592D2A",
			"price": "6.13902348362973235660652258469817627943428323986635292e+33",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "1411371619979223.99529790268269",
			"liquidity_BNB": "3348135616932.00936603028342123"
		},
		"0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c_0xeD3e3aa53e952912EDb2DB1E698F0ffb42110685": {
			"pair_address": "0x6a3C74E507E2C8e8BFBD01696C1d1f72B2369Db2",
			"base_name": "Wrapped BNB",
			"base_symbol": "WBNB",
			"base_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"quote_name": "Mecha Shiba Inu",
			"quote_symbol": "MECHASHIB",
			"quote_address": "0xeD3e3aa53e952912EDb2DB1E698F0ffb42110685",
			"price": "1.93366114388312082608489736384018800978019972840870689e+33",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "1390801981624632.572178730799495",
			"liquidity_BNB": "4948122904482.464039222641899105"
		},
		"0xa4Ac5A958B02ae1F2D2F636a515e2d2081F621C9_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0xe407A72E620C5932667564E496fB243906E71449",
			"base_name": "Mac Donald Token",
			"base_symbol": "MAC",
			"base_address": "0xa4Ac5A958B02ae1F2D2F636a515e2d2081F621C9",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "0",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "1374409193163741.056944519754257",
			"liquidity_BNB": "3876043695957.902262521582048733"
		},
		"0x4c791555D9835fA0B54CaFCa39A03BAA4298B20B_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0x03cC8a2D3d6472dc61fC263285A5216d39537a07",
			"base_name": "Evo Corgi Doge",
			"base_symbol": "EVODOGE",
			"base_address": "0x4c791555D9835fA0B54CaFCa39A03BAA4298B20B",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "0",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "1367738572879456.296640375728462",
			"liquidity_BNB": "4729119660212.091604487151322786"
		},
		"0x9F6182243C8fAA8fF08b19Ea54052DEc487924A9_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0xAb0552439A083E6a71a2D197d7626BaeF4387dD4",
			"base_name": "Kusa Coin",
			"base_symbol": "KUSA",
			"base_address": "0x9F6182243C8fAA8fF08b19Ea54052DEc487924A9",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "0",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "1366302289024174.164473198293733",
			"liquidity_BNB": "4747483446132.67683722772726373"
		},
		"0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c_0xe74a4Fa9bFAcfa87CB7cCceDd76ECdAa255dc97c": {
			"pair_address": "0x458796D0e708464C68037093416306ded99Ba6ca",
			"base_name": "Wrapped BNB",
			"base_symbol": "WBNB",
			"base_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"quote_name": "Theta DOGE",
			"quote_symbol": "DOGETHETA",
			"quote_address": "0xe74a4Fa9bFAcfa87CB7cCceDd76ECdAa255dc97c",
			"price": "5.3531691464158811466651083050183875150494179522741331e+32",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "1364382226265004.246175422118736",
			"liquidity_BNB": "3892292765341.965308463111388255"
		},
		"0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c_0xD2ecFdEe8fef5e258C05814049487Cdc197029F6": {
			"pair_address": "0xceB3feAEbA3373034E6AA2fBBfdaec574D99616c",
			"base_name": "Wrapped BNB",
			"base_symbol": "WBNB",
			"base_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"quote_name": "Ares Corgi Doge",
			"quote_symbol": "ARESCORGI",
			"quote_address": "0xD2ecFdEe8fef5e258C05814049487Cdc197029F6",
			"price": "1.25282963698447525314173526304948395956023988924306475e+33",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "1258194351281313.619654913297808",
			"liquidity_BNB": "4289971306737.823556306218546745"
		},
		"0x850791628aD65eE1E7D5dD60a724BEb7f4D710F6_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0xf6608cC6DA281cD0f4F70B951BB8Ab1Cb4EF06DD",
			"base_name": "Seda Corgi Doge",
			"base_symbol": "SEDADOGE",
			"base_address": "0x850791628aD65eE1E7D5dD60a724BEb7f4D710F6",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "0",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "1232701118459321.602233640570681",
			"liquidity_BNB": "4276792630610.153855111119788793"
		},
		"0xB58d3472353EdffB1a2B17dfEd3d73C9CE0C5fD6_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0xa390C56A0617eA0F832A076d8AdbA9C56f402f25",
			"base_name": "Ultra Shit",
			"base_symbol": "UltraShit",
			"base_address": "0xB58d3472353EdffB1a2B17dfEd3d73C9CE0C5fD6",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "0",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "1201251534166052.913807756814052",
			"liquidity_BNB": "3956408882716.80123968809695729"
		},
		"0x9C640BAc5896C3D4F8cb45792FCE466c820D107f_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0x595210bCc88Dc3ae9d9242d8F7f6867A743a204E",
			"base_name": "Supper Cake",
			"base_symbol": "SUPPERCAKE",
			"base_address": "0x9C640BAc5896C3D4F8cb45792FCE466c820D107f",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "0",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "1134134216829423.089138436466161",
			"liquidity_BNB": "3639600430968.143048636147139096"
		},
		"0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c_0xC1d3CFC0811a2a7a14549d9A86dDd579E0976436": {
			"pair_address": "0xa399ee3Cbcd52aF110615C74035f55b94E114441",
			"base_name": "Wrapped BNB",
			"base_symbol": "WBNB",
			"base_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"quote_name": "GunnyGaming NFT",
			"quote_symbol": "GUNNY",
			"quote_address": "0xC1d3CFC0811a2a7a14549d9A86dDd579E0976436",
			"price": "4.07738189099071284521792708954547948049816375514281197e+33",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "1120107011322009.895371467045773",
			"liquidity_BNB": "2894637439301.644297438851298376"
		},
		"0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c_0xD9129d4394F2FB512bd47aB40fE4cA7CA34b257F": {
			"pair_address": "0xDA061d372E6C46Aa9E24A5b30274295559759f75",
			"base_name": "Wrapped BNB",
			"base_symbol": "WBNB",
			"base_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"quote_name": "Easy Corgi Doge",
			"quote_symbol": "EASYDOGE",
			"quote_address": "0xD9129d4394F2FB512bd47aB40fE4cA7CA34b257F",
			"price": "1.07006475635518911809318508159332582291976452642922017e+33",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "1087348356013255.686206573474751",
			"liquidity_BNB": "3661384198640.16995844494713525"
		},
		"0x35a15e9B1392a745FB7943debF1F9F7EF9599391_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0x5FAEAdFb79D6dB772125a5bDb8F490fD9D11f46b",
			"base_name": "FOHO.io",
			"base_symbol": "FOHO",
			"base_address": "0x35a15e9B1392a745FB7943debF1F9F7EF9599391",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "0",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "1079874751189657.3416906287459565",
			"liquidity_BNB": "3402730113124.50825079798478563"
		},
		"0x4de7F1f0d8F5b6795f1a7f016bea714D3f3d83FB_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0x8Bb3F3197d91A7A869A435b660cE8bf12fdC7BCE",
			"base_name": "BornBadBoys",
			"base_symbol": "BOY",
			"base_address": "0x4de7F1f0d8F5b6795f1a7f016bea714D3f3d83FB",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "0",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "1067562995344703.15980073039035",
			"liquidity_BNB": "3618832276193.00170190436510407"
		},
		"0x6A208be9C67Ce857fE155b4f6F7f16B10554E014_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0x9190115c921Bd26a4D4000fa5f44eF927b1c0E6a",
			"base_name": "Baby Shepherd Doge",
			"base_symbol": "BSDOGE",
			"base_address": "0x6A208be9C67Ce857fE155b4f6F7f16B10554E014",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "0",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "1059759894315302.839620515044041",
			"liquidity_BNB": "3272239884584.682769847325864567"
		},
		"0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c_0xFB0734e92EdB936883f78a02a169c3C3CA8c93f1": {
			"pair_address": "0x33743e46F9D2F91f5EB98Af8d4BCbbc9a38205e5",
			"base_name": "Wrapped BNB",
			"base_symbol": "WBNB",
			"base_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"quote_name": "Casini Token",
			"quote_symbol": "CSN",
			"quote_address": "0xFB0734e92EdB936883f78a02a169c3C3CA8c93f1",
			"price": "9.6651049340327927803932979838512241149990866011912664e+32",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "1032742681139668.443807448044715",
			"liquidity_BNB": "3288522141020.514629295705333473"
		},
		"0x9013eA902ae7d9Eb0508a3c037b415310d658fdF_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0x3BBfC391eeE5FE051B5Ef8Cf1Ab402B271B78C9E",
			"base_name": "SteakBank Finance",
			"base_symbol": "SBF",
			"base_address": "0x9013eA902ae7d9Eb0508a3c037b415310d658fdF",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "0",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "1026561048852048.389139755784293",
			"liquidity_BNB": "2880469424995.41074826005967184"
		},
		"0x3AC47281542aF6462638608e98c241b05CaC55CD_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0x3ce5CCd93949E3F1C1FdC4f6A9927ee02C6a06a4",
			"base_name": "Doge Inu Daddy",
			"base_symbol": "🐶DINU",
			"base_address": "0x3AC47281542aF6462638608e98c241b05CaC55CD",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "0",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "945192768172318.607955631242819",
			"liquidity_BNB": "2320467266845.62192800863099002"
		},
		"0x23fCfbb268FdeC1eCD16B6887533b20003dd671f_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0x976A6c7cD5B7c1cf294f82EEb2CEb549eA33FfE8",
			"base_name": "Money Hound Doge",
			"base_symbol": "MONEYDOGE",
			"base_address": "0x23fCfbb268FdeC1eCD16B6887533b20003dd671f",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "0",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "935779160681630.440072273666897",
			"liquidity_BNB": "2320346065088.05151016287384666"
		},
		"0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c_0xBc0A2eceA89Ecb5b05A207C34fD0B47a1cB6A3D7": {
			"pair_address": "0xc467810e9feBaf1B524831b9649d0E04C3B46455",
			"base_name": "Wrapped BNB",
			"base_symbol": "WBNB",
			"base_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"quote_name": "Venus Shiba Inu",
			"quote_symbol": "VSHI",
			"quote_address": "0xBc0A2eceA89Ecb5b05A207C34fD0B47a1cB6A3D7",
			"price": "2.4908505319201409442382631500352643907642807257276949e+33",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "892618194411345.905365355498382",
			"liquidity_BNB": "2968315170187.616200913960372833"
		},
		"0x5Da0495B10B7789f0e0cd66427aE8b7382218000_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0xD1252ee28743aF0E7cdA097C656DB44e74C3116A",
			"base_name": "BattleSeal",
			"base_symbol": "SEAL",
			"base_address": "0x5Da0495B10B7789f0e0cd66427aE8b7382218000",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "0",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "832543538000636.249081126258997",
			"liquidity_BNB": "1854346075380.525676620529264732"
		},
		"0x5CFf7a56F863e8c99738D031355E81f7BE423255_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0x434fb0387407d88C0C486D2326dcc2543C81473F",
			"base_name": "BabyPepe",
			"base_symbol": "BABYPEPE",
			"base_address": "0x5CFf7a56F863e8c99738D031355E81f7BE423255",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "0",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "830302970338504.112126008248596",
			"liquidity_BNB": "2234240283778.212879757900310006"
		},
		"0xAAE4ee70b370b8490C2776Ba9A1BD0CC6e078E6A_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0xDEA43439C742CcA7a65EFD235C4DBA07d455e1DB",
			"base_name": "Jungle Doge Coin",
			"base_symbol": "JungleDoge",
			"base_address": "0xAAE4ee70b370b8490C2776Ba9A1BD0CC6e078E6A",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "0",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "816688208251930.914954494787609",
			"liquidity_BNB": "1926808397758.775448413000772278"
		},
		"0x06dbD1eC39c008AB0FE2AE72917156C95176A6A9_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0x8cF8659e34931D477D9F030bb2F46E4B36C2DbD8",
			"base_name": "Jungle Doge Token",
			"base_symbol": "JungleDoge",
			"base_address": "0x06dbD1eC39c008AB0FE2AE72917156C95176A6A9",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "0",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "799975062053410.139547178846015",
			"liquidity_BNB": "1930559222793.906238371523891709"
		},
		"0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c_0xbCc8C619eBa684b167B0c657AC5187CA2D8eA1dc": {
			"pair_address": "0x2dF4597EFc6b704c1aF4337A1c54bb2d1879121A",
			"base_name": "Wrapped BNB",
			"base_symbol": "WBNB",
			"base_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"quote_name": "Elite Doge Token",
			"quote_symbol": "EDOGE",
			"quote_address": "0xbCc8C619eBa684b167B0c657AC5187CA2D8eA1dc",
			"price": "3.4888475451478400780180289443681921149698185171702676e+33",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "793903977194145.325369541216626",
			"liquidity_BNB": "1938514985218.908190329978122232"
		},
		"0xB535779CFD889A9612a7cbEE5a74Ff6A6BB7C03B_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0x590a3ff0e9000F8c83F5F1C139CAdFb6cf4C489c",
			"base_name": "SnowDoge",
			"base_symbol": "SNOWDOGE",
			"base_address": "0xB535779CFD889A9612a7cbEE5a74Ff6A6BB7C03B",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "0",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "790325829288626.286381641631824",
			"liquidity_BNB": "1950265478624.42485065140917797"
		},
		"0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c_0xD3459bAa98f85a0a2f5D9e2B8e47b41C2A6c2bb6": {
			"pair_address": "0x90Ff1BDB50b1BE34D44Da5a52Dd6669212671416",
			"base_name": "Wrapped BNB",
			"base_symbol": "WBNB",
			"base_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"quote_name": "Doge Infinity",
			"quote_symbol": "DOGEINF",
			"quote_address": "0xD3459bAa98f85a0a2f5D9e2B8e47b41C2A6c2bb6",
			"price": "1.34084856829626589688387165008426238594913882876872397e+33",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "783412026923539.470135318597975",
			"liquidity_BNB": "1941624730529.266494992755762002"
		},
		"0x6DaE5174c4092B0f013064a72c42b0CdAaE87723_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0xD0Cd76746a084B538631BA4a843bAB8a31351444",
			"base_name": "JunkYardDogs",
			"base_symbol": "JYDOGE",
			"base_address": "0x6DaE5174c4092B0f013064a72c42b0CdAaE87723",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "0",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "781541744542069.968540563943052",
			"liquidity_BNB": "1755877329122.373288156556226377"
		},
		"0xac4BAA2279099567ec66ED906CA5e81348E4e794_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0x281A2a646c2a86AA2254Cec65A7dF89C407C9b7e",
			"base_name": "TrueDeck",
			"base_symbol": "TDP",
			"base_address": "0xac4BAA2279099567ec66ED906CA5e81348E4e794",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "0",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "758924566992654.56712428977408",
			"liquidity_BNB": "1871339491764.829287859566659779"
		},
		"0xB20CC4cBb7BF4F15E0aD6b92D0d2f20eaa48F4E9_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0xEC9926E02BDa685290b6Ca012d0D24138297dA63",
			"base_name": "CryptoMarginswap",
			"base_symbol": "CMFI",
			"base_address": "0xB20CC4cBb7BF4F15E0aD6b92D0d2f20eaa48F4E9",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "0",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "750014590727105.23816851162872",
			"liquidity_BNB": "1509978464150.686543496403118403"
		},
		"0x12471225F9C485e19bbdbCE71490d36eB13CA8c6_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0x3f65806c55a0c71428C65Fd87FD651d7901CA06c",
			"base_name": "Baby BNBSafeMars",
			"base_symbol": "BNBSafeMars",
			"base_address": "0x12471225F9C485e19bbdbCE71490d36eB13CA8c6",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "0",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "748315016110626.588508116654768",
			"liquidity_BNB": "2496603402681.27301412184243824"
		},
		"0x91da6C8d681a8f6D885315f197519E430474DBF4_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0x6637629356f9625160B75aA6c6e839E1b25710Eb",
			"base_name": "Folgory Token",
			"base_symbol": "FLG",
			"base_address": "0x91da6C8d681a8f6D885315f197519E430474DBF4",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "0",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "747822783296649.312860652363882",
			"liquidity_BNB": "1838385598245.197854049054360121"
		},
		"0x3ce2E01b923aBDd9867b057aEE6D4f1f275E26dB_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0x2c1A66A8348C903e17982E9e901F130F35e687B4",
			"base_name": "Simba Doge",
			"base_symbol": "SDOGE",
			"base_address": "0x3ce2E01b923aBDd9867b057aEE6D4f1f275E26dB",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "0",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "747324612713285.625634574960721",
			"liquidity_BNB": "2370513370172.030452612977790276"
		},
		"0x30352FBff08380b2fFA19795EBe50a1Edbd6641a_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0x204a6A19872393ACa805E86eAa0199cb22e4b9A1",
			"base_name": "Dog Shield",
			"base_symbol": "DOGSHI",
			"base_address": "0x30352FBff08380b2fFA19795EBe50a1Edbd6641a",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "0",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "738654052360859.50241800200737",
			"liquidity_BNB": "1625939086432.639015203867325523"
		},
		"0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c_0xBff670131627e94dD4B6fD4eE7361CC767113970": {
			"pair_address": "0x7a2f7F9d4329CB821D31f9Fd9c536539FbA387ED",
			"base_name": "Wrapped BNB",
			"base_symbol": "WBNB",
			"base_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"quote_name": "Crazy Defense Heroes",
			"quote_symbol": "TOWER",
			"quote_address": "0xBff670131627e94dD4B6fD4eE7361CC767113970",
			"price": "2.90833361888045621027816953691904976604320278753028159e+33",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "737931954411853.805565113538439",
			"liquidity_BNB": "1479228649022.47073142580164519"
		},
		"0xb0e9F71CB259babB7c499eC14629653ea94E61f6_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0x9F47d38be9412D0848124871c051C464763E7E85",
			"base_name": "RooCoin",
			"base_symbol": "ROO",
			"base_address": "0xb0e9F71CB259babB7c499eC14629653ea94E61f6",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "0",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "735647675170639.546817879672722",
			"liquidity_BNB": "1865941707005.245658319738578722"
		},
		"0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c_0xe0750Bd282A5B787Ac1423FC9108fA7AEd95758c": {
			"pair_address": "0xB10C1A7ddf2caCa14D1D897AFE3761f5086bD0b8",
			"base_name": "Wrapped BNB",
			"base_symbol": "WBNB",
			"base_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"quote_name": "Doge Proof",
			"quote_symbol": "DOGEPROOF",
			"quote_address": "0xe0750Bd282A5B787Ac1423FC9108fA7AEd95758c",
			"price": "3.27170484222116337221824325957037677225822056885120312e+33",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "735392944061289.190802682449239",
			"liquidity_BNB": "1648143323855.350779787609957082"
		},
		"0x38bfceFdee88496A9A73e191ABC3ff19C4B0E1f5_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0xDFBE4Dd7F52Aba6205414AcD7843F944c47446cc",
			"base_name": "DOGE NFT",
			"base_symbol": "DOGENFT",
			"base_address": "0x38bfceFdee88496A9A73e191ABC3ff19C4B0E1f5",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "0",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "724895973136280.047131270676962",
			"liquidity_BNB": "1719595099600.266720188882192807"
		},
		"0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c_0xc34498F42E8bb0c644fE447a0070C8b093962464": {
			"pair_address": "0xFD3c806D5E42a2d296Ae60BE9bC183248aD8d090",
			"base_name": "Wrapped BNB",
			"base_symbol": "WBNB",
			"base_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"quote_name": "KryptoBoomer Token",
			"quote_symbol": "KBOOMER",
			"quote_address": "0xc34498F42E8bb0c644fE447a0070C8b093962464",
			"price": "2.90775907855567884322456789719888548641854270334371992e+33",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "718647273347564.80735381835934",
			"liquidity_BNB": "1479218844130.692403906183859007"
		},
		"0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c_0xf04B81EEDAa6D8b4314c58D31500EdD210519C69": {
			"pair_address": "0x1fA63D4c75f32B3DE3BaC28931FED1c4d0546af8",
			"base_name": "Wrapped BNB",
			"base_symbol": "WBNB",
			"base_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"quote_name": "Doge Passport",
			"quote_symbol": "DOGEPP",
			"quote_address": "0xf04B81EEDAa6D8b4314c58D31500EdD210519C69",
			"price": "3.27184745210695068865547724523115751771862280347441433e+33",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "708891277269959.030756262405345",
			"liquidity_BNB": "1653379457738.042401445515114778"
		},
		"0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c_0xe20E75D279b3f46948451C96D458bFe91043D4AD": {
			"pair_address": "0x7d65550BEC27b57c9beccf4405f514dd1DFb3b74",
			"base_name": "Wrapped BNB",
			"base_symbol": "WBNB",
			"base_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"quote_name": "PolkaUniswap Token",
			"quote_symbol": "PUNFI",
			"quote_address": "0xe20E75D279b3f46948451C96D458bFe91043D4AD",
			"price": "2.75550845355587504575898168317143301163914756723548918e+33",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "705892621152483.41465203566589",
			"liquidity_BNB": "1417224267983.520119313177298058"
		},
		"0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c_0xE8ff76Ac7691F4b6F4b2C468378E89Ac4515F169": {
			"pair_address": "0x0b8D393608f39A9A0b16fC9A6E097F96395394eA",
			"base_name": "Wrapped BNB",
			"base_symbol": "WBNB",
			"base_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"quote_name": "DOGEYES",
			"quote_symbol": "DOGEYES",
			"quote_address": "0xE8ff76Ac7691F4b6F4b2C468378E89Ac4515F169",
			"price": "3.32091676659192319391942401247156742315242187160405127e+33",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "695967420935090.857445900809786",
			"liquidity_BNB": "1622246009370.290001559134010264"
		},
		"0x188214A11e931c0Ab807f9ccfaEE72F9d57BD688_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0xC27bD8A9888D40cB7C0F82C391B7710579246d45",
			"base_name": "LonelyDoge",
			"base_symbol": "LOD",
			"base_address": "0x188214A11e931c0Ab807f9ccfaEE72F9d57BD688",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "0",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "693329553461986.140290703774633",
			"liquidity_BNB": "1546801177069.29260754139895831"
		},
		"0xa0D2d109F70aE7412e21406E00129bbb439f0Ddc_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0x62B1cF41293265f1A3aF3C6A7e9168DC29595772",
			"base_name": "Doge Success",
			"base_symbol": "DOGES",
			"base_address": "0xa0D2d109F70aE7412e21406E00129bbb439f0Ddc",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "0",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "683636492830060.439015723513058",
			"liquidity_BNB": "1609786645609.834471174218755123"
		},
		"0xba4230A9d8e9674061B7bCC9830be1294b0c2D2B_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0xA70a958525b8dEc7607eFbBA721430d0A7f7eF40",
			"base_name": "PiplCoin",
			"base_symbol": "PIPL",
			"base_address": "0xba4230A9d8e9674061B7bCC9830be1294b0c2D2B",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "0",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "674243967773416.506941465676854",
			"liquidity_BNB": "1615325473014.410280226397790364"
		},
		"0x5724f4b24f0D528F23AF31E7ff651fcB973e2b25_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0xacf5B4CB94ac7955ec1dd80D0A0016824B44c686",
			"base_name": "Doge Bond 007",
			"base_symbol": "DBOND",
			"base_address": "0x5724f4b24f0D528F23AF31E7ff651fcB973e2b25",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "0",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "667939375177149.779756588902725",
			"liquidity_BNB": "1363351433442.866909013283719903"
		},
		"0x60832780A29f0eF02AA06b7279aD4f9bd00039e8_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0x248A1767645c799C3bc2Cf40dC0cE5301852EA3A",
			"base_name": "EXchange Doge",
			"base_symbol": "EXDOGE",
			"base_address": "0x60832780A29f0eF02AA06b7279aD4f9bd00039e8",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "0",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "665626405893115.498480394230235",
			"liquidity_BNB": "1664139665398.016945587324155917"
		},
		"0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c_0xC1669031f0Fa97CC75cf3eB5724b6AE9b78c5392": {
			"pair_address": "0x8eCe22828fFf0deA1e54D30FFbaC1560B9EBa60F",
			"base_name": "Wrapped BNB",
			"base_symbol": "WBNB",
			"base_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"quote_name": "CryptoCakeInu Token",
			"quote_symbol": "CakeINU",
			"quote_address": "0xC1669031f0Fa97CC75cf3eB5724b6AE9b78c5392",
			"price": "2.61790114150402360837271307944019128706246680514442204e+33",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "665138510273376.495099518269804",
			"liquidity_BNB": "1345816090314.342199618723177933"
		},
		"0xaf3C53F02c8f9a9f19D9D38fF18fd65501372E70_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0x16c706604a359Eb1c524c4135e240Dc4d3e4107c",
			"base_name": "Super Jungle Doge",
			"base_symbol": "SuperDOGE",
			"base_address": "0xaf3C53F02c8f9a9f19D9D38fF18fd65501372E70",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "0",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "665100797760871.955186557103089",
			"liquidity_BNB": "1671408996366.60724660920671297"
		},
		"0x0A022A9379BAa8D2120e8A93A872de9A9f50e3F4_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0x621e58D16201F30C847450f005feDfF7c33ea721",
			"base_name": "SCC DIGforIT",
			"base_symbol": "SCC",
			"base_address": "0x0A022A9379BAa8D2120e8A93A872de9A9f50e3F4",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "0",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "664107876512764.13429997902952",
			"liquidity_BNB": "1398887250567.792032136833286132"
		},
		"0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c_0xE827Bcbb4Fa3AA8636145950b8F8be56bcD1Aa5f": {
			"pair_address": "0xfA187e730c0efD80B8C4C66c66681FCef9553b3B",
			"base_name": "Wrapped BNB",
			"base_symbol": "WBNB",
			"base_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"quote_name": "OK Boomer Token",
			"quote_symbol": "OKBOOMER",
			"quote_address": "0xE827Bcbb4Fa3AA8636145950b8F8be56bcD1Aa5f",
			"price": "2.90890862830151254404188146630534748012549557752745259e+33",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "663635574846735.080667498886762",
			"liquidity_BNB": "1477794327466.764379130661590455"
		},
		"0x8eb755A4D9949257C01069d2cBe7d78840B0CC62_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0x8949CBF9C429A03FD19FB4AC5828fF3Aa99B4545",
			"base_name": "MoonCast Token",
			"base_symbol": "MOONCAST",
			"base_address": "0x8eb755A4D9949257C01069d2cBe7d78840B0CC62",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "0",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "658379769306234.243589856235535",
			"liquidity_BNB": "1375988755138.477825639389113443"
		},
		"0x72c8b8bb2109A67eaA93C4Bc07da87EC0007c3c7_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0x292F1aaD6C5301B1486392e32bA576B53af8ebe1",
			"base_name": "LTCDOGE",
			"base_symbol": "LTCDOGE",
			"base_address": "0x72c8b8bb2109A67eaA93C4Bc07da87EC0007c3c7",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "0",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "657363834937124.62762042578633",
			"liquidity_BNB": "1610714594298.531610458894315683"
		},
		"0x5a79D877D4435f1a7c631345fc0Ecf8ED761f1BC_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0xAAB95b683A49Add2Dc33f3C6233987C11028a695",
			"base_name": "SEED DOGE",
			"base_symbol": "SEEDOGE",
			"base_address": "0x5a79D877D4435f1a7c631345fc0Ecf8ED761f1BC",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "0",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "651018620994629.0276890656100655",
			"liquidity_BNB": "1379917183887.44523305288744297"
		},
		"0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c_0xD6b0d0EEF1C05EEd156B3cF7329773194ec60AF2": {
			"pair_address": "0x7f082138fDCC84C12F8629105d8b887A334868D2",
			"base_name": "Wrapped BNB",
			"base_symbol": "WBNB",
			"base_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"quote_name": "Hero Floki",
			"quote_symbol": "HLOKI",
			"quote_address": "0xD6b0d0EEF1C05EEd156B3cF7329773194ec60AF2",
			"price": "3.48888972914744281274994016210807939262203400465353973e+33",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "646802519959570.405351560796271",
			"liquidity_BNB": "1643557575659.114080654528676132"
		},
		"0x3347f13968d78417930E9a6372Ee047c34AcCAA4_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0x083Ab878282944f468DE66C1C83aDEadD5C2dA17",
			"base_name": "specialDoge",
			"base_symbol": "SPEDOGE",
			"base_address": "0x3347f13968d78417930E9a6372Ee047c34AcCAA4",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "0",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "642564067266556.939472003439533",
			"liquidity_BNB": "1599483298726.62452918851452349"
		},
		"0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c_0xD52d57834aFeA39c92f1fD1038c925B7D3004E51": {
			"pair_address": "0xb47B9F3BEBf9c304f33cbf5C19E9446fAC46afCE",
			"base_name": "Wrapped BNB",
			"base_symbol": "WBNB",
			"base_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"quote_name": "Option Doge",
			"quote_symbol": "OPTIONDOGE",
			"quote_address": "0xD52d57834aFeA39c92f1fD1038c925B7D3004E51",
			"price": "3.48913070235853274747323088791306695756191331414293304e+33",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "621647308570262.861123269365318",
			"liquidity_BNB": "1617763115743.917379910151318994"
		},
		"0x28f7A5155B63e08BDC68992613Ee708E94CA4f07_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0x86b6016C5DeC2E038EC08f6291eBCb4C1318d3c4",
			"base_name": "Marine Doge",
			"base_symbol": "MARDOGE",
			"base_address": "0x28f7A5155B63e08BDC68992613Ee708E94CA4f07",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "0",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "580311100262347.087641694337068",
			"liquidity_BNB": "1318211703037.047238037309399706"
		},
		"0xBa6B349cbd21308648db4b34c5e753a733A81aFB_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0xA1036F8B8B939ca36b8a2Cad68e0927e3B4459BD",
			"base_name": "Venus BNB",
			"base_symbol": "VBNB",
			"base_address": "0xBa6B349cbd21308648db4b34c5e753a733A81aFB",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "0",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "577739990308918.027156594786284",
			"liquidity_BNB": "1896371150534.302190895802897517"
		},
		"0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c_0xcD5d3302F89d8602D01d894355e9AadFFb6DEd1d": {
			"pair_address": "0x74bbD857Cd406920dA98E86D63F75b784847Cc23",
			"base_name": "Wrapped BNB",
			"base_symbol": "WBNB",
			"base_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"quote_name": "MOONLIGHT SONATA",
			"quote_symbol": "MOONLIGHT",
			"quote_address": "0xcD5d3302F89d8602D01d894355e9AadFFb6DEd1d",
			"price": "5.5322356150270146533597147946657365429571927059841e+29",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "556560522450715.360820922521409",
			"liquidity_BNB": "1803068535933.034525759330797155"
		},
		"0x9eDeA699a38CB68CB04A5A4f520650178ce983fA_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0x85b83E16555cF82911Be5B55b6B78bC5EA3AEa53",
			"base_name": "Rocket1H Token",
			"base_symbol": "ROCKET1H",
			"base_address": "0x9eDeA699a38CB68CB04A5A4f520650178ce983fA",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "0",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "449874010007871.0850583254008437",
			"liquidity_BNB": "1132677146846.96411279721351499"
		},
		"0x91d17E20c67Bcfccabb389C0474bA159b34f1290_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0x7FE46f65c1bAA93dfFF2FbE4C62f76249A6809B5",
			"base_name": "TicTalk",
			"base_symbol": "TIC",
			"base_address": "0x91d17E20c67Bcfccabb389C0474bA159b34f1290",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "0",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "419745189647517.3822498200406",
			"liquidity_BNB": "1190749682355.717343330364875032"
		},
		"0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c_0xCE80ceAb33BA1f95f6F9927F67b8B66da7a8c26b": {
			"pair_address": "0x288C9ECf46DECdc6cc4B130c30d18877c59E8978",
			"base_name": "Wrapped BNB",
			"base_symbol": "WBNB",
			"base_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"quote_name": "Tratok Token",
			"quote_symbol": "TRAT",
			"quote_address": "0xCE80ceAb33BA1f95f6F9927F67b8B66da7a8c26b",
			"price": "5.23389748249509635126477298433917068419154473261808279e+33",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "416672484933320.013558995135851",
			"liquidity_BNB": "1049064159798.232588290802979334"
		},
		"0x58220169Fc25Fa5Ce4a0a3B65DC46d2538c1540D_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0x5BeDB991A328F45B152690556413986356b8907C",
			"base_name": "OptionRoom Doge",
			"base_symbol": "ROOMDOGE",
			"base_address": "0x58220169Fc25Fa5Ce4a0a3B65DC46d2538c1540D",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "0",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "413510226220148.0894227391957982",
			"liquidity_BNB": "1039636354907.0128671222455841805"
		},
		"0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c_0xF95d2C5E66ec87EA48D400Ca4CB42DEB8c43c3d0": {
			"pair_address": "0x5Bc10AEc0bFa8EB5cEa0691E607E0B3de58b051e",
			"base_name": "Wrapped BNB",
			"base_symbol": "WBNB",
			"base_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"quote_name": "OptionRoom",
			"quote_symbol": "ROOM",
			"quote_address": "0xF95d2C5E66ec87EA48D400Ca4CB42DEB8c43c3d0",
			"price": "5.23446252176155010659360202243677193003643775315516153e+33",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "412829007976010.6792519316183605",
			"liquidity_BNB": "1048640172180.1402865550199507896"
		},
		"0x889104053309C78362279D0Aa7673119919d8c1B_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0x6275A67AABdefA599405374e0bB75c1Fd25ebFc4",
			"base_name": "Alpaca Baby Token",
			"base_symbol": "APACA",
			"base_address": "0x889104053309C78362279D0Aa7673119919d8c1B",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "0",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "412316210533504.3079669315547107",
			"liquidity_BNB": "1067995135268.184769645269064656"
		},
		"0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c_0xe56e4A608e458603EcF54C461cA49e60E867d5e3": {
			"pair_address": "0x3F59D10810Be719A9f67d7be4B2E867027420DCa",
			"base_name": "Wrapped BNB",
			"base_symbol": "WBNB",
			"base_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"quote_name": "Razor Network",
			"quote_symbol": "RAZOR",
			"quote_address": "0xe56e4A608e458603EcF54C461cA49e60E867d5e3",
			"price": "5.31249046987051145028184940828508962456703162828069834e+33",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "410744664434520.2734525698087604",
			"liquidity_BNB": "1236513387536.922397505433018306"
		},
		"0x943aBb09997a2AFfEa3974B0dab8eaC1F866A1ca_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0x7466d30937e6B0481f61DcF3395B9773cE81cfe8",
			"base_name": "BBSCoin",
			"base_symbol": "BBS",
			"base_address": "0x943aBb09997a2AFfEa3974B0dab8eaC1F866A1ca",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "0",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "406704502596677.896053707423995",
			"liquidity_BNB": "1056803238822.5729774830567405716"
		},
		"0x806e918685266E1cE148a83808b7162B80D87cbE_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0xa624e020cFf50DB2Faa0d1bd8BC4c2AEaFCD9BfC",
			"base_name": "NFTXSwap",
			"base_symbol": "NFTX",
			"base_address": "0x806e918685266E1cE148a83808b7162B80D87cbE",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "0",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "405118355581769.6765186247275725",
			"liquidity_BNB": "1176238666112.957808388854290937"
		},
		"0x951fAac64e748E15997959B4595de0303BEA3Cbc_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0xB2f38C05405b90d6561C39452d1F72255Fb2a9Ba",
			"base_name": "Crust Network",
			"base_symbol": "CRU",
			"base_address": "0x951fAac64e748E15997959B4595de0303BEA3Cbc",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "0",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "399928147660712.091478679645626",
			"liquidity_BNB": "1215128748081.999700098412531697"
		},
		"0xA44F2689805C4D5742bddA74D2E7018185F147E9_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0x80D36d7E7c666592e6268e40D9bbeBE86f903179",
			"base_name": "Koto Doge",
			"base_symbol": "KDOGE",
			"base_address": "0xA44F2689805C4D5742bddA74D2E7018185F147E9",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "0",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "395694561598078.332649095022711",
			"liquidity_BNB": "1075954490265.741047058436227869"
		},
		"0x81ED36178bd7E96EbC3dCae076bA8857c54e4381_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0xcC29830A67F9C55eb4abF4a6017a537b435fC3d4",
			"base_name": "Darwinia Network",
			"base_symbol": "RING",
			"base_address": "0x81ED36178bd7E96EbC3dCae076bA8857c54e4381",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "0",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "392851264574306.8155939752026136",
			"liquidity_BNB": "1176804041664.826386591781126964"
		},
		"0x8DFCa562c6FCFe2aF90e68258314352aFC293EE0_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0x428d53394b72527c12Ba3d43a8A92e9CcCd1E310",
			"base_name": "DogeCash",
			"base_symbol": "DOGECASH",
			"base_address": "0x8DFCa562c6FCFe2aF90e68258314352aFC293EE0",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "0",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "387284738259739.0462285364021455",
			"liquidity_BNB": "1099586342213.104610807482531184"
		},
		"0xaCccA1F4B1D7c57358C2f770D0c28443d7aEb0D9_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0x73b7f6e5BcA79190AA53AC8C1DE980dC98565413",
			"base_name": "CyberDog",
			"base_symbol": "CYBERDOG",
			"base_address": "0xaCccA1F4B1D7c57358C2f770D0c28443d7aEb0D9",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "0",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "385412393426582.376246651569953",
			"liquidity_BNB": "993935258554.146583722352611805"
		},
		"0x3037C5CAA1684Df70DDbECDCaF7898167311ff10_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0x339e922B6D2366D95dB322Ffb39c8EE1D8d6cAD4",
			"base_name": "SwapRoom",
			"base_symbol": "ROOM",
			"base_address": "0x3037C5CAA1684Df70DDbECDCaF7898167311ff10",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "0",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "379813781815437.643261856130598",
			"liquidity_BNB": "952713126726.215020424917813797"
		},
		"0x93f3ABB72B773920FE3452E6571A61c3D8E8F375_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0xcB0a93b6eaF07293D34579f268561c0B29b1fc4D",
			"base_name": "Exchange Token",
			"base_symbol": "EXC",
			"base_address": "0x93f3ABB72B773920FE3452E6571A61c3D8E8F375",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "0",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "375486541326300.062006299071162",
			"liquidity_BNB": "981627925632.770014931692573596"
		},
		"0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c_0xF9C49A7C4ACd050064D31829d1C716A251890e5B": {
			"pair_address": "0x510dd497284286c8ffdA8E09B60a90ec910557F0",
			"base_name": "Wrapped BNB",
			"base_symbol": "WBNB",
			"base_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"quote_name": "Whale Token",
			"quote_symbol": "WHALE",
			"quote_address": "0xF9C49A7C4ACd050064D31829d1C716A251890e5B",
			"price": "5.31304685678953301925839567644521393609045172393589602e+33",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "369790819947374.7400344659987916",
			"liquidity_BNB": "1153169297448.508125778105160767"
		},
		"0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c_0xf1e999ECf1453a171438B4EF4c6Cc2E38c5d6cB7": {
			"pair_address": "0xBE0123c32a177C37cf5B11097e06c31e2d8230F7",
			"base_name": "Wrapped BNB",
			"base_symbol": "WBNB",
			"base_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"quote_name": "Hegic",
			"quote_symbol": "HEGIC",
			"quote_address": "0xf1e999ECf1453a171438B4EF4c6Cc2E38c5d6cB7",
			"price": "3.79651762293068338945910381444485494333424478356713247e+33",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "362841098684575.493934039297606",
			"liquidity_BNB": "1077659963379.032460348001064704"
		},
		"0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c_0xcbE921fE4688862B84f1EDB2b1829F31E5908fd5": {
			"pair_address": "0xC8881959fc9FF7D45B6dBcC176a4E86395f1e7A3",
			"base_name": "Wrapped BNB",
			"base_symbol": "WBNB",
			"base_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"quote_name": "Mobius Network ",
			"quote_symbol": "MOBI",
			"quote_address": "0xcbE921fE4688862B84f1EDB2b1829F31E5908fd5",
			"price": "3.7968897854592285930917345464527822962875606372790941e+33",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "362565489850868.5730900787116604",
			"liquidity_BNB": "1068964574313.460164593060217721"
		},
		"0x2CAcC8Ab7E2F5602a7F3EAF3Beb4b6C9aE9111a1_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0x964ddF120C28F4f673d3A36914E22492DBD66a47",
			"base_name": "DogeWarrior Token",
			"base_symbol": "DOGEWARIOR",
			"base_address": "0x2CAcC8Ab7E2F5602a7F3EAF3Beb4b6C9aE9111a1",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "0",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "360373684487044.532855746928502",
			"liquidity_BNB": "1024686675507.865976105913156558"
		},
		"0x950f8054FA19EdA8Ebd26AcFdf82F4b3f6d2450c_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0x321A2795e801E87b1f143e6e30fA162416524031",
			"base_name": "Bosco Doge Coin",
			"base_symbol": "BCDOGE",
			"base_address": "0x950f8054FA19EdA8Ebd26AcFdf82F4b3f6d2450c",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "0",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "357003792731985.273128335491416",
			"liquidity_BNB": "1152180997354.253747834164786107"
		},
		"0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c_0xdC22f3c6A82939dF1A747ca323357b40E42e8767": {
			"pair_address": "0xE909D322E54451Be2534CdCD29587366C979786a",
			"base_name": "Wrapped BNB",
			"base_symbol": "WBNB",
			"base_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"quote_name": "Binance Akita",
			"quote_symbol": "BinanceAKITA",
			"quote_address": "0xdC22f3c6A82939dF1A747ca323357b40E42e8767",
			"price": "8.7418275154319619479284753322316554772023360232485e+32",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "350955720200136.7118299328885387",
			"liquidity_BNB": "1152996865461.795848497107355004"
		},
		"0x13e43Dc74903fF56B89D1199e68f2Ed0f7ddEFF4_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0x8a978ed4D246A4328d79a5c723d6998D67f44c91",
			"base_name": "Raze Network Token",
			"base_symbol": "RAZE",
			"base_address": "0x13e43Dc74903fF56B89D1199e68f2Ed0f7ddEFF4",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "0",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "350123274598514.3017360486265415",
			"liquidity_BNB": "1091978403009.259110380508552245"
		},
		"0x9dbF636Cc8A71C5c66e15aa0DaD15996928D15F7_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0x723C78982F2073E2F4a23BA0fc4f0A8FC620155f",
			"base_name": "LocalCoinSwap Token",
			"base_symbol": "LCS",
			"base_address": "0x9dbF636Cc8A71C5c66e15aa0DaD15996928D15F7",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "0",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "345113697827837.612365896253806",
			"liquidity_BNB": "1068963819086.127144592116150938"
		},
		"0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c_0xef551b543502317b5E5d74632d6C98a57523B0D4": {
			"pair_address": "0xFb0E89577bb449FDb8417c1D07d7B76Bf6a125E4",
			"base_name": "Wrapped BNB",
			"base_symbol": "WBNB",
			"base_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"quote_name": "LABS Group Swap",
			"quote_symbol": "LABS",
			"quote_address": "0xef551b543502317b5E5d74632d6C98a57523B0D4",
			"price": "3.54390028822091295561886443271572530834785346065867739e+33",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "335815865134394.565087105567755",
			"liquidity_BNB": "947946638146.963184233548730223"
		},
		"0x5289c37299722C3d086924f7D24ABbC55f74F1e9_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0x8bBA7E53969c8730D040285A981F211e65E5dbCA",
			"base_name": "Feathercoin Swap",
			"base_symbol": "FTC",
			"base_address": "0x5289c37299722C3d086924f7D24ABbC55f74F1e9",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "0",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "331303527805361.3626599192169913",
			"liquidity_BNB": "937628389015.5443063235272671135"
		},
		"0xB888132d2946318E8Aa971A8CEf7eDfB18CF46aA_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0xC81D002B05CCcfCeD7e90D65591b8A78a83D11B2",
			"base_name": "Stafi",
			"base_symbol": "FIS",
			"base_address": "0xB888132d2946318E8Aa971A8CEf7eDfB18CF46aA",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "0",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "323058849120080.457130447883074",
			"liquidity_BNB": "956991273429.461735238585590682"
		},
		"0x82AB77f59076562B1788c3f801B4a185fd3Eb588_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0x36B2a0423bAFa240dDe15e4E44CD1007f1140932",
			"base_name": "ZECBACK",
			"base_symbol": "ZECBACK",
			"base_address": "0x82AB77f59076562B1788c3f801B4a185fd3Eb588",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "0",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "321340095689096.9555797881577037",
			"liquidity_BNB": "1062289039814.759675778177318614"
		},
		"0x88a5cC2c9e6805209FFF69ac2E52629E66BB9141_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0x8BDE939955A6376cC1F717023470c4Ea97624aaA",
			"base_name": "ShibaBack",
			"base_symbol": "ShibaBack",
			"base_address": "0x88a5cC2c9e6805209FFF69ac2E52629E66BB9141",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "0",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "309219506060213.8053894112813365",
			"liquidity_BNB": "1031603928611.942772987925682489"
		},
		"0x99B100D770B041B1044Ef3e3cB7C145E4B51f143_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0xCE73CF3325f11F6ae44D580888AA8155459c937b",
			"base_name": "Doge Network",
			"base_symbol": "DOGENET",
			"base_address": "0x99B100D770B041B1044Ef3e3cB7C145E4B51f143",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "0",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "288211756784759.047304491682088",
			"liquidity_BNB": "927529123749.351571981402067934"
		},
		"0x7D7F552E4dC434B9636c1b7f78E33f713a62AF71_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0xF976Dcd6919f630aeDda684d8110b2f9fC32b051",
			"base_name": "Swap Shield Network",
			"base_symbol": "SWAPSHIELD",
			"base_address": "0x7D7F552E4dC434B9636c1b7f78E33f713a62AF71",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "0",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "286134446902259.2497741092231416",
			"liquidity_BNB": "911923701413.413202177403030162"
		},
		"0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c_0xE3C9F28A15719c365Be3530bCa86871e54E4Dcd0": {
			"pair_address": "0x41849B4158cd61C05691BFE769569CF82C91DB36",
			"base_name": "Wrapped BNB",
			"base_symbol": "WBNB",
			"base_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"quote_name": "NFT Revolution Finance",
			"quote_symbol": "RVF",
			"quote_address": "0xE3C9F28A15719c365Be3530bCa86871e54E4Dcd0",
			"price": "4.83214939569165901783936774947338752672637669917459737e+33",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "283124001444298.19140670474795",
			"liquidity_BNB": "859467797869.5448032366899791005"
		},
		"0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c_0xc021d00C8cCA744756b61d29482DEE69966767CD": {
			"pair_address": "0x941658746280D0cf4A9D0059341A98D2e9F4330D",
			"base_name": "Wrapped BNB",
			"base_symbol": "WBNB",
			"base_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"quote_name": "Mini Splintershards Token",
			"quote_symbol": "MiniSPS",
			"quote_address": "0xc021d00C8cCA744756b61d29482DEE69966767CD",
			"price": "4.43001582007360787519728509399856976584852800776630681e+33",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "271284803486553.104706304217308",
			"liquidity_BNB": "870746909443.342302586588766352"
		},
		"0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c_0xF74f5158626d64aF338E4fB11E109a3ee1d67ba3": {
			"pair_address": "0x3a525Bf5eC789628E9d1c5f362ed6bF2db8f8d8d",
			"base_name": "Wrapped BNB",
			"base_symbol": "WBNB",
			"base_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"quote_name": "Baby Pampther",
			"quote_symbol": "BABYPAMPTHER",
			"quote_address": "0xF74f5158626d64aF338E4fB11E109a3ee1d67ba3",
			"price": "4.43047414750893879733692596571013976265641631695354019e+33",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "268086931765759.9954587573419724",
			"liquidity_BNB": "780455938271.630723350930447215"
		},
		"0xA25Afd16a875CA49c17B7E3915D0293C45B47d73_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0x1B73F2cd6e9E18B7F3b8f1c48Cc34b63dFDe41e7",
			"base_name": "Shield Corgi",
			"base_symbol": "SHIELDCORGI",
			"base_address": "0xA25Afd16a875CA49c17B7E3915D0293C45B47d73",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "0",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "264120686226395.4418144171824636",
			"liquidity_BNB": "839827849956.200172379254005534"
		},
		"0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c_0xf4Eae1CC1a3b7e6Fb5Dbe34B0E9Afacb19d354b8": {
			"pair_address": "0xd795A9458cDFea2A761E698Ba7e5Ebe8829EE68e",
			"base_name": "Wrapped BNB",
			"base_symbol": "WBNB",
			"base_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"quote_name": "Cake KLend Token",
			"quote_symbol": "CakeKLT",
			"quote_address": "0xf4Eae1CC1a3b7e6Fb5Dbe34B0E9Afacb19d354b8",
			"price": "4.43072916384151937396080732796825377591812513000700896e+33",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "263071826487778.0027700368927792",
			"liquidity_BNB": "766913798078.596145758870115908"
		},
		"0x9AFad676e86D6da8412d44e3bcbdA09E89DEBd7F_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0x9eE671e120eFdf13633fBea874c520213c91De4d",
			"base_name": "Mini Pizza Swap",
			"base_symbol": "MiniPIZZA",
			"base_address": "0x9AFad676e86D6da8412d44e3bcbdA09E89DEBd7F",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "0",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "261567631075875.7405306619208047",
			"liquidity_BNB": "754348959520.857071818029581628"
		},
		"0x991102966942Da0Ad96D1b3260ccC2a9c9b2AFc0_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0x962623D77A55b5cC2B83C9f1D8DcC854dF9A189A",
			"base_name": "Chain DashSports",
			"base_symbol": "CDASS",
			"base_address": "0x991102966942Da0Ad96D1b3260ccC2a9c9b2AFc0",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "0",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "255086210726638.5616268233584483",
			"liquidity_BNB": "809436569224.9035994594274309654"
		},
		"0x2a787b2044E066A46B172DCc396FeBdb4428b06a_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0x96eC863140318F503E9845F0c0f0a061b213c5b5",
			"base_name": "Swole Doge",
			"base_symbol": "SWOLEDOG",
			"base_address": "0x2a787b2044E066A46B172DCc396FeBdb4428b06a",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "0",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "254243975073913.1538174199488034",
			"liquidity_BNB": "770966842815.075523661497413549"
		},
		"0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c_0xe451376E825dd1836AfeAD71F72ACDf4Ed808495": {
			"pair_address": "0x0D5E3D620fa026D345d36ca21a5F0A488E5d0BC2",
			"base_name": "Wrapped BNB",
			"base_symbol": "WBNB",
			"base_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"quote_name": "Spaceswap Cocktail",
			"quote_symbol": "SsCOCKTAIL",
			"quote_address": "0xe451376E825dd1836AfeAD71F72ACDf4Ed808495",
			"price": "4.36527462883219802752215001796554965074363134282130081e+33",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "253856454715634.2428591347528664",
			"liquidity_BNB": "767847970023.747983152504850832"
		},
		"0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c_0xCc17Fd7E02D1BD9062e751b516FA2154F9aE217d": {
			"pair_address": "0x8f31AbDc56c0510b5d1e37B8Cee2f62Df373F139",
			"base_name": "Wrapped BNB",
			"base_symbol": "WBNB",
			"base_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"quote_name": "Mini Dot Token",
			"quote_symbol": "MiniDOT",
			"quote_address": "0xCc17Fd7E02D1BD9062e751b516FA2154F9aE217d",
			"price": "4.43054730637543031385095338946647142875129613335405802e+33",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "251650952373054.314614252872282",
			"liquidity_BNB": "795495809082.770398392629636439"
		},
		"0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c_0xf174caBb24C35e5033c2c856af4C12d6fa457b84": {
			"pair_address": "0x3C0e34cD2262157059EFa229e0e048Ce13253F43",
			"base_name": "Wrapped BNB",
			"base_symbol": "WBNB",
			"base_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"quote_name": "Cake Shake Token",
			"quote_symbol": "CakeSHAKE",
			"quote_address": "0xf174caBb24C35e5033c2c856af4C12d6fa457b84",
			"price": "4.43057456834296414222026330421726021362477832428728007e+33",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "249758585669379.0803213770699926",
			"liquidity_BNB": "760652136571.868318983761019728"
		},
		"0x7636c8dCcF3cD2e01Af2bDD5c5E30a8f0F7fe6DE_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0x66479665DE19CFfE39744FeaBeBddB8011c5CdF1",
			"base_name": "Baby Cow Boy",
			"base_symbol": "BCB",
			"base_address": "0x7636c8dCcF3cD2e01Af2bDD5c5E30a8f0F7fe6DE",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "0",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "247888746374285.958904375410035",
			"liquidity_BNB": "744174256026.491505384626001118"
		},
		"0xae48E925d16632541C423e0030464435530364b8_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0x2531A1735236215B438835a875d3a3483B3251af",
			"base_name": "Cake Dot Token",
			"base_symbol": "CaKeDOT",
			"base_address": "0xae48E925d16632541C423e0030464435530364b8",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "0",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "246692702168711.5568771575663671",
			"liquidity_BNB": "794658283766.68064443316680215"
		},
		"0x7a65e33f304aebC5eC07480d7e295FA2A10F00d3_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0x6728Ef381E0ea16ADeF836fCB9C8DA32Af172144",
			"base_name": "ShibaShield Finance",
			"base_symbol": "SHIBSHIELD",
			"base_address": "0x7a65e33f304aebC5eC07480d7e295FA2A10F00d3",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "0",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "245430531167645.0785680956783408",
			"liquidity_BNB": "783467094991.2933357786601445685"
		},
		"0x9C89EE6FAb0c0C0178F1F95aA86C39d3697DfcCB_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0x9E07E3B9A27Af1538e4C27a1b414811cE1db762a",
			"base_name": "SPF Finance ",
			"base_symbol": "SPFFinance ",
			"base_address": "0x9C89EE6FAb0c0C0178F1F95aA86C39d3697DfcCB",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "0",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "241784990268399.3807788108225414",
			"liquidity_BNB": "680781897651.241540627902366603"
		},
		"0x803D13dd1aA2c74f0E131C022Bff57fe3f1F5462_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0x2e3cCa663bB0C79d2E484dEA69b05bF1B639D943",
			"base_name": "Mini Fibo Token",
			"base_symbol": "MiniFIBO",
			"base_address": "0x803D13dd1aA2c74f0E131C022Bff57fe3f1F5462",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "0",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "231050066104427.7672922372083273",
			"liquidity_BNB": "738664891614.987949366103680878"
		},
		"0x7Ca32819af24A9b2309A2E41Db365759d93F8FcF_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0x09FB81D263e20623Ac031aCD78EBa1d09182C1B5",
			"base_name": "Mini APWars Token",
			"base_symbol": "MGOLD",
			"base_address": "0x7Ca32819af24A9b2309A2E41Db365759d93F8FcF",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "0",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "227910097841147.043932504755141",
			"liquidity_BNB": "721702298199.667011552114291953"
		},
		"0x5f0D41DCa92230Bf69f4d1A223cb6c6d2A4d8aa1_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0xb8e631d59D32267F09fd47ffFe8b255f7CF279C9",
			"base_name": "Cake Arena Token",
			"base_symbol": "ARENA",
			"base_address": "0x5f0D41DCa92230Bf69f4d1A223cb6c6d2A4d8aa1",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "0",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "225832872104572.4818397665210168",
			"liquidity_BNB": "726479601859.467548082972828262"
		},
		"0x49b972261d76B400Fe9042953b9425999A3ae3Bf_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0xF8e92f7e911941609629C648A86143f90AFAE609",
			"base_name": "DogeGive10x",
			"base_symbol": "DogeGive10x",
			"base_address": "0x49b972261d76B400Fe9042953b9425999A3ae3Bf",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "0",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "219082723488999.0140612089415484",
			"liquidity_BNB": "765030592364.11548749165096853"
		},
		"0x56593b29364da1C94ddF661b32A2CEfFFaDEA08D_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0x8Faf04F4AfbF01D8518cFfdA2d0B5C6df186E3E0",
			"base_name": "PolkaBunny",
			"base_symbol": "PKBUNNY",
			"base_address": "0x56593b29364da1C94ddF661b32A2CEfFFaDEA08D",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "0",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "216282298481790.8752157608500604",
			"liquidity_BNB": "475355448759.5333029076404500832"
		},
		"0x346Cfc17f8bEb98E0cb90d76DEfeeFB616E62221_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0x1AE3896A926039619c8f224ecEAaE4179857dFf2",
			"base_name": "Teddly Finance",
			"base_symbol": "TED",
			"base_address": "0x346Cfc17f8bEb98E0cb90d76DEfeeFB616E62221",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "0",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "212682206649016.2370366500365282",
			"liquidity_BNB": "733035413136.102080782795621322"
		},
		"0x6E7408f929287560D8eAE56120eD57a258e9D9D8_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0x3754788F3dB402De3115d7C72Cd967262AF017e0",
			"base_name": "Mini PanCake Token",
			"base_symbol": "MMPCAKE",
			"base_address": "0x6E7408f929287560D8eAE56120eD57a258e9D9D8",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "0",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "196650741429335.6224306907800283",
			"liquidity_BNB": "617723327248.580627353628302145"
		},
		"0x1152B350B582198F43F48c45498eA1F728Cf0A87_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0x1D2C6f8D6FBCf33671d543C3f129b0247622F967",
			"base_name": "MiniDogeInu",
			"base_symbol": "MINIDOGEINU",
			"base_address": "0x1152B350B582198F43F48c45498eA1F728Cf0A87",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "0",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "196282062040270.4185125599365798",
			"liquidity_BNB": "623212534525.330714518411443603"
		},
		"0x1ca4153B105AA30eab666E9ff883dee5A567bbED_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0x8B1d1128271E316A58F3A632a8EDaBD04A595085",
			"base_name": "MiniDot",
			"base_symbol": "MDOT",
			"base_address": "0x1ca4153B105AA30eab666E9ff883dee5A567bbED",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "0",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "195368857370558.4563336138282221",
			"liquidity_BNB": "624327233014.438339414040733148"
		},
		"0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c_0xF19EaEC046C3ce43Ab7b90ae3b9580986Bc7F6C4": {
			"pair_address": "0x5948B14D29A3b34b3c480F067E7C9b69222eaCB8",
			"base_name": "Wrapped BNB",
			"base_symbol": "WBNB",
			"base_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"quote_name": "SushiSwap Cake",
			"quote_symbol": "SHUSHICAKE",
			"quote_address": "0xF19EaEC046C3ce43Ab7b90ae3b9580986Bc7F6C4",
			"price": "5.31716044847191875279656948091530582567655227855495067e+33",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "195072368639145.51264950782199",
			"liquidity_BNB": "609317188391.381291484579910751"
		},
		"0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c_0xE5c9F5966F70255D7CC75d103F51e3A67832Bb2d": {
			"pair_address": "0x3A34a2b8b613D258825d74A6e7cA26096203396B",
			"base_name": "Wrapped BNB",
			"base_symbol": "WBNB",
			"base_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"quote_name": "krypto The SuperDog Coin",
			"quote_symbol": "KRSDOG",
			"quote_address": "0xE5c9F5966F70255D7CC75d103F51e3A67832Bb2d",
			"price": "5.32870062658442012860876274267500799153225988272035082e+33",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "193128496307440.8945406399773526",
			"liquidity_BNB": "601150756999.014783473195254251"
		},
		"0x3F93676e782D1098D615845c8E67f1D3209D1282_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0x618942772fd9387AA53052BCc1f1D436D522E498",
			"base_name": "MiniMatic",
			"base_symbol": "MINIMATIC",
			"base_address": "0x3F93676e782D1098D615845c8E67f1D3209D1282",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "0",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "191031719442298.2692412645507494",
			"liquidity_BNB": "625509810372.474749427247714414"
		},
		"0x22122F3B64428956477701a396b207E7E119C503_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0x9b7164D3e49B4790879408da5530b2C8d457ccf2",
			"base_name": "CAKITA",
			"base_symbol": "CAKITA",
			"base_address": "0x22122F3B64428956477701a396b207E7E119C503",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "0",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "185971751620187.9787229198090193",
			"liquidity_BNB": "308867436841.2429453977678837457"
		},
		"0x21518063371E1893B9d18AD5Ed7D979607bFeed2_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0x2AcBe8D587ea72A6084ee5b19F12b8929663a2Eb",
			"base_name": "Paw Patrol Dog Coin",
			"base_symbol": "PPDOG",
			"base_address": "0x21518063371E1893B9d18AD5Ed7D979607bFeed2",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "0",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "172411477787540.824664580532504",
			"liquidity_BNB": "577749113775.854149235247774469"
		},
		"0x807f5C2C51eD9dba2df9985f854aCbbC3dF17677_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0xBa69B705FddC8f31274837DdAc867d30BB4C1Cb7",
			"base_name": "Big Dog Token",
			"base_symbol": "BIGDOG",
			"base_address": "0x807f5C2C51eD9dba2df9985f854aCbbC3dF17677",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "0",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "168039057751735.995437262810341",
			"liquidity_BNB": "555555410542.407514653164739788"
		},
		"0x626A56C69982FfAD28948BfF91A203643Dec067A_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0x3ae0B9EE2517628868cC52306761DC9325c9Ae53",
			"base_name": "Citizen Finance Shiba",
			"base_symbol": "CIFISHIB",
			"base_address": "0x626A56C69982FfAD28948BfF91A203643Dec067A",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "0",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "164074221613079.101390350866549",
			"liquidity_BNB": "553983973489.350893367024515774"
		},
		"0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c_0xc3ef914e2b5C3aF7CC426f16d57b5B06daf509E5": {
			"pair_address": "0x7FBa1D319B593d4b5Fdf480deF78060Ec16e9Ba3",
			"base_name": "Wrapped BNB",
			"base_symbol": "WBNB",
			"base_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"quote_name": "PolkaMonster Game",
			"quote_symbol": "PKMon",
			"quote_address": "0xc3ef914e2b5C3aF7CC426f16d57b5B06daf509E5",
			"price": "4.425495150943250742967462443316983025079352043331533e+31",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "138012141049618.0798442966993117",
			"liquidity_BNB": "361651673472.719829705712853704"
		},
		"0x0838DC6B6FE31d570e508700FdB1812988E5a139_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0x66ED5a2105D1d16eCb548185a46a2F02f16813fb",
			"base_name": "SafeDogeBabyMoonRisePumpInu",
			"base_symbol": "MEME",
			"base_address": "0x0838DC6B6FE31d570e508700FdB1812988E5a139",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "0",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "131811794839141.6493230284924967",
			"liquidity_BNB": "413455321360.3263333308272940315"
		},
		"0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c_0xD3C8Fa27B3470183beEbd69592D1BbfF520C2464": {
			"pair_address": "0xBCee0d3c38F718cA0105A10Cf295902bf0554fd3",
			"base_name": "Wrapped BNB",
			"base_symbol": "WBNB",
			"base_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"quote_name": "Baby Bull",
			"quote_symbol": "BabyBull",
			"quote_address": "0xD3C8Fa27B3470183beEbd69592D1BbfF520C2464",
			"price": "1.9015291358068969215952786669156878798343905337e+26",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "103086590407445.5946583609201471",
			"liquidity_BNB": "328977597382.857270569007470191"
		},
		"0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c_0xDc1Bf87cc9693830A68531E050c8d2eC3D482F5D": {
			"pair_address": "0xEfddc67d5F4862071572fA90f39E02BEfc5bBaa7",
			"base_name": "Wrapped BNB",
			"base_symbol": "WBNB",
			"base_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"quote_name": "Raku Doge Coin",
			"quote_symbol": "RakuDoge",
			"quote_address": "0xDc1Bf87cc9693830A68531E050c8d2eC3D482F5D",
			"price": "3.08727916420086260788593045630273777483482411231564079e+33",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "99261857813950.8504170120425689",
			"liquidity_BNB": "230102331049.5572743394941528916"
		},
		"0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c_0xF828c5AD6d23d4cA33D6E25280d8AE36B91bf96D": {
			"pair_address": "0x74e7F04ba0678857e8fE7E645D37e53d7F081f15",
			"base_name": "Wrapped BNB",
			"base_symbol": "WBNB",
			"base_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"quote_name": "Foxi Equilibrium Network",
			"quote_symbol": "FOXINET",
			"quote_address": "0xF828c5AD6d23d4cA33D6E25280d8AE36B91bf96D",
			"price": "4.43637277009209051635512741758581360139719478922109769e+33",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "87517069759779.6052208521501486",
			"liquidity_BNB": "271421212877.2030169924249696663"
		},
		"0x650917741d8505cA7059f00d06CDC0CAb87b75E8_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0x90D95eEcbcA89Af293485cc4E369bb7D98057c6b",
			"base_name": "MINIUNCLE",
			"base_symbol": "MINIUNCLE",
			"base_address": "0x650917741d8505cA7059f00d06CDC0CAb87b75E8",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "0",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "85158982486141.106848580230132",
			"liquidity_BNB": "276144712471.25987143513567839"
		},
		"0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c_0xeF83808c91683aA366356c2C85b8226B3E2A5B66": {
			"pair_address": "0x21704AC608457030D739890402d88616D23690C9",
			"base_name": "Wrapped BNB",
			"base_symbol": "WBNB",
			"base_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"quote_name": "Sunny Baby Token",
			"quote_symbol": "SunnyBaby",
			"quote_address": "0xeF83808c91683aA366356c2C85b8226B3E2A5B66",
			"price": "1.2270561565330686604788881903935798904280744145413192e+32",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "74681021831104.4006549706418462",
			"liquidity_BNB": "218487894482.5291715154515098526"
		},
		"0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c_0xC846359C19DBf507F55A7F371f2AC44A79216Ca6": {
			"pair_address": "0xDADb40a890272FB2C74AEa90680B1d18d3404776",
			"base_name": "Wrapped BNB",
			"base_symbol": "WBNB",
			"base_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"quote_name": "Golden Shiba Inu",
			"quote_symbol": "GSHIBA",
			"quote_address": "0xC846359C19DBf507F55A7F371f2AC44A79216Ca6",
			"price": "3.066647038665726557683190325482991637617692882084e+28",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "72358415545186.5271622481515154",
			"liquidity_BNB": "254824573249.889885701890710616"
		},
		"0x3069a53bE1e3691f5cB957fcF0C307a2C81AaC6A_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0x099C72cc9277DDf0D2B5dD577f12E7b90c55108F",
			"base_name": "DOGEBALL",
			"base_symbol": "BALL",
			"base_address": "0x3069a53bE1e3691f5cB957fcF0C307a2C81AaC6A",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "0",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "65744420084239.3200814463432222",
			"liquidity_BNB": "217079105417.6281444240711011873"
		},
		"0x06443AaD8bF655e1EF8C7d7a036C421749Fe2cde_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0xea818a5221040586e0D8069159bc07bDB98fE4c2",
			"base_name": "SaveYourAss",
			"base_symbol": "SYA",
			"base_address": "0x06443AaD8bF655e1EF8C7d7a036C421749Fe2cde",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "0",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "63765252246194.1797016920376879",
			"liquidity_BNB": "100416474261.6629473801391151479"
		},
		"0x93C048036e327D5e7F4AFF226626eB2133310e47_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0x517e6ef7d3206ACeb1a8a2B37b0bE93780922124",
			"base_name": "Planet Wolf",
			"base_symbol": "PLANETWOLF",
			"base_address": "0x93C048036e327D5e7F4AFF226626eB2133310e47",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "0",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "61914718331813.40845608094300606",
			"liquidity_BNB": "197872728061.320652308798164086"
		},
		"0x933E9b56Cee51e60247C48834b0F6521D7D9b17f_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0x908B691Eb2956F8A2De9a4914604FaE1Cc170e3C",
			"base_name": "Bake Bank",
			"base_symbol": "BakeBank",
			"base_address": "0x933E9b56Cee51e60247C48834b0F6521D7D9b17f",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "0",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "59507769976713.9383274314308228",
			"liquidity_BNB": "174292351483.529407487795101841"
		},
		"0x60fC8f2D01D883cCad21A42d1aDD0cE2091a803b_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0x9A238866Ff75D60192b49deFbe524071c5981Dd7",
			"base_name": "Starship Inu",
			"base_symbol": "STARINU",
			"base_address": "0x60fC8f2D01D883cCad21A42d1aDD0cE2091a803b",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "0",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "52745338854289.68476081746926004",
			"liquidity_BNB": "177461851607.9835104992438620714"
		},
		"0xB4D80B8F4F8D532E3048FA2C343784eEfC91Fd36_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0x64F5f145424Af4E8289717853767BF672D01A7C4",
			"base_name": "Binance Super Coin",
			"base_symbol": "SuperCoin",
			"base_address": "0xB4D80B8F4F8D532E3048FA2C343784eEfC91Fd36",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "0",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "51073218010656.6295311372454304",
			"liquidity_BNB": "189496660181.2021011663532418683"
		},
		"0xaC513A6a2a58BaeA12b5C0F779E6C742f4730402_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0x1952d1bC70B67688B928aDa227Da48C468996965",
			"base_name": "Binance-Peg Pamptherdot Token",
			"base_symbol": "PAMPTHERDOT",
			"base_address": "0xaC513A6a2a58BaeA12b5C0F779E6C742f4730402",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "0",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "49133651934046.32955020239077343",
			"liquidity_BNB": "143669203680.0389630464859042408"
		},
		"0xB1470fcDB0a4141Bb56784d52460174710bbeEe4_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0xC3962a4aF443fc5CF4cbBCEc339d24fF24ae3bF1",
			"base_name": "Wonder Land",
			"base_symbol": "WONDERLAND",
			"base_address": "0xB1470fcDB0a4141Bb56784d52460174710bbeEe4",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "0",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "48068392557077.66331348504496235",
			"liquidity_BNB": "144438393740.6448533254273009657"
		},
		"0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c_0xBc01F2a1942a3061fe9356483792DB39Be67EDB1": {
			"pair_address": "0xAB988C15a1c951Ad235895Ffd00F6241Bdf02954",
			"base_name": "Wrapped BNB",
			"base_symbol": "WBNB",
			"base_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"quote_name": "CakeRefund",
			"quote_symbol": "CAKEREFUND",
			"quote_address": "0xBc01F2a1942a3061fe9356483792DB39Be67EDB1",
			"price": "1.557610191989723989192253930373481762494435963e+25",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "42792852554726.308742984270963",
			"liquidity_BNB": "158870160452.7287839493691325502"
		},
		"0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c_0xdA8A6d7372F5Ba3979C367813c097F465490A551": {
			"pair_address": "0x08344ED46890F8A1a85943ff7c36ccd18bfbAAdB",
			"base_name": "Wrapped BNB",
			"base_symbol": "WBNB",
			"base_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"quote_name": "XRP Monster",
			"quote_symbol": "XRPMON",
			"quote_address": "0xdA8A6d7372F5Ba3979C367813c097F465490A551",
			"price": "1.52650461102600666105300708972531526017173899433e+27",
			"base_volume": "339.631426572233583765",
			"quote_volume": "1.000000002107954203328413267795e+21",
			"liquidity": "41780601358529.6867371183735339",
			"liquidity_BNB": "81769224923.4254611172932747862"
		},
		"0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c_0xbba3F3d65B21B9977AA61687Bd6674E13F5eAE8d": {
			"pair_address": "0x3D635138DE2288f02E2f3BBa3E5a7cab13D238Da",
			"base_name": "Wrapped BNB",
			"base_symbol": "WBNB",
			"base_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"quote_name": "ETH Monster",
			"quote_symbol": "ETHMON",
			"quote_address": "0xbba3F3d65B21B9977AA61687Bd6674E13F5eAE8d",
			"price": "1.52615810460041736050428724155122316784106129323e+27",
			"base_volume": "265.5617270958060278220000000002",
			"quote_volume": "1.000000000537351577202947600943e+21",
			"liquidity": "40752769562981.0800820882454626",
			"liquidity_BNB": "79398557666.7104658323591950172"
		},
		"0xa426122A0AA2D072F35949aF8a23830580394aD9_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0x32517a67643311C759025bB85C042F88B066002c",
			"base_name": "PolkaMonster SafeMoon",
			"base_symbol": "PMSAFEMOON",
			"base_address": "0xa426122A0AA2D072F35949aF8a23830580394aD9",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "0",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "40371815404286.4516987471663019",
			"liquidity_BNB": "81916959115.6936039773257480254"
		},
		"0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c_0xCa60A098FB7e2202fB1552af6C4E048c62Aa777a": {
			"pair_address": "0xe26d9C38cbAD72aCE267bBA3338e80b75AB897ee",
			"base_name": "Wrapped BNB",
			"base_symbol": "WBNB",
			"base_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"quote_name": "Bunny Monster",
			"quote_symbol": "BUNNYMON",
			"quote_address": "0xCa60A098FB7e2202fB1552af6C4E048c62Aa777a",
			"price": "1.52627373227117448753617427177752517493394281509e+27",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "40193330340978.8331279830206842",
			"liquidity_BNB": "83341484281.6962149682140406185"
		},
		"0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c_0xE34484c2DD0b2b43bE1cED3268EE13AbCd7B972A": {
			"pair_address": "0x4EE2B8431Ad4D42aed3d80E79e4Aa71Ee0e9b44c",
			"base_name": "Wrapped BNB",
			"base_symbol": "WBNB",
			"base_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"quote_name": "Floki Shiba Monster",
			"quote_symbol": "FSHIBMON",
			"quote_address": "0xE34484c2DD0b2b43bE1cED3268EE13AbCd7B972A",
			"price": "1.52611268583992755570755800816576963545543794532e+27",
			"base_volume": "356.919354428449838082",
			"quote_volume": "1.000000002486625864033285117143e+21",
			"liquidity": "39970383086806.04106506808781357",
			"liquidity_BNB": "81097640744.2206451780888092868"
		},
		"0xAA0950923B05F7C581D0212c2874C93e13D478B0_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0x79eF22C47a0338F24748850d1aF9D8eb57aef608",
			"base_name": "PolkaMonster Rabbit",
			"base_symbol": "PMRABBIT",
			"base_address": "0xAA0950923B05F7C581D0212c2874C93e13D478B0",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "0",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "39784291471408.36406730091086187",
			"liquidity_BNB": "79837714389.6844099446106401548"
		},
		"0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c_0xe7221f68881d264541Bc0BcB4A9bAF41cfd7ACA6": {
			"pair_address": "0x4Fa1C77335D39eD1fC2F61C1E3DD1d4B33E2f676",
			"base_name": "Wrapped BNB",
			"base_symbol": "WBNB",
			"base_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"quote_name": "Shiba Monster",
			"quote_symbol": "SHIBMON",
			"quote_address": "0xe7221f68881d264541Bc0BcB4A9bAF41cfd7ACA6",
			"price": "1.52633448034837466330645141931805188012935204054e+27",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "39583774888116.22087510121580384",
			"liquidity_BNB": "82651963723.4070330342593585424"
		},
		"0x024E5033816e3089E9D5606d83Eb0648Ae81f651_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0xCfcAD179AE4907B27E7a2941Ed2478C80279a914",
			"base_name": "PolkaCorgi",
			"base_symbol": "PKCORGI",
			"base_address": "0x024E5033816e3089E9D5606d83Eb0648Ae81f651",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "0",
			"base_volume": "1.000000002242858988486579818141e+21",
			"quote_volume": "348.574562125546184857",
			"liquidity": "39346555000230.74094107653613933",
			"liquidity_BNB": "81712321034.2146151633735715897"
		},
		"0x47eec2d8f4f5de839aE5a7A928D07DD9c7a67dC7_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0x15281dE35695B99b19e88Cdd545D5EAB13DB38EF",
			"base_name": "PolkaCake",
			"base_symbol": "PKCAKE",
			"base_address": "0x47eec2d8f4f5de839aE5a7A928D07DD9c7a67dC7",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "0",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "39009643385552.99832850812099905",
			"liquidity_BNB": "81371874761.3157536925767310942"
		},
		"0x113724bF08Da4272FFCA1365323dB6e49F50E61b_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0x0930551DdeEC3e8585Ee63Fae236060eC8E52F48",
			"base_name": "Moon EverDoge",
			"base_symbol": "MOONED",
			"base_address": "0x113724bF08Da4272FFCA1365323dB6e49F50E61b",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "0",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "37976133949191.59861431648661",
			"liquidity_BNB": "127505085053.8496929780077948703"
		},
		"0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c_0xBc73063C34D039ba56E9C22245ACdEf16329E4F7": {
			"pair_address": "0x7633c4999ecD738a0c07160e5210224bFE2Dd1d8",
			"base_name": "Wrapped BNB",
			"base_symbol": "WBNB",
			"base_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"quote_name": "BNB Monster",
			"quote_symbol": "BNBMON",
			"quote_address": "0xBc73063C34D039ba56E9C22245ACdEf16329E4F7",
			"price": "1.52622393714855621066106580435251052172684634103e+27",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "37582225456925.7695877121456673",
			"liquidity_BNB": "78170590787.2758841606354337168"
		},
		"0x8e4e61a74eb7D87aFD6154e6b5342D56dD84400F_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0x3db092aC502dE559d52C78dC5C910D6E949ce5EA",
			"base_name": "PolkaMonster Bunny",
			"base_symbol": "PMBUNNY",
			"base_address": "0x8e4e61a74eb7D87aFD6154e6b5342D56dD84400F",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "0",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "37064997787833.0073667605739954",
			"liquidity_BNB": "82298605547.8026050371134324166"
		},
		"0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c_0xC9ec88A4175CE34D08C63C6d8661f45D59Fa2A3E": {
			"pair_address": "0x0E51C604f2A022cA4384CFeF5975715feEda5675",
			"base_name": "Wrapped BNB",
			"base_symbol": "WBNB",
			"base_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"quote_name": "SolarDoge",
			"quote_symbol": "SolarDoge",
			"quote_address": "0xC9ec88A4175CE34D08C63C6d8661f45D59Fa2A3E",
			"price": "9.9729014137947456715478370431339813968010215347e+26",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "36776405467987.039447629719562",
			"liquidity_BNB": "118136388798.2248614140885158086"
		},
		"0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c_0xE7D502b72BE5379989Bb8f4463A2B8388294A3d6": {
			"pair_address": "0x5314821dCB4419D57C779979EF686E78dC994535",
			"base_name": "Wrapped BNB",
			"base_symbol": "WBNB",
			"base_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"quote_name": "PolkaBunny",
			"quote_symbol": "PKBUNNY",
			"quote_address": "0xE7D502b72BE5379989Bb8f4463A2B8388294A3d6",
			"price": "1.52642947271088564124747246673376863584113509876e+27",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "35785927942410.5341829137819449",
			"liquidity_BNB": "79481067261.1084578377858554719"
		},
		"0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c_0xC97701c9b90EC23715d8b7A24c55c63565eb1E9d": {
			"pair_address": "0x27e92840F41117D0475Eb2979Cce054265E02Ae1",
			"base_name": "Wrapped BNB",
			"base_symbol": "WBNB",
			"base_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"quote_name": "Crazy Lizard Army",
			"quote_symbol": "CLA",
			"quote_address": "0xC97701c9b90EC23715d8b7A24c55c63565eb1E9d",
			"price": "3.68825504314679880967768646708511265068162646908744e+30",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "35637290960773.0109485362529691",
			"liquidity_BNB": "103484342494.953667377088841881"
		},
		"0x99E4F52C0af4E86bB9C19D07a42D0C9CB89a5f19_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0xd980974593B2ea89A52A56f2EBA495F7B7B8224a",
			"base_name": "EARNDOGE",
			"base_symbol": "EARNDOGE",
			"base_address": "0x99E4F52C0af4E86bB9C19D07a42D0C9CB89a5f19",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "0",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "35597920048631.41515836935686656",
			"liquidity_BNB": "121145590764.8949547275226015"
		},
		"0x444e937045c2C732E67801085D0cEB1867bb87eb_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0x3a4f41493418513391A61D53d209D34da955Fe7A",
			"base_name": "Cyber Doge",
			"base_symbol": "CYBERDOGE",
			"base_address": "0x444e937045c2C732E67801085D0cEB1867bb87eb",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "0",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "35490557538128.1071737592560646",
			"liquidity_BNB": "111559528562.2822817016891951128"
		},
		"0x184196BBd55a746b1209034C1637dc972CEa7850_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0xe6b749EB1e26FC2aC8948bf9877a73CC3a4dCDd9",
			"base_name": "SpaceSwap Memes",
			"base_symbol": "SWAPMEMES",
			"base_address": "0x184196BBd55a746b1209034C1637dc972CEa7850",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "0",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "35472631957390.58322989823716953",
			"liquidity_BNB": "107335591065.2411414602615795772"
		},
		"0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c_0xd8D1c3964aD47FAB5616c0657Ae411da4FE146Bd": {
			"pair_address": "0xc294559091b821AD829e14090f9106e1E53Eb77C",
			"base_name": "Wrapped BNB",
			"base_symbol": "WBNB",
			"base_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"quote_name": "ENZYME xFactor",
			"quote_symbol": "ENZY",
			"quote_address": "0xd8D1c3964aD47FAB5616c0657Ae411da4FE146Bd",
			"price": "3.98275480063407798161335056745546587783279889586391e+30",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "35405551072859.9521662325309604",
			"liquidity_BNB": "107982833665.2977104351929467525"
		},
		"0x02420fcD708eCF8990394fa6bA6Bd10d10A7F6aD_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0x73dcDe58B28003e07F8b6F800DEEcFE8C3982b14",
			"base_name": "Floppa",
			"base_symbol": "FLOPPA",
			"base_address": "0x02420fcD708eCF8990394fa6bA6Bd10d10A7F6aD",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "0",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "34268731892615.66730896096985015",
			"liquidity_BNB": "94213791626.6829255350722889948"
		},
		"0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c_0xF62C9A666C61F0BEce2BAD59163c00CEc2759a29": {
			"pair_address": "0x1841c2b6e7A10090581Ec86f3402C2aB331065Cf",
			"base_name": "Wrapped BNB",
			"base_symbol": "WBNB",
			"base_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"quote_name": "Bake Capital Holding",
			"quote_symbol": "CAPITALBAKE",
			"quote_address": "0xF62C9A666C61F0BEce2BAD59163c00CEc2759a29",
			"price": "4.60978096314418887481941148229288524079938717059298e+30",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "33823186714115.86420939193261143",
			"liquidity_BNB": "105648909041.5889278263469044105"
		},
		"0x574BfCc13cc043Ead4747631FB23079848231165_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0x4ce5F29547bB3A16e71445dFa696A83E1EE0B557",
			"base_name": "BlackHoleSwap",
			"base_symbol": "SERCURE",
			"base_address": "0x574BfCc13cc043Ead4747631FB23079848231165",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "0",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "33142788219621.9726073215283854",
			"liquidity_BNB": "99768555225.8121964989941408546"
		},
		"0x0A2875CedD8304a66E8f0d2bF41daE1544668C5A_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0x9046534bEd5aDf7277992cD3629D0263a5e8Dc42",
			"base_name": "BSC Red Fox",
			"base_symbol": "BSCREDFOX",
			"base_address": "0x0A2875CedD8304a66E8f0d2bF41daE1544668C5A",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "0",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "32075002702721.21863772337634374",
			"liquidity_BNB": "100233296347.3323959085121376381"
		},
		"0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c_0xC4dACb39f28e53b911AC754Ba5C841b7a6BC7759": {
			"pair_address": "0x6D5FC058EFa5179B8E3C769CF0e25768FFBfda62",
			"base_name": "Wrapped BNB",
			"base_symbol": "WBNB",
			"base_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"quote_name": "YOYO Baby",
			"quote_symbol": "YOYOEGG",
			"quote_address": "0xC4dACb39f28e53b911AC754Ba5C841b7a6BC7759",
			"price": "4.14919165417196672336177028229610680473595582836159e+30",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "31110504313481.13582076119928788",
			"liquidity_BNB": "96759005215.263586454218455988"
		},
		"0x9D7964B418Cc1D386A7682Ca88DF0434960F689C_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0xF82F9e361879056dDf06EF5e9c05764736a04516",
			"base_name": "Splinter DogeCoin",
			"base_symbol": "SPSDOGE",
			"base_address": "0x9D7964B418Cc1D386A7682Ca88DF0434960F689C",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "0",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "31077909123468.89402099985642394",
			"liquidity_BNB": "100301652798.2573045642044679375"
		},
		"0x93ee4E9E8b32aa1B5e670B5554FA37C68a2fa353_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0x51a5e4fb792BdC6cb3B32fdf30fbe84A90276031",
			"base_name": "Ero Finance",
			"base_symbol": "ERO",
			"base_address": "0x93ee4E9E8b32aa1B5e670B5554FA37C68a2fa353",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "0",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "29067467766952.76407222835191735",
			"liquidity_BNB": "101523459812.89927089272721907"
		},
		"0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c_0xbDD721f39cC09CBe6b9392961Fcb21FDDd6A1965": {
			"pair_address": "0x711Cda72e40786ad3f001b66f270448BE9e7A2f1",
			"base_name": "Wrapped BNB",
			"base_symbol": "WBNB",
			"base_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"quote_name": "PUSSYHUNTER",
			"quote_symbol": "PUSSYHUNTER",
			"quote_address": "0xbDD721f39cC09CBe6b9392961Fcb21FDDd6A1965",
			"price": "4.9874552144939720405219803595051083052432873271712e+29",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "28578088389389.09323550982412895",
			"liquidity_BNB": "50360195442.89774430157856043656"
		},
		"0xb32D461653a86648C71dfeF8Ee082A26a86F58B2_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0x908633F8C164A285D7E9A5370949cc6249B7206a",
			"base_name": "Lazy SheepDoge",
			"base_symbol": "LAZYDOGE",
			"base_address": "0xb32D461653a86648C71dfeF8Ee082A26a86F58B2",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "0",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "26879659321426.10051972671711656",
			"liquidity_BNB": "89437636275.1086253358930516516"
		},
		"0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c_0xd9A138ECAf025F396Ef3A05d8fa278BFCf8bdA99": {
			"pair_address": "0x6508409325c1f6c9cad847566c3b36Ad994862A7",
			"base_name": "Wrapped BNB",
			"base_symbol": "WBNB",
			"base_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"quote_name": "The City Doge",
			"quote_symbol": "CITYDOGE",
			"quote_address": "0xd9A138ECAf025F396Ef3A05d8fa278BFCf8bdA99",
			"price": "6.98468785359598480837437016550971370471629583852027e+30",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "26020645215835.36980720206406026",
			"liquidity_BNB": "90063099942.5183466813934302509"
		},
		"0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c_0xEaD3157654b35CDBea754A49a22436F5FEA49bEC": {
			"pair_address": "0x1894b4247043A2ebBC527829BD7C8cB31dA1357B",
			"base_name": "Wrapped BNB",
			"base_symbol": "WBNB",
			"base_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"quote_name": "Pepe Doge",
			"quote_symbol": "PPDOGE",
			"quote_address": "0xEaD3157654b35CDBea754A49a22436F5FEA49bEC",
			"price": "6.15991696326167331145287851360797188610203277521493e+30",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "24423019352767.47995367009175813",
			"liquidity_BNB": "81581848453.9002514746360474879"
		},
		"0x7b4981E07bf6A1A4897C60FdC0D69Cb73f9F9F01_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0x250dc488A9DE91Dac9BF6b4e4b9141CD377a62F7",
			"base_name": "VirtualRise Token",
			"base_symbol": "VRISE",
			"base_address": "0x7b4981E07bf6A1A4897C60FdC0D69Cb73f9F9F01",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "0",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "23667246432068.33398917178405826",
			"liquidity_BNB": "80494661970.3761567898285848388"
		},
		"0x69a7C8cACB32773900D3B4cF6501C54B12d51b0E_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0x610B898CAF4926956Ea055a204608bf504b41451",
			"base_name": "Lucky Pandas",
			"base_symbol": "LUCKYDAS",
			"base_address": "0x69a7C8cACB32773900D3B4cF6501C54B12d51b0E",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "0",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "21603354229118.5129469185648374",
			"liquidity_BNB": "72532370255.9339583820669381407"
		},
		"0xabaaEE31c954736d40B8dEf498c311606394482D_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0x3c96ca4D0a42703612d350F4f8fb858d60DfE6D8",
			"base_name": "Animeta NFT",
			"base_symbol": "ANIMETA",
			"base_address": "0xabaaEE31c954736d40B8dEf498c311606394482D",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "0",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "20792500093767.04576765730729587",
			"liquidity_BNB": "62316349178.00689942790708264594"
		},
		"0x811795710cf9a29cDB821f76fB1400C554Db8cc7_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0x4665306C8d0f33dE865facbd551297EEdE2C3D8B",
			"base_name": "SabatonSAFU",
			"base_symbol": "SABATON",
			"base_address": "0x811795710cf9a29cDB821f76fB1400C554Db8cc7",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "0",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "19474538688359.9984535451007723",
			"liquidity_BNB": "31065800447.6233571395825222954"
		},
		"0x760eD470347738C4918A3bF83da432C02366306b_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0x4b3b8831a0DC5e5F0f8C0355815009780A13f852",
			"base_name": "BABY TRX",
			"base_symbol": "BTRX",
			"base_address": "0x760eD470347738C4918A3bF83da432C02366306b",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "0",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "17619163046932.9626931196616601",
			"liquidity_BNB": "53012267271.40971903278759094205"
		},
		"0xb4e3E1deFB07BC516b1482b6917142e153c34aE0_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0x678C2Ab5698E6fefa4b62A90E896a39504F73090",
			"base_name": "Candy Baby Doge",
			"base_symbol": "CBD",
			"base_address": "0xb4e3E1deFB07BC516b1482b6917142e153c34aE0",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "0",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "16010128325145.23475341339109615",
			"liquidity_BNB": "53463768402.1951322025833956666"
		},
		"0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c_0xd864C65b0175Dd8cD75C253B530e673356Cc773F": {
			"pair_address": "0x1746598099B2A537B7D290A593185F912e285855",
			"base_name": "Wrapped BNB",
			"base_symbol": "WBNB",
			"base_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"quote_name": "Babe Doge Mars",
			"quote_symbol": "BabeDOGE",
			"quote_address": "0xd864C65b0175Dd8cD75C253B530e673356Cc773F",
			"price": "3.7325224248750444064387663614955907862246851199038e+29",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "15615619294384.57858235946087043",
			"liquidity_BNB": "49703803125.2974029531513585485"
		},
		"0x010E5D4364C346d95f5E117800133674B37B145D_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0xc0582601d7704f402fD3af642BF35089575b318B",
			"base_name": "BigBabyDoge",
			"base_symbol": "BigBabyDoge",
			"base_address": "0x010E5D4364C346d95f5E117800133674B37B145D",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "0",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "14987821910142.78727153513815876",
			"liquidity_BNB": "51085721416.81776930409577342315"
		},
		"0x4c0a643140E3a1Cc82305a568D2661B97e5e65b8_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0x0CD7f09ce66af5c29f987529Ae083d742d39a56B",
			"base_name": "https://www.smoke-high.com",
			"base_symbol": "SmokeHigh",
			"base_address": "0x4c0a643140E3a1Cc82305a568D2661B97e5e65b8",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "0",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "12608330286151.86661489575250628",
			"liquidity_BNB": "41015424690.9701557257353873364"
		},
		"0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c_0xE1d0f12f50e5801456e0Bd78383Bc2370d90254D": {
			"pair_address": "0xA51C483e8273EcD373692d2E6FD5A85E7C4EAb1D",
			"base_name": "Wrapped BNB",
			"base_symbol": "WBNB",
			"base_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"quote_name": "Cybrrrtruck",
			"quote_symbol": "Cybrrrtruck",
			"quote_address": "0xE1d0f12f50e5801456e0Bd78383Bc2370d90254D",
			"price": "1.992983351651069224520553217874994093719102586e+26",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "12329626305001.12065050674651014",
			"liquidity_BNB": "40369033613.42395780654794845325"
		},
		"0x970c577D8bb1fbE627887A19231B7908dF74edAc_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0xe997D2807b891673AD3dFF83d306e71712A61456",
			"base_name": "MetaBunny Token",
			"base_symbol": "METABUNNY",
			"base_address": "0x970c577D8bb1fbE627887A19231B7908dF74edAc",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "0",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "11951162554910.9765538302871583",
			"liquidity_BNB": "39357828040.1921699335829046405"
		},
		"0x72291976C3427028c83167611d1528eF4aE21C1B_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0x9De6c97494756b95172DeE96d22203E4B3c417CF",
			"base_name": "SHIBA Rising",
			"base_symbol": "SHIBRISE",
			"base_address": "0x72291976C3427028c83167611d1528eF4aE21C1B",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "0",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "11608835608343.64344189897384418",
			"liquidity_BNB": "38041187987.70413442340156486856"
		},
		"0x18eF448297d9518737D6e371517c0aDE67152A9B_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0x875e0DD2fFe4bb3789F5A487E90b70faD38CbfFA",
			"base_name": "LAMAFINANCE",
			"base_symbol": "LMF",
			"base_address": "0x18eF448297d9518737D6e371517c0aDE67152A9B",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "0",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "11407150238552.37450695848043951",
			"liquidity_BNB": "40491446505.8972496865094492636"
		},
		"0x72F570208be2590888B5435204961cFF52218180_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0xe1F2D8D2378bFAE48298D744804d45B0233B0BC6",
			"base_name": "Bondly Token",
			"base_symbol": "BONDLY",
			"base_address": "0x72F570208be2590888B5435204961cFF52218180",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "0",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "10878661295824.16793519838173743",
			"liquidity_BNB": "35135794154.81318246249707087724"
		},
		"0x66E2db6aB335Fa53FE06a551bA0aBd71C95F75ec_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0xAD9aaB2861b666dC6BA60d47bb9500e10CA79e7F",
			"base_name": "ALPHABET INC",
			"base_symbol": "ALPHABETINC",
			"base_address": "0x66E2db6aB335Fa53FE06a551bA0aBd71C95F75ec",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "0",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "10830501476913.84486252183046606",
			"liquidity_BNB": "30659165477.4308390558603988563"
		},
		"0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c_0xFD08F5017Bd18fc6280b172F77A8236f0993faa9": {
			"pair_address": "0xC4d179EA8f1868dF74eD8230E547B6BCA6109971",
			"base_name": "Wrapped BNB",
			"base_symbol": "WBNB",
			"base_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"quote_name": "Cybrrrtruck",
			"quote_symbol": "Cybrrrtruck",
			"quote_address": "0xFD08F5017Bd18fc6280b172F77A8236f0993faa9",
			"price": "1.9931760587957426846826374052093253208628471433e+26",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "9740403510354.88982604034566337",
			"liquidity_BNB": "31828322629.88931499659343440827"
		},
		"0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c_0xc1F11B22261a156b0D04fef99b8B4F5Ee639A217": {
			"pair_address": "0x52dEEd3deDa200B8aD531d4C1be5352c58f17227",
			"base_name": "Wrapped BNB",
			"base_symbol": "WBNB",
			"base_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"quote_name": "Baby Cattle Doge",
			"quote_symbol": "BABYCATTLE",
			"quote_address": "0xc1F11B22261a156b0D04fef99b8B4F5Ee639A217",
			"price": "9.331663116941425019246202956795814416157761174308e+28",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "8198697264341.67622313319469954",
			"liquidity_BNB": "24752712102.7032388814903098687"
		},
		"0xB4B09b3AD180C8432b99b90DEA13Fb52784Ae893_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0xcd2662c931B28d1B8550D48dD49D5EEefA97C495",
			"base_name": "Extra Ethereum Token",
			"base_symbol": "xETH",
			"base_address": "0xB4B09b3AD180C8432b99b90DEA13Fb52784Ae893",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "0",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "8042222048129.37266363552346501",
			"liquidity_BNB": "23056805487.53151248419097919936"
		},
		"0xA3Fd97D990003f32c3FFd860F919333Ee3ac2385_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0x560EbC5D1f9A71Ff359C08A8b0052495fB5F99c1",
			"base_name": "CryptoDoge Skill",
			"base_symbol": "DOGESKILL",
			"base_address": "0xA3Fd97D990003f32c3FFd860F919333Ee3ac2385",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "0",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "7984645869920.4805642405344755",
			"liquidity_BNB": "23722335234.14333290988354703002"
		},
		"0x15453B6c42e563f7293B9Ef0Bb54fcA1303E33Ce_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0x89636af5c56E3af0e86cabED05d473EE41a2e2c1",
			"base_name": "DeFi Scale Network",
			"base_symbol": "DEFISCALE",
			"base_address": "0x15453B6c42e563f7293B9Ef0Bb54fcA1303E33Ce",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "0",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "7375374498034.821773421627854536",
			"liquidity_BNB": "22387388415.86431475354652583177"
		},
		"0x65f093536AbAB32137f02a354380A358756889e7_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0x41ce01A38C44F9E99B48e76a7CCf8c25bE095f45",
			"base_name": "XCross Chain",
			"base_symbol": "XCHAIN",
			"base_address": "0x65f093536AbAB32137f02a354380A358756889e7",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "0",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "7304576105518.9788956872008838",
			"liquidity_BNB": "22407760019.77645491645931613935"
		},
		"0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c_0xCE6c3FD4A858b5E08bAC94BF20A11A6CC7eC3201": {
			"pair_address": "0x28DfcCDd44B8Ca0D1193be5E440978380C0fA2A7",
			"base_name": "Wrapped BNB",
			"base_symbol": "WBNB",
			"base_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"quote_name": "Baby CryptoZoon",
			"quote_symbol": "BABYZOON",
			"quote_address": "0xCE6c3FD4A858b5E08bAC94BF20A11A6CC7eC3201",
			"price": "8.7340129429039367426760812196174021529798044659812e+29",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "6839253778247.70288402550064264",
			"liquidity_BNB": "21935228205.78668223988041227545"
		},
		"0x279B777B703270bc6cdbe659d6fb469fF800C491_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0x7a002B9510F5C01070b33684F7C0008Fe377D7bF",
			"base_name": "ToastedDoge",
			"base_symbol": "TSD",
			"base_address": "0x279B777B703270bc6cdbe659d6fb469fF800C491",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "0",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "6838198799813.690185713290516034",
			"liquidity_BNB": "22835587351.542698418961392994"
		},
		"0xB13e98F9d13c6c8ED782C76750C194C26250Ca96_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0x2f9437f26D718bf28d808fb034cd74B799Af132C",
			"base_name": "Buny Fury Festival",
			"base_symbol": "FURRY",
			"base_address": "0xB13e98F9d13c6c8ED782C76750C194C26250Ca96",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "0",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "6652660592530.34160812985928567",
			"liquidity_BNB": "20148778320.75387573477634473035"
		},
		"0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c_0xCa3fC14e0A7443569a053105aF27E3aD2b3A3AC9": {
			"pair_address": "0x1a5CDca55af8D0d7Cb598edb91381a013a1Cde74",
			"base_name": "Wrapped BNB",
			"base_symbol": "WBNB",
			"base_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"quote_name": "robotarena.io",
			"quote_symbol": "🤖ARRO",
			"quote_address": "0xCa3fC14e0A7443569a053105aF27E3aD2b3A3AC9",
			"price": "1.1654569210756737017954886940088550101410259e+23",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "6621148076792.84172855204992496",
			"liquidity_BNB": "16414294666.90011255406099477089"
		},
		"0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c_0xfd03b8aE6C598c060Be092554e42B9376B505971": {
			"pair_address": "0x432eb452b881f9fF648f83d7886d44Fd883Ad65E",
			"base_name": "Wrapped BNB",
			"base_symbol": "WBNB",
			"base_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"quote_name": "YOLO DOGGY Coin",
			"quote_symbol": "YOLODOGGY",
			"quote_address": "0xfd03b8aE6C598c060Be092554e42B9376B505971",
			"price": "8.8279083324727376035101828204831479278461252544616e+29",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "6523305733602.699371503517266804",
			"liquidity_BNB": "20280296457.7674807288610357569"
		},
		"0x6Ff753f58797c7c6d6e61cBD504B3832EfEA22Fa_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0x9f07C619BD608e097f8282afAa31533e8800660F",
			"base_name": "Captain SHIBA",
			"base_symbol": "CAPTAINSHIB",
			"base_address": "0x6Ff753f58797c7c6d6e61cBD504B3832EfEA22Fa",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "0",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "6242998457777.77297130283213852",
			"liquidity_BNB": "20582920145.63697485230020425438"
		},
		"0x9f52fF779446a5d52457CE4f88c51E97a025A01c_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0xbBE7ea83Ab6986C5F03A70CC13F617683Ac84442",
			"base_name": "Bobby Finke Fan",
			"base_symbol": "BOBBYFINKE",
			"base_address": "0x9f52fF779446a5d52457CE4f88c51E97a025A01c",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "0",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "6073596184489.99720227363484056",
			"liquidity_BNB": "19358342145.06803739652210514458"
		},
		"0x60C2D7a35Ee313AeF16451a6B4dd57880D3F389d_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0xF4f0270CEDeD5924939bC9b0bC31A59374D52Fa0",
			"base_name": "Baby Kutta Inu",
			"base_symbol": "BABYKUTTA",
			"base_address": "0x60C2D7a35Ee313AeF16451a6B4dd57880D3F389d",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "0",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "5518002311269.88436540192715766",
			"liquidity_BNB": "16606510962.38961096841351497694"
		},
		"0x511a179Cb872B9b77e3FEE392BBac0095aa8E1C8_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0x6fd786cD319084599AD62313f524D766BD78b4CE",
			"base_name": "BabyDucky Token",
			"base_symbol": "DUCKY",
			"base_address": "0x511a179Cb872B9b77e3FEE392BBac0095aa8E1C8",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "0",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "5362683213941.79749498002599769",
			"liquidity_BNB": "16658426367.96321346687527701342"
		},
		"0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c_0xc7514e357C3Feb59B6a72222C641625cb027193f": {
			"pair_address": "0xce18Ea4c487BcED25AFDb3510FBfb433998B694f",
			"base_name": "Wrapped BNB",
			"base_symbol": "WBNB",
			"base_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"quote_name": "Nerd Coin",
			"quote_symbol": "NERD",
			"quote_address": "0xc7514e357C3Feb59B6a72222C641625cb027193f",
			"price": "2.93309645336755515028306686074282253284199062135e+27",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "5302669533429.568429112773780686",
			"liquidity_BNB": "8731400262.88945276311725492617"
		},
		"0xb7CBedC796B4a0f071462da518F5Bf68cB97285a_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0x8A66C6b53DcFFaDe6b1BbE2C9e2Ffdd724e502E3",
			"base_name": "Bunny Hunter",
			"base_symbol": "HUNTER",
			"base_address": "0xb7CBedC796B4a0f071462da518F5Bf68cB97285a",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "0",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "5230810150947.88963241526671345",
			"liquidity_BNB": "16853859294.9114357576295032146"
		},
		"0x71650DDaCD0285Ab7e69F66083675dDC5834d542_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0x1b7ADecc783C3d4d6b5a93c0CAfa8eDA0dB15DCA",
			"base_name": "JapaneseChin Inu",
			"base_symbol": "JPCHIN",
			"base_address": "0x71650DDaCD0285Ab7e69F66083675dDC5834d542",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "0",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "4763816975180.009228946245197045",
			"liquidity_BNB": "15128494178.31016902821284178553"
		},
		"0x37Ec3a6E2E226805C2bd27485F15cd62a1E39173_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0xEA34844179C4F35277FcF4d265EBaae80D7d99a5",
			"base_name": "t.me/CaptainUnderpantsBSC",
			"base_symbol": "CUP",
			"base_address": "0x37Ec3a6E2E226805C2bd27485F15cd62a1E39173",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "0",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "4669463984207.757941367857003445",
			"liquidity_BNB": "11307770189.62175515652354484049"
		},
		"0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c_0xFfbA998357872E146bcd1345c6E24C5F6472551b": {
			"pair_address": "0xcb4aD3b85bF49eD08dB9753d633E1F8C68F82006",
			"base_name": "Wrapped BNB",
			"base_symbol": "WBNB",
			"base_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"quote_name": "WorldCup Airdrop Token",
			"quote_symbol": "WCUP",
			"quote_address": "0xFfbA998357872E146bcd1345c6E24C5F6472551b",
			"price": "1.39738362477115098036144721511439340179956304186984e+30",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "4648920272906.48217636357012113",
			"liquidity_BNB": "14328361389.96479120944880802791"
		},
		"0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c_0xF8FEdC38c68fE3b813c9C2f3117959AE2D8BdBCB": {
			"pair_address": "0xf932aB2264cA840733eAF98c5B20d1437A731FA8",
			"base_name": "Wrapped BNB",
			"base_symbol": "WBNB",
			"base_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"quote_name": "Lazy Cats Token",
			"quote_symbol": "LAZYCAT",
			"quote_address": "0xF8FEdC38c68fE3b813c9C2f3117959AE2D8BdBCB",
			"price": "1.38281072907418445833999553645070304770531408441378e+30",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "4538429177660.578340627686757605",
			"liquidity_BNB": "16001553134.0918555758383551256"
		},
		"0x8aaFb1F03fA4BBEf66fb83E376474F1bf3f9e790_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0xed85BB7356F5E4da4cf308d117969d1b5979ceBe",
			"base_name": "YooShiGame",
			"base_symbol": "YooShiGame",
			"base_address": "0x8aaFb1F03fA4BBEf66fb83E376474F1bf3f9e790",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "0",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "4441756023611.78437036686059186",
			"liquidity_BNB": "14616336650.06336513089247255597"
		},
		"0x330130EE5566c2FF9dE40F612F3Cc388A535aA20_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0xE8338e6d8523211BEC4CB5B0c8d38Cf61442212E",
			"base_name": "Puppy DOGE",
			"base_symbol": "PUPPYDOGE",
			"base_address": "0x330130EE5566c2FF9dE40F612F3Cc388A535aA20",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "0",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "4406603915500.10817264250428687",
			"liquidity_BNB": "14593995518.11465866022625939382"
		},
		"0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c_0xF4540cF57bf80b42108478557446E180231F302e": {
			"pair_address": "0xEEB2b881620c147F4093b4BB239924Cfb485d451",
			"base_name": "Wrapped BNB",
			"base_symbol": "WBNB",
			"base_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"quote_name": "Mini Terrier Inu",
			"quote_symbol": "TERI",
			"quote_address": "0xF4540cF57bf80b42108478557446E180231F302e",
			"price": "1.39745615391168701741002910848001994281275887841123e+30",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "4367584777108.972503443640603375",
			"liquidity_BNB": "13701252837.91925242705225623842"
		},
		"0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c_0xD15db375ca260a1593999072B8FECd850f51fe27": {
			"pair_address": "0xad981F650a508d1a35E7076605c4976879717cD1",
			"base_name": "Wrapped BNB",
			"base_symbol": "WBNB",
			"base_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"quote_name": "SpaceSwap Cola",
			"quote_symbol": "COLA",
			"quote_address": "0xD15db375ca260a1593999072B8FECd850f51fe27",
			"price": "6.321326974394260229475317965358061409809984362614e+28",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "4348607838650.72171862763080035",
			"liquidity_BNB": "13252899744.94040152170810516187"
		},
		"0xa297e15802Fd5D3163813B24fD0C419e73a87C7A_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0x705f378553e57E284BCb23efF2c0881735Fc941b",
			"base_name": "DuckDuck Token",
			"base_symbol": "DUCK",
			"base_address": "0xa297e15802Fd5D3163813B24fD0C419e73a87C7A",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "0",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "4311501668556.455431636786755148",
			"liquidity_BNB": "13306730906.55312842998902890357"
		},
		"0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c_0xd1d3bbbE080cC86Df671BFe2d3Fe8757373D6537": {
			"pair_address": "0xFcF52C6e80635e2d037718A3735752aa65acD537",
			"base_name": "Wrapped BNB",
			"base_symbol": "WBNB",
			"base_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"quote_name": "TosaKen Warrior Inu",
			"quote_symbol": "TOSA",
			"quote_address": "0xd1d3bbbE080cC86Df671BFe2d3Fe8757373D6537",
			"price": "1.37320401548265037850714560863615047327438040443491e+30",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "4304823151594.989791898206958076",
			"liquidity_BNB": "13586184835.40964232450016953901"
		},
		"0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c_0xDc102aAd412c547B3cf2F0f3C5BF62e36feA7c82": {
			"pair_address": "0x2421015e997e9b06766cA7742bae13F576f937cB",
			"base_name": "Wrapped BNB",
			"base_symbol": "WBNB",
			"base_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"quote_name": "Safe Doge Space",
			"quote_symbol": "SAFESPACEDDOGE",
			"quote_address": "0xDc102aAd412c547B3cf2F0f3C5BF62e36feA7c82",
			"price": "5.28871658252923640157683614928541360831304498842951e+30",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "4028349783793.34988907237773141",
			"liquidity_BNB": "11895197833.10332995999654283066"
		},
		"0x9637B0778581ba4505A8493D7FfA1fDa4Af2DE6F_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0xa58bdDFCD6A7Fd1D801CD8b77ce5eB20f9F927b9",
			"base_name": "Retro Doge Coin",
			"base_symbol": "RETRODOGE",
			"base_address": "0x9637B0778581ba4505A8493D7FfA1fDa4Af2DE6F",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "0",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "3910170287519.239124837699668212",
			"liquidity_BNB": "12275953859.02568693712521799814"
		},
		"0x01f1bacA39B2E366DaE708Fddb3E670d7d110184_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0xF1eA48646208E69501d2fa44cD55A51CFA49E322",
			"base_name": "F98 DEFI",
			"base_symbol": "F98",
			"base_address": "0x01f1bacA39B2E366DaE708Fddb3E670d7d110184",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "0",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "3725435316212.14318493432634294",
			"liquidity_BNB": "12519416730.45118246152884180772"
		},
		"0x1ed296e2869f0d2C25cdDf4eC20599C862Bed4bd_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0x54ddEbA221F4570012b114225cAe5070a822df4b",
			"base_name": "Nigga",
			"base_symbol": "NIGGA",
			"base_address": "0x1ed296e2869f0d2C25cdDf4eC20599C862Bed4bd",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "0",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "3721854927095.4476411075100494",
			"liquidity_BNB": "10599737721.93474862328444370137"
		},
		"0x6EE5a44bA1A8A9A208Ca5Da0e629f721e736EBDF_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0x392FdBD156a532837DcE12e6848988fAfE2bf7CC",
			"base_name": "F98 Token",
			"base_symbol": "F98",
			"base_address": "0x6EE5a44bA1A8A9A208Ca5Da0e629f721e736EBDF",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "0",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "3717977565833.044810461432277734",
			"liquidity_BNB": "12479816267.70437564847157931555"
		},
		"0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c_0xea0B489b7AeE88C358d3ed9232FeF789C8154003": {
			"pair_address": "0x5E3C21361eB26B2AFAb861e4e47e4313ed6a2f0d",
			"base_name": "Wrapped BNB",
			"base_symbol": "WBNB",
			"base_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"quote_name": "Flash Oracle",
			"quote_symbol": "FORCL",
			"quote_address": "0xea0B489b7AeE88C358d3ed9232FeF789C8154003",
			"price": "2.3713102090728033548924577664756298941891692649e+26",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "3709696847136.45617699713530291",
			"liquidity_BNB": "10489075759.35960476925946575898"
		},
		"0x3f121f685883275d22E057736B760a38A9e4A945_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0x8f8119049C7a8Ffb841f262FF24Aa48ffd013B32",
			"base_name": "CeloBNB",
			"base_symbol": "CBNB",
			"base_address": "0x3f121f685883275d22E057736B760a38A9e4A945",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "0",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "3699995710055.196595890342643135",
			"liquidity_BNB": "8068344482.07635177382877345652"
		},
		"0x8B55ED1107aEc6D07B71b2A14647d8610A2EFEb8_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0xA9009722A38F3F69350C5e480ca431c8e095d159",
			"base_name": "SpaceSwap BankETH",
			"base_symbol": "SWAPBANKE",
			"base_address": "0x8B55ED1107aEc6D07B71b2A14647d8610A2EFEb8",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "0",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "3630306712512.33015554818885752",
			"liquidity_BNB": "11009454309.64385764689707325681"
		},
		"0x9fc1401C9245faF08416c3682267bB7866c031bD_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0x97Df505425d31756421bd8e3386245C874d20987",
			"base_name": "@NemoCommunity",
			"base_symbol": "NEMO",
			"base_address": "0x9fc1401C9245faF08416c3682267bB7866c031bD",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "0",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "3627839905735.462034468860897933",
			"liquidity_BNB": "6000000015.000000037593984869"
		},
		"0x69FE1EC74D3d4A94a62520d31CFbDb6bfA4A7FB5_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0x4B91e7c3d9175925EbcBf96f010d7BFf57BC4A96",
			"base_name": "XRP BSC",
			"base_symbol": "XRPBSC",
			"base_address": "0x69FE1EC74D3d4A94a62520d31CFbDb6bfA4A7FB5",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "0",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "3587774613931.272379246435715337",
			"liquidity_BNB": "11118378716.84981669439854531705"
		},
		"0xb60F5174c4aEfF1f08fa2E653b7aD918A5eE744b_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0x2E2839607F7C2edFCe9b783bDCFD440578bd277a",
			"base_name": "Goldy.Finance",
			"base_symbol": "GOLDY",
			"base_address": "0xb60F5174c4aEfF1f08fa2E653b7aD918A5eE744b",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "0",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "3574780242514.721773117450177765",
			"liquidity_BNB": "10064976033.17525246011628833514"
		},
		"0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c_0xF474075274A239Af5D675212B2a5D1979F029525": {
			"pair_address": "0xB20Ca5715BE14dca581283d22905f0928f2707c8",
			"base_name": "Wrapped BNB",
			"base_symbol": "WBNB",
			"base_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"quote_name": "Capital Cake",
			"quote_symbol": "CAKECAPITAL",
			"quote_address": "0xF474075274A239Af5D675212B2a5D1979F029525",
			"price": "4.556665133457021885275765228067820610185189562963e+28",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "3510155033861.413248074990878282",
			"liquidity_BNB": "10564078501.10810437673526567718"
		},
		"0x142ED88EE6D50a99Ac5FdB771D003a20Bc206857_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0x9e647B4506177BF378b172Bf917F87B7682E59e3",
			"base_name": "Baby ElonTech",
			"base_symbol": "BabyETCH",
			"base_address": "0x142ED88EE6D50a99Ac5FdB771D003a20Bc206857",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "0",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "3495673716518.346585664244844934",
			"liquidity_BNB": "8128022786.42301632416320569952"
		},
		"0x5373e4d0BA236d1acE059729d9DcA32e6900EBda_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0xB2c610156Ed98AEe4Ca135740Cc5a35cf3053927",
			"base_name": "Dior Dogecoin",
			"base_symbol": "DIORDOGE",
			"base_address": "0x5373e4d0BA236d1acE059729d9DcA32e6900EBda",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "0",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "3486367636288.168972136409347252",
			"liquidity_BNB": "7616613303.61183818205773202789"
		},
		"0x7819768cA0fa25E204d0b9f1B721859B80Bb5648_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0xC0E5E95FA313653307Acdde642df9926F04F6434",
			"base_name": "NanoCake Token",
			"base_symbol": "nCAKE",
			"base_address": "0x7819768cA0fa25E204d0b9f1B721859B80Bb5648",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "0",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "3378392585814.089621874057127644",
			"liquidity_BNB": "10150121507.89385016450110560879"
		},
		"0x065d750e46AFfAC336DC8aA51AB3cEEc5eD35066_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0x33300b08d279e9BC89b991579C4C84702FA73C7d",
			"base_name": "LilDogeX",
			"base_symbol": "LDX",
			"base_address": "0x065d750e46AFfAC336DC8aA51AB3cEEc5eD35066",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "0",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "3336062179825.61085092112649193",
			"liquidity_BNB": "11024148279.79714433276670082405"
		},
		"0xa428F9F503D10b66BC9559dbf4B3cf8c3a1E197C_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0xeBA335E085DB5c9D8D772FEB5dDb2280d24EA839",
			"base_name": "PolyCenter.io",
			"base_symbol": "POLYC",
			"base_address": "0xa428F9F503D10b66BC9559dbf4B3cf8c3a1E197C",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "0",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "3319582576005.733397240466604018",
			"liquidity_BNB": "10552691994.78251589076991235696"
		},
		"0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c_0xEd790fB587C7F0efA0E60f27cf1d3b423d480f72": {
			"pair_address": "0xb45C430Cc28a31B675De897AD5a407B83Dfa42ed",
			"base_name": "Wrapped BNB",
			"base_symbol": "WBNB",
			"base_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"quote_name": "BaByForeverDoge",
			"quote_symbol": "BFDOGE",
			"quote_address": "0xEd790fB587C7F0efA0E60f27cf1d3b423d480f72",
			"price": "9.97385236372088063352054757881669636189208465e+24",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "3315899982377.867846599804953413",
			"liquidity_BNB": "10925263779.82821333197008950244"
		},
		"0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c_0xc0D75911B1Ce967c795f584A4039d9Fc2EE47f78": {
			"pair_address": "0x5C3aCB801C6513b7052AA687281149687f637f18",
			"base_name": "Wrapped BNB",
			"base_symbol": "WBNB",
			"base_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"quote_name": "UNIPANCAKE",
			"quote_symbol": "UNIPANCAKE",
			"quote_address": "0xc0D75911B1Ce967c795f584A4039d9Fc2EE47f78",
			"price": "98754995273275577.76900386548105864152",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "3309272955617.58086653811508096",
			"liquidity_BNB": "10055036191.85004541900430951885"
		},
		"0x6f3dECb1AcD04386ec95C06796A45Da49837835F_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0x9c89DE28DFAEb67A233937970a4c6590C0279BAf",
			"base_name": "Uncle Doge",
			"base_symbol": "UncleDoge",
			"base_address": "0x6f3dECb1AcD04386ec95C06796A45Da49837835F",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "0",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "3290837224657.495022395430609333",
			"liquidity_BNB": "10450346903.16958704556604833368"
		},
		"0x8C0d3a6C477BF7A784Ab7e2CD1d149d3fC46c37f_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0x8a4AABD1fF70A8fB06dd0059ab414BFb0a5DdBd0",
			"base_name": "Waves Doge",
			"base_symbol": "WDOGE",
			"base_address": "0x8C0d3a6C477BF7A784Ab7e2CD1d149d3fC46c37f",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "0",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "3259874948719.39163702232725556",
			"liquidity_BNB": "9900226235.21479728686996648436"
		},
		"0x653C29af85fd5d5817cF01EeaEc1f26ce4a28efB_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0x0Db6dD4C17400F7D3965C52DdA567C60cf9016fa",
			"base_name": "F98 Token",
			"base_symbol": "F98",
			"base_address": "0x653C29af85fd5d5817cF01EeaEc1f26ce4a28efB",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "0",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "3251702471680.67507101117801085",
			"liquidity_BNB": "10885693751.61979360752602629982"
		},
		"0x70475D1031a89778dc50202723912832EE2B2FE5_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0xE9bA6d9F855551f8884974FF49179969716D1793",
			"base_name": "BSC x MegaTron",
			"base_symbol": "BSCTRON",
			"base_address": "0x70475D1031a89778dc50202723912832EE2B2FE5",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "0",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "3223661994061.840957577662331753",
			"liquidity_BNB": "9520389845.6433574004492769463"
		},
		"0xB685996f08677399E1C5B619aBD4bF16E713012F_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0xB1f36a1B8B48D2e13DA3D6b944537b2D596080DF",
			"base_name": "The Shiba Town",
			"base_symbol": "SHIBTOWN",
			"base_address": "0xB685996f08677399E1C5B619aBD4bF16E713012F",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "0",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "3210268540384.95969793623386071",
			"liquidity_BNB": "10769563281.3500661729120955873"
		},
		"0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c_0xe676C695dB2FAE12C2106Bc25c5638C1784Fcf17": {
			"pair_address": "0x6250Ed3c254950b16791BAA6A6f019cD66b3338F",
			"base_name": "Wrapped BNB",
			"base_symbol": "WBNB",
			"base_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"quote_name": "Celo Dogecoin",
			"quote_symbol": "CELODOGE",
			"quote_address": "0xe676C695dB2FAE12C2106Bc25c5638C1784Fcf17",
			"price": "2.212764449926729947613605148089407744083239932e+26",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "3198988379187.763641979920273592",
			"liquidity_BNB": "7841320299.11680380980375749616"
		},
		"0xB9a644590a37ee10bE77788866756a588472AfE8_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0x970c0c50017c828815F8F530A85BBb5b09B1D3f2",
			"base_name": "Doge Coin",
			"base_symbol": "DOGE",
			"base_address": "0xB9a644590a37ee10bE77788866756a588472AfE8",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "0",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "3170053003212.18365823227815539",
			"liquidity_BNB": "10466567443.66863105467722040055"
		},
		"0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c_0xd916B4Fe454CFFE15b2521d911DBcE17383be01A": {
			"pair_address": "0x40286314237499D945264Ae2191C2e640Cf85c73",
			"base_name": "Wrapped BNB",
			"base_symbol": "WBNB",
			"base_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"quote_name": "Super Corgi Doge",
			"quote_symbol": "SuperCORGI",
			"quote_address": "0xd916B4Fe454CFFE15b2521d911DBcE17383be01A",
			"price": "2.212409269928126943829535942766344003168093921e+26",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "3121898599507.205579683477522538",
			"liquidity_BNB": "8051946709.053867641470503154905"
		},
		"0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c_0xf5f4a6b3167DfF0Fdd1529d433dDF99424f7670B": {
			"pair_address": "0xbF9E563CA061b797D8687b98F6e8017A88BC58Cf",
			"base_name": "Wrapped BNB",
			"base_symbol": "WBNB",
			"base_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"quote_name": "Bezos Dogecoin",
			"quote_symbol": "BEZOSDOGE",
			"quote_address": "0xf5f4a6b3167DfF0Fdd1529d433dDF99424f7670B",
			"price": "2.2129027382314029783832476994070304695291127321e+26",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "3120806511087.435214895243817384",
			"liquidity_BNB": "7741703208.715851713422771956744"
		},
		"0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c_0xE436841046F58f33190AD2D27deCDbA96B17d6E6": {
			"pair_address": "0xC429c811Bcb83eFDD7aaE2FcdB3315bD82240e91",
			"base_name": "Wrapped BNB",
			"base_symbol": "WBNB",
			"base_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"quote_name": "Titan Dogecoin",
			"quote_symbol": "TITANDOGE",
			"quote_address": "0xE436841046F58f33190AD2D27deCDbA96B17d6E6",
			"price": "2.2127365737385289925054492084860537512868613301e+26",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "3102527074162.256640865218945496",
			"liquidity_BNB": "7684144380.83479567924216855687"
		},
		"0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c_0xdfE9c8211c5d7781bD02F82E35e902CE7199c460": {
			"pair_address": "0x6c7722b5E11B93497f48053CDfcC50e401aE67b6",
			"base_name": "Wrapped BNB",
			"base_symbol": "WBNB",
			"base_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"quote_name": "NFT Polkamon",
			"quote_symbol": "NFTPMON",
			"quote_address": "0xdfE9c8211c5d7781bD02F82E35e902CE7199c460",
			"price": "2.2127362691926357768187237670243276422295205293e+26",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "3074280586441.847331044158806345",
			"liquidity_BNB": "8042023147.064319403696712625636"
		},
		"0x75b85D379eF369c0C7ffA3a7B6C8e354912e4756_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0x3ce5f59377c85f80b31906E1497Eb6142E5C4F17",
			"base_name": "White Dogecoin",
			"base_symbol": "WHITEDOGE",
			"base_address": "0x75b85D379eF369c0C7ffA3a7B6C8e354912e4756",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "0",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "3061384891365.67955708573447275",
			"liquidity_BNB": "7656689399.11478658518818391881"
		},
		"0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c_0xfd7686952dBb01A13bb45763d535E0954B434aC9": {
			"pair_address": "0x7A51D4c29DB040564E8B4Bf913D8153E64d30EC1",
			"base_name": "Wrapped BNB",
			"base_symbol": "WBNB",
			"base_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"quote_name": "XRPBABY",
			"quote_symbol": "XRPBABY",
			"quote_address": "0xfd7686952dBb01A13bb45763d535E0954B434aC9",
			"price": "2.8453388318741433081135064601409576088471825971e+26",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "3016672027043.30995977405775314",
			"liquidity_BNB": "8746904353.26720548720997255168"
		},
		"0x914E15A28eA147C7708d66E4ad289FDAd736f82a_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0x2f979BB7F61D2923F0646E0FCB6C07D73c3e872f",
			"base_name": "Digi Dogecoin",
			"base_symbol": "DIGIDOGE",
			"base_address": "0x914E15A28eA147C7708d66E4ad289FDAd736f82a",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "0",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "2975520386248.64671409695335735",
			"liquidity_BNB": "7319408229.71108846350348866048"
		},
		"0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c_0xBeC77c7821A7a0B27f4ae405ea6d95b43732EB98": {
			"pair_address": "0x2AdD01ed730ED3ce8efdC0E18992011e9DA7DB01",
			"base_name": "Wrapped BNB",
			"base_symbol": "WBNB",
			"base_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"quote_name": "Captain America BULLDOGE",
			"quote_symbol": "BULLDOGE",
			"quote_address": "0xBeC77c7821A7a0B27f4ae405ea6d95b43732EB98",
			"price": "8.363933047500763354614992249659953984142186157401e+28",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "2974687111128.407360736161710358",
			"liquidity_BNB": "9739343179.83490401589341791486"
		},
		"0x9c6bE9ba9d8F99A2f09D7e06FF3A7d3eaf198C74_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0x79Fa9abeCcDA457B3fDb69cBB5F3260b5a3ebEC4",
			"base_name": "Waves Dogecoin",
			"base_symbol": "WAVESDOGE",
			"base_address": "0x9c6bE9ba9d8F99A2f09D7e06FF3A7d3eaf198C74",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "0",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "2957486946790.886085763861888014",
			"liquidity_BNB": "7659083556.885101042498303501954"
		},
		"0x64Ae61C985EFf3f4F9A3E09A93A43Ff67fA9DCB8_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0x9228cA4780448811D771F5d43a00F49dfA443336",
			"base_name": "Ero Finance",
			"base_symbol": "ERO",
			"base_address": "0x64Ae61C985EFf3f4F9A3E09A93A43Ff67fA9DCB8",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "1e-20",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "2906447651710.671980395936997627",
			"liquidity_BNB": "10152345990.35830918734008403005"
		},
		"0x57ef7e7aabB9B2301072402e7D2DDB38D55Bda65_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0x61495EF263184038286eFEB7b5b6001914D1543D",
			"base_name": "Quantum Moon",
			"base_symbol": "QMM",
			"base_address": "0x57ef7e7aabB9B2301072402e7D2DDB38D55Bda65",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "0",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "2901979875762.97891892556349952",
			"liquidity_BNB": "8798251746.07405760181078715383"
		},
		"0x59B5E08fac61C95B251632D49fC195A0DcFbc813_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0xb9A6383B2b178107878bf9481d93F4E3E9429a10",
			"base_name": "Extreme Network",
			"base_symbol": "EXTR",
			"base_address": "0x59B5E08fac61C95B251632D49fC195A0DcFbc813",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "0",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "2887560845209.483700456364004355",
			"liquidity_BNB": "8735162938.93569720278227997925"
		},
		"0x9a721f73Fc960456cF3635116e0154a0E8716D46_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0x9EFf416cF9bd5Ed9B31D857CAC9e722C958fF45a",
			"base_name": "CANVA",
			"base_symbol": "CANVA",
			"base_address": "0x9a721f73Fc960456cF3635116e0154a0E8716D46",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "0",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "2841067071357.64932882625498612",
			"liquidity_BNB": "8701997632.05717244868033030428"
		},
		"0x85dEB5cC15141787076C4CBEF6320741D8F22858_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0x34aA4B8D6AB3CE7f3dE3FE2A26F7960288344114",
			"base_name": "BakeryCake Token",
			"base_symbol": "BakeryCake",
			"base_address": "0x85dEB5cC15141787076C4CBEF6320741D8F22858",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "0",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "2837259954870.897871030700225555",
			"liquidity_BNB": "7503417153.33210144858974372376"
		},
		"0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c_0xf198353295F6E5BA2334B0fb40889be2Eb87e44f": {
			"pair_address": "0x2810a7c4360B648C5738f284AaD96be9A5f769A6",
			"base_name": "Wrapped BNB",
			"base_symbol": "WBNB",
			"base_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"quote_name": "NFT Apex Legends",
			"quote_symbol": "NFTAPEX",
			"quote_address": "0xf198353295F6E5BA2334B0fb40889be2Eb87e44f",
			"price": "2.8456563052887484780538489253834844852816334156e+26",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "2818115978585.48410876918857198",
			"liquidity_BNB": "8545543848.05716664434775550625"
		},
		"0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c_0xD9d3173C0fDB0C651a554F6477eD19707560b615": {
			"pair_address": "0xB2E409de98be63b3511E8cb0e2f7037e10948Afb",
			"base_name": "Wrapped BNB",
			"base_symbol": "WBNB",
			"base_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"quote_name": "NFT Binamars",
			"quote_symbol": "NFTBMARS",
			"quote_address": "0xD9d3173C0fDB0C651a554F6477eD19707560b615",
			"price": "2.212808472800757086427417871063969399488357068e+26",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "2811917974590.739989315976200844",
			"liquidity_BNB": "7478221366.01400656899483775979"
		},
		"0x6D4D1D0A09a967207EC86261a7249Ce7F2C1F17d_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0xa8dc46542E453e8Ee904f4C81CF0Ce60B02d425a",
			"base_name": "Doge Diamond",
			"base_symbol": "DODI",
			"base_address": "0x6D4D1D0A09a967207EC86261a7249Ce7F2C1F17d",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "0",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "2768564088311.22748921870016174",
			"liquidity_BNB": "7740771816.6065100985729315019"
		},
		"0x5577353cBedE89fE74E2402f11ed51b49a94CAe4_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0xDEDd5c3D9779018dE4d6c8ca2119B36E2de78FD1",
			"base_name": "BankCake Token",
			"base_symbol": "BANKCAKE",
			"base_address": "0x5577353cBedE89fE74E2402f11ed51b49a94CAe4",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "0",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "2768360474141.773310655136192578",
			"liquidity_BNB": "8120280251.12927526673247826898"
		},
		"0x972E416f48d214BFB9A13b9F0d2d968d1b1198c5_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0xF7C7818FEE441Aa5A4EF08eeE6048A09a16D25Cb",
			"base_name": "Monero BSC",
			"base_symbol": "XMRBSC",
			"base_address": "0x972E416f48d214BFB9A13b9F0d2d968d1b1198c5",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "0",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "2708224398420.678500612850374704",
			"liquidity_BNB": "7980753417.12393255558904025168"
		},
		"0xA94f188c732a2B4501ddbD142a7c9Eeb56e0b5F2_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0x7fb0285f74de9501d7d05D571764e1a5fda91130",
			"base_name": "SuperDODO",
			"base_symbol": "SuperDODO",
			"base_address": "0xA94f188c732a2B4501ddbD142a7c9Eeb56e0b5F2",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "0",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "2703786328670.818669160032853928",
			"liquidity_BNB": "7710085439.71471749245643824304"
		},
		"0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c_0xE9cAb0A8b44e5deb86F290557155FC37479B4720": {
			"pair_address": "0xef4DbC993f2C555993c740498dcD9995A0670455",
			"base_name": "Wrapped BNB",
			"base_symbol": "WBNB",
			"base_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"quote_name": "CrowdStrike Coin",
			"quote_symbol": "CRWD",
			"quote_address": "0xE9cAb0A8b44e5deb86F290557155FC37479B4720",
			"price": "2.2123573205883132387190679195209903394368487154e+26",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "2691684157143.68072903454070772",
			"liquidity_BNB": "8016210813.18734071416493754864"
		},
		"0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c_0xfD73846f6B27cC89FAC19954dF8bdcC47aB8a8b5": {
			"pair_address": "0x31A1747f7031f7468255E727116Bb717C58543c3",
			"base_name": "Wrapped BNB",
			"base_symbol": "WBNB",
			"base_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"quote_name": "DCOL SWAP",
			"quote_symbol": "DCOLSWAP",
			"quote_address": "0xfD73846f6B27cC89FAC19954dF8bdcC47aB8a8b5",
			"price": "3.353774074549724924364158878479029657979959e+22",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "2670845724229.379112151661419237",
			"liquidity_BNB": "7652937782.45115201906507947277"
		},
		"0x92Ea293874E97fbC4974e39D47933D1C8eeDf12F_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0x218162B2Bf1595c32220AEfD91eBD5c81db40bea",
			"base_name": "MinaDOGE",
			"base_symbol": "MinaDOGE",
			"base_address": "0x92Ea293874E97fbC4974e39D47933D1C8eeDf12F",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "0",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "2652928717181.2405962697949736",
			"liquidity_BNB": "7465933923.89196544716398260779"
		},
		"0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c_0xdef7BAffE4C2aa18AF200b5aE92AE9353525e459": {
			"pair_address": "0x3E6104aC49d23193b75F70F3cF1532d6B5BE01b1",
			"base_name": "Wrapped BNB",
			"base_symbol": "WBNB",
			"base_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"quote_name": "NanoPUG",
			"quote_symbol": "NanoPUG",
			"quote_address": "0xdef7BAffE4C2aa18AF200b5aE92AE9353525e459",
			"price": "4.98372789877866733652918474302483016154065408e+24",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "2640781011441.616835054707682455",
			"liquidity_BNB": "8677607340.28869280237140587116"
		},
		"0xA954275D51ed24B5e4990bC0BF79F4d9F472bc4f_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0x7A581e0E647dC4c6f1Ff89aCE00D72255EB7BF15",
			"base_name": "BSC Banking",
			"base_symbol": "BSCBANK",
			"base_address": "0xA954275D51ed24B5e4990bC0BF79F4d9F472bc4f",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "0",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "2602342074969.805485935044474403",
			"liquidity_BNB": "8022729268.810599193307660905625"
		},
		"0x8f1ed9f4E852a3A85aa454D463aBC3a113f62b42_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0x4fFf9f1aE6cdED9021677a5302a3A88Cc5163CD9",
			"base_name": "SuperMaker",
			"base_symbol": "SuperMKR",
			"base_address": "0x8f1ed9f4E852a3A85aa454D463aBC3a113f62b42",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "0",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "2600371201700.831163632045524178",
			"liquidity_BNB": "7393064798.71027062021223255961"
		},
		"0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c_0xEFa04a4DF756DD2c38C4b27f63C334587Ec4E525": {
			"pair_address": "0x79036999499587647Dc365491FAe3C652ce69CBf",
			"base_name": "Wrapped BNB",
			"base_symbol": "WBNB",
			"base_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"quote_name": "BTCPump",
			"quote_symbol": "BTCPUMP",
			"quote_address": "0xEFa04a4DF756DD2c38C4b27f63C334587Ec4E525",
			"price": "2.3530048613836740733914298447260539410285471e+23",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "2585988324955.690467103225915566",
			"liquidity_BNB": "7399396401.16526443426389033964"
		},
		"0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c_0xFc6F5db15a20Ed19B04eCaa1D572fFd415AA6b4e": {
			"pair_address": "0xdEFaBD2d47F40e1bf61E611e0dD9464f9b05b4dB",
			"base_name": "Wrapped BNB",
			"base_symbol": "WBNB",
			"base_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"quote_name": "Uniswap BSC",
			"quote_symbol": "UNIBSC",
			"quote_address": "0xFc6F5db15a20Ed19B04eCaa1D572fFd415AA6b4e",
			"price": "2.096167213677562654626696516266660048754586522e+26",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "2565840143533.324057187350042995",
			"liquidity_BNB": "7186276880.392359055820316585715"
		},
		"0x7e0deFAE6CB1D094F54386Fc49aA90B14BD9905e_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0x7F8058F22541d2D56E0603d1a3D5cEE6107A1b01",
			"base_name": "Ape",
			"base_symbol": "$APE",
			"base_address": "0x7e0deFAE6CB1D094F54386Fc49aA90B14BD9905e",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "6.3e-19",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "2521531809156.657933893616246025",
			"liquidity_BNB": "7375381349.905277596222847291114"
		},
		"0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c_0xD5429389A2F02D7Ee9995607e7F1AbD27002c1b5": {
			"pair_address": "0x07FE5dc3d5fC1D70d321547BdB765D8041327158",
			"base_name": "Wrapped BNB",
			"base_symbol": "WBNB",
			"base_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"quote_name": "SuperSushi",
			"quote_symbol": "SUPSushi",
			"quote_address": "0xD5429389A2F02D7Ee9995607e7F1AbD27002c1b5",
			"price": "1582753098902237935.3021031430672045364",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "2514331279160.383820678519681508",
			"liquidity_BNB": "7368672107.52016876208908956659"
		},
		"0x72087e581FAdc3bb97d044878Adb655A1f3c00E7_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0xB888e792181133917535EDDb35639Df6593151D3",
			"base_name": "Safe Hoppy Doge",
			"base_symbol": "HOPPY",
			"base_address": "0x72087e581FAdc3bb97d044878Adb655A1f3c00E7",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "0",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "2510846756070.521120903136582145",
			"liquidity_BNB": "8262269704.64933175475952118598"
		},
		"0x902E9BF462cd7361B6aAbE07C0b354F20F40e4D9_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0xbb34B17aca06aEdF6f22858c04808F0AEeF8C274",
			"base_name": "Space Cardano",
			"base_symbol": "SPACEADA",
			"base_address": "0x902E9BF462cd7361B6aAbE07C0b354F20F40e4D9",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "6.3e-19",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "2477357820559.375617129995599354",
			"liquidity_BNB": "7323928899.147609717890812890325"
		},
		"0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c_0xC47eDe0bA2D4DC0BF64F6C146f4dBb4c59Fb469f": {
			"pair_address": "0x8149D2e2C9fD31420672C484251233746951A299",
			"base_name": "Wrapped BNB",
			"base_symbol": "WBNB",
			"base_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"quote_name": "NFTNFTs",
			"quote_symbol": "NFTNFTS",
			"quote_address": "0xC47eDe0bA2D4DC0BF64F6C146f4dBb4c59Fb469f",
			"price": "1584078148962327641.96259000931945175577",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "2471139279311.317066616859304023",
			"liquidity_BNB": "7310353963.670236689354076788795"
		},
		"0xA5282021d4d39d0b06B3f2E1B95198458C18BDC7_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0x0bf850D5641Cd33C45337AaBC6a54e3E11490e78",
			"base_name": "BNB Rocket Doge",
			"base_symbol": "BROCKETDOGE",
			"base_address": "0xA5282021d4d39d0b06B3f2E1B95198458C18BDC7",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "0",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "2466021884876.091548869621444914",
			"liquidity_BNB": "8050945146.1527640182586072422"
		},
		"0x6E114F787993d35a9D45cC42880653633cc2D1e1_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0x471685C8F3808602085dD3FD5270CfcE77816e60",
			"base_name": "BabyDoge",
			"base_symbol": "$bDoge",
			"base_address": "0x6E114F787993d35a9D45cC42880653633cc2D1e1",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "6.3e-19",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "2432147827269.406906646165238726",
			"liquidity_BNB": "7168079371.60605023150584409638"
		},
		"0xad80fC0c0f90D2aa5a933F88d7Fc720919cf44Bb_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0x2ADb3e0339616fe76f3EDeaFF1E8462a00202Ef8",
			"base_name": "MiniStrongElon",
			"base_symbol": "miniSElon",
			"base_address": "0xad80fC0c0f90D2aa5a933F88d7Fc720919cf44Bb",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "6.3e-19",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "2419517436501.587501994169274636",
			"liquidity_BNB": "7068285498.21926796898407485463"
		},
		"0x62C953eb4D1Eaf5bC910c336ac598356Fc9fD6c6_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0x719D403bfa2A8cBA25806398970EA3bEef5e9719",
			"base_name": "Mask Doge Coin",
			"base_symbol": "MDOGE",
			"base_address": "0x62C953eb4D1Eaf5bC910c336ac598356Fc9fD6c6",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "0",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "2396108846369.470860839191540027",
			"liquidity_BNB": "6968069080.910452439729814982915"
		},
		"0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c_0xcc80564a259941e65c14c273819cA161e5369BFA": {
			"pair_address": "0x01C3A107aAB71b64517C56C580f030Cf89beA488",
			"base_name": "Wrapped BNB",
			"base_symbol": "WBNB",
			"base_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"quote_name": "BSC BullPerks",
			"quote_symbol": "BSCBULL",
			"quote_address": "0xcc80564a259941e65c14c273819cA161e5369BFA",
			"price": "3.119605270408340126968313474808137707020327257154e+28",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "2355007048466.00209801166750807",
			"liquidity_BNB": "7279076373.80072309695565689159"
		},
		"0xbB332bEf98c4603FDA83eb85EE59d985d63eA8F3_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0x44B6F4dFdF65Aeea399864Ca3fadea529C9CeBAD",
			"base_name": "Akita BSC",
			"base_symbol": "AKITABSC",
			"base_address": "0xbB332bEf98c4603FDA83eb85EE59d985d63eA8F3",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "0",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "2332532806152.419457646779879234",
			"liquidity_BNB": "7288824223.45529036563150860809"
		},
		"0x3E889D88183742bAa4c70c51fE924B7F75D9bf20_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0x7D280e24153d579E48599d1241ad4155C62EB5b2",
			"base_name": "Doge ProMax",
			"base_symbol": "DOGEPROMAX",
			"base_address": "0x3E889D88183742bAa4c70c51fE924B7F75D9bf20",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "0",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "2280463341693.240663859698199128",
			"liquidity_BNB": "5911437290.686610322550297307625"
		},
		"0xA8961A49f201bc7ecCd915614B4Ec766591C0e6A_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0x0cAbfdCeD366eef8E4dC25f5fcF03d6F9c907895",
			"base_name": "ShibaEthereum",
			"base_symbol": "SHIBAETH",
			"base_address": "0xA8961A49f201bc7ecCd915614B4Ec766591C0e6A",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "6.3e-19",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "2274756734858.566641057751771066",
			"liquidity_BNB": "6611959346.278226147679356416095"
		},
		"0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c_0xd2F42C1E062Bd2738ABbE115310a8B390cEDA7e2": {
			"pair_address": "0x47aE1323eF3c3A157dD8A37C17433415d99ED267",
			"base_name": "Wrapped BNB",
			"base_symbol": "WBNB",
			"base_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"quote_name": "WrappedMOON",
			"quote_symbol": "wMOON",
			"quote_address": "0xd2F42C1E062Bd2738ABbE115310a8B390cEDA7e2",
			"price": "1583605269954902542.81360024203243767245",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "2239001466362.342713672877040847",
			"liquidity_BNB": "6527301032.78021697295679831564"
		},
		"0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c_0xF82EEbA7f949960AC153414566581B17CD18147b": {
			"pair_address": "0x3e69dcE9b469802313a4c19Bf9B3f3c9a36F9a27",
			"base_name": "Wrapped BNB",
			"base_symbol": "WBNB",
			"base_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"quote_name": "American Cake",
			"quote_symbol": "UCAKE",
			"quote_address": "0xF82EEbA7f949960AC153414566581B17CD18147b",
			"price": "2.2138752590030230492261288870760983538529344464e+26",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "2209295130422.824144097756337655",
			"liquidity_BNB": "5566567008.665541603394311549006"
		},
		"0x73c52503B9D56427Be967F08F52e47a3c2358736_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0x3ac1Ea8DCFab0a1486f6FA0D5Cba4CeD74279D20",
			"base_name": "Hundred x Inu",
			"base_symbol": "100xInu",
			"base_address": "0x73c52503B9D56427Be967F08F52e47a3c2358736",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "6.3e-19",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "2207898782044.40357204154800167",
			"liquidity_BNB": "6503128424.49625908620092871997"
		},
		"0x93Ac1C2c33558f688B65b05dAA4feC1cDB7CAFE1_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0x7c5A3FB2C5CBc8fB685e048DF47D781f0B435e84",
			"base_name": "Astro Bull",
			"base_symbol": "NFTASTRO",
			"base_address": "0x93Ac1C2c33558f688B65b05dAA4feC1cDB7CAFE1",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "0",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "2203884964285.82522054835058853",
			"liquidity_BNB": "6586730156.6806053124158269191"
		},
		"0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c_0xE89c66F5bD4F4Aa6Ef0373365f8cBa7A7ba7B473": {
			"pair_address": "0x45cF686CF3792ba58A046bB40a92CffeF240fF47",
			"base_name": "Wrapped BNB",
			"base_symbol": "WBNB",
			"base_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"quote_name": "Hundred x Boxer",
			"quote_symbol": "100xBOX",
			"quote_address": "0xE89c66F5bD4F4Aa6Ef0373365f8cBa7A7ba7B473",
			"price": "1578989723993641890.79783488768159729439",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "2137547850175.61337766764707194",
			"liquidity_BNB": "5553204671.94794234504454360475"
		},
		"0x8C0646E3c04e46b3F4dF350Ed9A3614782694960_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0xDeC03348477B5fb80283C79C34acAD887484aa6A",
			"base_name": "AquaFEG",
			"base_symbol": "AquaFEG",
			"base_address": "0x8C0646E3c04e46b3F4dF350Ed9A3614782694960",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "6.3e-19",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "2115991665845.381541015985375714",
			"liquidity_BNB": "5533542373.280786076784926264795"
		},
		"0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c_0xf089993308b99a8DDDABBc045e956D91dACB93b5": {
			"pair_address": "0x316f0C0A4bAF638e7150E211Ec6806cca8E3E8A8",
			"base_name": "Wrapped BNB",
			"base_symbol": "WBNB",
			"base_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"quote_name": "BabyMOON",
			"quote_symbol": "BabyMOON",
			"quote_address": "0xf089993308b99a8DDDABBc045e956D91dACB93b5",
			"price": "1580494466695956973.8962106312146315564",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "2115301252586.505747705099175394",
			"liquidity_BNB": "5253689841.733173840044888435296"
		},
		"0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c_0xcA3DD236F78Eb1cd7776f5d4A734B640f039D3E9": {
			"pair_address": "0xd32A8D783DF45909D83F724C6890Ee1d1DB3C1DD",
			"base_name": "Wrapped BNB",
			"base_symbol": "WBNB",
			"base_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"quote_name": "Ripple",
			"quote_symbol": "$XRP",
			"quote_address": "0xcA3DD236F78Eb1cd7776f5d4A734B640f039D3E9",
			"price": "1582243538475322901.89674064926602711416",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "2105197245176.913665188283245083",
			"liquidity_BNB": "5497120142.66899538809765292303"
		},
		"0x38217Ebe801dd97A14eb309324CbD8110ABA2a86_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0xa4F725a0Ca450b9b9e5ADf970CfdA68005522207",
			"base_name": "Hundred x Ethereum",
			"base_symbol": "100xETH",
			"base_address": "0x38217Ebe801dd97A14eb309324CbD8110ABA2a86",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "6.3e-19",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "2084624793415.354087422474275918",
			"liquidity_BNB": "6037152876.07784132431965335569"
		},
		"0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c_0xFd2B1c5B842580b07bd131f31D0a39b20D89b61B": {
			"pair_address": "0x84110856270Cb844Ab670A3129b458717E1b4Ad0",
			"base_name": "Wrapped BNB",
			"base_symbol": "WBNB",
			"base_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"quote_name": "The Asia Doge",
			"quote_symbol": "ASIADOGE",
			"quote_address": "0xFd2B1c5B842580b07bd131f31D0a39b20D89b61B",
			"price": "5.872473629952401233565428346253495662619934449243e+28",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "2069080976501.361522284714275076",
			"liquidity_BNB": "7317130707.155835349711248857074"
		},
		"0x8C2cD4777BB1A04cA8c0E2129E7FF270Bd6Ecc30_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0xA7d8741f4212FcfeeF7c915a741Ef13EE822ff59",
			"base_name": "FlokiDoge",
			"base_symbol": "FlokiDOGE",
			"base_address": "0x8C2cD4777BB1A04cA8c0E2129E7FF270Bd6Ecc30",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "6.3e-19",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "2062953811215.898289429423306106",
			"liquidity_BNB": "5386161090.3628847145212295943"
		},
		"0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c_0xc01A70FC7a523ce0A8B55AaFB8E4f86653647046": {
			"pair_address": "0x577931576D2e1bfcF7BaD962135C0EbFC9668e01",
			"base_name": "Wrapped BNB",
			"base_symbol": "WBNB",
			"base_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"quote_name": "RoyalPanda",
			"quote_symbol": "RoyalPNDA",
			"quote_address": "0xc01A70FC7a523ce0A8B55AaFB8E4f86653647046",
			"price": "1578701159756491199.61671571205776557511",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "2025916677392.09546694955863054",
			"liquidity_BNB": "5252669172.942091962345168018425"
		},
		"0xaa9D0abB06f6284CB0d5B4c74d1606082342f307_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0xD41f079c4Bbb59c42984223d20AE35b032FB556B",
			"base_name": "SuperArt",
			"base_symbol": "SUPART",
			"base_address": "0xaa9D0abB06f6284CB0d5B4c74d1606082342f307",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "6.3e-19",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "2007753293225.794747543894200648",
			"liquidity_BNB": "5237223355.38718936058407507599"
		},
		"0x21cF74c5fc432619975ba8e39Df91C4B02fAbDF3_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0x3cE1946D295C9930265668676B47F61A725706A4",
			"base_name": "Hundred x Cat",
			"base_symbol": "100xCat",
			"base_address": "0x21cF74c5fc432619975ba8e39Df91C4B02fAbDF3",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "6.3e-19",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "2004429874709.159167868679395566",
			"liquidity_BNB": "5898738415.568032219369390013054"
		},
		"0xb549Daf57F3b5A9e7f2424F95D511cB742d965Bc_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0x231acABB33C722D04f4FDbAA0D46271e0Cb1d985",
			"base_name": "BabySafe",
			"base_symbol": "BabySAFE",
			"base_address": "0xb549Daf57F3b5A9e7f2424F95D511cB742d965Bc",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "6.3e-19",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "1968730227414.862617469979253046",
			"liquidity_BNB": "5838741169.19119370291587871604"
		},
		"0xA3C25E89215bEBA31D127Ea25bE92cBd9d3C92Bf_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0xE51a11db5F6F303FB38095FE968FeE041d026961",
			"base_name": "FlokiPanda",
			"base_symbol": "FlokiPNDA",
			"base_address": "0xA3C25E89215bEBA31D127Ea25bE92cBd9d3C92Bf",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "6.3e-19",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "1961571366362.664121492547338264",
			"liquidity_BNB": "5797506097.43517685458792212473"
		},
		"0x8ea8df2BECa5C7486f937Fc4a6871dD18055673a_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0x92B24E0015b442A6C89E35fEDCB29734f415CEfc",
			"base_name": "EverMOON",
			"base_symbol": "EverMOON",
			"base_address": "0x8ea8df2BECa5C7486f937Fc4a6871dD18055673a",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "6.3e-19",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "1925213865514.657738862974043827",
			"liquidity_BNB": "5733128512.445532948464809993535"
		},
		"0x5e3D1E67619e978FeF428244b3477771803D6521_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0x09Be6a4a62a2c6C20cb439ec379F04609AC40cf0",
			"base_name": "Impossible Decentrailized Incubator Access",
			"base_symbol": "IDIA",
			"base_address": "0x5e3D1E67619e978FeF428244b3477771803D6521",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "0",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "1923376536008.913452248682460222",
			"liquidity_BNB": "4827682149.60335209317488468998"
		},
		"0x45534D4dCc9a336375a61A0cc06CA5F5E861Ad1d_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0x5e03a171078e38B03BE043A1e44Dd809d464bC90",
			"base_name": "WrappedBabyDoge",
			"base_symbol": "wbDoge",
			"base_address": "0x45534D4dCc9a336375a61A0cc06CA5F5E861Ad1d",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "6.3e-19",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "1921523315294.10070569289681905",
			"liquidity_BNB": "5710050586.103578869504381509005"
		},
		"0x4f4a0377a4E93C1De98C30EC9d054D6937ec2c66_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0xE89c4A1985C3A9862eFE50B70E6E111B6AEb8011",
			"base_name": "OnlyUpSushi",
			"base_symbol": "UpSushi",
			"base_address": "0x4f4a0377a4E93C1De98C30EC9d054D6937ec2c66",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "6.3e-19",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "1909831323489.608055882165293847",
			"liquidity_BNB": "5757369893.58392693004460908216"
		},
		"0x728f3efE0a8B53d375B1A1bcCd3502F677962cd6_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0xa1703AE5AC790B0d01e0C41f81984520D88Bf285",
			"base_name": "NFTs x LiteCoin",
			"base_symbol": "NFTxLTC",
			"base_address": "0x728f3efE0a8B53d375B1A1bcCd3502F677962cd6",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "6.3e-19",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "1908892912898.481509944998034706",
			"liquidity_BNB": "5747406292.58654421374713537156"
		},
		"0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c_0xd4E505Aeab8A88bD28ABD754D59c4cD22A2C1220": {
			"pair_address": "0x86d9D3F68590DE2fDD9EEAe130a725b95FBc4dfb",
			"base_name": "Wrapped BNB",
			"base_symbol": "WBNB",
			"base_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"quote_name": "SuperSafe",
			"quote_symbol": "SUPSAFE",
			"quote_address": "0xd4E505Aeab8A88bD28ABD754D59c4cD22A2C1220",
			"price": "1585563728039241413.72889750170670541834",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "1831846536453.40542701743941053",
			"liquidity_BNB": "5523318685.90723440892973106484"
		},
		"0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c_0xBD407ace25C9E65987BAe2acA5a1019De9956b35": {
			"pair_address": "0x4226aB7046a4c43bA01c733DbAEB1Ad7b76204bd",
			"base_name": "Wrapped BNB",
			"base_symbol": "WBNB",
			"base_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"quote_name": "SpaceBabyDoge",
			"quote_symbol": "SPACEbDoge",
			"quote_address": "0xBD407ace25C9E65987BAe2acA5a1019De9956b35",
			"price": "1584913378200907290.78068480494031163468",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "1808069271426.523147226272909042",
			"liquidity_BNB": "5312096127.995311238488543707274"
		},
		"0x82d386464C86982695E02A4c7DC2d8B55DCCBF6F_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0x1A1cB07975DF5CA5d2aa93DA1358211e1f73380B",
			"base_name": "Chubby BabyDoge",
			"base_symbol": "CBDoge",
			"base_address": "0x82d386464C86982695E02A4c7DC2d8B55DCCBF6F",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "0",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "1791010159304.051100897243712933",
			"liquidity_BNB": "5765875438.121892806078816730264"
		},
		"0x9f7099c96DFfE2B5EddE98dDB2ECDF0aE9BA338a_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0x0D3378590f79618a27d6Dee4FAa527649b270e5f",
			"base_name": "MiniShibaInu",
			"base_symbol": "MiniSHIBA",
			"base_address": "0x9f7099c96DFfE2B5EddE98dDB2ECDF0aE9BA338a",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "0",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "1770145360051.42652317222982513",
			"liquidity_BNB": "5168924816.272506672973145160355"
		},
		"0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c_0xe69a7349e0eACA7ED7986FB8590b11106DA53534": {
			"pair_address": "0x9FEf342a587Dc3Fc1AA456C3664EC944e8F5951c",
			"base_name": "Wrapped BNB",
			"base_symbol": "WBNB",
			"base_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"quote_name": "Mini Doge Cash",
			"quote_symbol": "MDC",
			"quote_address": "0xe69a7349e0eACA7ED7986FB8590b11106DA53534",
			"price": "4.98674242187215357562967129558014121460654243e+24",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "1743146325953.662137529676891755",
			"liquidity_BNB": "5581169906.83435823968171071858"
		},
		"0x8FE9eEf046682B8A6E3DE5b5fA19A896aBc2212C_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0xa635FE27E9dF1cd81730553b93ddB62A3943A717",
			"base_name": "ShibaBNB",
			"base_symbol": "ShibBNB",
			"base_address": "0x8FE9eEf046682B8A6E3DE5b5fA19A896aBc2212C",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "6.3e-19",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "1698990349485.894729388115547966",
			"liquidity_BNB": "5100781247.90264290954037292322"
		},
		"0x20E974eafD211Fa4f37dfbd189ad7fdEc41a513b_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0xF2b5DbbCDf83754B950E14b49C3F3bCAce4B5B1b",
			"base_name": "XRPPay Gateway",
			"base_symbol": "XRPPAY",
			"base_address": "0x20E974eafD211Fa4f37dfbd189ad7fdEc41a513b",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "0",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "1689208925099.449027320518585946",
			"liquidity_BNB": "5045260486.012606438242455985934"
		},
		"0x8655aF16a6463F01f8cDD1DCd7D7cDC338d83EDb_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0x148760052f5F85c50cf5fA5bED3c2dC0E0e343c5",
			"base_name": "SuperSushi",
			"base_symbol": "SUPSushi",
			"base_address": "0x8655aF16a6463F01f8cDD1DCd7D7cDC338d83EDb",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "6.3e-19",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "1653349675694.300855252376573507",
			"liquidity_BNB": "4176155487.621987356616677542614"
		},
		"0x7F5E1fDB2058085F255eaA80a985Bf8a2Fa47dF8_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0x757BBE99AE74b5346fcfbACdf74BfFa48c2428f2",
			"base_name": "FlokiStrongElon",
			"base_symbol": "FlokiSElon",
			"base_address": "0x7F5E1fDB2058085F255eaA80a985Bf8a2Fa47dF8",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "6.3e-19",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "1622101492997.315980550553022998",
			"liquidity_BNB": "4067232306.874721365793462614194"
		},
		"0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c_0xFa4934A2c8FB944b650DD578F1e1aB0DDCC541A6": {
			"pair_address": "0xa5FAeaD0056E06084058fd156672F5979dfc2f70",
			"base_name": "Wrapped BNB",
			"base_symbol": "WBNB",
			"base_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"quote_name": "EverNFTArt",
			"quote_symbol": "EverNFTA",
			"quote_address": "0xFa4934A2c8FB944b650DD578F1e1aB0DDCC541A6",
			"price": "1585702408269018524.78737727790633823859",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "1578431947614.144487122922982622",
			"liquidity_BNB": "4901988424.62818405795731848277"
		},
		"0x9ca7917699f33BE092E3c3Ec08ae73a3DfaFC8F4_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0xd20B75ffe2e5908bCB8EA83C31C0ebFC3AC927dA",
			"base_name": "NanoNFTArt",
			"base_symbol": "NanoNFTA",
			"base_address": "0x9ca7917699f33BE092E3c3Ec08ae73a3DfaFC8F4",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "6.3e-19",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "1570236850505.377167457227485165",
			"liquidity_BNB": "4014405750.292333135875922119374"
		},
		"0xb6b6dd144cACA3e8E1087fB3A0F98C60740ad918_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0xf998e7cCf4D5Ec7304Dca5b0D6582A752E602D0a",
			"base_name": "WrappedNFTArt",
			"base_symbol": "wNFTA",
			"base_address": "0xb6b6dd144cACA3e8E1087fB3A0F98C60740ad918",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "6.3e-19",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "1567037273361.14749281413533224",
			"liquidity_BNB": "3936132661.535583673726710978985"
		},
		"0x3E2fa145517Fbe8b1E0f781b834A707c8Ba72292_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0x646e3767BC5A57C4654fdbAd6638C1f4E80c3Cf6",
			"base_name": "OnlyUpSafe",
			"base_symbol": "UpSAFE",
			"base_address": "0x3E2fa145517Fbe8b1E0f781b834A707c8Ba72292",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "6.3e-19",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "1566865340088.769423940797835862",
			"liquidity_BNB": "4855590134.029486802724631813104"
		},
		"0x9F79B041AdA5e7f51145f81b0582db506c649257_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0xB09bE0C2EFEAb690F1Ca8f6Ff832D6f751B68341",
			"base_name": "FEG Finance",
			"base_symbol": "FEGFIN",
			"base_address": "0x9F79B041AdA5e7f51145f81b0582db506c649257",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "6.3e-19",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "1549035516956.629403736097225854",
			"liquidity_BNB": "3939309025.20638493102898417374"
		},
		"0x6e3f40378eDE9778F455fda4E670750F70b52220_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0x904CD760D481d3E9Bc3332ec2CB7F8e9b1D07fd9",
			"base_name": "FlokiNFTArt",
			"base_symbol": "FlokiNFTA",
			"base_address": "0x6e3f40378eDE9778F455fda4E670750F70b52220",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "6.3e-19",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "1547775604112.273592850513686479",
			"liquidity_BNB": "3890109961.69857356540692709927"
		},
		"0x21665f22511C38F94d8856ce007C9156Fbf76d62_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0x30EcCBFA76e2F05C0b4de8761Cbfb1FceCA60275",
			"base_name": "MiniBoxer",
			"base_symbol": "miniBOX",
			"base_address": "0x21665f22511C38F94d8856ce007C9156Fbf76d62",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "6.3e-19",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "1546623256892.877078027987181773",
			"liquidity_BNB": "3912887466.009651651453150671897"
		},
		"0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c_0xf988270a52202b67b327963f43D9f647E40c8fac": {
			"pair_address": "0xfA933Bed8fF2b9861c2883d012CEB5191AC50730",
			"base_name": "Wrapped BNB",
			"base_symbol": "WBNB",
			"base_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"quote_name": "SuperSushi",
			"quote_symbol": "SSushi",
			"quote_address": "0xf988270a52202b67b327963f43D9f647E40c8fac",
			"price": "1582783200412988198.88014497986590844743",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "1546598068050.189064877046215121",
			"liquidity_BNB": "4809399160.47475954490371198126"
		},
		"0x49181Ecac151B262796a03E76Cdec7FaeBc6dcF5_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0x264366cD3c673EbbaDa69Fe2b2732EEcaC07a41E",
			"base_name": "NFTDoge",
			"base_symbol": "NFTDOGE",
			"base_address": "0x49181Ecac151B262796a03E76Cdec7FaeBc6dcF5",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "6.3e-19",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "1542893125288.557504611278272213",
			"liquidity_BNB": "3923717187.749363587132249367375"
		},
		"0x12D9573eC609E9ca646564Ae6c364442687C0b5C_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0x6283c70ed4aBf87462116a058D75B9D3A8D7190c",
			"base_name": "FEGSafe",
			"base_symbol": "FEGSAFE",
			"base_address": "0x12D9573eC609E9ca646564Ae6c364442687C0b5C",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "6.3e-19",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "1534945779506.869353861631448219",
			"liquidity_BNB": "4158192751.031453080958098343664"
		},
		"0x6e808385857E6F72C66DB046256442945c44A1D3_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0x0380ed8c64D4Ec2cB54b093E8D203258E1325d14",
			"base_name": "FlokiFEG",
			"base_symbol": "fFEG",
			"base_address": "0x6e808385857E6F72C66DB046256442945c44A1D3",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "6.3e-19",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "1534230558343.22779393339908833",
			"liquidity_BNB": "3787253078.100363525080700784327"
		},
		"0x0505Bb9e1945F16707d2c4B60374bA2d68e514c6_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0xA0B6f0F8e46921C423826525D85388c3888E8381",
			"base_name": "EverPanda",
			"base_symbol": "EverPanda",
			"base_address": "0x0505Bb9e1945F16707d2c4B60374bA2d68e514c6",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "6.3e-19",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "1531946429968.938375922036534102",
			"liquidity_BNB": "3854277430.077696855030171322416"
		},
		"0x488c378E5C4BE70d24155Bd1f55717980557a791_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0xF6fD4bb39bda0a27FdF9146fBC34A7Fc67d73Fd6",
			"base_name": "NanoStrongElon",
			"base_symbol": "NanoSElon",
			"base_address": "0x488c378E5C4BE70d24155Bd1f55717980557a791",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "6.3e-19",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "1530314261118.037348464928981891",
			"liquidity_BNB": "4136790608.264788380355956953627"
		},
		"0xaB8BF38cD9F5f0CC93c1Ab176Fe077bFBF913109_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0xb8Ed9d82dBb3aD5dA1514fa2BbF5104791f149a0",
			"base_name": "Binance Ape",
			"base_symbol": "BSCAPE",
			"base_address": "0xaB8BF38cD9F5f0CC93c1Ab176Fe077bFBF913109",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "6.3e-19",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "1530073256013.237045509089140021",
			"liquidity_BNB": "4159157409.239318532078647385723"
		},
		"0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c_0xEE418Fe597526E8e6B6fB2630c941AA2Ff902e54": {
			"pair_address": "0x178db844E7B590F1F60cb6cFfb7078494d1fF4ef",
			"base_name": "Wrapped BNB",
			"base_symbol": "WBNB",
			"base_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"quote_name": "RoyalCash",
			"quote_symbol": "RoyalCASH",
			"quote_address": "0xEE418Fe597526E8e6B6fB2630c941AA2Ff902e54",
			"price": "1581382072451981649.33165478755739133044",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "1529956352361.380919146939557599",
			"liquidity_BNB": "3845525028.417956489989393369872"
		},
		"0x2D090f31A3Dc6D2d980c0ccb2567B0032a1bac1d_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0xb475305443663b08095301D28db0F60BF8A18459",
			"base_name": "OnlyUpPanda",
			"base_symbol": "UpPanda",
			"base_address": "0x2D090f31A3Dc6D2d980c0ccb2567B0032a1bac1d",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "6.3e-19",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "1527111190639.183120055753342077",
			"liquidity_BNB": "4160655016.0327719270618647453"
		},
		"0x1fC9EaA575eF929640133a0569F909f86a14531D_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0xc2D96d055699597fBF8Fd28cc391Cf690E1262B7",
			"base_name": "EverNFTArt",
			"base_symbol": "EverNFTA",
			"base_address": "0x1fC9EaA575eF929640133a0569F909f86a14531D",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "6.3e-19",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "1521953285211.597231286047686381",
			"liquidity_BNB": "3823277695.76270671842794957268"
		},
		"0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c_0xc84B821FAeec5822D1b2cc9c71bdf7078a7C1f0d": {
			"pair_address": "0x8Cede613a2367B24e8B5397c623e0EF52e6C7Ed0",
			"base_name": "Wrapped BNB",
			"base_symbol": "WBNB",
			"base_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"quote_name": "ShibaEthereum",
			"quote_symbol": "SHIBAETH",
			"quote_address": "0xc84B821FAeec5822D1b2cc9c71bdf7078a7C1f0d",
			"price": "1581377009652393870.3529272440839501019",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "1515632947482.3550160671051986",
			"liquidity_BNB": "3804351593.91561802307418951445"
		},
		"0x00306A4316dD29e21087515e5Fe11005A11e4d5d_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0x56DFe476E566fcd5aED51560fDC75E3427829518",
			"base_name": "SuperDoge",
			"base_symbol": "SDOGE",
			"base_address": "0x00306A4316dD29e21087515e5Fe11005A11e4d5d",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "6.3e-19",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "1503037239409.108821198974518269",
			"liquidity_BNB": "4082017875.275303289447184179678"
		},
		"0x6391b8e7bB2BC744d3Df93456FBD74b7AC10c182_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0x9498479F473Ea5295d69d2252Bb9e137eE7481Bb",
			"base_name": "BabyElon",
			"base_symbol": "BABYELON",
			"base_address": "0x6391b8e7bB2BC744d3Df93456FBD74b7AC10c182",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "0",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "1486957752672.27340724439213369",
			"liquidity_BNB": "4870930960.27230618972776606291"
		},
		"0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c_0xC366850FDBf95633A5F9Fd7FF6ead93ef1f233F7": {
			"pair_address": "0x83197a41B24F8c07F6185df7eb32BADDDffAe357",
			"base_name": "Wrapped BNB",
			"base_symbol": "WBNB",
			"base_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"quote_name": "FEGNFTs",
			"quote_symbol": "FEGNFTS",
			"quote_address": "0xC366850FDBf95633A5F9Fd7FF6ead93ef1f233F7",
			"price": "1581731037660652865.13061889026335078771",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "1473128994024.239526239389330342",
			"liquidity_BNB": "4042243831.12336327633662857776"
		},
		"0x6Ced3747414c564E4FEfe94Bb4292AC1a5B50408_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0x238C0F53d6Ac2315d8dB62226602B210E8C057e8",
			"base_name": "NFTs x Sushi",
			"base_symbol": "NFTxSushi",
			"base_address": "0x6Ced3747414c564E4FEfe94Bb4292AC1a5B50408",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "6.3e-19",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "1465767062555.74635166955525728",
			"liquidity_BNB": "3993935109.207057306114674391527"
		},
		"0x37Ba9EE739428E0e68D14ccEbD8527413d1023c6_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0x057C95DbD572bd98f9e8d2B87e7Ab5827343d3F4",
			"base_name": "NanoLoki",
			"base_symbol": "NanoLOKI",
			"base_address": "0x37Ba9EE739428E0e68D14ccEbD8527413d1023c6",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "6.3e-19",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "1457001528559.595007568212066124",
			"liquidity_BNB": "3952161345.88699449868545560987"
		},
		"0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c_0xbF1f1C39429dB06e95FDBc4b216FFAc0E8D0938B": {
			"pair_address": "0xfAB81A6613055DB214FCbE143789282cee72D876",
			"base_name": "Wrapped BNB",
			"base_symbol": "WBNB",
			"base_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"quote_name": "Shiba Cardano",
			"quote_symbol": "SHIBAADA",
			"quote_address": "0xbF1f1C39429dB06e95FDBc4b216FFAc0E8D0938B",
			"price": "1585614936206058716.04118414915136700226",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "1455951549087.754249148432181802",
			"liquidity_BNB": "4556513694.698200318014663540595"
		},
		"0x2F0fAaC9229d68caA0A76abE83C4C069a8297752_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0x00c3aC3F7E7c2b7c66410dbFdAD5AE95282C9cc9",
			"base_name": "BabyInu",
			"base_symbol": "bInu",
			"base_address": "0x2F0fAaC9229d68caA0A76abE83C4C069a8297752",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "6.3e-19",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "1429467797070.5156745610822645",
			"liquidity_BNB": "3877263156.176602325484793193686"
		},
		"0x768c4eE206eb5114dF8D98639B04EECB60055043_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0xAFaaB7AB26420C0462D998e9a0aD1cbC28c11Fd4",
			"base_name": "FlokiLiteCoin",
			"base_symbol": "fLTC",
			"base_address": "0x768c4eE206eb5114dF8D98639B04EECB60055043",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "6.3e-19",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "1413042669599.305709551390866285",
			"liquidity_BNB": "3869177803.589460690911840552952"
		},
		"0x6505E477A38610c4B5006b7979A1E86D64dadFEa_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0x4B8734742Da108b2976411d51c5ebE3cec1de02A",
			"base_name": "NFTs x FEG",
			"base_symbol": "NFTxFEG",
			"base_address": "0x6505E477A38610c4B5006b7979A1E86D64dadFEa",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "6.3e-19",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "1394935122709.411604634615670742",
			"liquidity_BNB": "3750479062.7938543663653181077"
		},
		"0x21Cf7AF880c1e73e9eeE63Bc45fDa9d6c5f9a942_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0x9De9B1e1C93bA659024Cd823131BB7dbC2524897",
			"base_name": "EverSushi",
			"base_symbol": "EverSushi",
			"base_address": "0x21Cf7AF880c1e73e9eeE63Bc45fDa9d6c5f9a942",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "6.3e-19",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "1391806927742.475730099094174108",
			"liquidity_BNB": "3720581180.322795662595633465114"
		},
		"0x64f39aBCDcEF4c00738e347761693EF6954f6A30_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0x400C896EE34f437C2f418daA49FE60c2b3308347",
			"base_name": "NanoDoggy.Finnance",
			"base_symbol": "NANODOGGY",
			"base_address": "0x64f39aBCDcEF4c00738e347761693EF6954f6A30",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "0",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "1382979329405.264193619641456285",
			"liquidity_BNB": "4017074249.68214085194010809529"
		},
		"0xB33dC55Ecf41Efb7018BADa3686E2252223b4237_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0x94fdE1B358AD8f6dFe1A96969991DCf4188fF966",
			"base_name": "Mini Black",
			"base_symbol": "miniB",
			"base_address": "0xB33dC55Ecf41Efb7018BADa3686E2252223b4237",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "6.3e-19",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "1373867308503.22010186256360897",
			"liquidity_BNB": "4312515551.72129834932771538505"
		},
		"0x970B72E21d987bfc250875fBf6a911dDa4104470_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0x5FA4Ac38C1b4710cfcee2DBdA8f366aB545AB414",
			"base_name": "NanoMOON",
			"base_symbol": "NanoMOON",
			"base_address": "0x970B72E21d987bfc250875fBf6a911dDa4104470",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "6.3e-19",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "1365596679462.169474742024502417",
			"liquidity_BNB": "4245660353.3120974382945031863"
		},
		"0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c_0xD310d280a15B463433f581C88c3774A3bB0673dB": {
			"pair_address": "0x84f3d250BB757bD98F4A85ee8628FEc62F1f78a8",
			"base_name": "Wrapped BNB",
			"base_symbol": "WBNB",
			"base_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"quote_name": "NFTs x Cash",
			"quote_symbol": "NFTxCASH",
			"quote_address": "0xD310d280a15B463433f581C88c3774A3bB0673dB",
			"price": "1586149539352588094.12304167874718259035",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "1361752896706.30106573331772497",
			"liquidity_BNB": "4234515459.872453093762343445517"
		},
		"0x491f9299efeC6e42e631663bBBfdcF6a5e52dB18_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0x9C72c3AAdcC4687B8c5cb25a094d0f6a22a82f33",
			"base_name": "ShibaSushi",
			"base_symbol": "SHIBASushi",
			"base_address": "0x491f9299efeC6e42e631663bBBfdcF6a5e52dB18",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "6.3e-19",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "1357065221288.646508667067154244",
			"liquidity_BNB": "3695851801.310192212809384514307"
		},
		"0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c_0xDA49eC493144a97DF3A34fBF65ffEc9f19643607": {
			"pair_address": "0x8221cdF652FdF26929cbbf92dd80A709fc8f518f",
			"base_name": "Wrapped BNB",
			"base_symbol": "WBNB",
			"base_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"quote_name": "NFTs by Safe",
			"quote_symbol": "NFTSAFE",
			"quote_address": "0xDA49eC493144a97DF3A34fBF65ffEc9f19643607",
			"price": "1584373299331176372.71312967981004802007",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "1322736734909.060588161626875145",
			"liquidity_BNB": "3716812515.020782408404464409316"
		},
		"0x58f247bFb429643e4e43FdE5279736efACE31A13_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0x85b821430563c3A5E1D86656BF9A2861768b9e53",
			"base_name": "BabyNFTArt",
			"base_symbol": "bNFTA",
			"base_address": "0x58f247bFb429643e4e43FdE5279736efACE31A13",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "6.3e-19",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "1319061639691.090524093916227398",
			"liquidity_BNB": "3699276083.186984030294574357452"
		},
		"0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c_0xC218a1756986d98DF391dbe87D6e295985c39965": {
			"pair_address": "0xa4db25d47035fa673914A3Cf62C4454ED0647a02",
			"base_name": "Wrapped BNB",
			"base_symbol": "WBNB",
			"base_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"quote_name": "ShibaArt",
			"quote_symbol": "SHIBAART",
			"quote_address": "0xC218a1756986d98DF391dbe87D6e295985c39965",
			"price": "1578899910813143306.14459585167586695177",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "1308941286129.791739922849147295",
			"liquidity_BNB": "3598828236.590220977462020152742"
		},
		"0x5B83360BbE51c8b1230FCcA6CC43729590b35911_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0xD3a10Dbe0f69B0ba8eb2151f5d7a0A0285E0398A",
			"base_name": "ShibaCat",
			"base_symbol": "SHIBACat",
			"base_address": "0x5B83360BbE51c8b1230FCcA6CC43729590b35911",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "6.3e-19",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "1305566985367.446094353137974872",
			"liquidity_BNB": "3683489234.10107952511543135006"
		},
		"0x4211E5259AC22BD3B273F1b09f00F9918Ab999Fa_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0x550022Ba77c095177775A4d1061Cd602E43c6a30",
			"base_name": "SuperSafe",
			"base_symbol": "SSAFE",
			"base_address": "0x4211E5259AC22BD3B273F1b09f00F9918Ab999Fa",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "6.3e-19",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "1299539803090.266921382060479842",
			"liquidity_BNB": "3631775137.93915876335405241488"
		},
		"0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c_0xE4171495DFC5535f1BD4942155B3D7AAa2bb492D": {
			"pair_address": "0x04a04d9112E627fb3733F0891FC1eA8D84Dd1e45",
			"base_name": "Wrapped BNB",
			"base_symbol": "WBNB",
			"base_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"quote_name": "MiniPanda",
			"quote_symbol": "miniPNDA",
			"quote_address": "0xE4171495DFC5535f1BD4942155B3D7AAa2bb492D",
			"price": "1584257038617174946.03913238509942586364",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "1294793463305.629530243518731075",
			"liquidity_BNB": "4032759302.171151987147863076044"
		},
		"0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c_0xE7D7F404DeB357C82C8468b65856319c91d96066": {
			"pair_address": "0x2dF5bfC4DA0208D08FAbd4C3feb8e9F6ddAC350c",
			"base_name": "Wrapped BNB",
			"base_symbol": "WBNB",
			"base_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"quote_name": "baby Shark",
			"quote_symbol": "BBS",
			"quote_address": "0xE7D7F404DeB357C82C8468b65856319c91d96066",
			"price": "2.0357122705321780034184291638727062987820012818e+26",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "1294040866175.385987225367553425",
			"liquidity_BNB": "2042447274.267180653801340793754"
		},
		"0x57fc9cA698feb030BA9a393413F3cbA66f832240_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0x45d1Bfe08069f886e2Cbc3A78107b4e88faF7d01",
			"base_name": "KingDoge",
			"base_symbol": "KingDoge",
			"base_address": "0x57fc9cA698feb030BA9a393413F3cbA66f832240",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "0",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "1284888177385.113224565889098726",
			"liquidity_BNB": "4362092849.348379328223221812844"
		},
		"0x11Bb292D005A5dEd259a6809d2193a464c94E4F8_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0x2E2895B639dfD64B53b533921184a9C06AC748AE",
			"base_name": "Hundred x Boxer",
			"base_symbol": "100xBOX",
			"base_address": "0x11Bb292D005A5dEd259a6809d2193a464c94E4F8",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "6.3e-19",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "1279044362735.019879581635481185",
			"liquidity_BNB": "3990515443.70271156239976574255"
		},
		"0x8b4Cd1438fbC91417e068DC5617F91Cc01e391b1_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0xE1bC48C8955b0E95e45F47ef1afafE81A383C8D0",
			"base_name": "Polka Cake Swap",
			"base_symbol": "PolkaCake",
			"base_address": "0x8b4Cd1438fbC91417e068DC5617F91Cc01e391b1",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "0",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "1259419504287.93726223512803744",
			"liquidity_BNB": "3116240171.816616249442942593756"
		},
		"0x644c6f34b7653e19C38FcF10C9BaBB55CA1a57b3_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0x664F5DAB36fCD4b241f8683581650ceD19f1fBb1",
			"base_name": "DogeKool Token",
			"base_symbol": "DOGEKOOL",
			"base_address": "0x644c6f34b7653e19C38FcF10C9BaBB55CA1a57b3",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "0",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "1253367501950.257692962133224355",
			"liquidity_BNB": "3553401363.961806166607840072984"
		},
		"0x699152A585F928873f1A1dd821DC5931279C93F5_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0xB8E8828eEd79d99f855F794fF4A37b881D8ffFe3",
			"base_name": "SONIC INU",
			"base_symbol": "SONIC",
			"base_address": "0x699152A585F928873f1A1dd821DC5931279C93F5",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "0",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "1248505773012.254315160050601527",
			"liquidity_BNB": "4101393472.44290339275895508899"
		},
		"0x555209Ce5Fea24AD1575613C4ca16673bC8E0335_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0xAc4Fb4aF7Bc10b4bde69fe17b3a050A0D45AAFE1",
			"base_name": "OnlyUpApe",
			"base_symbol": "UpAPE",
			"base_address": "0x555209Ce5Fea24AD1575613C4ca16673bC8E0335",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "6.3e-19",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "1241927162161.953552671637603974",
			"liquidity_BNB": "3763007615.09164223927909510925"
		},
		"0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c_0xd8aE3b736D7c67eBc5A256eAdFcb3DE45AE87D5B": {
			"pair_address": "0x64E5c49d5Fc3C4187Ee40b60162a1956B0a6F465",
			"base_name": "Wrapped BNB",
			"base_symbol": "WBNB",
			"base_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"quote_name": "NFTs x StrongElon",
			"quote_symbol": "NFTxSElon",
			"quote_address": "0xd8aE3b736D7c67eBc5A256eAdFcb3DE45AE87D5B",
			"price": "1585986402881376198.7023534668787316193",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "1239706697186.244424449564464923",
			"liquidity_BNB": "3961284795.721552193394329971213"
		},
		"0xAac0c34B1Bd96739b6fFE0040BE2Fb67feA8eC64_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0x0ee701AAF003566f1eAf4aC752d72A1Af24375Fb",
			"base_name": "NFTs x BNB",
			"base_symbol": "NFTxBNB",
			"base_address": "0xAac0c34B1Bd96739b6fFE0040BE2Fb67feA8eC64",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "6.3e-19",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "1234342708887.888536178927295911",
			"liquidity_BNB": "3913775415.33034071282006596404"
		},
		"0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c_0xfD7943a4674A49166A610D46CDeDee0986e108C7": {
			"pair_address": "0x72E610056D04af5F39A13b22beC377e186ce1040",
			"base_name": "Wrapped BNB",
			"base_symbol": "WBNB",
			"base_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"quote_name": "SpaceNFTs",
			"quote_symbol": "SPACENFTS",
			"quote_address": "0xfD7943a4674A49166A610D46CDeDee0986e108C7",
			"price": "1585816481730236778.8495491706923388308",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "1233518676029.381698410211234136",
			"liquidity_BNB": "3953852898.077747051373316751526"
		},
		"0xa28EF6440073dFC218bCeA6C0955d607A83D983F_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0xd6fC730aC7233132dCc46c3262B60fC77EfC0d07",
			"base_name": "Floki Finance",
			"base_symbol": "fFIN",
			"base_address": "0xa28EF6440073dFC218bCeA6C0955d607A83D983F",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "6.3e-19",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "1222408182258.575135846257224265",
			"liquidity_BNB": "3891388793.022324387978685414716"
		},
		"0x789A41c785D033D9C8DF1255653cf69Cc4244414_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0xb9EF0899cc7F5a14991e6d3b7F19582Dbd548bB0",
			"base_name": "OnlyUpApe",
			"base_symbol": "UpAPE",
			"base_address": "0x789A41c785D033D9C8DF1255653cf69Cc4244414",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "6.3e-19",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "1211370563067.232509095012591585",
			"liquidity_BNB": "3814461261.173910992456868674567"
		},
		"0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c_0xf3DBDA558fA5F5F2779a9A1c4E03f4aA56147197": {
			"pair_address": "0x6Bba1013E5b81317Bb454B247cc35d47625213e2",
			"base_name": "Wrapped BNB",
			"base_symbol": "WBNB",
			"base_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"quote_name": "SpaceEthereum",
			"quote_symbol": "SPACEETH",
			"quote_address": "0xf3DBDA558fA5F5F2779a9A1c4E03f4aA56147197",
			"price": "1586182006240234858.89610152710800844553",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "1204107654073.14886408588756782",
			"liquidity_BNB": "3862705339.180089549600925995605"
		},
		"0x35dE455fB5bDde7f537f4c8A798176c47d1651fe_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0x3b6ec8024411a77de2762d30A6A285A9Dcc697Db",
			"base_name": "Baby Finance",
			"base_symbol": "BabyFIN",
			"base_address": "0x35dE455fB5bDde7f537f4c8A798176c47d1651fe",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "6.3e-19",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "1201543505399.022690795142072857",
			"liquidity_BNB": "3852791713.352490764250366127707"
		},
		"0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c_0xF340afF5D9eA5f0f1CF4D1c131CC0928e17b21C1": {
			"pair_address": "0x6c5af67A0bFC44F7608fCc60f6d6d3ac85fCC374",
			"base_name": "Wrapped BNB",
			"base_symbol": "WBNB",
			"base_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"quote_name": "MiniArt",
			"quote_symbol": "miniART",
			"quote_address": "0xF340afF5D9eA5f0f1CF4D1c131CC0928e17b21C1",
			"price": "1577565577448157914.91204053858111836377",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "1200827454340.518161858861412474",
			"liquidity_BNB": "3399670070.947313316328198351977"
		},
		"0x7731980dBfE6c3Bc3a40c02B72742571d02D74f0_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0xd9AFeE549bB8A7AeeE4494b12362a9aD330174c2",
			"base_name": "BabyArt",
			"base_symbol": "BabyART",
			"base_address": "0x7731980dBfE6c3Bc3a40c02B72742571d02D74f0",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "6.3e-19",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "1190489460341.288113789420525563",
			"liquidity_BNB": "3379226826.313696015892818432134"
		},
		"0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c_0xd6cB5D9204de038c02073bA9e8Ac873981f4c1B0": {
			"pair_address": "0x947C2EC807BAd55Df89bF187a4568e864485E0F2",
			"base_name": "Wrapped BNB",
			"base_symbol": "WBNB",
			"base_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"quote_name": "ShibaInu",
			"quote_symbol": "SHIBAInu",
			"quote_address": "0xd6cB5D9204de038c02073bA9e8Ac873981f4c1B0",
			"price": "1586325272846952765.45175120241401788686",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "1178726444857.70367999902276644",
			"liquidity_BNB": "3783365578.02882816099019947436"
		},
		"0xb026e4e7bb4a74f3bACCc0037B6408a610600072_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0x2452d171a547a2975Ba7850bE8Cee7B1a75bc388",
			"base_name": "FlokiBoxer",
			"base_symbol": "FlokiBOX",
			"base_address": "0xb026e4e7bb4a74f3bACCc0037B6408a610600072",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "6.3e-19",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "1174269935837.002415243206069362",
			"liquidity_BNB": "3333081132.885302725560108511415"
		},
		"0x4aA6A5E3B23612Ffce2B31894a85c0Df164a0937_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0x77648FCC581e4d94785907c502d0851fE01B7E69",
			"base_name": "NFTs x Cat",
			"base_symbol": "NFTxCat",
			"base_address": "0x4aA6A5E3B23612Ffce2B31894a85c0Df164a0937",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "6.3e-19",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "1161700773748.739192750459439874",
			"liquidity_BNB": "3726241205.250944258522481010196"
		},
		"0x568964d4F60498f51D4603d1D422c474E066A44e_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0x7a2C795BBa67E4B0dE6f496bddADe41F316B99f0",
			"base_name": "ShibaCash",
			"base_symbol": "SHIBACASH",
			"base_address": "0x568964d4F60498f51D4603d1D422c474E066A44e",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "6.3e-19",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "1154863120270.169109026330895312",
			"liquidity_BNB": "3705958878.181622941397028222352"
		},
		"0xaa091A3131AeDbF8a3541530fB50A2D394DF33dd_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0x90620B15De535E0B402866618E669C26711a32D1",
			"base_name": "RocketBoys",
			"base_symbol": "RocketBoys",
			"base_address": "0xaa091A3131AeDbF8a3541530fB50A2D394DF33dd",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "0",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "1151405081215.692374162044198556",
			"liquidity_BNB": "3785106768.22957409356896333687"
		},
		"0x96cC2e87f7c79adbD141aCA1Fe51994D384e9717_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0x9F298100FEC4cc44aDE5EF954eb4671f2AEc5ae6",
			"base_name": "BabyCat",
			"base_symbol": "BabyCat",
			"base_address": "0x96cC2e87f7c79adbD141aCA1Fe51994D384e9717",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "6.3e-19",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "1146153083323.772985609531721803",
			"liquidity_BNB": "3676293053.9351848231926030006"
		},
		"0x4A3eEe191E89EBdD6ce9E4bFA54FdF5E17F9643a_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0x1a65E393603f0C01EdD667E8b32996b2B9efe0e2",
			"base_name": "NFTs by BabyDoge",
			"base_symbol": "NFTbDoge",
			"base_address": "0x4A3eEe191E89EBdD6ce9E4bFA54FdF5E17F9643a",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "6.3e-19",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "1140027832582.402234103028646043",
			"liquidity_BNB": "3654225656.114120474342548463045"
		},
		"0x3c8D527C6E104E148929fae107948930a002bb20_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0x8EC05338EeCfb6eC95040E981150994f53EeAB41",
			"base_name": "BabyRipple",
			"base_symbol": "bXRP",
			"base_address": "0x3c8D527C6E104E148929fae107948930a002bb20",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "6.3e-19",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "1130020710367.346035968502190924",
			"liquidity_BNB": "3205969421.508629881492938605007"
		},
		"0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c_0xD92D9a37dd9D178B2181091c8b98e64a934EB48F": {
			"pair_address": "0x2B299160490F0eA78581f433A4d5A8fA2e65E94B",
			"base_name": "Wrapped BNB",
			"base_symbol": "WBNB",
			"base_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"quote_name": "EverBabyDoge",
			"quote_symbol": "EverbDoge",
			"quote_address": "0xD92D9a37dd9D178B2181091c8b98e64a934EB48F",
			"price": "1575434932560739047.66387612434037440534",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "1120357392884.850434615408431935",
			"liquidity_BNB": "3062039692.913422297804834932387"
		},
		"0x25447873750578bD92464B45b865e30f723993D2_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0x9F0eBa83efF36438b3D16903050FB626fF22BeB3",
			"base_name": "SpaceSushi",
			"base_symbol": "SPACESushi",
			"base_address": "0x25447873750578bD92464B45b865e30f723993D2",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "6.3e-19",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "1120088328866.69210193420586472",
			"liquidity_BNB": "3201248301.709867644225820398787"
		},
		"0x25cD807d4CE5e2570A172Ba2c70B6545722Ea599_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0x0dc0128650F7D413Aa2047dBEf9C06127fDB496E",
			"base_name": "SuperStrongElon",
			"base_symbol": "SSElon",
			"base_address": "0x25cD807d4CE5e2570A172Ba2c70B6545722Ea599",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "6.3e-19",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "1115775294170.837737238401570238",
			"liquidity_BNB": "3175359600.59274041253936960643"
		},
		"0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c_0xF59D7a78D138e4d013A1b6B07760383fE1A58E58": {
			"pair_address": "0x762e48b466E10E3b0a100745F6866E3ee2929708",
			"base_name": "Wrapped BNB",
			"base_symbol": "WBNB",
			"base_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"quote_name": "FEGPanda",
			"quote_symbol": "FEGPanda",
			"quote_address": "0xF59D7a78D138e4d013A1b6B07760383fE1A58E58",
			"price": "1579490230299537832.18131959448162584087",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "1114836638279.946261729589259675",
			"liquidity_BNB": "3144230002.505882656061876781947"
		},
		"0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c_0xF368e5cf4533B5419C42751c8b6886037CbDB7c6": {
			"pair_address": "0x0c04C159dD2a540829f6573D658569766e382DD2",
			"base_name": "Wrapped BNB",
			"base_symbol": "WBNB",
			"base_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"quote_name": "Floki Cardano",
			"quote_symbol": "fADA",
			"quote_address": "0xF368e5cf4533B5419C42751c8b6886037CbDB7c6",
			"price": "1581801891858139299.57761485501073989467",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "1112263262000.654729415571623239",
			"liquidity_BNB": "3578577132.430377417469740597448"
		},
		"0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c_0xd2184b7DDf1781c18696b28493b012eD43B45013": {
			"pair_address": "0xD32d1fD76ebCBa245f6697052a14cFcA566F3bDD",
			"base_name": "Wrapped BNB",
			"base_symbol": "WBNB",
			"base_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"quote_name": "MiniBabyDoge",
			"quote_symbol": "minibDoge",
			"quote_address": "0xd2184b7DDf1781c18696b28493b012eD43B45013",
			"price": "1582575986709127480.05115034575002485887",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "1100864496065.835987753863853862",
			"liquidity_BNB": "3539931383.313004306050092013077"
		},
		"0xA45754c9A72134869eC3F1B0eB1140855f442006_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0x21F8e791a05645f8BB6dFBaAFd963Bc2868F19e2",
			"base_name": "OnlyUpNFTArt",
			"base_symbol": "UpNFTA",
			"base_address": "0xA45754c9A72134869eC3F1B0eB1140855f442006",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "6.3e-19",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "1095499234952.829565324503065892",
			"liquidity_BNB": "3118494366.579670873040786771157"
		},
		"0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c_0xf3da67A595BD9aF7087e545E141e0634E236e92F": {
			"pair_address": "0x67BA5078727013C67C27e1CA00dC8FcAb79e0d9b",
			"base_name": "Wrapped BNB",
			"base_symbol": "WBNB",
			"base_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"quote_name": "WrappedStrongElon",
			"quote_symbol": "wSElon",
			"quote_address": "0xf3da67A595BD9aF7087e545E141e0634E236e92F",
			"price": "1586897726869607306.34949846156438862721",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "1091960953525.3023763926694273295",
			"liquidity_BNB": "3518829812.999544513536329339307"
		},
		"0x24289f29338A5FCbDd64481ddE7Dc306380C6118_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0x4912e272287E1e58a85fc756dEBc87e2470c3ec4",
			"base_name": "NFTs by FEG",
			"base_symbol": "NFTFEG",
			"base_address": "0x24289f29338A5FCbDd64481ddE7Dc306380C6118",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "6.3e-19",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "1091639261673.88686683190453113",
			"liquidity_BNB": "3080152510.70916806590616340218"
		},
		"0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c_0xBC1387B119FFa568506F8b5CC96ea7Dd40f63F04": {
			"pair_address": "0xbB0AC745aCcE2d65032C27c99758cC212BE932F7",
			"base_name": "Wrapped BNB",
			"base_symbol": "WBNB",
			"base_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"quote_name": "RoyalArt",
			"quote_symbol": "RoyalART",
			"quote_address": "0xBC1387B119FFa568506F8b5CC96ea7Dd40f63F04",
			"price": "1579854130338783136.55913067307819989223",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "1085381886223.172572855982130842",
			"liquidity_BNB": "3066833716.886866274917353012064"
		},
		"0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c_0xF8087A2c874dE99a480F4085D31ce1649e5794e2": {
			"pair_address": "0x8885E813838f595119894e87713BdacAcCe1b6D7",
			"base_name": "Wrapped BNB",
			"base_symbol": "WBNB",
			"base_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"quote_name": "SpaceEthereum",
			"quote_symbol": "SPACEETH",
			"quote_address": "0xF8087A2c874dE99a480F4085D31ce1649e5794e2",
			"price": "1580737149125899016.46524800091557312495",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "1082922506696.489006186850096069",
			"liquidity_BNB": "3056017266.533887620615663675905"
		},
		"0x6bA8d2E9865601631ae093580A144C9b88135fd3_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0x0Ec4797a65291EB4E927B857bA2D99de3bae77d1",
			"base_name": "SuperCash",
			"base_symbol": "SCASH",
			"base_address": "0x6bA8d2E9865601631ae093580A144C9b88135fd3",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "6.3e-19",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "1079202270224.676116120567020042",
			"liquidity_BNB": "3063117980.34554884333252021564"
		},
		"0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c_0xc67C1c80910AF8C99d07Daa43Aa447d53fA9C81A": {
			"pair_address": "0x888E74f083095f805D75635f2CeCdD5f6E68D336",
			"base_name": "Wrapped BNB",
			"base_symbol": "WBNB",
			"base_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"quote_name": "Binance Loki",
			"quote_symbol": "BSCLOKI",
			"quote_address": "0xc67C1c80910AF8C99d07Daa43Aa447d53fA9C81A",
			"price": "1578149080274627491.17238784749784473425",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "1072652571823.771251672150785265",
			"liquidity_BNB": "3038356504.469757163818380205142"
		},
		"0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c_0xE8551fB0118769eC389B7DF5Eac43F11d14769ca": {
			"pair_address": "0x6ac182fE6E34a0635c9AD7d214E9F12657a050b1",
			"base_name": "Wrapped BNB",
			"base_symbol": "WBNB",
			"base_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"quote_name": "RoyalDoge",
			"quote_symbol": "RoyalDOGE",
			"quote_address": "0xE8551fB0118769eC389B7DF5Eac43F11d14769ca",
			"price": "1580360353422508675.73874530444777193067",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "1072602241865.643175179710447086",
			"liquidity_BNB": "3052352716.48827762596302998617"
		},
		"0xA81D37bFC75769a875ac373bB5b109c3d6212ff7_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0x7B562D9E9a37870Cbc2729a100fEE3e28C5349Df",
			"base_name": "NanoDoge",
			"base_symbol": "NanoDOGE",
			"base_address": "0xA81D37bFC75769a875ac373bB5b109c3d6212ff7",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "6.3e-19",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "1067824551068.321481986865721217",
			"liquidity_BNB": "3012008973.254294868094365253945"
		},
		"0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c_0xef9f735426d514e7300aA4364A7ea41F06108154": {
			"pair_address": "0x0F21745713791E9fa05Bf2D90772ce0f4a0DA48c",
			"base_name": "Wrapped BNB",
			"base_symbol": "WBNB",
			"base_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"quote_name": "EverDoge",
			"quote_symbol": "EverDOGE",
			"quote_address": "0xef9f735426d514e7300aA4364A7ea41F06108154",
			"price": "1583351738956781496.80576499474310919915",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "1061482926782.851658394998802582",
			"liquidity_BNB": "3046891519.370196983514946483134"
		},
		"0x930B9D0C7612Dd71c1A95806818292Db05E9Ce7b_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0x8A619af0eedEF2e568D73ec031281F677164FA0d",
			"base_name": "Hundred x Cat",
			"base_symbol": "100xCat",
			"base_address": "0x930B9D0C7612Dd71c1A95806818292Db05E9Ce7b",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "6.3e-19",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "1055535037457.812140751156894127",
			"liquidity_BNB": "2972543392.740310378156479124485"
		},
		"0x762F7D245CD97F11F35d5f3f5B015c02b04411E2_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0xa0B24aa6e2fa20556C599A7ffFfDbC49cA2bB30A",
			"base_name": "SpaceBabyDoge",
			"base_symbol": "SPACEbDoge",
			"base_address": "0x762F7D245CD97F11F35d5f3f5B015c02b04411E2",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "6.3e-19",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "1051713765050.986697154172946701",
			"liquidity_BNB": "3399460931.58801390181334438595"
		},
		"0x6879cDeA540Eb323dea59227b3138CE6e602DD1b_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0x2C533000E330a62c252Fadb6353114ff8aDc9A3C",
			"base_name": "EverBitcoin",
			"base_symbol": "EverBTC",
			"base_address": "0x6879cDeA540Eb323dea59227b3138CE6e602DD1b",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "6.3e-19",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "1046207357674.290092315784769093",
			"liquidity_BNB": "2946110380.044465345961326859288"
		},
		"0x03289Be4f12A296f5B7067a72c78d0B2881C1135_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0x0AcCd73ccA4c6B583B71B3B9d9483d19c869E858",
			"base_name": "BabyCat",
			"base_symbol": "bCat",
			"base_address": "0x03289Be4f12A296f5B7067a72c78d0B2881C1135",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "6.3e-19",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "1031298925932.136442502017236488",
			"liquidity_BNB": "2587821135.842414274834144758863"
		},
		"0x1C325655dd47D3d0ACACDD3ba8964bBafc37Ece1_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0x3e608253fbCe582fD5020897693F50498EC08E93",
			"base_name": "Hundred x Art",
			"base_symbol": "100xART",
			"base_address": "0x1C325655dd47D3d0ACACDD3ba8964bBafc37Ece1",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "6.3e-19",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "1018804142795.730488304147866584",
			"liquidity_BNB": "2567890167.744588749012068185566"
		},
		"0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c_0xf53431960226F553208861580a396CAa28f08f9e": {
			"pair_address": "0xC841E4D277e337835b0d2B7eCdb2B13549488d66",
			"base_name": "Wrapped BNB",
			"base_symbol": "WBNB",
			"base_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"quote_name": "SpaceBitcoin",
			"quote_symbol": "SPACEBTC",
			"quote_address": "0xf53431960226F553208861580a396CAa28f08f9e",
			"price": "1582074817401946607.80057787584170861149",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "1011971136766.0924508174932904695",
			"liquidity_BNB": "2570010943.110134303492082738947"
		},
		"0x8c7A29DcC4e2e06223C50B91484A59466c15De11_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0xCE3C602a1680FB83Ebc370A1C3a828084341B8D4",
			"base_name": "FlokiSafe",
			"base_symbol": "fSAFE",
			"base_address": "0x8c7A29DcC4e2e06223C50B91484A59466c15De11",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "3.16e-18",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "1000295455430.843098008864333274",
			"liquidity_BNB": "3323911667.98803021897362508366"
		},
		"0x1C595d2dDDa1FEa54A7B5f3F907B30eb505A4666_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0x31655476a4758406F0dBF5Da2a580FFf37B873Dc",
			"base_name": "Baby Nikita",
			"base_symbol": "BNIKITA",
			"base_address": "0x1C595d2dDDa1FEa54A7B5f3F907B30eb505A4666",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "0",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "992737408855.725219642528016122",
			"liquidity_BNB": "3127907687.147931975541537154415"
		},
		"0x5A95E6dBc71d389e53ac2bF983D2Ed36ADd1a7Fe_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0x41198488B5b2cb36bFf65fff6A85F9Cb50227731",
			"base_name": "BabyBNB",
			"base_symbol": "bBNB",
			"base_address": "0x5A95E6dBc71d389e53ac2bF983D2Ed36ADd1a7Fe",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "3.16e-18",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "990114284149.556736949520981067",
			"liquidity_BNB": "3279536649.98466287892640338771"
		},
		"0x6fD084069751c9C7d4554bd8f45088EB87651f0b_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0x934B5AF40fE73f15908AF25A26fB144008970652",
			"base_name": "SuperSushi",
			"base_symbol": "SUPSushi",
			"base_address": "0x6fD084069751c9C7d4554bd8f45088EB87651f0b",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "6.3e-19",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "952988615994.8708282009286045944",
			"liquidity_BNB": "2470891901.606606415563194395615"
		},
		"0x03c36AF840A87C35D8f005Ec285cf2732910eA13_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0xDd661f11E3b9139BBc37e5cE775d5394a805D023",
			"base_name": "Ever Black",
			"base_symbol": "EverB",
			"base_address": "0x03c36AF840A87C35D8f005Ec285cf2732910eA13",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "3.16e-18",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "944888682411.2905419878444387575",
			"liquidity_BNB": "3132651094.513437255581862464718"
		},
		"0xA6f2Ac0CAF296173c43AF624157c08b06078f6B8_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0x4221259275F0b2a9B2CbBf6e4c44F497eECa33B3",
			"base_name": "Boxer",
			"base_symbol": "$BOX",
			"base_address": "0xA6f2Ac0CAF296173c43AF624157c08b06078f6B8",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "6.3e-19",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "940689957768.810781873772068061",
			"liquidity_BNB": "2426518575.239805074892926930836"
		},
		"0xA13b092714596e8292Fe6cC33c8be30cA22f9112_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0x331C056EA74909Cd83fF573071153c0a2DcbcF33",
			"base_name": "AquaInu",
			"base_symbol": "AquaInu",
			"base_address": "0xA13b092714596e8292Fe6cC33c8be30cA22f9112",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "6.3e-19",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "934664309033.323766929681613222",
			"liquidity_BNB": "2412742397.602897557067235888143"
		},
		"0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c_0xD6F6EDe422194C84b5E203aeF31A5a0691A242f3": {
			"pair_address": "0x560DB34223BF511Bd32Dcce0cCf6079364d3f08D",
			"base_name": "Wrapped BNB",
			"base_symbol": "WBNB",
			"base_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"quote_name": "EverFEG",
			"quote_symbol": "EverFEG",
			"quote_address": "0xD6F6EDe422194C84b5E203aeF31A5a0691A242f3",
			"price": "1581879109655098607.81993569724218540017",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "920237538064.743585670819541276",
			"liquidity_BNB": "2380873522.77817432723919845317"
		},
		"0x5bf0fd8006F2189270BAFb7150380240c2c848A4_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0xa4acF8bf3b0A5993Df76f85F4e2e157bf650fa2B",
			"base_name": "timeantiques",
			"base_symbol": "TAQ",
			"base_address": "0x5bf0fd8006F2189270BAFb7150380240c2c848A4",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "2.4e-19",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "896082760620.94448286842337058",
			"liquidity_BNB": "2338202335.557708243663411621264"
		},
		"0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c_0xBD6D511B546C10cD05816EbC0F1FFfD71128bD39": {
			"pair_address": "0x69A6a12F5D3dd0617C387AE18477F0ACcEC95cC1",
			"base_name": "Wrapped BNB",
			"base_symbol": "WBNB",
			"base_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"quote_name": "ShibaSushi",
			"quote_symbol": "SHIBASushi",
			"quote_address": "0xBD6D511B546C10cD05816EbC0F1FFfD71128bD39",
			"price": "1583959879889381168.65027439156181939605",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "893893408581.561230650940244191",
			"liquidity_BNB": "2327206277.089242367650925553887"
		},
		"0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c_0xEa62DE278297A049e82298C94bD7C4998099e2E7": {
			"pair_address": "0xff298bf961103b6CF0528887Ba8E00421048505c",
			"base_name": "Wrapped BNB",
			"base_symbol": "WBNB",
			"base_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"quote_name": "SuperMOON",
			"quote_symbol": "SUPMOON",
			"quote_address": "0xEa62DE278297A049e82298C94bD7C4998099e2E7",
			"price": "1579732785888885229.87631657734638637581",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "888242697368.246312684005788953",
			"liquidity_BNB": "2667559066.332306405682607959097"
		},
		"0x3f846AB30D233166d17E12ac027CCD5b622F783e_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0xFe06D25b378B473E3B2552f8c7A1E80388B30C20",
			"base_name": "WrappedApe",
			"base_symbol": "wAPE",
			"base_address": "0x3f846AB30D233166d17E12ac027CCD5b622F783e",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "6.3e-19",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "887118777380.1728538728012377415",
			"liquidity_BNB": "2298341581.98960334125574893218"
		},
		"0x9Ccd07fEDce7640B64D02348A2a886113B7bb072_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0x5f18975bB578B093c677a0381B608D210Aa386ce",
			"base_name": "Aqua Finance",
			"base_symbol": "AquaFIN",
			"base_address": "0x9Ccd07fEDce7640B64D02348A2a886113B7bb072",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "6.3e-19",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "882213211981.6693942737999526394",
			"liquidity_BNB": "2286389429.415386955541693600572"
		},
		"0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c_0xF3031e5709876f844B78370fb0c449895f896846": {
			"pair_address": "0x4eDF942Fda7a33E132BE295a657C867adfa80E1a",
			"base_name": "Wrapped BNB",
			"base_symbol": "WBNB",
			"base_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"quote_name": "FEGSafe",
			"quote_symbol": "FEGSAFE",
			"quote_address": "0xF3031e5709876f844B78370fb0c449895f896846",
			"price": "1582505469269153042.33712735260556930676",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "878334506080.11098291921073844",
			"liquidity_BNB": "2319211869.593408903172616366065"
		},
		"0x7163635149dcFcC5a0F2D3052F4400dA9158f9d9_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0x10A3889dBaB5bDed5033f1e3CCFe09b5E3D29446",
			"base_name": "ShibaArt",
			"base_symbol": "SHIBAART",
			"base_address": "0x7163635149dcFcC5a0F2D3052F4400dA9158f9d9",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "6.3e-19",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "877893331410.263729936073848933",
			"liquidity_BNB": "2287665046.79085013248972886581"
		},
		"0xb415d4Fe902b09eF5E06E7e7C788f784ebcC30fB_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0x35DF1463DD879D2E05E40Bc258983ed3f628cFFd",
			"base_name": "BabyDoge",
			"base_symbol": "BabyDOGE",
			"base_address": "0xb415d4Fe902b09eF5E06E7e7C788f784ebcC30fB",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "6.3e-19",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "873439667250.600179518443369001",
			"liquidity_BNB": "2240700025.23613156568933012575"
		},
		"0x299135e880346fC2DA4e81f77B072C31749573aC_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0xc34BBCBfc6c2c77C5aFA8043981F3eFC0A1fB8bf",
			"base_name": "Ever Finance",
			"base_symbol": "EverFIN",
			"base_address": "0x299135e880346fC2DA4e81f77B072C31749573aC",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "6.3e-19",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "869933041023.341080125715120382",
			"liquidity_BNB": "2290756156.10458089041463694456"
		},
		"0x83fc23c016bBD676d9C94Dfc98760a66FbF48132_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0x84f6c617B760Ec38C377104ee955Dee2f5D36a73",
			"base_name": "BabyLoki",
			"base_symbol": "bLOKI",
			"base_address": "0x83fc23c016bBD676d9C94Dfc98760a66FbF48132",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "6.3e-19",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "867710432587.358716595608586442",
			"liquidity_BNB": "2297936503.931103162360012669564"
		},
		"0x4762cCF25CeAc01220F873b110D4751015E43eDC_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0x8d74DE54754104F027f71DBA3f3d199fB054a4c5",
			"base_name": "BabyPanda",
			"base_symbol": "bPanda",
			"base_address": "0x4762cCF25CeAc01220F873b110D4751015E43eDC",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "6.3e-19",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "867436144313.015674232906403716",
			"liquidity_BNB": "2269651677.254504268132082380524"
		},
		"0x7d49fAB3A0c2f05Eb03C12b93b220b395f4B3603_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0xe5b6da124016d3cc5356a033826f0e8c632b366c",
			"base_name": "FEGRipple",
			"base_symbol": "FEGXRP",
			"base_address": "0x7d49fAB3A0c2f05Eb03C12b93b220b395f4B3603",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "6.3e-19",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "862341378982.154773873089382116",
			"liquidity_BNB": "2228951230.593646030228681144175"
		},
		"0x47A5d483463cAcFd08ae3dC509Ae7a2bd169880E_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0x5542e2Bf698d4b1555f98E7bDe1922F807B3a73a",
			"base_name": "FEGCash",
			"base_symbol": "FEGCASH",
			"base_address": "0x47A5d483463cAcFd08ae3dC509Ae7a2bd169880E",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "6.3e-19",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "857618919023.442222197253956755",
			"liquidity_BNB": "2224348865.96897523250539207731"
		},
		"0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c_0xc2d191596DD704b3b024263BE7CC55fF127c7a8F": {
			"pair_address": "0x68ff655519e5692c70Af4C15DB75C447877fCefd",
			"base_name": "Wrapped BNB",
			"base_symbol": "WBNB",
			"base_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"quote_name": "RoyalBitcoin",
			"quote_symbol": "RoyalBTC",
			"quote_address": "0xc2d191596DD704b3b024263BE7CC55fF127c7a8F",
			"price": "1580376397674376244.33196579230375109643",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "857149592927.5871377254280056275",
			"liquidity_BNB": "2564104693.707060948272215668243"
		},
		"0x3d5225f1Af7C64E4E107B4e87a3943C75576CCF2_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0xd0c38f1795Fa4810fE983c06c23388803523332A",
			"base_name": "EverFEG",
			"base_symbol": "EverFEG",
			"base_address": "0x3d5225f1Af7C64E4E107B4e87a3943C75576CCF2",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "6.3e-19",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "856793413156.351333797682028916",
			"liquidity_BNB": "2246652763.40096022451702387533"
		},
		"0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c_0xfcf1b248CFE8C3970D8B62E61d7CF8973c75e8F6": {
			"pair_address": "0xA971756c176C57A97dB0323F324968B79c065009",
			"base_name": "Wrapped BNB",
			"base_symbol": "WBNB",
			"base_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"quote_name": "EverRipple",
			"quote_symbol": "EverXRP",
			"quote_address": "0xfcf1b248CFE8C3970D8B62E61d7CF8973c75e8F6",
			"price": "1576696724851638389.01612055971075281179",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "853089141923.314360695756043122",
			"liquidity_BNB": "2256481566.978168694361375768818"
		},
		"0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c_0xC6B638F42b2504d5A99CC08ae0fD4D54E4509B03": {
			"pair_address": "0x15db08EE1ebc87f9cE749EB3d192657976C59320",
			"base_name": "Wrapped BNB",
			"base_symbol": "WBNB",
			"base_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"quote_name": "AquaRocket",
			"quote_symbol": "AquaRocket",
			"quote_address": "0xC6B638F42b2504d5A99CC08ae0fD4D54E4509B03",
			"price": "1584509415706426795.95612666488714796732",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "851956452028.054620380088000615",
			"liquidity_BNB": "2195782662.418725771402599569898"
		},
		"0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c_0xF0fA8202B2778CDe9f3c0e3da7af333024509EAa": {
			"pair_address": "0x7f9AC79d3ABF91b87E577FDe0FACfdA1805a9F33",
			"base_name": "Wrapped BNB",
			"base_symbol": "WBNB",
			"base_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"quote_name": "NFTs by  Cardano",
			"quote_symbol": "NFTADA",
			"quote_address": "0xF0fA8202B2778CDe9f3c0e3da7af333024509EAa",
			"price": "1580656886374518571.00878427659442550373",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "847530749120.066436310888700476",
			"liquidity_BNB": "2242752256.93231437977005475829"
		},
		"0x88ABf023ee4D9bbf84F088F0aC8402B532809c9C_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0x75e9964A132Eb09356f7Ab315744af15fB67cDf5",
			"base_name": "MiniLoki",
			"base_symbol": "miniLOKI",
			"base_address": "0x88ABf023ee4D9bbf84F088F0aC8402B532809c9C",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "6.3e-19",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "847274089714.192655765934259975",
			"liquidity_BNB": "2259166882.15486511531152837563"
		},
		"0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c_0xd87FA6b0AbbEDCD79Bd3E37Fe24339AEE3681381": {
			"pair_address": "0x6536674ac25A0cF8A37ea16DC4C51cE40D6A765c",
			"base_name": "Wrapped BNB",
			"base_symbol": "WBNB",
			"base_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"quote_name": "WrappedPanda",
			"quote_symbol": "wPanda",
			"quote_address": "0xd87FA6b0AbbEDCD79Bd3E37Fe24339AEE3681381",
			"price": "1583528540361970402.8309560681052777524",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "841530405614.094970112250586782",
			"liquidity_BNB": "2214164496.036814282844562132494"
		},
		"0xB72EaBC89D77010185770b56268C8a133476EcA9_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0x8c440c8F41c98Fa81A9e9B5F38aC9D25612f03A1",
			"base_name": "FlokiLoki",
			"base_symbol": "FlokiLOKI",
			"base_address": "0xB72EaBC89D77010185770b56268C8a133476EcA9",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "6.3e-19",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "840288580385.347091631280474142",
			"liquidity_BNB": "2223153343.647876716991293512384"
		},
		"0x1aFE52D6eB012C67b5e07EBCFC86CCcE8162EC9b_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0x5a0D2edb0Df77Ce8391602802205EBEB73E5f841",
			"base_name": "RoyalArt",
			"base_symbol": "RoyalART",
			"base_address": "0x1aFE52D6eB012C67b5e07EBCFC86CCcE8162EC9b",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "6.3e-19",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "838192996747.32834207109311967",
			"liquidity_BNB": "2235666546.59894343349984148538"
		},
		"0x90aB34d4EF68a1a295996A3504Fc0b55b0fa79E2_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0x1B4a9AaEA98cdbD71Dc119e15Fcd49E516aC56Df",
			"base_name": "SuperSafe",
			"base_symbol": "SSAFE",
			"base_address": "0x90aB34d4EF68a1a295996A3504Fc0b55b0fa79E2",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "3.16e-18",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "835612105185.890145625805817968",
			"liquidity_BNB": "2793812357.635332685734189404434"
		},
		"0x0e26b393219425a188f8fB74fB3e23A0a9BdE259_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0x48B598d4e738Ac4c1e08871EB71AEB089dB921E4",
			"base_name": "SpaceRocket",
			"base_symbol": "SPACERocket",
			"base_address": "0x0e26b393219425a188f8fB74fB3e23A0a9BdE259",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "6.3e-19",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "835082806411.91881479610259347",
			"liquidity_BNB": "2248300478.881580448850316100334"
		},
		"0x3bd5Fa83195C4fC0BFAC83d43250b6cE7b74cebF_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0x3f35f4696421bDF4873B6B7Cb3E5c65047220dC9",
			"base_name": "FlokiSushi",
			"base_symbol": "FlokiSushi",
			"base_address": "0x3bd5Fa83195C4fC0BFAC83d43250b6cE7b74cebF",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "6.3e-19",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "832542216916.807427124826552421",
			"liquidity_BNB": "2181226349.10018015590935803419"
		},
		"0x35634E1C6Db74987944B570007055f3e3B3614EB_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0xCD1a5F5b5A43AaEB017c260e398ceCE99E90f9E7",
			"base_name": "ShibaArt",
			"base_symbol": "ShibART",
			"base_address": "0x35634E1C6Db74987944B570007055f3e3B3614EB",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "6.3e-19",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "832490179806.891754676804972899",
			"liquidity_BNB": "2141924519.29622727088941260312"
		},
		"0x216AB1d1CabEdac2357564D37540cb3e667b86aF_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0xA04D5724c44B95AbcAb8f60Cd54cc50dbE0A40F0",
			"base_name": "OnlyUpBoxer",
			"base_symbol": "UpBOX",
			"base_address": "0x216AB1d1CabEdac2357564D37540cb3e667b86aF",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "6.3e-19",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "831900253966.479080794268405375",
			"liquidity_BNB": "2232229391.41307248969824070253"
		},
		"0x1BA3129bDC06e7D117a6Dc025Ca1D2E122AA0c3a_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0x4930376075388059b118620126d65E731D73A56F",
			"base_name": "NFTs x Ripple",
			"base_symbol": "NFTxXRP",
			"base_address": "0x1BA3129bDC06e7D117a6Dc025Ca1D2E122AA0c3a",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "6.3e-19",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "831571129554.422200305909473298",
			"liquidity_BNB": "2231652675.451131836451700386414"
		},
		"0x19B98f4E7a3270733AD825Ef689A28884EA4128a_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0xbB7dcCc4FCaD19F652276BA99f6d55D6b3ebedA9",
			"base_name": "RoyalMOON",
			"base_symbol": "RoyalMOON",
			"base_address": "0x19B98f4E7a3270733AD825Ef689A28884EA4128a",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "6.3e-19",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "828177915523.099523490175254018",
			"liquidity_BNB": "2182941827.11120828188221563822"
		},
		"0xA75909aBaf5bFB43c54819379A34fC01b0c1aE7b_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0xCaAF8Bf4Cf6cFC2E4128DD1e26E6B9224600cB26",
			"base_name": "NanoApe",
			"base_symbol": "NanoAPE",
			"base_address": "0xA75909aBaf5bFB43c54819379A34fC01b0c1aE7b",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "3.16e-18",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "827301610693.2933348811507054624",
			"liquidity_BNB": "2775967792.12134365967677703475"
		},
		"0x99F45C3e41daCFa796f0b99C1ed6e175D061966c_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0xC8E28E147C9b04FEb72e331e320f81a09db0A062",
			"base_name": "FlokiCat",
			"base_symbol": "FlokiCat",
			"base_address": "0x99F45C3e41daCFa796f0b99C1ed6e175D061966c",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "6.3e-19",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "826939889939.15333604227995882",
			"liquidity_BNB": "2201485106.51683967583167114564"
		},
		"0x15dcf856E965777E47A32Bf52D80a193d3Dc6EfA_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0x724D6647baEBAa2883da222cC5e115f641a0Bf70",
			"base_name": "RoyalRocket",
			"base_symbol": "RoyalRocket",
			"base_address": "0x15dcf856E965777E47A32Bf52D80a193d3Dc6EfA",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "6.3e-19",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "821961765286.745085970832396792",
			"liquidity_BNB": "2166519114.246001738995326578455"
		},
		"0x18935e0085af35c0baF53B6a9a7fa977e580E2c8_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0x250b738F3dA35cb0E6Fb5a84a986D548C38D81e8",
			"base_name": "NFTBoxer",
			"base_symbol": "NFTBOX",
			"base_address": "0x18935e0085af35c0baF53B6a9a7fa977e580E2c8",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "6.3e-19",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "819448457721.5721508877481260354",
			"liquidity_BNB": "2158300971.725994463851605953925"
		},
		"0x52261060390a5b132036202B1c0E5d2Aed6c8769_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0xDEFBc2f94A19aE30397465A929A51d33Fee9E8eB",
			"base_name": "Duke Doge",
			"base_symbol": "DukeDoge",
			"base_address": "0x52261060390a5b132036202B1c0E5d2Aed6c8769",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "0",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "816476538334.5633642949857661005",
			"liquidity_BNB": "2602850973.860703355361409409337"
		},
		"0x3A28606c995a0F51998714B3D238Ca1f4f4Bc676_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0x41008aC4e7e325a402a097A4268cAF62B229Fc99",
			"base_name": "BabySafe",
			"base_symbol": "BabySAFE",
			"base_address": "0x3A28606c995a0F51998714B3D238Ca1f4f4Bc676",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "3.16e-18",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "810077026411.643344870760129133",
			"liquidity_BNB": "2710421983.962681748354733673768"
		},
		"0xC8d4101Bf1eF5BC899ADd47b3b1b7460B82abCED_0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56": {
			"pair_address": "0xdae778085c94644AC7600b48B89d3D99E6746A14",
			"base_name": "MOONSOON",
			"base_symbol": "MOSO",
			"base_address": "0xC8d4101Bf1eF5BC899ADd47b3b1b7460B82abCED",
			"quote_name": "BUSD Token",
			"quote_symbol": "BUSD",
			"quote_address": "0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56",
			"price": "9e-20",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "810065827768.864682568162840615",
			"liquidity_BNB": "1916981695.84150558163516099697"
		},
		"0x7900a48bf830d272983C59cA6729BEC1384bbc78_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0x9aEBdC48a9a99C9810D1154f5c2aCA064F766d2C",
			"base_name": "SuperBabyDoge",
			"base_symbol": "SUPbDoge",
			"base_address": "0x7900a48bf830d272983C59cA6729BEC1384bbc78",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "6.3e-19",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "809947345725.346210812856592934",
			"liquidity_BNB": "2141921881.096829020565600881328"
		},
		"0x474D39a0aa895B6b24903536552B8447c6503b03_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0x7248648E89cD8fcA02817225a66a3FF1E76BeDde",
			"base_name": "FEGFEG",
			"base_symbol": "FEGFEG",
			"base_address": "0x474D39a0aa895B6b24903536552B8447c6503b03",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "6.3e-19",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "809493114017.996808680597111551",
			"liquidity_BNB": "2180983035.627907832837940104174"
		},
		"0xb1016834A26352f913375660B27e19d6D6a3e31b_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0xbB085B5bef96372a932E9D41B9B7Cb6351A3b54b",
			"base_name": "BabyMOON",
			"base_symbol": "BabyMOON",
			"base_address": "0xb1016834A26352f913375660B27e19d6D6a3e31b",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "6.3e-19",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "807788504022.979549658450160073",
			"liquidity_BNB": "2429628382.734409361775332813836"
		},
		"0x76A315D54d221e7AC34Dd11eD517803EdC7e1773_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0x5f44718ECf74D13781d388Dea637043F2A724a6a",
			"base_name": "ShibaEthereum",
			"base_symbol": "SHIBAETH",
			"base_address": "0x76A315D54d221e7AC34Dd11eD517803EdC7e1773",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "3.16e-18",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "803836889513.338877768957648776",
			"liquidity_BNB": "2691959287.75586420208542627141"
		},
		"0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c_0xf2C08B1526Ec2d79cF13d1db80C9a5017086141b": {
			"pair_address": "0xA445141f56E6f75EF32eb4678db0bB636D65fF16",
			"base_name": "Wrapped BNB",
			"base_symbol": "WBNB",
			"base_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"quote_name": "Hundred x FEG",
			"quote_symbol": "100xFEG",
			"quote_address": "0xf2C08B1526Ec2d79cF13d1db80C9a5017086141b",
			"price": "1582868189596426059.59837069060302948571",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "798017667615.383600090359499107",
			"liquidity_BNB": "2139508371.790186358525146704996"
		},
		"0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c_0xDb7c9df60269465b98337a146F7A1485172E43e2": {
			"pair_address": "0xe1087227AF79382e9c3e986f34b0017D98DEF53A",
			"base_name": "Wrapped BNB",
			"base_symbol": "WBNB",
			"base_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"quote_name": "LokiDoge",
			"quote_symbol": "LDOGE",
			"quote_address": "0xDb7c9df60269465b98337a146F7A1485172E43e2",
			"price": "5.69591638073331988379071542980769448506308545584e+27",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "796793092592.949481434295755668",
			"liquidity_BNB": "2537245484.73127046433625007969"
		},
		"0xA2CcD7F0577E9073049b1E79Dbc8Bd1B5ac7c19E_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0x052d14CfB91571BeBf857B8d2F9b086139045923",
			"base_name": "DRAGON BABY",
			"base_symbol": "DRAGONBABY",
			"base_address": "0xA2CcD7F0577E9073049b1E79Dbc8Bd1B5ac7c19E",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "0",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "790661764243.349288202740019246",
			"liquidity_BNB": "2534029457.435621612804720573997"
		},
		"0xA31E96167B9eE647143e4a0e8B0c7dc320BFeF6C_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0xA4143258cCe288c6bcA24680dFB4916829A85381",
			"base_name": "DogeFire Token",
			"base_symbol": "DOGEFIRE",
			"base_address": "0xA31E96167B9eE647143e4a0e8B0c7dc320BFeF6C",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "0",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "789024516888.981821454574659795",
			"liquidity_BNB": "2312225293.022838907604642966775"
		},
		"0x8dDD535879AA7332e99C9808E595D7c8E0e52fF3_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0xCd3C8431B088fc763213FAF0Df85600560F2a09a",
			"base_name": "EverMOON",
			"base_symbol": "EverMOON",
			"base_address": "0x8dDD535879AA7332e99C9808E595D7c8E0e52fF3",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "3.16e-18",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "736701604081.083321370138932937",
			"liquidity_BNB": "2487297244.354952619580059138395"
		},
		"0x646ad1869f3337a86f645E38179f08692b4525e6_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0xBB7e95852c85675Db7eed3abf732EdB0A9D38CFc",
			"base_name": "TEST",
			"base_symbol": "TEST",
			"base_address": "0x646ad1869f3337a86f645E38179f08692b4525e6",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "0",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "726748426584.996117911211118964",
			"liquidity_BNB": "1841303498.89322259421569945179"
		},
		"0x5975c15C9c463eD51bae1db8FACF902151D86754_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0x5BfAac389af76A80275fd5250976397E1F989Db0",
			"base_name": "MiniDogePRO",
			"base_symbol": "MiniDogePRO",
			"base_address": "0x5975c15C9c463eD51bae1db8FACF902151D86754",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "0",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "719314110084.5996949021673219846",
			"liquidity_BNB": "2354775568.817965051610888508293"
		},
		"0x3f1fe75bBe61F79c16724d7ebA04BF52f9Ee3962_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0x5019CddAF3A45391bE999E122cfB502C43657e1D",
			"base_name": "FEG Black",
			"base_symbol": "FEGB",
			"base_address": "0x3f1fe75bBe61F79c16724d7ebA04BF52f9Ee3962",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "3.16e-18",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "707803794329.28597924554215283",
			"liquidity_BNB": "2348427115.63972169024154429068"
		},
		"0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c_0xc5F35da9EC5D5892437aeca58D7517a6CEDe500A": {
			"pair_address": "0x3fB9269FaFeF3F8EA58880Aa1c388CD40E8D2F73",
			"base_name": "Wrapped BNB",
			"base_symbol": "WBNB",
			"base_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"quote_name": "Floki Cardano",
			"quote_symbol": "FlokiADA",
			"quote_address": "0xc5F35da9EC5D5892437aeca58D7517a6CEDe500A",
			"price": "316365759315637321.98675884514049241615",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "681414760268.412678689075036271",
			"liquidity_BNB": "2280553609.486424878012647161652"
		},
		"0x1261C79C78C848769E73EF73CCF05C35dA7C3B6B_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0xD6b453a62654f529ad560Cab085b2bE0204BD21A",
			"base_name": "MiniApe",
			"base_symbol": "miniAPE",
			"base_address": "0x1261C79C78C848769E73EF73CCF05C35dA7C3B6B",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "3.16e-18",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "653589645265.8588723786334819515",
			"liquidity_BNB": "2262780952.350743132027519897908"
		},
		"0x437aF57E817f8ac09f34eA6C6ecc10bb90d0aBe4_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0x331276BbABeE5fB14C40445b46251e663e67837d",
			"base_name": "ShibaRipple",
			"base_symbol": "SHIBAXRP",
			"base_address": "0x437aF57E817f8ac09f34eA6C6ecc10bb90d0aBe4",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "1.58e-18",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "648766195246.299402951464845915",
			"liquidity_BNB": "2173954299.456911657120384055983"
		},
		"0x3aF223E3625c808BCE66B51Fa5739B6A027b40AF_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0xf39EB391f3EC70f3D17539d578E9A95e1e319B76",
			"base_name": "OnlyUpRipple",
			"base_symbol": "UpXRP",
			"base_address": "0x3aF223E3625c808BCE66B51Fa5739B6A027b40AF",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "1.58e-18",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "644651806889.614646534936919629",
			"liquidity_BNB": "2160217547.127359722451072166618"
		},
		"0x38e83Ce878A797DC62b5b88AefEF8b8CDe157246_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0x7A528fD295f9d718f1849f0F41fE33d1998Baddc",
			"base_name": "ShibaRocket",
			"base_symbol": "SHIBARocket",
			"base_address": "0x38e83Ce878A797DC62b5b88AefEF8b8CDe157246",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "3.16e-18",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "643460607524.1364995065908666426",
			"liquidity_BNB": "2231933689.816651238216863043476"
		},
		"0x39FecA765ae9c86C4f3b9E4E9A01d42c0C23ca00_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0xA8Cd652C93e63190Be6fF5e87D5bb1914872011D",
			"base_name": "BabyPanda",
			"base_symbol": "bPanda",
			"base_address": "0x39FecA765ae9c86C4f3b9E4E9A01d42c0C23ca00",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "1.58e-18",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "641400538521.336243584708885075",
			"liquidity_BNB": "2153012019.989848579518087218528"
		},
		"0x1639B43cFf1e7E90A961996C75602D9ae0557F1B_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0xe1b3ACB8792B23791E5457154645480265f4f9A4",
			"base_name": "OnlyUpPanda",
			"base_symbol": "UpPanda",
			"base_address": "0x1639B43cFf1e7E90A961996C75602D9ae0557F1B",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "1.58e-18",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "634477471263.6039439827352916",
			"liquidity_BNB": "2126327119.01032709707123894195"
		},
		"0xB0158E0A788D0A88c4D67E0e93ba675607DbE76C_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0xffd9FDe0Bb5477b6D75D132127663EedCBB62943",
			"base_name": "EverMOON",
			"base_symbol": "EverMOON",
			"base_address": "0xB0158E0A788D0A88c4D67E0e93ba675607DbE76C",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "1.58e-18",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "633705987932.675217664899504249",
			"liquidity_BNB": "2111927546.616758955807748432449"
		},
		"0x7cfe0eBB989A7509c1D5f8aE0c479D74f878C55C_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0x0550fd6E60d84401d364c7EE41dC6C0786A81f31",
			"base_name": "NanoLoki",
			"base_symbol": "NanoLOKI",
			"base_address": "0x7cfe0eBB989A7509c1D5f8aE0c479D74f878C55C",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "1.58e-18",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "624709045518.518533946238669944",
			"liquidity_BNB": "2077411771.389327062369118883303"
		},
		"0x69C6cafE63a8908B186Fc08C29b519166503af90_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0xe61547796c5b7a511acef380A2cF3B7D8bde2850",
			"base_name": "Shiba Finance",
			"base_symbol": "ShibFIN",
			"base_address": "0x69C6cafE63a8908B186Fc08C29b519166503af90",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "1.58e-18",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "620466074639.042591319811398375",
			"liquidity_BNB": "2051463240.357010192253429660668"
		},
		"0x968eC6E0eAF42d917909B790C7751F660283500c_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0x412a49b8361d6e84EFBB9490a277901Ce6204B80",
			"base_name": "Ever Black",
			"base_symbol": "EverB",
			"base_address": "0x968eC6E0eAF42d917909B790C7751F660283500c",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "3.16e-18",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "616541134614.816884877658715297",
			"liquidity_BNB": "2134139653.643262695020542880272"
		},
		"0x044c234d3DD7B6Edd2BA5faE9C9017032C184d4B_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0x26D070E68770f1BDef44Fc30c4815A00C544d463",
			"base_name": "FlokiApe",
			"base_symbol": "fAPE",
			"base_address": "0x044c234d3DD7B6Edd2BA5faE9C9017032C184d4B",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "1.58e-18",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "613978054637.547242294283259282",
			"liquidity_BNB": "2043595734.847876630862683142911"
		},
		"0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c_0xF3f3CEc219A1206d468BfBa32895414F326D38A4": {
			"pair_address": "0xd9170C363a8B8F9a0B2D32948DC1549ac5148c4A",
			"base_name": "Wrapped BNB",
			"base_symbol": "WBNB",
			"base_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"quote_name": "MiniInu",
			"quote_symbol": "miniInu",
			"quote_address": "0xF3f3CEc219A1206d468BfBa32895414F326D38A4",
			"price": "633309406064335324.82619514101097241996",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "612127094827.594138689887875099",
			"liquidity_BNB": "2026578127.9705776780915248566"
		},
		"0x48247A6E0e1dD99a9d9ab4456AEdbfb313f5bc6c_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0xb950D041e6879aA61076550bB56137cb7c259A4E",
			"base_name": "SpaceBitcoin",
			"base_symbol": "SPACEBTC",
			"base_address": "0x48247A6E0e1dD99a9d9ab4456AEdbfb313f5bc6c",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "3.16e-18",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "611928128672.738995462157728378",
			"liquidity_BNB": "2052796602.698926430182477448076"
		},
		"0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c_0xc7B0e512981C5a8534EEBd1823e71b4e56767035": {
			"pair_address": "0x0B0C0de492A33CF3bEf9d764b34E3543F0d955D7",
			"base_name": "Wrapped BNB",
			"base_symbol": "WBNB",
			"base_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"quote_name": "BabyMOON",
			"quote_symbol": "bMOON",
			"quote_address": "0xc7B0e512981C5a8534EEBd1823e71b4e56767035",
			"price": "633050682369723222.48189403119530048313",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "609797682001.049306409337943443",
			"liquidity_BNB": "2021686991.461384031143619789424"
		},
		"0x59dF10D87A9BEC26e00DE497f410207A35C77A88_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0x95B750a296345EE6d1Bc9b6f6d3A814fc3d9751b",
			"base_name": "SuperMOON",
			"base_symbol": "SMOON",
			"base_address": "0x59dF10D87A9BEC26e00DE497f410207A35C77A88",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "3.16e-18",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "609150331530.15224545294778494",
			"liquidity_BNB": "2051111953.135559593534491307256"
		},
		"0x6140a3E62387d9B44384ECD6eb417F49D64Ab73F_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0x2361bd3a6712f7003E6A9fd6970Da1dCD35447Cd",
			"base_name": "SpaceBoxer",
			"base_symbol": "SPACEBOX",
			"base_address": "0x6140a3E62387d9B44384ECD6eb417F49D64Ab73F",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "3.16e-18",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "594145413081.135567595818595245",
			"liquidity_BNB": "2094717025.170041512522955814593"
		},
		"0x895b854682d681F3e8f9bb5A8eEC7a9eAEF5e240_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0x899959c879850211C37206F4122542F420b53285",
			"base_name": "Cash",
			"base_symbol": "$CASH",
			"base_address": "0x895b854682d681F3e8f9bb5A8eEC7a9eAEF5e240",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "3.16e-18",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "588728370672.519236135947250773",
			"liquidity_BNB": "2075060666.295072488921843143193"
		},
		"0xA683BdC67225590f5cA0B0d4157D740e3233a540_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0x222007438A00eEf97E4db9C7C5880b7a79285a41",
			"base_name": "EverEthereum",
			"base_symbol": "EverETH",
			"base_address": "0xA683BdC67225590f5cA0B0d4157D740e3233a540",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "3.16e-18",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "588726728313.011952969964511726",
			"liquidity_BNB": "2083152083.38342635436822352093"
		},
		"0x0E00189Ab107a3a66d33396C989DAB4beFFDdfAd_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0xAcdaaddd1d70e84374B54A782d7C0deb2eFF3Ab2",
			"base_name": "NanoRocket",
			"base_symbol": "NanoRocket",
			"base_address": "0x0E00189Ab107a3a66d33396C989DAB4beFFDdfAd",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "1.58e-18",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "586476160052.820870962810430227",
			"liquidity_BNB": "1935138204.368967436750827873642"
		},
		"0x0888e9AbEA3701d163F9CD5e99dcb84B08388E74_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0xc039ABA4ac72B6b785d3aF5767A4b3241ED98AA8",
			"base_name": "SuperStrongElon",
			"base_symbol": "SUPSElon",
			"base_address": "0x0888e9AbEA3701d163F9CD5e99dcb84B08388E74",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "1.58e-18",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "586075745318.863752943051029063",
			"liquidity_BNB": "1939174466.48073629846624359039"
		},
		"0xb9549157d8a42E8163A55eF14648619F536ae4e3_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0xb9110Fa361A5E7946cB6461e84b94F09Ad8Ccb1e",
			"base_name": "FlokiFEG",
			"base_symbol": "FlokiFEG",
			"base_address": "0xb9549157d8a42E8163A55eF14648619F536ae4e3",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "3.16e-18",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "577747449945.966485042166954017",
			"liquidity_BNB": "2004487935.995570315469669910446"
		},
		"0x287e9Dc874587258afEA086C9d11Ae342e2561ec_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0xf17174ae66Cb70cd45D06F6363c86e83D4ac4770",
			"base_name": "Super Cardano",
			"base_symbol": "SUPADA",
			"base_address": "0x287e9Dc874587258afEA086C9d11Ae342e2561ec",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "3.16e-18",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "577175771350.3034089173053431295",
			"liquidity_BNB": "1968482407.681519801041796226796"
		},
		"0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c_0xF4242FDffD0Fd9B7d3A08c04ca3137e88F86e0B8": {
			"pair_address": "0x7720976B7461C9c8B5902F2F14A994A20B51a7e9",
			"base_name": "Wrapped BNB",
			"base_symbol": "WBNB",
			"base_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"quote_name": "ShibaPanda",
			"quote_symbol": "ShibPNDA",
			"quote_address": "0xF4242FDffD0Fd9B7d3A08c04ca3137e88F86e0B8",
			"price": "633069208966328618.51053193228286582473",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "567291791730.956395417852627103",
			"liquidity_BNB": "1889585905.566656150855770613616"
		},
		"0x6C51D9155a2239EbAbDB04Ca149Fe2C8a838ffeE_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0x0E702E7f40BFF86977Cb456236f641b500cfCa86",
			"base_name": "OnlyUpMOON",
			"base_symbol": "UpMOON",
			"base_address": "0x6C51D9155a2239EbAbDB04Ca149Fe2C8a838ffeE",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "1.58e-18",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "565786801826.944908579916536734",
			"liquidity_BNB": "1888266827.830337256430134248463"
		},
		"0x44Fa06BB3807e020b6448cF3617b58a22cC150CF_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0xE6079202AEE010062B9d017B4439e66Fac8eE7FC",
			"base_name": "SuperBoxer",
			"base_symbol": "SUPBOX",
			"base_address": "0x44Fa06BB3807e020b6448cF3617b58a22cC150CF",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "1.58e-18",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "562811169235.9844510677005070985",
			"liquidity_BNB": "1869233154.991890529705590322896"
		},
		"0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c_0xF21133d2f680e73750F1f1D87845Ce4F5DB57f81": {
			"pair_address": "0x899fcc7129080c2D4a17d713C147549fcB40e55F",
			"base_name": "Wrapped BNB",
			"base_symbol": "WBNB",
			"base_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"quote_name": "BabySafe",
			"quote_symbol": "BabySAFE",
			"quote_address": "0xF21133d2f680e73750F1f1D87845Ce4F5DB57f81",
			"price": "316372436604927262.55535450288356840353",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "561595150624.486880448936095791",
			"liquidity_BNB": "1984387737.631108539108494415319"
		},
		"0xae906bB98F3281503E06646390c225A207Efc4B7_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0xFd609615087Cb802D01cF66566413952328692dc",
			"base_name": "EverApe",
			"base_symbol": "EverAPE",
			"base_address": "0xae906bB98F3281503E06646390c225A207Efc4B7",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "1.58e-18",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "545712037113.172428019878743072",
			"liquidity_BNB": "1816211785.868295790049112182503"
		},
		"0x1c1bB6cd5a8B9EFA043164205410ad6B21a98B52_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0xc54F6564701714cD7b1BaA29A37E79Ba89C4f2E6",
			"base_name": "Hundred x Loki",
			"base_symbol": "100xLOKI",
			"base_address": "0x1c1bB6cd5a8B9EFA043164205410ad6B21a98B52",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "1.58e-18",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "543241871565.661192974688045696",
			"liquidity_BNB": "1832785563.895519923226772482234"
		},
		"0x9c2C3C471EBe902ec3E8147153f602f44A019f3c_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0xC5603E2886495ACCd7a4A9C93389a55258E73a7a",
			"base_name": "AquaInu",
			"base_symbol": "AquaInu",
			"base_address": "0x9c2C3C471EBe902ec3E8147153f602f44A019f3c",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "1.58e-18",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "539129177971.080432285160258564",
			"liquidity_BNB": "1801822596.822887228839514709945"
		},
		"0x13f72492e80245E9f358575E279b932BF083499E_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0x526D326Fb2C4C410f25B14f475A2414aa95a90e1",
			"base_name": "FlokiLoki",
			"base_symbol": "FlokiLOKI",
			"base_address": "0x13f72492e80245E9f358575E279b932BF083499E",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "1.58e-18",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "538826378554.3668293049917204294",
			"liquidity_BNB": "1822572849.885447253636133919588"
		},
		"0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c_0xeA3B19D5eD4d5f53eE7D8185c2A26d846845Ea25": {
			"pair_address": "0xc50879eC544Ea973C41391727b5C5D2B528344b0",
			"base_name": "Wrapped BNB",
			"base_symbol": "WBNB",
			"base_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"quote_name": "Binance  Finance",
			"quote_symbol": "BSCFIN",
			"quote_address": "0xeA3B19D5eD4d5f53eE7D8185c2A26d846845Ea25",
			"price": "633458421857640427.37356752031300054848",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "527927907294.720103858901325556",
			"liquidity_BNB": "1766529526.726573648428967720727"
		},
		"0x9ab48095a89229b97ed9af461cdF08169b5DBcF7_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0xaeabFac2962409f5d9c5D83fA2bCef065E06c0C2",
			"base_name": "SpacePanda",
			"base_symbol": "SPACEPanda",
			"base_address": "0x9ab48095a89229b97ed9af461cdF08169b5DBcF7",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "3.16e-18",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "521308403225.3256719048010309676",
			"liquidity_BNB": "1803973596.418669513767156975909"
		},
		"0x134F984f75D83bF7f36179a4591D27532Ffe14B5_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0x43f899B559a7EbEAAA754ac14bA03Fa371BdBB90",
			"base_name": "FlokiApe",
			"base_symbol": "FlokiAPE",
			"base_address": "0x134F984f75D83bF7f36179a4591D27532Ffe14B5",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "3.16e-18",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "516641851926.9522443907833360575",
			"liquidity_BNB": "1780090908.86252729618167922378"
		},
		"0x9ceC4823d8a0D4BC73062ddD404Ec85eeF0cDfAC_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0x64164b29B8F684a6bcb2402bD5635b9717b1F10f",
			"base_name": "ShibaEthereum",
			"base_symbol": "ShibETH",
			"base_address": "0x9ceC4823d8a0D4BC73062ddD404Ec85eeF0cDfAC",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "1.58e-18",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "505727213447.562135493698135519",
			"liquidity_BNB": "1690923856.556274939827964074326"
		},
		"0x24E61403F170dd837D65f699300EC652Bb36C653_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0x550c254FaE3893898eA28149731bCC929F579A72",
			"base_name": "FlokiMOON",
			"base_symbol": "FlokiMOON",
			"base_address": "0x24E61403F170dd837D65f699300EC652Bb36C653",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "1.58e-18",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "499820636719.977354763814165848",
			"liquidity_BNB": "1689752527.461826975853632999106"
		},
		"0x7e6D2C67fA6Bba5058025823988bf0D33333D1e0_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0xa521b1B02E252e97eA5383a692Da66bbbC33770f",
			"base_name": "BabyBabyDoge",
			"base_symbol": "BabybDoge",
			"base_address": "0x7e6D2C67fA6Bba5058025823988bf0D33333D1e0",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "6.3e-19",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "497342301807.149982112617387037",
			"liquidity_BNB": "1401227018.318140931311649317386"
		},
		"0x28B71b5308956E59813620CA664F488B402E30b5_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0xa2cAA636FDd1F29999f8351736e28aF084a59d22",
			"base_name": "Rocket",
			"base_symbol": "$Rocket",
			"base_address": "0x28B71b5308956E59813620CA664F488B402E30b5",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "1.58e-18",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "490578274694.7989959174745748295",
			"liquidity_BNB": "1639918530.121275130863625328003"
		},
		"0xA521B91bd39d7f79E1Ed96857f65aA0e0e60d567_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0xbf0DAf835c32521A69c6ef616C4F70e63AC6C240",
			"base_name": "SpaceStrongElon",
			"base_symbol": "SPACESElon",
			"base_address": "0xA521B91bd39d7f79E1Ed96857f65aA0e0e60d567",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "1.58e-18",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "487959429257.733673640067912059",
			"liquidity_BNB": "1629629009.305954034676526306916"
		},
		"0x5F7D1de2466DF884957E4396a38D37510EDF5B10_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0xdbcA65472237425c76387Cf227107bFcB4b39A15",
			"base_name": "AquaRocket",
			"base_symbol": "AquaRocket",
			"base_address": "0x5F7D1de2466DF884957E4396a38D37510EDF5B10",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "3.16e-18",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "485750934569.3108455526865211015",
			"liquidity_BNB": "1668093430.48235072599429787127"
		},
		"0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c_0xF359fd97A9C31Bb7fbc682DeE85C289D5ecAcc69": {
			"pair_address": "0xa192353F212d20f524ED26d04b576c8Eda140bF5",
			"base_name": "Wrapped BNB",
			"base_symbol": "WBNB",
			"base_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"quote_name": "ShibaDoge",
			"quote_symbol": "ShibDOGE",
			"quote_address": "0xF359fd97A9C31Bb7fbc682DeE85C289D5ecAcc69",
			"price": "316259824822154442.1781148476222292677",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "485691104964.7683245169177836784",
			"liquidity_BNB": "1721145642.30426046571369690454"
		},
		"0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c_0xF19c25A99b2445B55290B82E8366F5FD415B942A": {
			"pair_address": "0xC8297b93B12c38A6AdFd46e7435A4611185fa185",
			"base_name": "Wrapped BNB",
			"base_symbol": "WBNB",
			"base_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"quote_name": "Space Finance",
			"quote_symbol": "SPACEFIN",
			"quote_address": "0xF19c25A99b2445B55290B82E8366F5FD415B942A",
			"price": "632266776927629337.55980408339200922934",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "479835030303.2978408093270533143",
			"liquidity_BNB": "1602097537.13926043586655060838"
		},
		"0x2bb90EE1d3E43F4Ed1c96681A64f71bffC6aB9C3_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0x6C362aC9C3276911644211C18F7590695BF2582E",
			"base_name": "EverFEG",
			"base_symbol": "EverFEG",
			"base_address": "0x2bb90EE1d3E43F4Ed1c96681A64f71bffC6aB9C3",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "3.16e-18",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "476017871249.3471005302096635",
			"liquidity_BNB": "1639956890.19685739101017404766"
		},
		"0x9BF1452f51E45115290cB0a00dD77e65c8107851_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0xCc3535AdadEf714b92d79772C7fb556D866102F7",
			"base_name": "The NFT",
			"base_symbol": "NFT",
			"base_address": "0x9BF1452f51E45115290cB0a00dD77e65c8107851",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "0",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "465286381421.7742884105703792793",
			"liquidity_BNB": "1218642391.294496091678580589703"
		},
		"0x7f84b183265B1F10edCE4cfDbCF33FcEbd3B7625_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0x3aF60AaA1a9590fbfEad79a0C15E838649fa7a08",
			"base_name": "BabyDoge",
			"base_symbol": "bDOGE",
			"base_address": "0x7f84b183265B1F10edCE4cfDbCF33FcEbd3B7625",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "3.16e-18",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "455114957460.9727004804782437694",
			"liquidity_BNB": "1572706735.179309403569706346292"
		},
		"0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c_0xf4a256f01A699DA828596A16Aa70583da4876Eea": {
			"pair_address": "0x4739a55dfB8EDeb2488347Cc5D19d463FB674497",
			"base_name": "Wrapped BNB",
			"base_symbol": "WBNB",
			"base_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"quote_name": "greendoge.biz",
			"quote_symbol": "GREENDOGE",
			"quote_address": "0xf4a256f01A699DA828596A16Aa70583da4876Eea",
			"price": "159189579217098416.10771137605552556095",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "443706800721.2437986926754086158",
			"liquidity_BNB": "1184599182.354693779205529437581"
		},
		"0x6Dc39BBec51E5b4dE9b350f0D7fC1d35977626a6_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0x40754d53A4fd4E64A8b1931CBEe4FEc01f5D15B7",
			"base_name": "BabyDoge",
			"base_symbol": "BabyDOGE",
			"base_address": "0x6Dc39BBec51E5b4dE9b350f0D7fC1d35977626a6",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "6.3e-19",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "438564695470.0078345141455856584",
			"liquidity_BNB": "1227673443.97888471757197141005"
		},
		"0x6185Af5D61581FaB6561a6b3F3Cd07562B9F1D49_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0x0a7E4D292424DAFf5D67f2e13106993E0E653F7C",
			"base_name": "SpaceLoki",
			"base_symbol": "SPACELOKI",
			"base_address": "0x6185Af5D61581FaB6561a6b3F3Cd07562B9F1D49",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "1.58e-18",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "436603633898.1590209325135377117",
			"liquidity_BNB": "1359356873.411638288573748460999"
		},
		"0xAdC15CB5e572f3E9148856Fcf9a65AdeEa030789_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0x4f924143811a5e04c2712a123e8ac746252A7338",
			"base_name": "WrappedInu",
			"base_symbol": "wInu",
			"base_address": "0xAdC15CB5e572f3E9148856Fcf9a65AdeEa030789",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "1.58e-18",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "435255387223.3565931820749940243",
			"liquidity_BNB": "1356600972.638196929831881596586"
		},
		"0x7642319F0dE6e91e0a520f49f0C6EEFbFf1Fc97e_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0x313eFb5FAdb9164a05d70359817652B95e68D24F",
			"base_name": "WrappedApe",
			"base_symbol": "wAPE",
			"base_address": "0x7642319F0dE6e91e0a520f49f0C6EEFbFf1Fc97e",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "1.58e-18",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "432541394435.24945621949493648",
			"liquidity_BNB": "1345777676.953964092487017039143"
		},
		"0x6481B92C289bb2E2fecC6B04fD414E02643b554d_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0xda853B078cC6da3cf3aF7a0eCcbb1528Ad2d1016",
			"base_name": "ShibaCat",
			"base_symbol": "SHIBACat",
			"base_address": "0x6481B92C289bb2E2fecC6B04fD414E02643b554d",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "1.58e-18",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "432281572945.2789163346265138635",
			"liquidity_BNB": "1344262506.733813489573363934175"
		},
		"0x44e163D5Fe0Fa7b01D99A208F731f28e2bDB7462_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0x922b1Fb7CBE0542695f8292FFc1839fe94a2401b",
			"base_name": "Floki Black",
			"base_symbol": "fB",
			"base_address": "0x44e163D5Fe0Fa7b01D99A208F731f28e2bDB7462",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "3.16e-18",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "431249018720.96958596747130393",
			"liquidity_BNB": "1490453568.826753359103199087537"
		},
		"0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c_0xbb6E04dCDbA541677Ea43A9815E6E060E842fC86": {
			"pair_address": "0xBF88Ba0Cc0c58D45aA4214d93aC5C1456E265B6B",
			"base_name": "Wrapped BNB",
			"base_symbol": "WBNB",
			"base_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"quote_name": "FlokiLoki",
			"quote_symbol": "FlokiLOKI",
			"quote_address": "0xbb6E04dCDbA541677Ea43A9815E6E060E842fC86",
			"price": "633324420085944343.2396569352410699433",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "429278650508.1668894631404503025",
			"liquidity_BNB": "1348941796.705956045506070110289"
		},
		"0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c_0xD7257A7581A90EBCA09f305DFd2dFc2F338F20bC": {
			"pair_address": "0x7A662A6DEC4c0ea331406d0a29A7414A8e3d251E",
			"base_name": "Wrapped BNB",
			"base_symbol": "WBNB",
			"base_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"quote_name": "FlokiMOON",
			"quote_symbol": "fMOON",
			"quote_address": "0xD7257A7581A90EBCA09f305DFd2dFc2F338F20bC",
			"price": "316468439977474138.17910519208984802176",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "428168523862.5368542559586076835",
			"liquidity_BNB": "1456196514.38449953892619443063"
		},
		"0x6864818aFFE5DaA214edA84cf77bD07D0f3f99F1_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0x431b654801F1229e5df622c033A3E6C5001eC9C9",
			"base_name": "SuperRocket",
			"base_symbol": "SUPRocket",
			"base_address": "0x6864818aFFE5DaA214edA84cf77bD07D0f3f99F1",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "1.58e-18",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "428010248548.8356221292683440062",
			"liquidity_BNB": "1332198291.168872750567726596464"
		},
		"0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c_0xd0eb8d9E6543AcD585E32EA6C7Cd5d2108aEB6be": {
			"pair_address": "0xf6F5fe1548E645F77c415918428F0e32E0b8c7eA",
			"base_name": "Wrapped BNB",
			"base_symbol": "WBNB",
			"base_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"quote_name": "Ever Black",
			"quote_symbol": "EverB",
			"quote_address": "0xd0eb8d9E6543AcD585E32EA6C7Cd5d2108aEB6be",
			"price": "316343175587419757.60787385696927624511",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "427676642834.5196858068386450177",
			"liquidity_BNB": "1475978044.386740588123066258898"
		},
		"0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c_0xDff9cB3E8F00D21b7340E9a800e73AeBF247cD01": {
			"pair_address": "0x71c760a59f4d42d2B3e1946241c6D8ae3a2BF51e",
			"base_name": "Wrapped BNB",
			"base_symbol": "WBNB",
			"base_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"quote_name": "ShibaSushi",
			"quote_symbol": "ShibSushi",
			"quote_address": "0xDff9cB3E8F00D21b7340E9a800e73AeBF247cD01",
			"price": "632967146663230501.75337366450537450174",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "427483722968.3652111819973791096",
			"liquidity_BNB": "1362069361.124591123538337939339"
		},
		"0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c_0xf42CEB53eae58E4AE32c4C6ac464C29A00cac9f1": {
			"pair_address": "0x7A116a0D27B96BBce3DE47591DAC7d7A822271d6",
			"base_name": "Wrapped BNB",
			"base_symbol": "WBNB",
			"base_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"quote_name": "FlokiMOON",
			"quote_symbol": "fMOON",
			"quote_address": "0xf42CEB53eae58E4AE32c4C6ac464C29A00cac9f1",
			"price": "632875276081884636.75655461133154920499",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "427481201277.5169304011152590643",
			"liquidity_BNB": "1381685298.971028597037329758032"
		},
		"0x6Ea915538b2Fb50Bc61d4e3CdEa4976547CED821_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0xd6B957ada4091cBb42E6Ef18a464bD0A3911d8A4",
			"base_name": "ShibaMOON",
			"base_symbol": "SHIBAMOON",
			"base_address": "0x6Ea915538b2Fb50Bc61d4e3CdEa4976547CED821",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "3.16e-18",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "427200638418.7196896891291892718",
			"liquidity_BNB": "1462743013.672620281673278617852"
		},
		"0x65a1f5391C9FF6117D8D62F2BDF42285dab24842_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0x54bd629F56b9435f14298a50fE1DA1a84A4Fd567",
			"base_name": "Shiba Finance",
			"base_symbol": "SHIBAFIN",
			"base_address": "0x65a1f5391C9FF6117D8D62F2BDF42285dab24842",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "1.58e-18",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "426638509911.5516768671517112113",
			"liquidity_BNB": "1323970475.59225345887480410275"
		},
		"0x644D5E145feB7fBd875a9f23E96767CbCE91b3D0_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0x02C69F7E489dFa00DA5b2e749ACD4a0C5e87eeF7",
			"base_name": "SpaceStrongElon",
			"base_symbol": "SPACESElon",
			"base_address": "0x644D5E145feB7fBd875a9f23E96767CbCE91b3D0",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "3.16e-18",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "425222137858.989452570836130607",
			"liquidity_BNB": "1451961949.398361016634096265987"
		},
		"0x04dF2246AF13F84019E956F663a99dBFe8b8cff1_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0x985fA956b77d3084503A0555df1Ecc7dd6b6BBa3",
			"base_name": "SuperRipple",
			"base_symbol": "SUPXRP",
			"base_address": "0x04dF2246AF13F84019E956F663a99dBFe8b8cff1",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "1.58e-18",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "424735241667.0441556984370964625",
			"liquidity_BNB": "1374641010.456991059826507799653"
		},
		"0x802ea9f86514bd254eB5176f0C059B8d4F382096_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0x20998bF9087323Fd75e6cdA75b62a7b0FaB0DB95",
			"base_name": "BabyDoge",
			"base_symbol": "bDOGE",
			"base_address": "0x802ea9f86514bd254eB5176f0C059B8d4F382096",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "3.16e-18",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "424697388884.8535771543452997396",
			"liquidity_BNB": "1471671350.534216419106202614781"
		},
		"0x9F45967cf1d6D77b885156DBF3D0bC8509a7a663_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0xFc4E9A5a97759374d5CB78df73F5Ef81be6acD89",
			"base_name": "ShibaRocket",
			"base_symbol": "SHIBARocket",
			"base_address": "0x9F45967cf1d6D77b885156DBF3D0bC8509a7a663",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "1.58e-18",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "417936187910.5809738517492483057",
			"liquidity_BNB": "1352033118.812986425999172543207"
		},
		"0x81aD7b207720B38c09Abb3bf1c7277e639df17Ac_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0xeb09B9b86E0CE8524b9444A756964d55d3A4B93c",
			"base_name": "WrappedInu",
			"base_symbol": "wInu",
			"base_address": "0x81aD7b207720B38c09Abb3bf1c7277e639df17Ac",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "1.58e-18",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "414661440704.213040686931477029",
			"liquidity_BNB": "1340041712.924823645052973816317"
		},
		"0x7763C8b57e5629B5342dAc915ae4B03F8A049537_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0xFDC0cA2B2E103E8010D215Ba2399389260433d49",
			"base_name": "ShibaBoxer",
			"base_symbol": "ShibBOX",
			"base_address": "0x7763C8b57e5629B5342dAc915ae4B03F8A049537",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "3.16e-18",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "413063429755.7404659073661343377",
			"liquidity_BNB": "1404599614.382421010687538301835"
		},
		"0x8d57C0dC71D0ee029c49073d5DB975958a08cFBc_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0x099474f7923b6a985a97B38e302B6355e5c47552",
			"base_name": "FlokiInu",
			"base_symbol": "fInu",
			"base_address": "0x8d57C0dC71D0ee029c49073d5DB975958a08cFBc",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "3.16e-18",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "410168198579.181469064894906868",
			"liquidity_BNB": "1394933614.472860086714498651773"
		},
		"0x377bcB16ca6Cf1A7aB156A1D7062998886068FA2_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0x1269e0681466bdFFd5cB2765f5C1427C3AADa6F6",
			"base_name": "FlokiDoge",
			"base_symbol": "fDOGE",
			"base_address": "0x377bcB16ca6Cf1A7aB156A1D7062998886068FA2",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "3.16e-18",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "409049066472.223754428996770805",
			"liquidity_BNB": "1384418669.204240490241207951483"
		},
		"0x6cF9e567e7361052f6874af2D9E4fC5FdD20ef3d_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0x14f4736b466A486fbE41E3B3B958d984B4D41F6b",
			"base_name": "Hundred x MOON",
			"base_symbol": "100xMOON",
			"base_address": "0x6cF9e567e7361052f6874af2D9E4fC5FdD20ef3d",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "3.16e-18",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "403218190357.0485839906111728533",
			"liquidity_BNB": "1358625899.08806933521911637169"
		},
		"0x6BEdF0481e61FBDeB8CC22F1335c1454E79B51e9_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0x638BA0bB22e2cc20Ef43F3F379F65Cbe8E9Ab34e",
			"base_name": "BabyMOON",
			"base_symbol": "bMOON",
			"base_address": "0x6BEdF0481e61FBDeB8CC22F1335c1454E79B51e9",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "3.16e-18",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "400346099365.5013173495348417644",
			"liquidity_BNB": "1347605126.301870205096561598813"
		},
		"0x012dF622CaA0A18Ddfbf2e6BB8F23ce72eaadD02_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0x03Af12C6Ae776298d927b6F24E84c1E5339890D2",
			"base_name": "DIGIMONGO",
			"base_symbol": "DIGIMONGO",
			"base_address": "0x012dF622CaA0A18Ddfbf2e6BB8F23ce72eaadD02",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "0",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "394847416956.988082216012445421",
			"liquidity_BNB": "1207259189.91370228751464983916"
		},
		"0x8c37f797f6768Fe54037ad7C099b1a928e96Ced7_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0xB6a2b2ABc751D78E7D513c57a71c2337e132e1eA",
			"base_name": "ShibaDoge",
			"base_symbol": "ShibDOGE",
			"base_address": "0x8c37f797f6768Fe54037ad7C099b1a928e96Ced7",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "1.58e-18",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "394396555262.802482371529897888",
			"liquidity_BNB": "1320455410.40101041637356760341"
		},
		"0x816B44dB612b6B8B3143f789A07C78203245C677_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0x2606EDA4fb3DCe2834716c7626c670D8d2b2d61e",
			"base_name": "ShibaLiteCoin",
			"base_symbol": "SHIBALTC",
			"base_address": "0x816B44dB612b6B8B3143f789A07C78203245C677",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "1.58e-18",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "391464556481.9289939112116690247",
			"liquidity_BNB": "1264380103.530577334956408638453"
		},
		"0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c_0xE70712fA650D13eb3416a03619b8eb22F0E8Bf0e": {
			"pair_address": "0x7c865dBd12D87Cd342042C4575FC7B240F86802a",
			"base_name": "Wrapped BNB",
			"base_symbol": "WBNB",
			"base_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"quote_name": "AquaLoki",
			"quote_symbol": "AquaLOKI",
			"quote_address": "0xE70712fA650D13eb3416a03619b8eb22F0E8Bf0e",
			"price": "633700875926832237.94412463701294240826",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "389810341975.277542739615603277",
			"liquidity_BNB": "1221297758.82531405205565685105"
		},
		"0x9FE01Fe488628011691c48C69EC2CE0c1d8129E2_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0xC4bDCA0b31c4c3942190c915Ae209781D31267cb",
			"base_name": "FEGMOON",
			"base_symbol": "FEGMOON",
			"base_address": "0x9FE01Fe488628011691c48C69EC2CE0c1d8129E2",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "1.58e-18",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "389094530110.301433329880348917",
			"liquidity_BNB": "1305498083.790457500785441722206"
		},
		"0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c_0xFF16712F7D2d0a9efDFC84d56440BfA9490A5cD9": {
			"pair_address": "0x29Ea947ea00dDEAeB5e163a5C16Ac91Eb79c8308",
			"base_name": "Wrapped BNB",
			"base_symbol": "WBNB",
			"base_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"quote_name": "Porn Token",
			"quote_symbol": "PORN",
			"quote_address": "0xFF16712F7D2d0a9efDFC84d56440BfA9490A5cD9",
			"price": "9.970830009165108166027014527334048596275111e+22",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "388574207456.8852748682037780655",
			"liquidity_BNB": "1290664630.56772534414997141343"
		},
		"0x41152924fd55A826870F457d596fC3B5767Ff02d_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0xd2A02442c5Cc3DC2f2270d426200afAD69bE8269",
			"base_name": "Super Finance",
			"base_symbol": "SFIN",
			"base_address": "0x41152924fd55A826870F457d596fC3B5767Ff02d",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "1.58e-18",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "386309197767.4689365820617204805",
			"liquidity_BNB": "1254030465.22517980995594047503"
		},
		"0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c_0xEfd94554B61C70181c70d50A239144511d3d479A": {
			"pair_address": "0x3E35b3D4730915faAbEb5e35BAB826704Ef4cAdE",
			"base_name": "Wrapped BNB",
			"base_symbol": "WBNB",
			"base_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"quote_name": "Aqua Black",
			"quote_symbol": "AquaB",
			"quote_address": "0xEfd94554B61C70181c70d50A239144511d3d479A",
			"price": "634348346505796630.77357035397298828208",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "384755345781.5866544683207310707",
			"liquidity_BNB": "1205444955.548099089188424464122"
		},
		"0x5e84e163591d1D1D9D39f0ddD93CA3699F74BCa9_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0x726E334224e0a352EE44224e7bD8B020859465eC",
			"base_name": "RoyalBoxer",
			"base_symbol": "RoyalBOX",
			"base_address": "0x5e84e163591d1D1D9D39f0ddD93CA3699F74BCa9",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "1.58e-18",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "384332736156.339977768848653195",
			"liquidity_BNB": "1285302982.728495672899615296607"
		},
		"0x4aE475342e8b27951A11F77Ba60c6B263F79468d_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0x9342747f086A370eEcA9FAff35CBeF08bB61184f",
			"base_name": "Nano Finance",
			"base_symbol": "NanoFIN",
			"base_address": "0x4aE475342e8b27951A11F77Ba60c6B263F79468d",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "1.58e-18",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "383523365233.347510462401295552",
			"liquidity_BNB": "1217724853.133084140795021672252"
		},
		"0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c_0xc8B070ED2D5984C83AcC568b42f3EA0761020597": {
			"pair_address": "0x14A2006aACE1F0169F0dc3EAA8BBD0C2feDB95ac",
			"base_name": "Wrapped BNB",
			"base_symbol": "WBNB",
			"base_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"quote_name": "BabyRocket",
			"quote_symbol": "BabyRocket",
			"quote_address": "0xc8B070ED2D5984C83AcC568b42f3EA0761020597",
			"price": "633309758336133033.04733526820907722173",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "382804928031.224582355297432734",
			"liquidity_BNB": "1256973138.263824392914939466533"
		},
		"0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c_0xD16FfFD0ae8C7f85cD4416cAF18EC04Fd224b524": {
			"pair_address": "0xdb65265ddf3a27DF7Ffc83f25430f3A88997f92f",
			"base_name": "Wrapped BNB",
			"base_symbol": "WBNB",
			"base_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"quote_name": "RoyalPanda",
			"quote_symbol": "RoyalPNDA",
			"quote_address": "0xD16FfFD0ae8C7f85cD4416cAF18EC04Fd224b524",
			"price": "633738902566427117.37164568664607010772",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "382298114823.0227944302478694906",
			"liquidity_BNB": "1264319088.945615749552186023722"
		},
		"0x0ce08Ec6F8aF09d9AF326F95243a43FdDA807D89_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0xf22B849aCA6FAE3D5b1A9bf9f01aaff77465edE2",
			"base_name": "AquaPanda",
			"base_symbol": "AquaPNDA",
			"base_address": "0x0ce08Ec6F8aF09d9AF326F95243a43FdDA807D89",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "1.58e-18",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "380976258399.6831055957509309193",
			"liquidity_BNB": "1283688142.452815004171396545528"
		},
		"0x0A4a77a02Fe0EE857CE0f0785D6D4b5F636336F3_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0x69b37953d1D5c046A62F5804771DfE312AC3a451",
			"base_name": "Binance Safe",
			"base_symbol": "BSCSAFE",
			"base_address": "0x0A4a77a02Fe0EE857CE0f0785D6D4b5F636336F3",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "1.58e-18",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "380702162799.085846231476434802",
			"liquidity_BNB": "1284770951.396312460557097718795"
		},
		"0x8918F1Cf5B0667ABa923FC3FafF404DF896994c1_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0xC17448336eC40d5592f5be5f11f86B10bD915A4c",
			"base_name": "SingerDoge",
			"base_symbol": "SingerDoge",
			"base_address": "0x8918F1Cf5B0667ABa923FC3FafF404DF896994c1",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "0",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "380447579758.973178404342759866",
			"liquidity_BNB": "1223523932.100576338583019436138"
		},
		"0x7aff55DA0691405725b75A493fBb12007641CdE4_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0x6820fd543E2474a29d16695Bc00088ca65ec23DF",
			"base_name": "Hundred x Panda",
			"base_symbol": "100xPNDA",
			"base_address": "0x7aff55DA0691405725b75A493fBb12007641CdE4",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "3.16e-18",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "380292266385.3390544409194150713",
			"liquidity_BNB": "1272574879.680901048185594040929"
		},
		"0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c_0xFe8e1eA3CBBF98870883b98B7DcA7F5deB1b8cA2": {
			"pair_address": "0xB0997013F6cD605323B7D8A649177347EdF26EFb",
			"base_name": "Wrapped BNB",
			"base_symbol": "WBNB",
			"base_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"quote_name": "SpaceBNB",
			"quote_symbol": "SPACEBNB",
			"quote_address": "0xFe8e1eA3CBBF98870883b98B7DcA7F5deB1b8cA2",
			"price": "631958367933895893.61415404504883453018",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "379476370469.395165720114850447",
			"liquidity_BNB": "1256533492.688761042080617914423"
		},
		"0x7310206cB22E12B4839b7B700759aCA8A58E60E2_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0x6f5c456BDE0fD6975FC76D0ad43422b1d3E80707",
			"base_name": "SuperEthereum",
			"base_symbol": "SETH",
			"base_address": "0x7310206cB22E12B4839b7B700759aCA8A58E60E2",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "1.58e-18",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "378657740619.1646033299482240696",
			"liquidity_BNB": "1259179491.231842892579302186564"
		},
		"0x9191191aecDfdD0DDf8f5724E1C6de38C3CBAFF9_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0x13C1b9a7F52f134b1ba30d3dA1189Fa96B3B78F6",
			"base_name": "BNB RAIN",
			"base_symbol": "BRAIN",
			"base_address": "0x9191191aecDfdD0DDf8f5724E1C6de38C3CBAFF9",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "0",
			"base_volume": "1.000000420204824728153921454936e+21",
			"quote_volume": "56.71814241642505307899999999997",
			"liquidity": "377500175794.2991168212165380503",
			"liquidity_BNB": "754092889.447015440464214066541"
		},
		"0x557BA5Fb3fDD4291697879789fd465aCEC455471_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0x4511aEca8483F13c2f219F77C1C11d328C40B6c1",
			"base_name": "AquaPanda",
			"base_symbol": "AquaPNDA",
			"base_address": "0x557BA5Fb3fDD4291697879789fd465aCEC455471",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "3.16e-18",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "376734134098.177825905497290288",
			"liquidity_BNB": "1270383245.373316990398621505004"
		},
		"0x0a90841DA1667a66F925Aa63772854b65EC0D51F_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0xa08422A6f23E0407951A1DCEAc4cC99DbD1261a3",
			"base_name": "RoyalMOON",
			"base_symbol": "RoyalMOON",
			"base_address": "0x0a90841DA1667a66F925Aa63772854b65EC0D51F",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "1.58e-18",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "375692799371.8465220915163847288",
			"liquidity_BNB": "1249255132.294473214246561102319"
		},
		"0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c_0xf6E6800731B45f9c36cb4b569A17f2E20ec5fBad": {
			"pair_address": "0x9E5106E9f92Fbd815bB8e018eB58fc93d30be44f",
			"base_name": "Wrapped BNB",
			"base_symbol": "WBNB",
			"base_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"quote_name": "EverEthereum",
			"quote_symbol": "EverETH",
			"quote_address": "0xf6E6800731B45f9c36cb4b569A17f2E20ec5fBad",
			"price": "633949940531330108.64787146801943992974",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "375653350944.2972996768108148386",
			"liquidity_BNB": "1191916349.690615306672546515046"
		},
		"0x5c303Ae355f47ea4Dd94956b6Bfec8C971045B8a_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0x9E231f518F57d7477B8342B60B1e3d585754Db2D",
			"base_name": "Binance Panda",
			"base_symbol": "BSCPanda",
			"base_address": "0x5c303Ae355f47ea4Dd94956b6Bfec8C971045B8a",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "1.58e-18",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "375197990727.205222229574560861",
			"liquidity_BNB": "1246546685.376092873430003148228"
		},
		"0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c_0xc54708110D29Af641Cf5cc5Bd6884Cafd84c706F": {
			"pair_address": "0x908E62Ac3C45C433770005Ab42635aE63dc1Ff37",
			"base_name": "Wrapped BNB",
			"base_symbol": "WBNB",
			"base_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"quote_name": "WrappedPanda",
			"quote_symbol": "wPanda",
			"quote_address": "0xc54708110D29Af641Cf5cc5Bd6884Cafd84c706F",
			"price": "316575347943435756.06957121449784469173",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "374650991596.2122477097499053653",
			"liquidity_BNB": "1261413408.106565341191395328119"
		},
		"0x66242B3DFe70b0e88eA8e45e5e37a478925A6245_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0xa1338577B06D5bd715E075C54686523E5a48eF2c",
			"base_name": "Binance Panda",
			"base_symbol": "BSCPanda",
			"base_address": "0x66242B3DFe70b0e88eA8e45e5e37a478925A6245",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "3.15e-18",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "371834723154.9129475316106134782",
			"liquidity_BNB": "1249385954.10875299377769119537"
		},
		"0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c_0xf3c769012718f38D3555abac3bC6304C29bE908B": {
			"pair_address": "0xc9F089b13Bbc4010d8fe484B30fEefD73ADf2f3b",
			"base_name": "Wrapped BNB",
			"base_symbol": "WBNB",
			"base_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"quote_name": "Son Of Gold",
			"quote_symbol": "SOG 🔆 ️",
			"quote_address": "0xf3c769012718f38D3555abac3bC6304C29bE908B",
			"price": "9.9658139839645046022742008523606719115493340704e+26",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "370616251600.072760786340518498",
			"liquidity_BNB": "773548880.812579348969180084036"
		},
		"0x2de258C75cCD9CE3329a7aa684Da9E65FB05D4B7_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0x90859b81dDBE8f62B62DAbD5DFeD5514ABAF4B29",
			"base_name": "SuperApe",
			"base_symbol": "SAPE",
			"base_address": "0x2de258C75cCD9CE3329a7aa684Da9E65FB05D4B7",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "1.58e-18",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "370302787451.1924819993431619294",
			"liquidity_BNB": "1178940826.71643822217419356473"
		},
		"0x73201ba782558ff8Cd800190e0c793b1a0f77466_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0xFf872AeA5be0c37dd314df9081e9d1B98DFC4a40",
			"base_name": "Electric Star",
			"base_symbol": "E-Star",
			"base_address": "0x73201ba782558ff8Cd800190e0c793b1a0f77466",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "0",
			"base_volume": "1.000000456550500100748360224705e+21",
			"quote_volume": "62.119919369666771381",
			"liquidity": "369499645011.2196517778455620773",
			"liquidity_BNB": "765867314.083270801261660380104"
		},
		"0x1F6AEa41a5702eb497CEe200c3250eC62cD5C2ba_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0xE2fbaa7cEa19f3816c4D38A11016fA93B8cBAeb3",
			"base_name": "AquaEthereum",
			"base_symbol": "AquaETH",
			"base_address": "0x1F6AEa41a5702eb497CEe200c3250eC62cD5C2ba",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "1.58e-18",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "368646710680.30480467171961241",
			"liquidity_BNB": "1175297402.984715754976729392294"
		},
		"0x4CF465db8DE3598498F4536A4080700eD10aa42e_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0x6F43bCeE6CE947eDB1fDBa7F35D394Ce740C00b2",
			"base_name": "BabySafe",
			"base_symbol": "BabySAFE",
			"base_address": "0x4CF465db8DE3598498F4536A4080700eD10aa42e",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "3.16e-18",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "367689930898.976720392139921108",
			"liquidity_BNB": "1273090352.22484738345066916153"
		},
		"0xADC7316883469908282970De0DA71b411A0001A0_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0x985fa2E511cC1738d0C53F5EC1008A1B192d447A",
			"base_name": "ShibaPanda",
			"base_symbol": "ShibPNDA",
			"base_address": "0xADC7316883469908282970De0DA71b411A0001A0",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "3.16e-18",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "366468942825.9090017932507559258",
			"liquidity_BNB": "1228855791.028469459155799574564"
		},
		"0x8F927B226594D3EA8E990B92Db7101aD86039f5B_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0x3364B7049f719732DCf8deA0ADbCa4E23230160b",
			"base_name": "FlokiCash",
			"base_symbol": "FlokiCASH",
			"base_address": "0x8F927B226594D3EA8E990B92Db7101aD86039f5B",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "3.16e-18",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "366210818406.551687508269173576",
			"liquidity_BNB": "1368052735.271358579164741420528"
		},
		"0x82eA421d5Fb0E84798760285A3A033cFCab3BA2a_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0x1585826048Be67dc5D0234651524b5801A004891",
			"base_name": "AquaStrongElon",
			"base_symbol": "AquaSElon",
			"base_address": "0x82eA421d5Fb0E84798760285A3A033cFCab3BA2a",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "1.58e-18",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "364017491255.927547643158491727",
			"liquidity_BNB": "1209536090.74817844373130064663"
		},
		"0x4eA38FDC811414e35e91F18c18276b363a37B249_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0x4c30683672541Cf9a2708819BCE420A685e56852",
			"base_name": "OnlyUp Cardano",
			"base_symbol": "UpADA",
			"base_address": "0x4eA38FDC811414e35e91F18c18276b363a37B249",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "3.16e-18",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "362651920683.6690240174999670716",
			"liquidity_BNB": "1364493686.33519603012713342623"
		},
		"0x8A20A9467E6c1ca5f96Ad1548D38230D845dE92F_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0x099E2C07707beBFbb092AD2F53eBaFD9eafD5F38",
			"base_name": "NanoSafe",
			"base_symbol": "NanoSAFE",
			"base_address": "0x8A20A9467E6c1ca5f96Ad1548D38230D845dE92F",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "3.16e-18",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "362192344137.258648273975448292",
			"liquidity_BNB": "1347558852.857178361957423570714"
		},
		"0x4CaE84beB773717cb3076E16D07F96033a115d1A_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0xd93Bd270943A9fAad28226cE8787DB6c02A43c38",
			"base_name": "Hundred x Safe",
			"base_symbol": "100xSAFE",
			"base_address": "0x4CaE84beB773717cb3076E16D07F96033a115d1A",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "1.58e-18",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "362148939341.997142087486413709",
			"liquidity_BNB": "1210947473.511314463305678386111"
		},
		"0x65D6D7934503C4222801061bf8A380B51a8fbeDa_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0xa61E7a84F4Eb00E3D5aEf20d44FE53364Ea9eE24",
			"base_name": "FlokiLiteCoin",
			"base_symbol": "fLTC",
			"base_address": "0x65D6D7934503C4222801061bf8A380B51a8fbeDa",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "1.58e-18",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "361920230439.252599744287830124",
			"liquidity_BNB": "1152532430.294562523697257278234"
		},
		"0xb657bfe3A04BbAe207bAADb6E024CFAb9C09864F_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0x61FddFF56b6Aeac3E2aDFda2F8245e258278ab7D",
			"base_name": " Light Protocol",
			"base_symbol": "Light 💡️",
			"base_address": "0xb657bfe3A04BbAe207bAADb6E024CFAb9C09864F",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "0",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "361715017623.83728739225534187",
			"liquidity_BNB": "760057862.078232419246685153329"
		},
		"0x95F086e0Fcfd2d3014fEa8dac2c185d9d3464509_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0xd9FF492bf0e7ecbcc3aE5A374cFbef572b339a29",
			"base_name": "Ever Black",
			"base_symbol": "EverB",
			"base_address": "0x95F086e0Fcfd2d3014fEa8dac2c185d9d3464509",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "3.16e-18",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "361061975380.2689528829682553733",
			"liquidity_BNB": "1333783481.170755356724451421207"
		},
		"0x978F5258A919aCDB20cEDB13c38458BB1c5296B0_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0x430744b702ADE930918E1E8293a82Cdf66F225ff",
			"base_name": "FEGMOON",
			"base_symbol": "FEGMOON",
			"base_address": "0x978F5258A919aCDB20cEDB13c38458BB1c5296B0",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "3.16e-18",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "359311741280.2666746440889761474",
			"liquidity_BNB": "1330040005.396166102891615535953"
		},
		"0x64446CD879BeB6fD3a53788A22cF30d6B0f165c9_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0xabb7FFa4f0BB49dac49f01b2ca4c0f135171Dc02",
			"base_name": "Hundred x Cat",
			"base_symbol": "100xCat",
			"base_address": "0x64446CD879BeB6fD3a53788A22cF30d6B0f165c9",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "3.16e-18",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "359255204418.217095094855004867",
			"liquidity_BNB": "1347931063.213773674263211634209"
		},
		"0x61cC90F586440C5F80900E1a6BBB691483948897_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0x4d395D9Fae166dff738Eb4709b4Ae253970161eE",
			"base_name": "Shiba Finance",
			"base_symbol": "ShibFIN",
			"base_address": "0x61cC90F586440C5F80900E1a6BBB691483948897",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "3.16e-18",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "358371143539.66642006435655946",
			"liquidity_BNB": "1328590960.864791280509848286968"
		},
		"0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c_0xc870a7241C641570F2Ce6C5AbFc159c575B93BC5": {
			"pair_address": "0xeBE4A6ed76cbcbBCE1BBa74262FF1b236f4b9904",
			"base_name": "Wrapped BNB",
			"base_symbol": "WBNB",
			"base_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"quote_name": "Floki Black",
			"quote_symbol": "FlokiB",
			"quote_address": "0xc870a7241C641570F2Ce6C5AbFc159c575B93BC5",
			"price": "633150848841643757.29622124485980583095",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "357048837806.9681851084521414987",
			"liquidity_BNB": "1194694738.606544019816785881637"
		},
		"0x7b5C0b1ce1176DbfeD1326EC5142Ad89E4EC4498_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0x7709E42a4532071ffF34Ea9AaE635851d2312548",
			"base_name": "FlokiLoki",
			"base_symbol": "FlokiLOKI",
			"base_address": "0x7b5C0b1ce1176DbfeD1326EC5142Ad89E4EC4498",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "1.58e-18",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "357026346080.515371038148536572",
			"liquidity_BNB": "1193905505.324575312442881862534"
		},
		"0x47B8B2F0682622aB901EAc7d8560B0D36cb1BfE4_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0x0aece57e257b17a9D5b0904446750a09fB909489",
			"base_name": "FlokiBNB",
			"base_symbol": "FlokiBNB",
			"base_address": "0x47B8B2F0682622aB901EAc7d8560B0D36cb1BfE4",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "1.58e-18",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "356068457823.7251961040455013204",
			"liquidity_BNB": "1191389009.986653166389341682059"
		},
		"0xA01000C52b234a92563BA61e5649b7C76E1ba0f3_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0x974d2549b034b093964088FF333C30153085c6ad",
			"base_name": "ROCKS",
			"base_symbol": "ROCKS",
			"base_address": "0xA01000C52b234a92563BA61e5649b7C76E1ba0f3",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "8.9895e-16",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "350915019223.655602290191075091",
			"liquidity_BNB": "1325520591.028142947631260281268"
		},
		"0x4D50cF2c92eec9243b7070CCde7A68B03123Ce5F_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0x5194f5596C2AD5654DA6C7C91Ed7ca170b75D9B8",
			"base_name": "SuperInu",
			"base_symbol": "SUPInu",
			"base_address": "0x4D50cF2c92eec9243b7070CCde7A68B03123Ce5F",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "1.58e-18",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "350641000713.833816471262783846",
			"liquidity_BNB": "1174250736.790308711955227419784"
		},
		"0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c_0xBf593922598195Ec51Eb62F117475846b7e249E7": {
			"pair_address": "0xe7335154038d2912eAe13D2A39F4745A2257267C",
			"base_name": "Wrapped BNB",
			"base_symbol": "WBNB",
			"base_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"quote_name": "AquaDoge",
			"quote_symbol": "AquaDOGE",
			"quote_address": "0xBf593922598195Ec51Eb62F117475846b7e249E7",
			"price": "316477213863361170.30216495452611927874",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "350354107808.3914807763525607032",
			"liquidity_BNB": "1172667186.340443858134044677415"
		},
		"0x722f367C9Ed5BeFBCCd3Ffe356A2eF78C5D17794_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0x8c7A0F6b06784A9661f4A15C47833029C62852c7",
			"base_name": "NanoMOON",
			"base_symbol": "NanoMOON",
			"base_address": "0x722f367C9Ed5BeFBCCd3Ffe356A2eF78C5D17794",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "1.58e-18",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "348211218121.4324821875168946353",
			"liquidity_BNB": "1156591601.462350427223562234007"
		},
		"0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c_0xbe86f587f13c783075b1C209673e8d9146c127F2": {
			"pair_address": "0xAc3C8555495d2e68418D64Af2aD853AE743B28d5",
			"base_name": "Wrapped BNB",
			"base_symbol": "WBNB",
			"base_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"quote_name": "SuperMOON",
			"quote_symbol": "SMOON",
			"quote_address": "0xbe86f587f13c783075b1C209673e8d9146c127F2",
			"price": "633265679772799533.20459325283067724427",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "346815897986.4186056365233531275",
			"liquidity_BNB": "1160810176.403260728797806968802"
		},
		"0x58D67239f43886f5B7B95943926000062d8fdd0C_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0xE3FAa5F5d6664E9913096Ce8485cFf7d2b27B072",
			"base_name": "NanoLoki",
			"base_symbol": "NanoLOKI",
			"base_address": "0x58D67239f43886f5B7B95943926000062d8fdd0C",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "1.58e-18",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "341436030719.8894938329622131515",
			"liquidity_BNB": "1144211073.391582786911367256022"
		},
		"0x64e7708B0d4B8B19A92E0e60568e8282C53F270D_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0xde7c8D5441F8E15b68d65E0fB7f9E44f822d5e4B",
			"base_name": "WrappedBabyDoge",
			"base_symbol": "wbDoge",
			"base_address": "0x64e7708B0d4B8B19A92E0e60568e8282C53F270D",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "6.3e-19",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "340972914967.435791146838984566",
			"liquidity_BNB": "884150381.411794647777809068786"
		},
		"0x5C554A9942c64E1378b62009017788243ec39dD0_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0xF000C113d7b22935FFd026cDe5CD749762c8f5c3",
			"base_name": "Rocket Horse",
			"base_symbol": "ROCKET",
			"base_address": "0x5C554A9942c64E1378b62009017788243ec39dD0",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "0",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "339636688027.8627455051277295546",
			"liquidity_BNB": "1298137218.634048779690791543863"
		},
		"0x4DF7fd1f96A7cBC1e6f110351fedD21C5186a64e_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0x1F922E8751b9c7BB63f57cF31910b78C519b3C21",
			"base_name": "MiniRocket",
			"base_symbol": "miniRocket",
			"base_address": "0x4DF7fd1f96A7cBC1e6f110351fedD21C5186a64e",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "3.16e-18",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "338800881552.5810287452514192757",
			"liquidity_BNB": "1260851083.422657418265572599326"
		},
		"0x27e913c4C6E04c16Fc8f63765aeB31ae57d7B2EF_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0x15976e9acCc612F14dA8fdd86394Ed43362c7c03",
			"base_name": "Hundred x Ape",
			"base_symbol": "100xAPE",
			"base_address": "0x27e913c4C6E04c16Fc8f63765aeB31ae57d7B2EF",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "1.58e-18",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "334843210782.5341792044821566314",
			"liquidity_BNB": "1031721669.343123289387984234899"
		},
		"0x9fFE675aa37A9E2C987D4D72fd48BC5480A1ba87_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0xD84c61fb09A546370ea7011541af0600369720D1",
			"base_name": "Hundred x Panda",
			"base_symbol": "100xPNDA",
			"base_address": "0x9fFE675aa37A9E2C987D4D72fd48BC5480A1ba87",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "3.16e-18",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "331923608985.0555149130452249526",
			"liquidity_BNB": "1229281719.93292843870357052549"
		},
		"0x9A32A5cFbbB9fF86B852391a20B6328Cc500214C_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0x0B52b70f6a250772018Bf34eB1F86c0dFbEa94e5",
			"base_name": "SuperLoki",
			"base_symbol": "SUPLOKI",
			"base_address": "0x9A32A5cFbbB9fF86B852391a20B6328Cc500214C",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "3.15e-18",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "331143155020.350452354871331658",
			"liquidity_BNB": "1101844455.246874416138176408618"
		},
		"0x9C387240376883BFB9689A16f8A334c341400A62_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0xD0e78851F19aE08C6CcaEB0338b2e46083Fe22e3",
			"base_name": "AquaMOON",
			"base_symbol": "AquaMOON",
			"base_address": "0x9C387240376883BFB9689A16f8A334c341400A62",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "3.16e-18",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "328548652262.4624550476632945666",
			"liquidity_BNB": "1224315796.696379419115122218837"
		},
		"0x86947FC7CA6957faCA609F975695126F43000af1_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0x2902E6fF1928c4520e8c4fb8acc5e02cCbb2452E",
			"base_name": "OnlyUp Finance",
			"base_symbol": "UpFIN",
			"base_address": "0x86947FC7CA6957faCA609F975695126F43000af1",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "1.58e-18",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "328499797715.022300602704259275",
			"liquidity_BNB": "1023521409.219332724415906270027"
		},
		"0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c_0xC8CC454b6a7b7f776B7fF9EBb41367e2b095e8A6": {
			"pair_address": "0xCD85De5221dAb650fbeB9b28905b99250d823eE8",
			"base_name": "Wrapped BNB",
			"base_symbol": "WBNB",
			"base_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"quote_name": "OnlyUp Cardano",
			"quote_symbol": "UpADA",
			"quote_address": "0xC8CC454b6a7b7f776B7fF9EBb41367e2b095e8A6",
			"price": "316368304145771913.53134749122860176617",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "327964496440.6552105193060548654",
			"liquidity_BNB": "1094584858.940362525384129958921"
		},
		"0x630fd7b6dc159fFF77f0e337E3ED63f421C5F3F7_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0xd807708407F7C0E155Fb2174F228Ab1c38956622",
			"base_name": "FlokiRocket",
			"base_symbol": "FlokiRocket",
			"base_address": "0x630fd7b6dc159fFF77f0e337E3ED63f421C5F3F7",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "3.15e-18",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "326910877971.893690615361670894",
			"liquidity_BNB": "1086550821.481330812887609998424"
		},
		"0xA6C94E128f860a59e69F75D9C77dAa9db96Ee9Fc_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0x5016Da2CF53BEe86fAb7B7dcdb3bFFCD50be6d98",
			"base_name": "Binance Inu",
			"base_symbol": "BSCInu",
			"base_address": "0xA6C94E128f860a59e69F75D9C77dAa9db96Ee9Fc",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "3.16e-18",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "326750912412.1757266480384071165",
			"liquidity_BNB": "1213243908.23213349310280151903"
		},
		"0x7A7306c24280911c117Ba2147759BF87571e02Ff_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0x07D2848A3607Efe83d2B368713F51b41359F2FC2",
			"base_name": "Binance Panda",
			"base_symbol": "BSCPNDA",
			"base_address": "0x7A7306c24280911c117Ba2147759BF87571e02Ff",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "1.58e-18",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "325674909986.0180490996393447706",
			"liquidity_BNB": "1013527493.476630697568257278409"
		},
		"0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c_0xbB90FeDaCaCD2eb66df2A84B1CFE0dB8a5E832c3": {
			"pair_address": "0x89d6880Ad7c8b8E5Fd1ACcD9f3A0392AB106f709",
			"base_name": "Wrapped BNB",
			"base_symbol": "WBNB",
			"base_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"quote_name": "FEGFEG",
			"quote_symbol": "FEGFEG",
			"quote_address": "0xbB90FeDaCaCD2eb66df2A84B1CFE0dB8a5E832c3",
			"price": "316869636110200109.26039634071231610885",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "323595766181.464076745693006821",
			"liquidity_BNB": "1214207273.927785430521271897303"
		},
		"0x7ca246472e85db2e0915106BC46d9ecAE1aeE8e1_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0x0CAAE2107c5e63E504C0e66fE02499801D6cf166",
			"base_name": "Kudo Solid Co",
			"base_symbol": "KSC️",
			"base_address": "0x7ca246472e85db2e0915106BC46d9ecAE1aeE8e1",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "0",
			"base_volume": "1.000000467357477280468946530593e+21",
			"quote_volume": "49.64341201470044674200000000003",
			"liquidity": "323075346071.9241035537366037657",
			"liquidity_BNB": "644196117.362552552447104716205"
		},
		"0x4a51B798C1FcdF28Eb32563C8b7E135b49A928a7_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0xfA8f5592fc62Cc51394d7089BBa262d7893aF26D",
			"base_name": "WrappedInu",
			"base_symbol": "wInu",
			"base_address": "0x4a51B798C1FcdF28Eb32563C8b7E135b49A928a7",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "3.16e-18",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "323018180954.9876456230659883817",
			"liquidity_BNB": "1211712940.197204329577971814535"
		},
		"0x95F0DA3077B287Cd717AF89eF0FDc9Fe4Bb675DD_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0xceF45DedF9903bD87c491ac56Af26330a6Bb05C3",
			"base_name": "BabyCat",
			"base_symbol": "BabyCat",
			"base_address": "0x95F0DA3077B287Cd717AF89eF0FDc9Fe4Bb675DD",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "3.15e-18",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "323008531563.4152773437417917224",
			"liquidity_BNB": "1206672689.834637223795296940121"
		},
		"0x493ca45Af3AF855f402d4B07309250AfC2F8e1B1_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0x1a70063980Df15Ec8D1313346d19E66741b7ed9a",
			"base_name": "WrappedEthereum",
			"base_symbol": "wETH",
			"base_address": "0x493ca45Af3AF855f402d4B07309250AfC2F8e1B1",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "1.58e-18",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "322779000875.453429381141870431",
			"liquidity_BNB": "1026103667.6703914487126627374526"
		},
		"0x8A8480d88E9446B2543e7E62AAEAbE6dbADdf4BE_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0xb6DbB169593814C4146F8d8a821343d05AB3bC0C",
			"base_name": "SpaceBoxer",
			"base_symbol": "SPACEBOX",
			"base_address": "0x8A8480d88E9446B2543e7E62AAEAbE6dbADdf4BE",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "3.15e-18",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "322288830318.6431412341124755805",
			"liquidity_BNB": "1210952070.032911471270197514372"
		},
		"0x2F740C78bf2846645BE16BcF241A62b124c04EA6_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0x14694E11070235893Dfb3c8eF454707CA57596BB",
			"base_name": "BabyRocket",
			"base_symbol": "bRocket",
			"base_address": "0x2F740C78bf2846645BE16BcF241A62b124c04EA6",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "3.16e-18",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "321772423177.744673412910850552",
			"liquidity_BNB": "1076300802.0978705630197129082"
		},
		"0x32Ade1Ff6DBeF28d60455966ad67dAAA86117a21_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0x47780830b57415f08A64a0F87C61dF8bc7b4EbeE",
			"base_name": "Lite Corgi Doge",
			"base_symbol": "LITECORGI",
			"base_address": "0x32Ade1Ff6DBeF28d60455966ad67dAAA86117a21",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "0",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "321755662411.5844514307525911284",
			"liquidity_BNB": "1076872714.238161631875774004202"
		},
		"0x1162962b1b2d95cb79439B62A85420DE336B4280_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0x9038b7A9a13DbC401aA00E30f8B131a2e625Afca",
			"base_name": "Hundred x Ethereum",
			"base_symbol": "100xETH",
			"base_address": "0x1162962b1b2d95cb79439B62A85420DE336B4280",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "3.16e-18",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "321377100276.999072064504987981",
			"liquidity_BNB": "1195119674.988247411618793057587"
		},
		"0x1652b02C51fDa8B119305E11BF4390A12A4889F4_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0xff7256eebc29Ea9364dB5AE95832279883c78628",
			"base_name": "FEGCash",
			"base_symbol": "FEGCASH",
			"base_address": "0x1652b02C51fDa8B119305E11BF4390A12A4889F4",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "3.15e-18",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "321299481691.9870742523783917937",
			"liquidity_BNB": "1203148980.924435740036453860457"
		},
		"0x97fdb5889221aA9c06551884D155764FeB1c6472_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0x7E0926e1E8b54394fd72c7aD288242c63AaDB381",
			"base_name": "MiniBoxer",
			"base_symbol": "miniBOX",
			"base_address": "0x97fdb5889221aA9c06551884D155764FeB1c6472",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "3.16e-18",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "319858250936.573457830304288668",
			"liquidity_BNB": "1072572361.508773023122594147736"
		},
		"0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c_0xFEFa113aae595EE9028C134e38F29aA50F22f230": {
			"pair_address": "0x2Cd79d9f9BFeAc3C5acf2ac19fD70285B0428Bbb",
			"base_name": "Wrapped BNB",
			"base_symbol": "WBNB",
			"base_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"quote_name": "Binance  Black",
			"quote_symbol": "BSCB",
			"quote_address": "0xFEFa113aae595EE9028C134e38F29aA50F22f230",
			"price": "633932822414492800.0894268132862856021",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "319430204442.3663380816125471127",
			"liquidity_BNB": "1071690868.485145992658516532063"
		},
		"0x2B40fA990619034Cf1A0441f61DE763CD1454e24_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0xcC6d423E910E8dDa0b51DF696c7306655B833114",
			"base_name": "WrappedRocket",
			"base_symbol": "wRocket",
			"base_address": "0x2B40fA990619034Cf1A0441f61DE763CD1454e24",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "1.58e-18",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "319106559358.8905950109428324006",
			"liquidity_BNB": "1026633785.507722141189898785676"
		},
		"0x3B709e9fFE9e416Afc67E68797EA8B8238D5846E_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0xbdAC72e94EB68a733ef3CB028583045dDc25c73A",
			"base_name": "FlokiMOON",
			"base_symbol": "fMOON",
			"base_address": "0x3B709e9fFE9e416Afc67E68797EA8B8238D5846E",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "3.16e-18",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "318677674391.4450528829296415065",
			"liquidity_BNB": "1196739877.101333740606322494199"
		},
		"0x426Be9252a51F03BFD85BC5315E6CD3d429933D9_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0xD269807601d8904582aF035245b1972709D1DDc0",
			"base_name": "Hundred x Loki",
			"base_symbol": "100xLOKI",
			"base_address": "0x426Be9252a51F03BFD85BC5315E6CD3d429933D9",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "1.58e-18",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "317804267657.8197115380555913765",
			"liquidity_BNB": "1016141005.22171881846320644906"
		},
		"0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c_0xeA83bC67c44696a5671728DDB76d7F636636C4e3": {
			"pair_address": "0xCad9fe2f03472Cae398ECA02DcA518A129e2AeE3",
			"base_name": "Wrapped BNB",
			"base_symbol": "WBNB",
			"base_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"quote_name": "AquaPanda",
			"quote_symbol": "AquaPNDA",
			"quote_address": "0xeA83bC67c44696a5671728DDB76d7F636636C4e3",
			"price": "316774731231598777.02428475679425775504",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "317227192151.2674496771899526493",
			"liquidity_BNB": "1063722835.1868660021287166794824"
		},
		"0x00d1aFF5109107E7af5cf807241Ee93cf4cdf446_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0xcE94c9de2cCB4873706BD2c6C21477d9Db4FBDB6",
			"base_name": "FEGSafe",
			"base_symbol": "FEGSAFE",
			"base_address": "0x00d1aFF5109107E7af5cf807241Ee93cf4cdf446",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "3.16e-18",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "315335531404.458794247760702279",
			"liquidity_BNB": "1053603794.922188884776116647579"
		},
		"0x92E48dF7771d36FBa862a019dAf2a3E41dd80f75_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0xea736fE07F8Ee0973aE48eb4035ed2d749d6B9C6",
			"base_name": "FlokiEthereum",
			"base_symbol": "FlokiETH",
			"base_address": "0x92E48dF7771d36FBa862a019dAf2a3E41dd80f75",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "3.16e-18",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "315009596040.785217679787870577",
			"liquidity_BNB": "1164683790.674114175031085912258"
		},
		"0x38b75f3c9A3528A608D5a692861c4b25bA41d1F0_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0xe93bAEA570E3e0df33ceCBcC11d5820B02fc2Bb5",
			"base_name": "Rocket",
			"base_symbol": "$Rocket",
			"base_address": "0x38b75f3c9A3528A608D5a692861c4b25bA41d1F0",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "3.16e-18",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "314823211767.1518575107466242574",
			"liquidity_BNB": "1043386581.077545932044771600187"
		},
		"0x642F1dc649a1a76DB8dE9aA9D3651fdEe1c3D1cb_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0xB84E5cC4361590E837Fee0D67874959B4DCea14f",
			"base_name": "MANEMOON",
			"base_symbol": "MNM",
			"base_address": "0x642F1dc649a1a76DB8dE9aA9D3651fdEe1c3D1cb",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "0",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "314414349360.20793941036875235",
			"liquidity_BNB": "793918144.424079341082269713047"
		},
		"0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c_0xD72828831a16478D729a03E52B476360177241EB": {
			"pair_address": "0x9536657EA15B8cC36b8E360911ca880fD633b12c",
			"base_name": "Wrapped BNB",
			"base_symbol": "WBNB",
			"base_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"quote_name": "Hundred x Ape",
			"quote_symbol": "100xAPE",
			"quote_address": "0xD72828831a16478D729a03E52B476360177241EB",
			"price": "632446360920567411.89892542419752773081",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "314221570702.0802380064732173826",
			"liquidity_BNB": "1051952261.633364736282739709818"
		},
		"0x8a4B4709B8Ebe5fC4dEFFD81E49e5D7c071F38b4_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0x4aeAB0AAE789dF9e6cEefa6fA1CD3115D40F0248",
			"base_name": " BabySharkBTC",
			"base_symbol": "https://t.me/BabySharkBTC",
			"base_address": "0x8a4B4709B8Ebe5fC4dEFFD81E49e5D7c071F38b4",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "0",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "312642282408.7906161807919652386",
			"liquidity_BNB": "1033220858.141345400962963198854"
		},
		"0x63916E7848f419208AedB1273cDa8f6c9B4AB6de_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0x7Ce940d8a35DA4c9F8cDE03d73c0B5D4E2ba5F7d",
			"base_name": "Mini Black",
			"base_symbol": "miniB",
			"base_address": "0x63916E7848f419208AedB1273cDa8f6c9B4AB6de",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "3.16e-18",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "310873961882.754441437442794982",
			"liquidity_BNB": "1027651766.711491003952182917195"
		},
		"0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c_0xef1Aee6fDA598fFabE23CAeBD251676D8694437a": {
			"pair_address": "0x517A633e8da34693200dd7D24804dD16901aBDf2",
			"base_name": "Wrapped BNB",
			"base_symbol": "WBNB",
			"base_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"quote_name": "Cream Doge Coin",
			"quote_symbol": "CREAMDOGE",
			"quote_address": "0xef1Aee6fDA598fFabE23CAeBD251676D8694437a",
			"price": "6.146457732135111924072275785837301149660420758e+25",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "310873771232.8422018346731755685",
			"liquidity_BNB": "959337115.408850613347709668589"
		},
		"0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c_0xfdb790Ac07f251873350CC4cc7AD27cD23415563": {
			"pair_address": "0x5d946e1A7d7b475c7dE039625b5713bC0F10CD91",
			"base_name": "Wrapped BNB",
			"base_symbol": "WBNB",
			"base_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"quote_name": "Mars Doge",
			"quote_symbol": "Mars Doge",
			"quote_address": "0xfdb790Ac07f251873350CC4cc7AD27cD23415563",
			"price": "4.980232910756645360325730130487680581846974e+22",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "310545241257.709153696105902961",
			"liquidity_BNB": "1016418555.183456050398051821058"
		},
		"0x247ebCC2660c91C4D9D11f71cdEC543dff82201a_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0xD287Dc60A15Db8E98887e0AB2e08Cbce556d5056",
			"base_name": "Lite Kishu Token",
			"base_symbol": "LITEKISHU",
			"base_address": "0x247ebCC2660c91C4D9D11f71cdEC543dff82201a",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "0",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "310240397521.8112893904246339175",
			"liquidity_BNB": "1038090551.064327656225558426851"
		},
		"0x32961ad121c7e576F960CBD72331EdB4E306B088_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0x8c24A7D6498C74b2b26848f930D79E2EDBa7b356",
			"base_name": "Shiba Finance",
			"base_symbol": "ShibFIN",
			"base_address": "0x32961ad121c7e576F960CBD72331EdB4E306B088",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "3.16e-18",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "309706752206.0839299747527878282",
			"liquidity_BNB": "1156399282.069326190242720517007"
		},
		"0x11FD5070567313166E3a6F675f84fAC2D9F8680c_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0xEE9b78F0B29299F965333Da6b59B24384216E3d6",
			"base_name": "Flow Corgi Doge",
			"base_symbol": "FLOWCORGI",
			"base_address": "0x11FD5070567313166E3a6F675f84fAC2D9F8680c",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "0",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "309131848749.6382042674425883856",
			"liquidity_BNB": "1022984876.2958172473145288527135"
		},
		"0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c_0xc3A2D9A2D8F07399A47fb3d3C53cbbcA51b1A630": {
			"pair_address": "0x106d1B800B2f922171506e31137e85A17553A6c7",
			"base_name": "Wrapped BNB",
			"base_symbol": "WBNB",
			"base_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"quote_name": "OnlyUpSafe",
			"quote_symbol": "UpSAFE",
			"quote_address": "0xc3A2D9A2D8F07399A47fb3d3C53cbbcA51b1A630",
			"price": "316703594783140491.63619299376601774771",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "307877229657.3511171652163361805",
			"liquidity_BNB": "1166748110.863781986507382304114"
		},
		"0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c_0xc368a0e29904f7F7dc7959B53c8395374F7103B7": {
			"pair_address": "0x3DDB2F39570dFd879462A56C6390Cc740Ed02A41",
			"base_name": "Wrapped BNB",
			"base_symbol": "WBNB",
			"base_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"quote_name": "FlokiMOON",
			"quote_symbol": "fMOON",
			"quote_address": "0xc368a0e29904f7F7dc7959B53c8395374F7103B7",
			"price": "316776185975463756.5586748042122154595",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "307875251263.114216204193461159",
			"liquidity_BNB": "1159349051.001363429300508607956"
		},
		"0x13Cb7Cd9Dcf351280ea058bE9B44A4473903e5f7_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0xe831cd17889c3De02e812d38086da10fbE9613Ac",
			"base_name": "Hundred x Inu",
			"base_symbol": "100xInu",
			"base_address": "0x13Cb7Cd9Dcf351280ea058bE9B44A4473903e5f7",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "3.16e-18",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "307605869745.677554390342845801",
			"liquidity_BNB": "1163441323.678538190689601765268"
		},
		"0x845F6561009F4D1d483D06062776C3884EDe0E2e_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0x33020A2c156881a33570D459CDe657Cf49BA6e95",
			"base_name": "OnlyUpInu",
			"base_symbol": "UpInu",
			"base_address": "0x845F6561009F4D1d483D06062776C3884EDe0E2e",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "3.16e-18",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "306925709059.8538502646529723614",
			"liquidity_BNB": "1164261717.347303199935300858472"
		},
		"0x6d6Ed7E2B47D0a49c62e7683C5C4ddE66086B6dB_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0x0c8c9B80e91DFD7abD1531783e0B67d4244a5098",
			"base_name": "EverMOON",
			"base_symbol": "EverMOON",
			"base_address": "0x6d6Ed7E2B47D0a49c62e7683C5C4ddE66086B6dB",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "3.16e-18",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "306845345715.7986671833561410265",
			"liquidity_BNB": "1161819457.373381153446911306036"
		},
		"0x576c109188e497A5Ca274cB959aa3B60908538Fc_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0xD183CdF2154Cea36c27d0fB6b4954757ba44F6C3",
			"base_name": "RoyalSushi",
			"base_symbol": "RoyalSushi",
			"base_address": "0x576c109188e497A5Ca274cB959aa3B60908538Fc",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "1.57e-18",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "306223642722.0839083450776915824",
			"liquidity_BNB": "979189049.213375659131978816981"
		},
		"0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c_0xCd639FFfbcAa89010BAAe41B39Bf14b74beD8fEe": {
			"pair_address": "0x04eDC8227fCf9F51a0Bf6f41dDF1feF2a4A89AE5",
			"base_name": "Wrapped BNB",
			"base_symbol": "WBNB",
			"base_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"quote_name": "BabyCat",
			"quote_symbol": "bCat",
			"quote_address": "0xCd639FFfbcAa89010BAAe41B39Bf14b74beD8fEe",
			"price": "316915397874408276.19911760699492188235",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "305597908608.3954707887052501467",
			"liquidity_BNB": "1161024620.733918127605721906957"
		},
		"0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c_0xF0D75f4B9BfcD43F214bda41bf25badF6545c89B": {
			"pair_address": "0x8ecEA673F992aeCF6e4b0B160c61bA340a353C84",
			"base_name": "Wrapped BNB",
			"base_symbol": "WBNB",
			"base_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"quote_name": "DekoFinance.live (BSC)",
			"quote_symbol": "DEKO",
			"quote_address": "0xF0D75f4B9BfcD43F214bda41bf25badF6545c89B",
			"price": "8.32522892355729268471117765692157750634002647e+24",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "305493351073.0340213937751181923",
			"liquidity_BNB": "1288032611.605895977004464283695"
		},
		"0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c_0xC7143EE587868051eaB403964413606A299A0690": {
			"pair_address": "0x11337eA0D07F5468525774a85aAb8F0B168c029C",
			"base_name": "Wrapped BNB",
			"base_symbol": "WBNB",
			"base_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"quote_name": "Lite Bunny Token",
			"quote_symbol": "LITEBUNNY",
			"quote_address": "0xC7143EE587868051eaB403964413606A299A0690",
			"price": "4.066091591392782494186506823757239132568473572e+25",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "305368047378.2936713981009540964",
			"liquidity_BNB": "984767549.871046634617561727972"
		},
		"0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c_0xc999e4188CA9E2777f6Dfa4C6F6171732Ba08045": {
			"pair_address": "0xD83a69EBEe5a35bF839AC3a774Acb60869A5d51e",
			"base_name": "Wrapped BNB",
			"base_symbol": "WBNB",
			"base_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"quote_name": "SuperDoge",
			"quote_symbol": "SDOGE",
			"quote_address": "0xc999e4188CA9E2777f6Dfa4C6F6171732Ba08045",
			"price": "316954547899309450.45980374107898234238",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "305256349076.716922589730545763",
			"liquidity_BNB": "1166767022.43102950381799974814"
		},
		"0x5FF53777671d471872134BE8a855e8AB08ea4eAA_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0xFd45662af85dDe11A4c4c7EA7a4B50eD8D4830A7",
			"base_name": "Baby Finance",
			"base_symbol": "BabyFIN",
			"base_address": "0x5FF53777671d471872134BE8a855e8AB08ea4eAA",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "1.58e-18",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "304480753211.877022526446253877",
			"liquidity_BNB": "980264770.090849810048101304878"
		},
		"0x3Fc1aa5DEB604ae254B95D289Aa76e0ABe2beAF9_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0x603a0d6FBc98636A52Ae1b43f18E949CF11d2bCE",
			"base_name": "FlokiMOON",
			"base_symbol": "fMOON",
			"base_address": "0x3Fc1aa5DEB604ae254B95D289Aa76e0ABe2beAF9",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "1.58e-18",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "303489337330.916253861167744523",
			"liquidity_BNB": "1020979114.64991040231106904612"
		},
		"0x6EEA3114adbD2183Ad2119506e06DfA40181d818_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0x4Eb0b7DC4c5c2eEB74704618E59260A426d5865C",
			"base_name": "MiniMOON",
			"base_symbol": "miniMOON",
			"base_address": "0x6EEA3114adbD2183Ad2119506e06DfA40181d818",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "3.16e-18",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "302555820704.9347915310227333653",
			"liquidity_BNB": "1014239424.220417647154379281769"
		},
		"0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c_0xcf9AdC4CCdB888d253cfaB6122719718A040797d": {
			"pair_address": "0xC3e4cd295Fbe24c2a1cf77915a083d4A90B33D56",
			"base_name": "Wrapped BNB",
			"base_symbol": "WBNB",
			"base_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"quote_name": "Binance Inu",
			"quote_symbol": "BSCInu",
			"quote_address": "0xcf9AdC4CCdB888d253cfaB6122719718A040797d",
			"price": "316551459674702080.0504356391254461625",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "302239059578.851235775156983123",
			"liquidity_BNB": "1014581165.794888335513245648088"
		},
		"0xA7c327B51328f68d2468695918b1DB17e08a99bD_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0xD18DC74b2Ff523121BCe1031E6FcbcccC46De301",
			"base_name": "FlokiDoge",
			"base_symbol": "FlokiDOGE",
			"base_address": "0xA7c327B51328f68d2468695918b1DB17e08a99bD",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "1.58e-18",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "301109110724.7238158672090655795",
			"liquidity_BNB": "964151523.078464242367212914246"
		},
		"0x5D057F1cfA6F857B036Ef43ec6A6DfbcAb32A830_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0xf2C63756A851E205Dc671dB155b1026775Cd7d3f",
			"base_name": "AquaPanda",
			"base_symbol": "AquaPNDA",
			"base_address": "0x5D057F1cfA6F857B036Ef43ec6A6DfbcAb32A830",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "3.16e-18",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "301077250669.293172120972657441",
			"liquidity_BNB": "1139899796.785297679556702534988"
		},
		"0x504E7Ebc8C8b9D9422A6aBA220C8b6BA23e34B42_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0x44CC606257c8797282823B7C5F8fFf35da37b946",
			"base_name": "SuperInu",
			"base_symbol": "SUPInu",
			"base_address": "0x504E7Ebc8C8b9D9422A6aBA220C8b6BA23e34B42",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "3.16e-18",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "300191613373.0926594612929714386",
			"liquidity_BNB": "985575675.086090058778937787199"
		},
		"0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c_0xf009958134897Ecc7A5D1dFBE82A364EE30E134d": {
			"pair_address": "0xce5158708EEAdef7350aFf3B1AC874325c786b49",
			"base_name": "Wrapped BNB",
			"base_symbol": "WBNB",
			"base_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"quote_name": "ShibaMOON",
			"quote_symbol": "SHIBAMOON",
			"quote_address": "0xf009958134897Ecc7A5D1dFBE82A364EE30E134d",
			"price": "633377620521276435.70841373545157449475",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "299910446439.2265416535281978487",
			"liquidity_BNB": "936277490.428578222601673186958"
		},
		"0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c_0xF576Eb1ac7A20e12794AE3cBe4a718A4bD9393ec": {
			"pair_address": "0x3c0fDE904f879EA7dCaC70551675B6132E8E1D3F",
			"base_name": "Wrapped BNB",
			"base_symbol": "WBNB",
			"base_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"quote_name": "Flow Akita Inu",
			"quote_symbol": "FLOWAKITA",
			"quote_address": "0xF576Eb1ac7A20e12794AE3cBe4a718A4bD9393ec",
			"price": "6.304682438609784161189179103081612205076932221e+25",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "298364591197.268295180175022195",
			"liquidity_BNB": "989900724.956285032894763084233"
		},
		"0x54b657B7d1a4e0d0fD01c0892dACC1604Add0FA3_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0x936d9F0406c01a30C0Dc2C97D1EA8b6D77e609AE",
			"base_name": " Black",
			"base_symbol": "$B",
			"base_address": "0x54b657B7d1a4e0d0fD01c0892dACC1604Add0FA3",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "3.16e-18",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "296894755251.1723137203853761373",
			"liquidity_BNB": "1136248930.221678400893981008757"
		},
		"0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c_0xEb32Ca0d96602176F177178a8c3A37FD557B6e5f": {
			"pair_address": "0x550778AA6ECf747A36eb4eC3D978FaEEAbDB9904",
			"base_name": "Wrapped BNB",
			"base_symbol": "WBNB",
			"base_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"quote_name": "SpaceEthereum",
			"quote_symbol": "SPACEETH",
			"quote_address": "0xEb32Ca0d96602176F177178a8c3A37FD557B6e5f",
			"price": "634956398325871629.63696701782622621516",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "296726006622.024085180788679165",
			"liquidity_BNB": "949584283.84821568777957455771"
		},
		"0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c_0xD8739655d0E27c3010162F0A6ef97DbD37Ead6F3": {
			"pair_address": "0xBf0016e1BD36Baf8835E7239E69cb53eDbF27BeE",
			"base_name": "Wrapped BNB",
			"base_symbol": "WBNB",
			"base_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"quote_name": "AquaPanda",
			"quote_symbol": "AquaPNDA",
			"quote_address": "0xD8739655d0E27c3010162F0A6ef97DbD37Ead6F3",
			"price": "316700853445327370.14177658980294199896",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "296478599736.118755981991311127",
			"liquidity_BNB": "980183519.2689212101996937540885"
		},
		"0x0C11BAB4CB1024cdfc80041751bc9a8E8f79837f_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0xa9050357b4B8921941aE29a8Bf0bf713c9cb3A8a",
			"base_name": "EverInu",
			"base_symbol": "EverInu",
			"base_address": "0x0C11BAB4CB1024cdfc80041751bc9a8E8f79837f",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "3.16e-18",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "296364681013.9168277870703203532",
			"liquidity_BNB": "1129031248.488602057079326306185"
		},
		"0xb3e12D2FfB421b8bcb77D29e922DCba3CF11fF82_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0x57154c584915796F1D3c9cbd637Bff809D0baBf9",
			"base_name": "Super Cardano",
			"base_symbol": "SUPADA",
			"base_address": "0xb3e12D2FfB421b8bcb77D29e922DCba3CF11fF82",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "3.16e-18",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "296361488839.5694489087111033005",
			"liquidity_BNB": "979382893.202020597587071344832"
		},
		"0x06108b5e6D377Ef47D0122b47103877Cd2040e75_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0xD50CA08C6bc222F0272e8fB373DE85A83a9E265e",
			"base_name": "Floki Finance",
			"base_symbol": "FlokiFIN",
			"base_address": "0x06108b5e6D377Ef47D0122b47103877Cd2040e75",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "3.16e-18",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "295870728053.6931724540421385686",
			"liquidity_BNB": "987426058.4515953682663154430995"
		},
		"0x5b1F903792eC2D07A746B55F8498FdD8C6d2D81a_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0x3011749344EC2BBBCE53841F1053Bd532d15Ebe5",
			"base_name": "Aqua Black",
			"base_symbol": "AquaB",
			"base_address": "0x5b1F903792eC2D07A746B55F8498FdD8C6d2D81a",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "3.15e-18",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "295304399524.4145443378675869018",
			"liquidity_BNB": "985139567.682903729214414582347"
		},
		"0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c_0xE067Bb88e913aEcac8aCF29De09eD73FF8A42A6e": {
			"pair_address": "0x484164Dbf6965B9149B29517229e3f920EFF0801",
			"base_name": "Wrapped BNB",
			"base_symbol": "WBNB",
			"base_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"quote_name": "SpaceCat",
			"quote_symbol": "SPACECat",
			"quote_address": "0xE067Bb88e913aEcac8aCF29De09eD73FF8A42A6e",
			"price": "316407355798635114.21065050563032959282",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "292159039428.2534953226877327487",
			"liquidity_BNB": "971452491.423975223500466462799"
		},
		"0x34c285dE991E80EfC76b5c1465d046F50d8664B6_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0x805eDac85E3cdF72b4881196a06fF09F62f0D868",
			"base_name": " Ocean Protocol",
			"base_symbol": "OCEAN 🌀️",
			"base_address": "0x34c285dE991E80EfC76b5c1465d046F50d8664B6",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "0",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "291465828278.9680334607751338275",
			"liquidity_BNB": "588515339.080673667511551542756"
		},
		"0x79cA029860544446c586Ca45ff1bBAA0942cD8e4_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0x0c45121C9aE7f3a3c81ff679Ab4b11Fb01874690",
			"base_name": "Ero Finance",
			"base_symbol": "ERO",
			"base_address": "0x79cA029860544446c586Ca45ff1bBAA0942cD8e4",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "1e-18",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "291242251817.091315663854952357",
			"liquidity_BNB": "1015037369.4500001002522808112835"
		},
		"0x1af954dA5576E4622626349d8cA06c71eA0661de_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0x924592168f7962e444d8581CD80C92cBF2727C33",
			"base_name": "Ero Finance",
			"base_symbol": "ERO",
			"base_address": "0x1af954dA5576E4622626349d8cA06c71eA0661de",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "1e-18",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "291044268631.8211431471110692665",
			"liquidity_BNB": "1015037369.4500001002522808112835"
		},
		"0x7Eb02Be15f2ef17B2bAac0804015b85279d3374B_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0x23ca49B3a251Cd4C00FBDBC94328EE9B166b1C2d",
			"base_name": "Ero Finance",
			"base_symbol": "ERO",
			"base_address": "0x7Eb02Be15f2ef17B2bAac0804015b85279d3374B",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "1e-18",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "291024569203.9333080402409517965",
			"liquidity_BNB": "1015037369.4500001002522808112835"
		},
		"0x8Ed893deA02e79277fE881c89F4Cb959824caEa8_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0xA717D7d276bf0786f68C2312ab6CF9E6a1f7F275",
			"base_name": "ShibaSafe",
			"base_symbol": "ShibSAFE",
			"base_address": "0x8Ed893deA02e79277fE881c89F4Cb959824caEa8",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "3.16e-18",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "290401158443.6977068263292123063",
			"liquidity_BNB": "1094735535.547152761523577742047"
		},
		"0x399AD4D78b154D229a5d4693A1aa17f7F9e67Fb2_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0x8203B067Bb075854f297bA116247ac8C7D350885",
			"base_name": "Ero Finance",
			"base_symbol": "ERO",
			"base_address": "0x399AD4D78b154D229a5d4693A1aa17f7F9e67Fb2",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "1e-19",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "290275655140.9797381848852568555",
			"liquidity_BNB": "1015234608.104213106115928922392"
		},
		"0x39d54dA3a6bc30D1d53DbccfA8ee9a7e1c019075_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0xe1536b3497406BCC452d32DEc1206D6aF84586C6",
			"base_name": "FlokiLoki",
			"base_symbol": "FlokiLOKI",
			"base_address": "0x39d54dA3a6bc30D1d53DbccfA8ee9a7e1c019075",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "3.16e-18",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "289998338092.0400725132254008192",
			"liquidity_BNB": "1094955760.874384799916103217341"
		},
		"0xa25f00c333F127b19CDf70092E0A33B1741490C7_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0x1F2e6dE12dA0638b302317DE420ed9F9c16954A8",
			"base_name": "Hundred x  Black",
			"base_symbol": "100xB",
			"base_address": "0xa25f00c333F127b19CDf70092E0A33B1741490C7",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "3.16e-18",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "289073807242.882283274895454933",
			"liquidity_BNB": "1096592038.778138709141598476372"
		},
		"0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c_0xBd13De1aab1220b037763bcC8db735f7BFA57107": {
			"pair_address": "0xB007b482b6BBf82Ca23233E0aeE67F0f68B977b4",
			"base_name": "Wrapped BNB",
			"base_symbol": "WBNB",
			"base_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"quote_name": "FlokiCat",
			"quote_symbol": "FlokiCat",
			"quote_address": "0xBd13De1aab1220b037763bcC8db735f7BFA57107",
			"price": "316626691629698210.56215808211325287041",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "288703111418.3547839234424027778",
			"liquidity_BNB": "963026653.970748298375472017299"
		},
		"0xa90B85907f7372FD73Ba2cFe6C718Da89663d487_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0x43a0bf849F814d2683ad0f10FD4E0a9A209F2325",
			"base_name": "Binance MOON",
			"base_symbol": "BSCMOON",
			"base_address": "0xa90B85907f7372FD73Ba2cFe6C718Da89663d487",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "3.16e-18",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "288105517424.743944444625296934",
			"liquidity_BNB": "959166866.890801757849948526319"
		},
		"0x83202C20d4F3a4f29aa8b2063A47a3494922535B_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0x9c9AB0bce4061d46F7031ACdD0C07e100E72cFCf",
			"base_name": "Panda",
			"base_symbol": "$PNDA",
			"base_address": "0x83202C20d4F3a4f29aa8b2063A47a3494922535B",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "3.16e-18",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "287574646849.43534057324215345",
			"liquidity_BNB": "956780850.842053480177469607466"
		},
		"0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c_0xf675B1E4fc0b561bE1f5A90155601898f1F28e4b": {
			"pair_address": "0xdF6131864999a6a095F270B71469D75a1E86B937",
			"base_name": "Wrapped BNB",
			"base_symbol": "WBNB",
			"base_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"quote_name": "Baby Black",
			"quote_symbol": "BabyB",
			"quote_address": "0xf675B1E4fc0b561bE1f5A90155601898f1F28e4b",
			"price": "316844366452324277.05877552386182061767",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "286810933788.0310831575650913536",
			"liquidity_BNB": "951289803.345602398196224062295"
		},
		"0x403006d29eD2DFB4a85c128162149B019bFCFa60_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0x767E561CA356e78b90B5a560BDcb96Da7082cE5E",
			"base_name": "OnlyUpDoge",
			"base_symbol": "UpDOGE",
			"base_address": "0x403006d29eD2DFB4a85c128162149B019bFCFa60",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "3.15e-18",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "284123668231.086080655111339282",
			"liquidity_BNB": "944360182.641671280622088009752"
		},
		"0x9408D0B4d5C744544c7651E69f5F5CF0FcBA71b8_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0x4BB64dBE3B88bf1eD34eb1A0928560d6c8ea7E9B",
			"base_name": "Mini Cardano",
			"base_symbol": "miniADA",
			"base_address": "0x9408D0B4d5C744544c7651E69f5F5CF0FcBA71b8",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "3.16e-18",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "284112636542.601387835573233736",
			"liquidity_BNB": "941998486.827315639723326613108"
		},
		"0x550C8897DfEEb5Fa37943F13829ce2D6D5f6C65d_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0x20D728Ad0d53EA56be6c26a825190Fadfe397e78",
			"base_name": "BabyInu",
			"base_symbol": "BabyInu",
			"base_address": "0x550C8897DfEEb5Fa37943F13829ce2D6D5f6C65d",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "3.16e-18",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "282883856547.4069014250963653967",
			"liquidity_BNB": "1071511780.833833337442228943369"
		},
		"0xA4Aa8c96dc0C08CbbC79c80DbC47f81FC6F8395C_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0x7d9EF7D6B4f940fCB01Fd7bD9D8B9344cf35735a",
			"base_name": "MiniInu",
			"base_symbol": "miniInu",
			"base_address": "0xA4Aa8c96dc0C08CbbC79c80DbC47f81FC6F8395C",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "3.16e-18",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "282101556516.7797676081927216087",
			"liquidity_BNB": "1077097215.610790833586707101986"
		},
		"0x5E38CB27083E7fA247FCF82db3A53c920eDD0333_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0x09AB86872487C84b28528f2Ca70C9897119f0666",
			"base_name": "ShibaApe",
			"base_symbol": "ShibAPE",
			"base_address": "0x5E38CB27083E7fA247FCF82db3A53c920eDD0333",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "3.16e-18",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "273890576777.804021515691069206",
			"liquidity_BNB": "913905878.934315609830856881222"
		},
		"0x6e9730EcFfBed43fD876A264C982e254ef05a0DE_0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56": {
			"pair_address": "0x6BD5e4E30A1bBcB9A78132B1135F0435dde9e2ae",
			"base_name": "Nord Token",
			"base_symbol": "NORD",
			"base_address": "0x6e9730EcFfBed43fD876A264C982e254ef05a0DE",
			"quote_name": "BUSD Token",
			"quote_symbol": "BUSD",
			"quote_address": "0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56",
			"price": "9.854096e-14",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "273101203512.6208427070717742466",
			"liquidity_BNB": "879195316.642641823950130455987"
		},
		"0x11Ecc9b7f1347974c3262524e2b9C0B5df655952_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0xd2eF9b1D7eAc1a757f4737cBa60d1fBe7Ca09a6d",
			"base_name": "AquaInu",
			"base_symbol": "AquaInu",
			"base_address": "0x11Ecc9b7f1347974c3262524e2b9C0B5df655952",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "3.16e-18",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "272126426467.5910084793881041326",
			"liquidity_BNB": "897981891.7143562379718790602465"
		},
		"0x5b038286768aC7A2F08dFFCc6DA41E1e53135afa_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0x64f7200b00cdc6dF9B3EF10B9d6F024E18fE10D3",
			"base_name": "OnlyUpLoki",
			"base_symbol": "UpLOKI",
			"base_address": "0x5b038286768aC7A2F08dFFCc6DA41E1e53135afa",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "3.16e-18",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "269570400832.320272225985922695",
			"liquidity_BNB": "904938439.4454281409329988198786"
		},
		"0xAA2633EB2be44A96f531640A8770cEF46711125E_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0xdF1574dE2f5ABABc9E6E836f02D897A8533907CA",
			"base_name": "BabyBitcoin",
			"base_symbol": "bBTC",
			"base_address": "0xAA2633EB2be44A96f531640A8770cEF46711125E",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "3.16e-18",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "268900974710.0153544910517144611",
			"liquidity_BNB": "876473083.998578224691548870962"
		},
		"0x19Dc812322A981b32127175552535740316265A4_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0x6459A57cAf9D8087ea6008322E139520965D9EC0",
			"base_name": "FlokiPanda",
			"base_symbol": "FlokiPNDA",
			"base_address": "0x19Dc812322A981b32127175552535740316265A4",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "3.15e-18",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "268333765619.352560283542724076",
			"liquidity_BNB": "888571031.776776043544335854165"
		},
		"0xb6d96Fcb503F9242f86E4F9434614D56992e60dc_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0x74f4cd7A0340c6bA265873551Add7b913Edd942A",
			"base_name": "BabyLoki",
			"base_symbol": "BabyLOKI",
			"base_address": "0xb6d96Fcb503F9242f86E4F9434614D56992e60dc",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "1.58e-18",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "266942561901.0215973019716837807",
			"liquidity_BNB": "830168841.211789217187456244739"
		},
		"0x1AE7450B6a2d58B52E44f2Fa9C30D4D3caBD7Cc0_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0x02003b06e354Df0739085895886360F1de380e07",
			"base_name": "MiniStrongElon",
			"base_symbol": "miniSElon",
			"base_address": "0x1AE7450B6a2d58B52E44f2Fa9C30D4D3caBD7Cc0",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "3.16e-18",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "266804420242.8096309934678889502",
			"liquidity_BNB": "875629058.976939272543254842763"
		},
		"0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c_0xf7DFd0cc76D4DE8FCE1eDc9c553b7311D909BE6B": {
			"pair_address": "0x44262c0c1Afe56E4a3d31132B44e00f97996CDb4",
			"base_name": "Wrapped BNB",
			"base_symbol": "WBNB",
			"base_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"quote_name": "ShibaFEG",
			"quote_symbol": "SHIBAFEG",
			"quote_address": "0xf7DFd0cc76D4DE8FCE1eDc9c553b7311D909BE6B",
			"price": "316807784624753761.46205813615721135349",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "266030299524.4370415027773431782",
			"liquidity_BNB": "873245690.035358044353112455781"
		},
		"0x91C727da48617dD00D1dF6D787014F913a8ffdd9_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0x9D22af67B122323A497B882cC16AaeD06c678c7d",
			"base_name": "Mozart Finance",
			"base_symbol": "Mozart 🎻️",
			"base_address": "0x91C727da48617dD00D1dF6D787014F913a8ffdd9",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "0",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "265421198417.6996939404268937965",
			"liquidity_BNB": "659570926.3192893084197682593065"
		},
		"0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c_0xDfd7BAa3903583E6ff8eC110723D274E980df271": {
			"pair_address": "0xb22fDF6F4953D0c029328a1E235aBf5F43aBD03E",
			"base_name": "Wrapped BNB",
			"base_symbol": "WBNB",
			"base_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"quote_name": "MiniPanda",
			"quote_symbol": "miniPNDA",
			"quote_address": "0xDfd7BAa3903583E6ff8eC110723D274E980df271",
			"price": "317012700421311377.53338292964330543616",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "265158483315.548220697309399687",
			"liquidity_BNB": "863125223.935853442650311914108"
		},
		"0x56d69890C7666287Aa2f78BAE63D72dF82027a04_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0x47eAE687D2B8ACE671849c698eAa57E0020D82F5",
			"base_name": "Ever Finance",
			"base_symbol": "EverFIN",
			"base_address": "0x56d69890C7666287Aa2f78BAE63D72dF82027a04",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "3.16e-18",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "264561685063.2500250586169695693",
			"liquidity_BNB": "869204126.599976218587260194562"
		},
		"0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c_0xe4be71052Ff356260d8321260f64c4f131B81350": {
			"pair_address": "0x565C7FFaCCC2D5c8052c1Db7b7271d212bC36174",
			"base_name": "Wrapped BNB",
			"base_symbol": "WBNB",
			"base_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"quote_name": "WrappedInu",
			"quote_symbol": "wInu",
			"quote_address": "0xe4be71052Ff356260d8321260f64c4f131B81350",
			"price": "316684747244733041.92131000178860951515",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "264438764334.4213204522056080833",
			"liquidity_BNB": "878091852.487044400692368430607"
		},
		"0x28Eb9fE1f17633093b03eb3d862EC648C93Ea26E_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0x65C827EE45326cBEF16BfDb0d6E60Bb036a7BA1c",
			"base_name": "AquaInu",
			"base_symbol": "AquaInu",
			"base_address": "0x28Eb9fE1f17633093b03eb3d862EC648C93Ea26E",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "3.15e-18",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "263968145119.5993427564921417563",
			"liquidity_BNB": "866534734.124416690112560292523"
		},
		"0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c_0xD27Fb367D6A4012148a0760D0AaD3847FBB7D08C": {
			"pair_address": "0x8c56b69D8c538b44C567c38ca0e7e9062465D0a0",
			"base_name": "Wrapped BNB",
			"base_symbol": "WBNB",
			"base_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"quote_name": "FlokiMOON",
			"quote_symbol": "FlokiMOON",
			"quote_address": "0xD27Fb367D6A4012148a0760D0AaD3847FBB7D08C",
			"price": "316946648081571054.17996177197873092311",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "261750430234.5287754180191125957",
			"liquidity_BNB": "995045924.305703710723928059209"
		},
		"0x80065b58B0eE4a069be48A5B6146CE29BdA69C26_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0xb715CE5778c510F8785F80faAFC896911ECDad5E",
			"base_name": "MiniPanda",
			"base_symbol": "miniPNDA",
			"base_address": "0x80065b58B0eE4a069be48A5B6146CE29BdA69C26",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "3.16e-18",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "261656196818.477984465392422541",
			"liquidity_BNB": "996250058.573374950282794890868"
		},
		"0x69294418324E081eC823CAc603abf678238f5164_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0x1DF0B532Af760C5c34893e0Fa9A0AD7ad757F48B",
			"base_name": "Ass Fucker inu",
			"base_symbol": "Ass 🍑️",
			"base_address": "0x69294418324E081eC823CAc603abf678238f5164",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "0",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "261416057259.9331517002687778218",
			"liquidity_BNB": "609663450.966487971368378698752"
		},
		"0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c_0xd3914274407d3E7Ce295f7B763a6E8efcB3303b2": {
			"pair_address": "0x4e63198E4bDb2F005067f14252b451F16Eb02989",
			"base_name": "Wrapped BNB",
			"base_symbol": "WBNB",
			"base_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"quote_name": "SpaceMOON",
			"quote_symbol": "SPACEMOON",
			"quote_address": "0xd3914274407d3E7Ce295f7B763a6E8efcB3303b2",
			"price": "316978711941973563.26997318297843896677",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "260157692795.7310998811748356755",
			"liquidity_BNB": "852034087.500278957411364592865"
		},
		"0x0F94D648F458Eb676446574f697D70f682a74D1d_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0xB33e2E1761FD6C1F1673768D054383b09D89A771",
			"base_name": "Hundred x Doge",
			"base_symbol": "100xDOGE",
			"base_address": "0x0F94D648F458Eb676446574f697D70f682a74D1d",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "3.16e-18",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "259802196008.8062036711407888933",
			"liquidity_BNB": "852388379.224126935051514571039"
		},
		"0x98225bb02eC95c1EC7575326A20eaac314856594_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0x20DA162cf34FB2Fe896695AdCcaf101636D1267B",
			"base_name": "MiniSafe",
			"base_symbol": "miniSAFE",
			"base_address": "0x98225bb02eC95c1EC7575326A20eaac314856594",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "3.16e-18",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "259027397849.029609626773814908",
			"liquidity_BNB": "849120209.921779807498771953122"
		},
		"0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c_0xC8A1cc36Ea7512Cd30dc6551B36E6DE6c5eB2D58": {
			"pair_address": "0xb62e8739Fd564390991523aeb67388c59d26ce48",
			"base_name": "Wrapped BNB",
			"base_symbol": "WBNB",
			"base_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"quote_name": "ShibaRocket",
			"quote_symbol": "SHIBARocket",
			"quote_address": "0xC8A1cc36Ea7512Cd30dc6551B36E6DE6c5eB2D58",
			"price": "316328246265556037.97157189641587419951",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "258299289243.9485348277979134116",
			"liquidity_BNB": "845737350.369678106587490493947"
		},
		"0x43536e4c0C3B6B0c1777dC12bC0Bde730560795E_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0xc6d4a8947f190951918546Ae3F8E619e2986Dcf6",
			"base_name": "SpaceCash",
			"base_symbol": "SPACECASH",
			"base_address": "0x43536e4c0C3B6B0c1777dC12bC0Bde730560795E",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "3.16e-18",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "258205540484.6685935362146169577",
			"liquidity_BNB": "856015094.242646164964600776205"
		},
		"0x019adAE921DFBC60ee0fA058AeB4F9BF95a84386_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0xa692C5D7A9c7Ab90317e2e4fc7cf13A45bC45e1d",
			"base_name": "Super Finance",
			"base_symbol": "SFIN",
			"base_address": "0x019adAE921DFBC60ee0fA058AeB4F9BF95a84386",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "3.16e-18",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "258169512739.320082886106588963",
			"liquidity_BNB": "864530161.1065984051758937468825"
		},
		"0x3b7d78baE8f9EF467884Bc81a0BF5c99c9A47Dc5_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0xE305773dF05659420840602B46DAFb382e20AA96",
			"base_name": "Binance Inu",
			"base_symbol": "BSCInu",
			"base_address": "0x3b7d78baE8f9EF467884Bc81a0BF5c99c9A47Dc5",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "3.16e-18",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "257229973112.0524626725653918847",
			"liquidity_BNB": "851588001.510782791742918562631"
		},
		"0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c_0xCCfB76baa005F813b7c53295F4A86337a358e029": {
			"pair_address": "0xd9BD655E21d80D41616C529d2A8E3a631b46fc45",
			"base_name": "Wrapped BNB",
			"base_symbol": "WBNB",
			"base_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"quote_name": "NanoSafe",
			"quote_symbol": "NanoSAFE",
			"quote_address": "0xCCfB76baa005F813b7c53295F4A86337a358e029",
			"price": "316165316784467072.73828367267916194879",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "257223311078.7609929312205532075",
			"liquidity_BNB": "987989966.940928858967062483259"
		},
		"0x5fBbE9Ea1CAEe2Fd4028f6289F6d190009fC71bD_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0xa161721a1468808235569dbFA9db4Ee1CF8D0097",
			"base_name": "BabyMOON",
			"base_symbol": "bMOON",
			"base_address": "0x5fBbE9Ea1CAEe2Fd4028f6289F6d190009fC71bD",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "3.16e-18",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "257200795948.1929754171859340733",
			"liquidity_BNB": "983617690.146529550654215373544"
		},
		"0xb046f1bBe91Ca1365F2641C986A820133A460916_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0xc5c2B2a441C32167E385673BE7D4f46276374Bd0",
			"base_name": "ShibaFEG",
			"base_symbol": "ShibFEG",
			"base_address": "0xb046f1bBe91Ca1365F2641C986A820133A460916",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "3.16e-18",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "257086283252.7452660443017406875",
			"liquidity_BNB": "846427154.731472694209678187494"
		},
		"0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c_0xBe4dB1ec86F92d3E4ed627FC9D383eE8253F2600": {
			"pair_address": "0x4c78B99329a0c5e490Fb9c7112144503406D6b3b",
			"base_name": "Wrapped BNB",
			"base_symbol": "WBNB",
			"base_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"quote_name": "SpaceMOON",
			"quote_symbol": "SPACEMOON",
			"quote_address": "0xBe4dB1ec86F92d3E4ed627FC9D383eE8253F2600",
			"price": "316902394314288684.45663873078335111467",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "256709210558.7180405556750626022",
			"liquidity_BNB": "850153833.622286063755313512985"
		},
		"0x00e86B9A2cFF00e8c0b1e4436f89e586D8d06e53_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0xfFeDbEa07cFc2ec21b9a332F40507CDA619c1DfC",
			"base_name": "FlokiBNB",
			"base_symbol": "fBNB",
			"base_address": "0x00e86B9A2cFF00e8c0b1e4436f89e586D8d06e53",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "3.16e-18",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "256351363722.3208211596964318",
			"liquidity_BNB": "839475803.395080222639401865995"
		},
		"0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c_0xc5f6fb0dBA52a3aE1d79183650783730B5155582": {
			"pair_address": "0x423C715ceD56c6a278dB3833a440fc4261D6507c",
			"base_name": "Wrapped BNB",
			"base_symbol": "WBNB",
			"base_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"quote_name": "Dash Corgi Doge",
			"quote_symbol": "DASHCORGI",
			"quote_address": "0xc5f6fb0dBA52a3aE1d79183650783730B5155582",
			"price": "8.699603440065236239989036012371360853410600236e+25",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "256271067200.2349611016124584406",
			"liquidity_BNB": "904611759.282212357383558899315"
		},
		"0x2010525c4BC41Df91BC0945D25294A2B90BC1256_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0x57FA44E96C67953026B29E4542F3cd90924CBcb2",
			"base_name": "FEGBNB",
			"base_symbol": "FEGBNB",
			"base_address": "0x2010525c4BC41Df91BC0945D25294A2B90BC1256",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "3.15e-18",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "255230883182.1020882050988966",
			"liquidity_BNB": "856373141.258664204390495087717"
		},
		"0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c_0xE797EB4fb0dCc92608B5028B1CfFA8Ed59e38A16": {
			"pair_address": "0xAb7924B66E8c6c05bF9Fb08Df2798122193bB84D",
			"base_name": "Wrapped BNB",
			"base_symbol": "WBNB",
			"base_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"quote_name": "ShibaMOON",
			"quote_symbol": "SHIBAMOON",
			"quote_address": "0xE797EB4fb0dCc92608B5028B1CfFA8Ed59e38A16",
			"price": "316939712702130840.92186600186704031167",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "254335726968.359575100150459631",
			"liquidity_BNB": "896872788.174892078075094659902"
		},
		"0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c_0xd18b4479c1A6708E4D015621881a8af1d75F1513": {
			"pair_address": "0xbd3EaB7D00e49A73595B43c3497F653942A7EBd8",
			"base_name": "Wrapped BNB",
			"base_symbol": "WBNB",
			"base_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"quote_name": "FlokiRocket",
			"quote_symbol": "FlokiRocket",
			"quote_address": "0xd18b4479c1A6708E4D015621881a8af1d75F1513",
			"price": "316508208227264572.43602593901924798772",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "252136828906.2317172360543467572",
			"liquidity_BNB": "832703183.057233401315741569852"
		},
		"0x6a09a8f4b0E185985ec72F9DefE20822d4C1021a_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0x4F430a4ae1b1ABC86136dB0441ECfDc5560b7192",
			"base_name": "EverPanda",
			"base_symbol": "EverPanda",
			"base_address": "0x6a09a8f4b0E185985ec72F9DefE20822d4C1021a",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "3.16e-18",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "251938597816.9106745432671073527",
			"liquidity_BNB": "827104903.434842142082810603104"
		},
		"0x07BD2980ABf45349E460Dd8d3380c97F3aB8932d_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0xf9a6995Ef31a3D17f1F70C9fcC33E09037411106",
			"base_name": "WrappedCat",
			"base_symbol": "wCat",
			"base_address": "0x07BD2980ABf45349E460Dd8d3380c97F3aB8932d",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "3.15e-18",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "251844763628.4749058271976327147",
			"liquidity_BNB": "985951583.117406589668855668009"
		},
		"0x896bEB516a42068229f098388387fa6EEF4364A4_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0xe36De1b95eA52289fE103B7c5f279B14F43adAda",
			"base_name": "AquaRocket",
			"base_symbol": "AquaRocket",
			"base_address": "0x896bEB516a42068229f098388387fa6EEF4364A4",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "3.16e-18",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "248640430355.5191490069419528802",
			"liquidity_BNB": "820859884.131371373034419767681"
		},
		"0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c_0xD7A39b6F6aA1Ab550D609D9a4b67Ad043b727F0B": {
			"pair_address": "0x421795591434cB646774Ed9ed744dB404cA8655A",
			"base_name": "Wrapped BNB",
			"base_symbol": "WBNB",
			"base_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"quote_name": "MiniSafe",
			"quote_symbol": "miniSAFE",
			"quote_address": "0xD7A39b6F6aA1Ab550D609D9a4b67Ad043b727F0B",
			"price": "316672877778705253.44087527017097409496",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "246947618138.9710798326181829586",
			"liquidity_BNB": "814684034.531215097211558991786"
		},
		"0xBB193399Fa1d2Cc654f54b64ddB8305126f641Dd_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0x3A7271B95D3074cD49178076854D74cFcF9B2190",
			"base_name": "NanoSushi",
			"base_symbol": "NanoSushi",
			"base_address": "0xBB193399Fa1d2Cc654f54b64ddB8305126f641Dd",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "1.58e-18",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "246596755090.7653358427891025404",
			"liquidity_BNB": "828921084.11490729029907353703"
		},
		"0x7bc56B6EFd397f75988779e763ECd70c0C6012C1_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0x2e3C2D921AD3e1272f04F4065eB45E9Df901c876",
			"base_name": "BabyCake",
			"base_symbol": "BABYCAKE 👶🏻️",
			"base_address": "0x7bc56B6EFd397f75988779e763ECd70c0C6012C1",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "0",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "245599608187.0049560717199172715",
			"liquidity_BNB": "618298654.462620507311518480507"
		},
		"0x54dbE7A5b82041b284a7162841DaB236Fa578A39_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0x17004dB30E8058a8B27E74e7A1c38E543DEEFA3A",
			"base_name": "MiniPanda",
			"base_symbol": "miniPNDA",
			"base_address": "0x54dbE7A5b82041b284a7162841DaB236Fa578A39",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "3.16e-18",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "245564539434.423567242906371091",
			"liquidity_BNB": "947185645.737213109247413727809"
		},
		"0x9A98C85bD1aeFf1d4268da377864a3C716494a90_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0x324f46670B36178dB28b29C0B19b86e6C0E9C650",
			"base_name": "FEG",
			"base_symbol": "$FEG",
			"base_address": "0x9A98C85bD1aeFf1d4268da377864a3C716494a90",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "3.16e-18",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "245357748900.8943724197384187679",
			"liquidity_BNB": "803171264.916357120051453242801"
		},
		"0x9743211F12941aC1A27DDA2fD12ffc629EdEf75e_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0x2754D5E07D65A6fA3Fffd9D732dD9e424D105c85",
			"base_name": "ShibaPanda",
			"base_symbol": "SHIBAPNDA",
			"base_address": "0x9743211F12941aC1A27DDA2fD12ffc629EdEf75e",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "3.16e-18",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "244373506232.5319940483421547561",
			"liquidity_BNB": "916778361.959416816245152911112"
		},
		"0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c_0xFc9DEac799E8Cde587171E73BB42111064Dbcd42": {
			"pair_address": "0x112B57568396698C937754aaEa24972a3b222aB7",
			"base_name": "Wrapped BNB",
			"base_symbol": "WBNB",
			"base_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"quote_name": "ShibaBNB",
			"quote_symbol": "ShibBNB",
			"quote_address": "0xFc9DEac799E8Cde587171E73BB42111064Dbcd42",
			"price": "317051886294697419.31572814295696859446",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "244339258773.4127339992407491615",
			"liquidity_BNB": "915993593.831347486533177587137"
		},
		"0x08b84bdEF03F4af3700e80a9dA5E230A8e5762F5_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0x3848fdF218eFF884C29Ea2ae6b4e4a3503351E2B",
			"base_name": "Mini Hippo Token",
			"base_symbol": "MiniHIP",
			"base_address": "0x08b84bdEF03F4af3700e80a9dA5E230A8e5762F5",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "0",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "243342892784.9612363711842896526",
			"liquidity_BNB": "797664290.340973694984668771253"
		},
		"0x89B3EBb4A601D28Fe0d564b130360Db92066Cc27_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0x8A8561f2f099a82Fb226A278B2b047CF73b0C453",
			"base_name": "Hundred x FEG",
			"base_symbol": "100xFEG",
			"base_address": "0x89B3EBb4A601D28Fe0d564b130360Db92066Cc27",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "3.16e-18",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "242851477036.3640520355060547074",
			"liquidity_BNB": "805871144.798493115712338529683"
		},
		"0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c_0xC402e98745F2b45BF356c98a210de26Fb116A12D": {
			"pair_address": "0xf308acf3b2777383A6F41DF939e331803EFC11fa",
			"base_name": "Wrapped BNB",
			"base_symbol": "WBNB",
			"base_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"quote_name": "SuperDoge",
			"quote_symbol": "SUPDOGE",
			"quote_address": "0xC402e98745F2b45BF356c98a210de26Fb116A12D",
			"price": "316910095827230259.99694004858788134804",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "241565885101.4702434714023317496",
			"liquidity_BNB": "804930237.2021505919802708768215"
		},
		"0x32fC99acA8CAaF452a015C6989F096F882D758B3_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0xa72f20e177DF544ca28Da28242c1C15D932651f7",
			"base_name": "Mini Shiba Pro",
			"base_symbol": "MINISHIBAPRO",
			"base_address": "0x32fC99acA8CAaF452a015C6989F096F882D758B3",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "0",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "241307369843.5452643166809206996",
			"liquidity_BNB": "807907001.257042737259952823134"
		},
		"0x7588074261C167794898F1412603e6BAA3a4256e_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0x5Bf7f2deA99295f4479299e2299A35dcf6F05271",
			"base_name": "WrappedStrongElon",
			"base_symbol": "wSElon",
			"base_address": "0x7588074261C167794898F1412603e6BAA3a4256e",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "3.16e-18",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "240500815320.4503846604505386756",
			"liquidity_BNB": "815697831.684675084529840661195"
		},
		"0xae3Dec98F91fF2D0fD8c1f81E8Bb9b7f5d83fae6_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0x5635757c935A0A20D5bDA54Ec68f26026Aa93E46",
			"base_name": "AquaBNB",
			"base_symbol": "AquaBNB",
			"base_address": "0xae3Dec98F91fF2D0fD8c1f81E8Bb9b7f5d83fae6",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "3.16e-18",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "239801591597.8245455219025956644",
			"liquidity_BNB": "810999636.629043235111326785426"
		},
		"0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c_0xFE52f74017baD79040d4203286531223d8d5D613": {
			"pair_address": "0x15741440aB683c28868FafF9A23b3aF319f0310B",
			"base_name": "Wrapped BNB",
			"base_symbol": "WBNB",
			"base_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"quote_name": "MiniRocket",
			"quote_symbol": "miniRocket",
			"quote_address": "0xFE52f74017baD79040d4203286531223d8d5D613",
			"price": "316759897117994867.47505660439589448722",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "239538790235.7259929729195352226",
			"liquidity_BNB": "792288399.333007314459559331327"
		},
		"0x535b44ED84bD1F5D89f9B078e708793B7cd376De_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0xAeD263b03f12925d02F0d6815F433D201250696c",
			"base_name": "FlokiDoge",
			"base_symbol": "FlokiDOGE",
			"base_address": "0x535b44ED84bD1F5D89f9B078e708793B7cd376De",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "3.15e-18",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "239424962928.1536398042520819623",
			"liquidity_BNB": "907152364.064908290096638134303"
		},
		"0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c_0xE8266ED175C927c57e0Bd29064d2E284e2a6fdC9": {
			"pair_address": "0x7090F1Dac7bc1B3C9EF39a72666AaA57de052765",
			"base_name": "Wrapped BNB",
			"base_symbol": "WBNB",
			"base_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"quote_name": "FlokiDoge",
			"quote_symbol": "fDOGE",
			"quote_address": "0xE8266ED175C927c57e0Bd29064d2E284e2a6fdC9",
			"price": "316880251473623849.35327486716412241364",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "239358339205.793244060626516362",
			"liquidity_BNB": "810018580.676748102917967605786"
		},
		"0xbA4b23C8a0665C9407d9604D3F81E8Da41796e4E_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0x40F2a0c9cF98821094f5473f1eb73c7358F462EE",
			"base_name": "WrappedRocket",
			"base_symbol": "wRocket",
			"base_address": "0xbA4b23C8a0665C9407d9604D3F81E8Da41796e4E",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "3.16e-18",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "238886777959.7326918040549865068",
			"liquidity_BNB": "905240539.998008709848647532159"
		},
		"0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c_0xf713D0222109090001e031ac52Cd5c6fbb57BC09": {
			"pair_address": "0xdcC6a0769fe8567E9A8C90A025Ce605C154c3764",
			"base_name": "Wrapped BNB",
			"base_symbol": "WBNB",
			"base_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"quote_name": "SpaceLoki",
			"quote_symbol": "SPACELOKI",
			"quote_address": "0xf713D0222109090001e031ac52Cd5c6fbb57BC09",
			"price": "316889718114210782.67589768815422996067",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "238215052611.20590607766988033",
			"liquidity_BNB": "787414354.637389573408767588982"
		},
		"0x65bf986dA8a974f5e530CfF60eD502dce3f2c4E8_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0x8362A9d5F5421d9A11089C083F5b0f70e2490db6",
			"base_name": "BabyFEG",
			"base_symbol": "bFEG",
			"base_address": "0x65bf986dA8a974f5e530CfF60eD502dce3f2c4E8",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "3.16e-18",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "236768275724.5237825251843740892",
			"liquidity_BNB": "793740956.511225333084907498046"
		},
		"0x17B68B67e2A6b952E681206ddE06324B5fAa48E5_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0x8C049d9C1718EcF80806Aef6D9Ca554e4AB008e5",
			"base_name": "WrappedMOON",
			"base_symbol": "wMOON",
			"base_address": "0x17B68B67e2A6b952E681206ddE06324B5fAa48E5",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "6.3e-19",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "236618620837.7553830718656240073",
			"liquidity_BNB": "613411284.39523620189532935347"
		},
		"0x28aa84C51f886d8d6385EF259fF95f0EAC081076_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0x5734c71C2B8993e921619C7D5E7d14a2291ee301",
			"base_name": "EverBoxer",
			"base_symbol": "EverBOX",
			"base_address": "0x28aa84C51f886d8d6385EF259fF95f0EAC081076",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "3.15e-18",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "236230420558.085451138622803291",
			"liquidity_BNB": "895355990.272819933049076336473"
		},
		"0x3E727B12ECf413C1903d4Ab6d2B59BDf429cFaC2_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0xf556ae0E5F6471dBeb48888DffF07a716Cde47a1",
			"base_name": "FlokiDoge",
			"base_symbol": "FlokiDOGE",
			"base_address": "0x3E727B12ECf413C1903d4Ab6d2B59BDf429cFaC2",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "3.15e-18",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "235895618254.82522701405779532",
			"liquidity_BNB": "885580000.397199795203248815018"
		},
		"0x934b3342e33b50F3966DA81CE907a69F18c1897B_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0xF4086D0980f3cC1901037Fb2b79f197D2d0181d2",
			"base_name": "SuperFEG",
			"base_symbol": "SUPFEG",
			"base_address": "0x934b3342e33b50F3966DA81CE907a69F18c1897B",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "3.16e-18",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "235835180531.1071412944130187826",
			"liquidity_BNB": "780712735.323327525568159475836"
		},
		"0x5F13B2c5A8bde01DFc5148781d3803C5dbC4024D_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0xb2BA42DeC12A5012E3E958f391DD3f3d7cf2b18A",
			"base_name": "SuperApe",
			"base_symbol": "SUPAPE",
			"base_address": "0x5F13B2c5A8bde01DFc5148781d3803C5dbC4024D",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "3.15e-18",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "235376521932.8651803638125312907",
			"liquidity_BNB": "889293251.465354855378155401095"
		},
		"0x1B933C9124Ab3EB6644A39Ea835393EF60260CA9_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0xbddf192FE9f31Efc9bA098E9Eed140B341C3e53D",
			"base_name": "Rocket",
			"base_symbol": "$Rocket",
			"base_address": "0x1B933C9124Ab3EB6644A39Ea835393EF60260CA9",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "3.15e-18",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "234992013744.7829327899828849835",
			"liquidity_BNB": "888225685.413915143975879622762"
		},
		"0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c_0xE819f1cCF908AB4848d9E24649EB8fE7B14A57fd": {
			"pair_address": "0x330D7C0c416825648a31Af91B4752AF0ABeD607E",
			"base_name": "Wrapped BNB",
			"base_symbol": "WBNB",
			"base_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"quote_name": "File Doge Coin",
			"quote_symbol": "FILDOGE",
			"quote_address": "0xE819f1cCF908AB4848d9E24649EB8fE7B14A57fd",
			"price": "5.106613550241579248716517915883089467317210634e+25",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "234953826229.985411440982415798",
			"liquidity_BNB": "800780148.1614736851833146560535"
		},
		"0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c_0xE16b35f03CC16b4549dBDd0514302061CFeEE53e": {
			"pair_address": "0x37B8B9Fdb04C36B24b22dAF449B30E6203009404",
			"base_name": "Wrapped BNB",
			"base_symbol": "WBNB",
			"base_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"quote_name": "Nano Cardano",
			"quote_symbol": "NanoADA",
			"quote_address": "0xE16b35f03CC16b4549dBDd0514302061CFeEE53e",
			"price": "316916231942759554.22577997000665423541",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "234454039130.885445947410359835",
			"liquidity_BNB": "887899149.19554540577587411611"
		},
		"0x8dA0AE17E42E5b2F579Dc8e25F4302043616466B_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0xA92c154B825d1a84717204590f82c74079856743",
			"base_name": "OnlyUp Finance",
			"base_symbol": "UpFIN",
			"base_address": "0x8dA0AE17E42E5b2F579Dc8e25F4302043616466B",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "3.16e-18",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "234276056901.745600188121514205",
			"liquidity_BNB": "768964651.0629420649678161743645"
		},
		"0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c_0xDb14535d0D9c0234aB82a8aF9F6B83FdA4808B41": {
			"pair_address": "0xA4f0cA0cee18b0d1bb1611428D407AD7F1035A89",
			"base_name": "Wrapped BNB",
			"base_symbol": "WBNB",
			"base_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"quote_name": "Binance MOON",
			"quote_symbol": "BSCMOON",
			"quote_address": "0xDb14535d0D9c0234aB82a8aF9F6B83FdA4808B41",
			"price": "317042110916290093.74547139141156034822",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "233821160448.7058143014352400582",
			"liquidity_BNB": "882556461.993521099835882620171"
		},
		"0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c_0xfDbf2f8b57721eEAc91143825c661fA5E985BA70": {
			"pair_address": "0x68D4cAd5d41e6fF939B152fC613BdC4Afe609Dc2",
			"base_name": "Wrapped BNB",
			"base_symbol": "WBNB",
			"base_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"quote_name": "WrappedRocket",
			"quote_symbol": "wRocket",
			"quote_address": "0xfDbf2f8b57721eEAc91143825c661fA5E985BA70",
			"price": "316654058546052403.62541964694357520352",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "233276166002.8739663251740080448",
			"liquidity_BNB": "762193814.966855791315335860891"
		},
		"0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c_0xC2F5bFE3b50167fC03BE819bf6c7CA624Cc2642A": {
			"pair_address": "0xF76ab4072eb38a0D8493caDF4fEc2826ea65A830",
			"base_name": "Wrapped BNB",
			"base_symbol": "WBNB",
			"base_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"quote_name": "Floki Cardano",
			"quote_symbol": "FlokiADA",
			"quote_address": "0xC2F5bFE3b50167fC03BE819bf6c7CA624Cc2642A",
			"price": "316862676572750492.95914127736731695139",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "232671303826.736828080529983326",
			"liquidity_BNB": "870220840.640977965130679528957"
		},
		"0x68444269870d789D302DD03c67A02731a548c3A9_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0xb60E2160A5BC580c8a4eCf90442123c5D195b533",
			"base_name": "FlokiStrongElon",
			"base_symbol": "fSElon",
			"base_address": "0x68444269870d789D302DD03c67A02731a548c3A9",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "3.16e-18",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "232025882711.4602583267560589968",
			"liquidity_BNB": "758614706.37477786845054031553"
		},
		"0x8fE68464D89adE45184Fd0a1fde01D433C26D69b_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0xE5F2B0D5F5EA3780C67Eb665c5fE7d0A21760bE3",
			"base_name": "SpaceDoge",
			"base_symbol": "SPACEDOGE",
			"base_address": "0x8fE68464D89adE45184Fd0a1fde01D433C26D69b",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "3.16e-18",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "231500132728.8029003792064962884",
			"liquidity_BNB": "769416518.760250189605189620195"
		},
		"0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c_0xF72bbeDb260941E45A2749D36CA88186cA0886d9": {
			"pair_address": "0xE799763Aa30210180e5319bb534cEFe2Aa0451A5",
			"base_name": "Wrapped BNB",
			"base_symbol": "WBNB",
			"base_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"quote_name": "Hundred x Ethereum",
			"quote_symbol": "100xETH",
			"quote_address": "0xF72bbeDb260941E45A2749D36CA88186cA0886d9",
			"price": "316518640992333526.14517104932493766903",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "230990835498.332571345034435916",
			"liquidity_BNB": "754760854.9957530401476924851095"
		},
		"0x5B4d10714405f1CDB6b74d1cBc71736D1A9B3324_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0x32503E14714A83bdDb656CD139AaA02Bb9d3E0ED",
			"base_name": "NanoRocket",
			"base_symbol": "NanoRocket",
			"base_address": "0x5B4d10714405f1CDB6b74d1cBc71736D1A9B3324",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "3.15e-18",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "230651527157.6314089600389363136",
			"liquidity_BNB": "871732632.718090407174088408711"
		},
		"0x9DeE116036B55beCA1C645acCddFc7f355535EcC_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0x1f0364A5670cd955b1E103177647A9f88E8a65B7",
			"base_name": "OnlyUpDoge",
			"base_symbol": "UpDOGE",
			"base_address": "0x9DeE116036B55beCA1C645acCddFc7f355535EcC",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "3.16e-18",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "229807322116.2043894400986816231",
			"liquidity_BNB": "776646504.010409772687435969598"
		},
		"0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c_0xea644f2af65989Fa2dEb52Dd0dfa7265Ff127526": {
			"pair_address": "0x0BA03f12f1D56A4d85cd3fb192a57287c8AC879B",
			"base_name": "Wrapped BNB",
			"base_symbol": "WBNB",
			"base_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"quote_name": "SuperLoki",
			"quote_symbol": "SLOKI",
			"quote_address": "0xea644f2af65989Fa2dEb52Dd0dfa7265Ff127526",
			"price": "316926811146994956.87414203561556266031",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "229486566463.587764704874993618",
			"liquidity_BNB": "749509684.07343350900665389622"
		},
		"0x6487D77eeab5F034fE984B72FAa9d3EC04153321_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0x31a3FF0D5B3319C5631A8fa2Bfbb24279C0cB39D",
			"base_name": "WrappedEthereum",
			"base_symbol": "wETH",
			"base_address": "0x6487D77eeab5F034fE984B72FAa9d3EC04153321",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "3.15e-18",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "229444337620.012340208553708159",
			"liquidity_BNB": "750691904.690370713677021147963"
		},
		"0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c_0xdc4658bdf0B5568145AfB5C47389e3D205863789": {
			"pair_address": "0x41C24a866b2143a3f2d729Aefb3A7073F2d7e23F",
			"base_name": "Wrapped BNB",
			"base_symbol": "WBNB",
			"base_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"quote_name": "Super Finance",
			"quote_symbol": "SUPFIN",
			"quote_address": "0xdc4658bdf0B5568145AfB5C47389e3D205863789",
			"price": "317024476335050880.21402556518507365743",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "229210232826.5308062128759098335",
			"liquidity_BNB": "752506477.167860575994365211775"
		},
		"0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c_0xbd411Fd8114D9A638DC0D70C1B02425764395C59": {
			"pair_address": "0x59Cf66C8d30A48fa2A91C88C97F1998F4DcA21A7",
			"base_name": "Wrapped BNB",
			"base_symbol": "WBNB",
			"base_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"quote_name": "SuperApe",
			"quote_symbol": "SAPE",
			"quote_address": "0xbd411Fd8114D9A638DC0D70C1B02425764395C59",
			"price": "317079026240626078.88932146602694596747",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "229133530214.8393678846948170757",
			"liquidity_BNB": "752233335.606991421794944390735"
		},
		"0xB071Cf159409bB52E7b4493e9889b9249Ece5A1F_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0x83b6a80d59768CA474BFB2b32202E3E996b64cFE",
			"base_name": "SpaceBNB",
			"base_symbol": "SPACEBNB",
			"base_address": "0xB071Cf159409bB52E7b4493e9889b9249Ece5A1F",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "3.16e-18",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "228981471417.1676824618273536254",
			"liquidity_BNB": "752244543.348998665932895547583"
		},
		"0xA92678b3509Bb031100f068517E8dA2eba85F99c_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0x560E6529D1899dA0649e0C55fcca18d5E60F601d",
			"base_name": "EverMOON",
			"base_symbol": "EverMOON",
			"base_address": "0xA92678b3509Bb031100f068517E8dA2eba85F99c",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "3.15e-18",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "228451888666.0862842175745103979",
			"liquidity_BNB": "748352108.918078175792109883336"
		},
		"0x8E0FeEE83e35269420Cb136BF9e7447C83760F18_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0x66Ce1Dc074646E75F844A2fEBB7E6A9f07E4a15f",
			"base_name": "BabyFEG",
			"base_symbol": "bFEG",
			"base_address": "0x8E0FeEE83e35269420Cb136BF9e7447C83760F18",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "3.16e-18",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "228225066653.5885851005074848904",
			"liquidity_BNB": "773063514.618970626891679905883"
		},
		"0x661A1A9768Ac46D1dcEea82e9beCc10EE81cC249_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0x85dbF796f39C988e6016DC201964f21ac0EAfbf9",
			"base_name": "SuperCat",
			"base_symbol": "SCat",
			"base_address": "0x661A1A9768Ac46D1dcEea82e9beCc10EE81cC249",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "3.16e-18",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "227953832759.0673884106318443734",
			"liquidity_BNB": "871636952.706944859839496008879"
		},
		"0x11696BDc2D6Fbef3f5f35a4CDCc1eba4a831fC3C_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0xE8942D3d28C9cf2aEb768E4D2BB55ED5A1a0e601",
			"base_name": "FEGPanda",
			"base_symbol": "FEGPNDA",
			"base_address": "0x11696BDc2D6Fbef3f5f35a4CDCc1eba4a831fC3C",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "3.15e-18",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "225806472317.5910327769535360814",
			"liquidity_BNB": "739121897.446038106253789842568"
		},
		"0x668A2F170cC21a7A1C47AaEc38B0e6D4622ed4b9_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0x7b8cAd403E3ECbf2e974BAA2d3e4A0F7ddA200DB",
			"base_name": "Super Bunny Token",
			"base_symbol": "SBUNNY",
			"base_address": "0x668A2F170cC21a7A1C47AaEc38B0e6D4622ed4b9",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "0",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "225726932317.2369555967007994487",
			"liquidity_BNB": "699494180.606687626797306863075"
		},
		"0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c_0xc47e8f21D5fAe6D00ECa228DCF0af08b8EFBea12": {
			"pair_address": "0x8c70F0283dDB9D1f5951B072530d8c93A2Eba31B",
			"base_name": "Wrapped BNB",
			"base_symbol": "WBNB",
			"base_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"quote_name": "MiniSafe",
			"quote_symbol": "miniSAFE",
			"quote_address": "0xc47e8f21D5fAe6D00ECa228DCF0af08b8EFBea12",
			"price": "316837650463927288.0496542083065395",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "225001166442.988071086122677277",
			"liquidity_BNB": "736384588.040437752792740414196"
		},
		"0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c_0xfD5B7cf1363507D9f47fa24940df70fc64AB08fF": {
			"pair_address": "0x40Ce6b5433E27952d046af9403CB787Db41c3082",
			"base_name": "Wrapped BNB",
			"base_symbol": "WBNB",
			"base_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"quote_name": "SpaceInu",
			"quote_symbol": "SPACEInu",
			"quote_address": "0xfD5B7cf1363507D9f47fa24940df70fc64AB08fF",
			"price": "317203453711196007.03505872831321170242",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "224636846530.1570678580130489236",
			"liquidity_BNB": "735556336.467986803808312675548"
		},
		"0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c_0xe68FbD7E6e2AA8401Fa770Fa54C683E31469779F": {
			"pair_address": "0xa85A67214E57EE7F69fB1cF35E10f56fd803d192",
			"base_name": "Wrapped BNB",
			"base_symbol": "WBNB",
			"base_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"quote_name": "ShibaPanda",
			"quote_symbol": "ShibPNDA",
			"quote_address": "0xe68FbD7E6e2AA8401Fa770Fa54C683E31469779F",
			"price": "634351829552927339.47379912782936034898",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "224410506684.9260300867582291384",
			"liquidity_BNB": "742951289.022585117627800611484"
		},
		"0x9407eb1172424CbDbB6775EA0DbC312f36a36E51_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0xD8986bb275892ace6D0F5033F00B09b75346B258",
			"base_name": "FEGMOON",
			"base_symbol": "FEGMOON",
			"base_address": "0x9407eb1172424CbDbB6775EA0DbC312f36a36E51",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "3.16e-18",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "223906641734.330202110004702479",
			"liquidity_BNB": "733650288.692289515637296743248"
		},
		"0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c_0xF0C1b4d524c0b640c5ff098Ea9a89739fb6fbeBA": {
			"pair_address": "0x29553DcacB2581d13DE7e55B2eC81b844708b98C",
			"base_name": "Wrapped BNB",
			"base_symbol": "WBNB",
			"base_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"quote_name": "Space Cardano",
			"quote_symbol": "SPACEADA",
			"quote_address": "0xF0C1b4d524c0b640c5ff098Ea9a89739fb6fbeBA",
			"price": "316596367867059148.77518685780726190161",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "223280720560.8572112232280691385",
			"liquidity_BNB": "855989212.315382160109557991584"
		},
		"0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c_0xf72B067c9fa744A4a57189d398ba1322A0BC0a73": {
			"pair_address": "0xbdEB7adEAfD7aFe38895733f4CF6Cda3ACCDD35a",
			"base_name": "Wrapped BNB",
			"base_symbol": "WBNB",
			"base_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"quote_name": "ShibaPanda",
			"quote_symbol": "ShibPNDA",
			"quote_address": "0xf72B067c9fa744A4a57189d398ba1322A0BC0a73",
			"price": "316733373714081995.82550509074628464248",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "221863526819.5393491539737930273",
			"liquidity_BNB": "746401729.760464577641394437057"
		},
		"0x57b51564EfF8760129B20829cC4f62cEa6aBE86D_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0x58A713804DBfb58DCD883FcC429D042177963824",
			"base_name": "NanoPanda",
			"base_symbol": "NanoPanda",
			"base_address": "0x57b51564EfF8760129B20829cC4f62cEa6aBE86D",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "3.15e-18",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "221685866000.4537683154647573608",
			"liquidity_BNB": "848171813.88315754817289810657"
		},
		"0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c_0xe815436B7896eCc6063129534cDe7b6D6F181bB4": {
			"pair_address": "0x0B0D5720Be9aDa95168FE3873710777095318951",
			"base_name": "Wrapped BNB",
			"base_symbol": "WBNB",
			"base_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"quote_name": "Mecha Akita Inu",
			"quote_symbol": "MECHAKITA",
			"quote_address": "0xe815436B7896eCc6063129534cDe7b6D6F181bB4",
			"price": "4.742454659369305837178532433118729329194399171e+25",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "221589382514.821835613797087055",
			"liquidity_BNB": "792745063.600078390412548289633"
		},
		"0x130Ec47a1aA556839826a03Af4d23F17a6B7C21A_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0x9A422b8fbd34e4678d40B2b2fdFe247A95Ce3225",
			"base_name": "Binance Boxer",
			"base_symbol": "BSCBOX",
			"base_address": "0x130Ec47a1aA556839826a03Af4d23F17a6B7C21A",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "3.15e-18",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "220969715136.0497552879924479698",
			"liquidity_BNB": "724969811.2560132467137952400715"
		},
		"0x48b8355FeEBa52D4ebC933Ef84cc25C3d035EfDA_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0x1509a126a823E87047e115aD31D4D8E409deD747",
			"base_name": "Hundred x Doge",
			"base_symbol": "100xDOGE",
			"base_address": "0x48b8355FeEBa52D4ebC933Ef84cc25C3d035EfDA",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "3.16e-18",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "220380772607.5689174753502310498",
			"liquidity_BNB": "723774829.246089385706358364401"
		},
		"0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c_0xea8107C7aa3aebb949d0E1A152a4190348A71E5D": {
			"pair_address": "0xc618a4edb7D7D415816EcBF556F17698AE196335",
			"base_name": "Wrapped BNB",
			"base_symbol": "WBNB",
			"base_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"quote_name": "FlokiBoxer",
			"quote_symbol": "fBOX",
			"quote_address": "0xea8107C7aa3aebb949d0E1A152a4190348A71E5D",
			"price": "316578072034559416.62757951076355224032",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "219836965838.2646859059766602398",
			"liquidity_BNB": "721601516.600453520918222035288"
		},
		"0x4823B96A9BaB6dD17B46e441C614c100283b99ed_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0x047523f1F30d1c15984b45e21534D668B8dc3104",
			"base_name": "Panda",
			"base_symbol": "$Panda",
			"base_address": "0x4823B96A9BaB6dD17B46e441C614c100283b99ed",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "3.16e-18",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "219832182496.7667571482112422573",
			"liquidity_BNB": "719695629.510238840506511032706"
		},
		"0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c_0xD7c950e1AacA955cE459b13e04aB727D90ecb2B6": {
			"pair_address": "0x67F109229A1DD1F2Cf580c609Cf6cE1a0A9F8a0B",
			"base_name": "Wrapped BNB",
			"base_symbol": "WBNB",
			"base_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"quote_name": "AquaMOON",
			"quote_symbol": "AquaMOON",
			"quote_address": "0xD7c950e1AacA955cE459b13e04aB727D90ecb2B6",
			"price": "316739440177940999.6484490913048182781",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "218491899591.782750610429858959",
			"liquidity_BNB": "749852556.7708255099577165805715"
		},
		"0x800e7350ebB59C757B4895649Fc062097B74814B_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0xE4396DC510Ecb822E6Cb990d4607007d44F57a67",
			"base_name": "BabyPanda",
			"base_symbol": "BabyPanda",
			"base_address": "0x800e7350ebB59C757B4895649Fc062097B74814B",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "3.16e-18",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "218226267982.3929612810879053147",
			"liquidity_BNB": "739620017.715456365661127332387"
		},
		"0x3c3051662f7B0624C14F04E27AA3684Dd8048eb5_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0xC355173CaaF4e52713E60BF348F961C258a49515",
			"base_name": "Binance Panda",
			"base_symbol": "BSCPanda",
			"base_address": "0x3c3051662f7B0624C14F04E27AA3684Dd8048eb5",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "3.16e-18",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "218222784973.9163857081867230556",
			"liquidity_BNB": "736829265.748068257364374022983"
		},
		"0x9997144dA758bECe0843C14722f83D7302599BaA_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0xae4f70459E3D7712113faAAB02fa61b17bA4009a",
			"base_name": "FlokiLoki",
			"base_symbol": "fLOKI",
			"base_address": "0x9997144dA758bECe0843C14722f83D7302599BaA",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "3.15e-18",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "216452827650.2749032509230532567",
			"liquidity_BNB": "731380594.397044699924327948618"
		},
		"0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c_0xE7A44Fa1E3FE02D457A8c613357e2f04d78b1CA6": {
			"pair_address": "0x523Bd53C70a6f890cc01BA2ac74Eb0Fb78E3d1b8",
			"base_name": "Wrapped BNB",
			"base_symbol": "WBNB",
			"base_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"quote_name": "SuperApe",
			"quote_symbol": "SUPAPE",
			"quote_address": "0xE7A44Fa1E3FE02D457A8c613357e2f04d78b1CA6",
			"price": "316864129667913718.45987266714973105652",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "214250876544.4869877645881405567",
			"liquidity_BNB": "724070611.805733105665842173993"
		},
		"0xb82aCdBd7d7684efF3BDF77A78ac8336617c04Ae_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0xE716a3cA65Fc96239c067a056178A95AA654C867",
			"base_name": "NanoApe",
			"base_symbol": "NanoAPE",
			"base_address": "0xb82aCdBd7d7684efF3BDF77A78ac8336617c04Ae",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "3.16e-18",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "213420851564.3555031228021179079",
			"liquidity_BNB": "727811673.479522670851517927398"
		},
		"0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c_0xf69Ea0060518938D4D127ea5a82A03AD2373dcbe": {
			"pair_address": "0x81DD98725505C9565866c037470b26a2d8481b29",
			"base_name": "Wrapped BNB",
			"base_symbol": "WBNB",
			"base_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"quote_name": "BabyPanda",
			"quote_symbol": "bPanda",
			"quote_address": "0xf69Ea0060518938D4D127ea5a82A03AD2373dcbe",
			"price": "316594420033986891.71923780554843732874",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "213335911069.9961048829083456744",
			"liquidity_BNB": "720495813.489583108981811419988"
		},
		"0x909942c310AF3130d9940fABd661DDc7252b3DD2_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0xa458Ed0E349DDf9Ae41F39e6dD6797439B255445",
			"base_name": "FEG",
			"base_symbol": "$FEG",
			"base_address": "0x909942c310AF3130d9940fABd661DDc7252b3DD2",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "3.15e-18",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "212870161618.97822688692538418",
			"liquidity_BNB": "719553811.387785697700101925215"
		},
		"0x1b63005E96Af2850E3A56EdbC8cE0FDEF3aA0d64_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0x8e488d6ACE95cb10e4A390Ba8d8F4596774a19Ba",
			"base_name": "AquaMOON",
			"base_symbol": "AquaMOON",
			"base_address": "0x1b63005E96Af2850E3A56EdbC8cE0FDEF3aA0d64",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "3.16e-18",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "212831816245.9376238566048459334",
			"liquidity_BNB": "731216920.4996258127284213127205"
		},
		"0x79839dae8d0a30685272F5FCff5864c7a3BACD7c_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0xF546eb5E3D8867A759F0B5eF3Bc6DcBb128CE5d5",
			"base_name": "WrappedLoki",
			"base_symbol": "wLOKI",
			"base_address": "0x79839dae8d0a30685272F5FCff5864c7a3BACD7c",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "3.15e-18",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "212469545427.0355722033514002626",
			"liquidity_BNB": "722945476.886191478475231539683"
		},
		"0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c_0xBC3B5413e7E21F774626EA28668177532DF9E620": {
			"pair_address": "0xd9cc225764F9Ea26d9999AcD9A799672454bfb84",
			"base_name": "Wrapped BNB",
			"base_symbol": "WBNB",
			"base_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"quote_name": "AquaApe",
			"quote_symbol": "AquaAPE",
			"quote_address": "0xBC3B5413e7E21F774626EA28668177532DF9E620",
			"price": "316950676308070997.57581403893295419325",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "211714381217.8506297364756720781",
			"liquidity_BNB": "722688294.829693198306171534989"
		},
		"0x572962B2FE8dfC33681cEd1767CC722D9f1DE8b8_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0xB8b9Cb0B50908fC48DB88ABb8f84A82D6550b748",
			"base_name": "MiniMOON",
			"base_symbol": "miniMOON",
			"base_address": "0x572962B2FE8dfC33681cEd1767CC722D9f1DE8b8",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "3.16e-18",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "211597853670.9249639540352234987",
			"liquidity_BNB": "723204974.1851224394193758923895"
		},
		"0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c_0xF56eA6877C2D05396Db31393855C18d6719d4080": {
			"pair_address": "0x5DE611716e372f090720099671727789e1aC90e9",
			"base_name": "Wrapped BNB",
			"base_symbol": "WBNB",
			"base_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"quote_name": "ShibaInu",
			"quote_symbol": "SHIBAInu",
			"quote_address": "0xF56eA6877C2D05396Db31393855C18d6719d4080",
			"price": "317081954720883062.91620714517221556528",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "210753288714.7983116570398311807",
			"liquidity_BNB": "718326379.179368645818674596686"
		},
		"0x115a8557b90BeFF577d0B265E6A952d0977e5e51_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0xF8d156A97cE5D9ffd6B22d33B02dbB3c45BD74AD",
			"base_name": "Hundred x Doge",
			"base_symbol": "100xDOGE",
			"base_address": "0x115a8557b90BeFF577d0B265E6A952d0977e5e51",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "3.16e-18",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "210527381659.8096116198005809477",
			"liquidity_BNB": "689130991.113237319264740760527"
		},
		"0x9F18ad2b81F0ab68bf1eD8Ba68758bE57C593e51_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0xf5Ec79826eCB7179F00F8655DF13EAd013d30a01",
			"base_name": "Hundred x Cash",
			"base_symbol": "100xCASH",
			"base_address": "0x9F18ad2b81F0ab68bf1eD8Ba68758bE57C593e51",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "3.16e-18",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "210406994759.5968432482467986185",
			"liquidity_BNB": "722407101.6914864537252094218795"
		},
		"0xB25A3c0E95F3aB117a5a85a6FCa1783f7655Ff22_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0xdA74213dcF14B42e6021c097A71e5Dd77FFc7fC0",
			"base_name": "WrappedStrongElon",
			"base_symbol": "wSElon",
			"base_address": "0xB25A3c0E95F3aB117a5a85a6FCa1783f7655Ff22",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "3.16e-18",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "210402595383.2405644517760871831",
			"liquidity_BNB": "688014670.572631993257288829455"
		},
		"0x873863FB6338D0D78BACf13911E83843c5Df5337_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0x44fb878B578dAAC476F62AF32BcF5e7941e04046",
			"base_name": "NanoBNB",
			"base_symbol": "NanoBNB",
			"base_address": "0x873863FB6338D0D78BACf13911E83843c5Df5337",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "3.15e-18",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "210072308117.1916432942621512617",
			"liquidity_BNB": "682618458.7637961028398162846425"
		},
		"0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c_0xcCbb595fca34748654f5aFF8D384282aaab6980E": {
			"pair_address": "0x2C5F89DF5467E195D71B1CAA1280D9a0A2Bde89f",
			"base_name": "Wrapped BNB",
			"base_symbol": "WBNB",
			"base_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"quote_name": "Aqua Black",
			"quote_symbol": "AquaB",
			"quote_address": "0xcCbb595fca34748654f5aFF8D384282aaab6980E",
			"price": "317143819450486730.78002569475836248748",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "209954281825.0125944402679717474",
			"liquidity_BNB": "743949644.353401538798679344978"
		},
		"0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c_0xE1785bD4d0166C3783970335907a98Df4e13f18B": {
			"pair_address": "0x43fB4426e04B4b0413E33aF72A9068b57A49eF5B",
			"base_name": "Wrapped BNB",
			"base_symbol": "WBNB",
			"base_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"quote_name": "My Doge Coin",
			"quote_symbol": "MYDOGE",
			"quote_address": "0xE1785bD4d0166C3783970335907a98Df4e13f18B",
			"price": "2.581100660624191549298865300919630065178884405e+25",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "209952086079.9470374648363539488",
			"liquidity_BNB": "661156057.556056110455540574122"
		},
		"0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c_0xD005B2F7348C26d8cB836a4A65699DE46b1dD401": {
			"pair_address": "0x37181938f52d491272422863Ae41583A8908eBDD",
			"base_name": "Wrapped BNB",
			"base_symbol": "WBNB",
			"base_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"quote_name": "MiniCat",
			"quote_symbol": "miniCat",
			"quote_address": "0xD005B2F7348C26d8cB836a4A65699DE46b1dD401",
			"price": "317186333337422713.22158809349287303842",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "209783958930.994201372261541751",
			"liquidity_BNB": "686359014.829317784294661344604"
		},
		"0x1DDB7b5a76e5975d63D07eE1461305E270A80Bfb_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0x3e5a25596DD635a648E1750d3b85B5A6bbBB5f1A",
			"base_name": "WrappedSafe",
			"base_symbol": "wSAFE",
			"base_address": "0x1DDB7b5a76e5975d63D07eE1461305E270A80Bfb",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "3.16e-18",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "209533607845.0658645619282319484",
			"liquidity_BNB": "741907130.315358238113412559024"
		},
		"0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c_0xf84ae9E4f5Ea99fbA1bBA3144f6BEA21eD54cf6b": {
			"pair_address": "0x30d8ff7BE66F26C4461cD6493EB4091bF8197Dd1",
			"base_name": "Wrapped BNB",
			"base_symbol": "WBNB",
			"base_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"quote_name": "NanoBoxer",
			"quote_symbol": "NanoBOX",
			"quote_address": "0xf84ae9E4f5Ea99fbA1bBA3144f6BEA21eD54cf6b",
			"price": "316595526452661746.15819239956568377853",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "209072574416.8131838630818656673",
			"liquidity_BNB": "784534056.300884231445060691456"
		},
		"0x8CB98E1cA11B84E83C31b2155e96B1b1bc898F01_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0x41a5f2FD26ff466D35fFEB1Ed529Cf07c64536D3",
			"base_name": "Binance BNB",
			"base_symbol": "BSCBNB",
			"base_address": "0x8CB98E1cA11B84E83C31b2155e96B1b1bc898F01",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "3.16e-18",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "208847267234.346825906631516593",
			"liquidity_BNB": "741039535.299638149275959928941"
		},
		"0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c_0xcb5527E1e0661BEF8e11f2A4cd51e7231d4Af9D4": {
			"pair_address": "0x46C4CAA7D2F46106Df6777896f2dB0530a7637bD",
			"base_name": "Wrapped BNB",
			"base_symbol": "WBNB",
			"base_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"quote_name": "NanoPanda",
			"quote_symbol": "NanoPNDA",
			"quote_address": "0xcb5527E1e0661BEF8e11f2A4cd51e7231d4Af9D4",
			"price": "317021199429660485.1947851668451529211",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "208790054792.5745715493960222467",
			"liquidity_BNB": "684372208.8403804856590207266695"
		},
		"0x6Eb268Da347e6edF8A2529213344e0109830678F_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0x402710B80387A0cF751b2ce7f1edCAbc91D7D267",
			"base_name": "FEGBoxer",
			"base_symbol": "FEGBOX",
			"base_address": "0x6Eb268Da347e6edF8A2529213344e0109830678F",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "3.16e-18",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "208735625898.2910482859628448623",
			"liquidity_BNB": "683745343.402669266559520517376"
		},
		"0x21F64D66b21D950bC7684359A8e55093E6B699eC_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0x83593Ce22c8476eA48134379bA1fF5DE04947CDa",
			"base_name": "AquaBNB",
			"base_symbol": "AquaBNB",
			"base_address": "0x21F64D66b21D950bC7684359A8e55093E6B699eC",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "3.16e-18",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "208443077642.8793438647904564735",
			"liquidity_BNB": "778749460.29264686643672830655"
		},
		"0x3a45a0781cC27E65f5CA28cF006a5623168F9786_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0x3AC4b5180be35f2FDdC211E707a08B2c4b43E7AC",
			"base_name": "Pro Shiba Inu",
			"base_symbol": "PROSHIB",
			"base_address": "0x3a45a0781cC27E65f5CA28cF006a5623168F9786",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "0",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "208089398249.0938516967907054762",
			"liquidity_BNB": "694092726.371178455208439378614"
		},
		"0x497052BB5f93F3624FEc84D1F3fDC70c4fE266b8_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0x03b220678E26c1afd51d1BC4a8a778DD7c2aAFD8",
			"base_name": "Hundred x  Cardano",
			"base_symbol": "100xADA",
			"base_address": "0x497052BB5f93F3624FEc84D1F3fDC70c4fE266b8",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "3.16e-18",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "208060586126.1633555756958981183",
			"liquidity_BNB": "775888490.527691794998338340422"
		},
		"0x2d7120E784A617DA4c97cC1108EE764ecC2A4CE3_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0x13395Ca0CEe7411Fc482e4b3CCCBBEBDf81fa471",
			"base_name": "BabyPanda",
			"base_symbol": "BabyPanda",
			"base_address": "0x2d7120E784A617DA4c97cC1108EE764ecC2A4CE3",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "3.16e-18",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "207941197882.4233390989182382377",
			"liquidity_BNB": "677650872.426358182581846506855"
		},
		"0xb0e5954c982967527E145b3221a804bC33615212_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0x39392FaF90325AF25379ce62E56864B785C69BF7",
			"base_name": "My Corgi Doge",
			"base_symbol": "MYCORGI",
			"base_address": "0xb0e5954c982967527E145b3221a804bC33615212",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "0",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "207839223367.6777943502950007802",
			"liquidity_BNB": "674526946.88189178403567446636"
		},
		"0x059e9922f3fDBED3dDcF7D92c24ba87B50442736_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0x55e6aF878676Dc5D45E4422c6a1E97D49B8E04f9",
			"base_name": "Binance MOON",
			"base_symbol": "BSCMOON",
			"base_address": "0x059e9922f3fDBED3dDcF7D92c24ba87B50442736",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "3.16e-18",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "207528200832.2407000604145294491",
			"liquidity_BNB": "759821739.264613393632287714322"
		},
		"0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c_0xcA201A477beb70c42049bf6Ed7b6E810dd55e7E7": {
			"pair_address": "0xE5796a9F5FF0D214975c1C7C69245E07558722CA",
			"base_name": "Wrapped BNB",
			"base_symbol": "WBNB",
			"base_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"quote_name": "Hundred x MOON",
			"quote_symbol": "100xMOON",
			"quote_address": "0xcA201A477beb70c42049bf6Ed7b6E810dd55e7E7",
			"price": "317237262554299786.90957029416120808632",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "207478479461.2135829612386826758",
			"liquidity_BNB": "763575720.2585658502501082162375"
		},
		"0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c_0xD6B320ED8088f7F2255f66a28f378957F5d875c4": {
			"pair_address": "0x85a88437F5F7832aE8AE9B840A1d1944bA53D08f",
			"base_name": "Wrapped BNB",
			"base_symbol": "WBNB",
			"base_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"quote_name": " Black",
			"quote_symbol": "$B",
			"quote_address": "0xD6B320ED8088f7F2255f66a28f378957F5d875c4",
			"price": "316837492616146669.52063969917441684851",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "207412361428.208944968914654748",
			"liquidity_BNB": "679526246.918208649356374619618"
		},
		"0x453ed4430617504277EC6B51aa677cE4423749d7_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0x834CaaE543cFB28212C25B9260A368CA5Ae26E5A",
			"base_name": "Aqua Black",
			"base_symbol": "AquaB",
			"base_address": "0x453ed4430617504277EC6B51aa677cE4423749d7",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "3.16e-18",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "207247950018.4755215806045237827",
			"liquidity_BNB": "679286788.097146773678962457091"
		},
		"0xB7F618Fd2BfB4D50fAe83750d31B2b67E367c309_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0xD2f32b64404cD7E4847b14433b666848BcE0d7C3",
			"base_name": "MiniBoxer",
			"base_symbol": "miniBOX",
			"base_address": "0xB7F618Fd2BfB4D50fAe83750d31B2b67E367c309",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "3.15e-18",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "206999550989.0229913516432637649",
			"liquidity_BNB": "707086375.011600171771907131796"
		},
		"0x04cbB1cC7a8244304B24C59f4fF85eB78f13df9A_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0x74EE9ff43951FC55FAcC7fa4E28F914Faf07f232",
			"base_name": "AquaBNB",
			"base_symbol": "AquaBNB",
			"base_address": "0x04cbB1cC7a8244304B24C59f4fF85eB78f13df9A",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "3.15e-18",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "206843682155.4489187953789663117",
			"liquidity_BNB": "750651536.21659808732515191827"
		},
		"0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c_0xeCad934bb9c88a191d809ad7CF7efA1946565607": {
			"pair_address": "0xB5C5aA79E9f6c17148E96649BcbE5f055b02F80f",
			"base_name": "Wrapped BNB",
			"base_symbol": "WBNB",
			"base_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"quote_name": "ShibaSafe",
			"quote_symbol": "SHIBASAFE",
			"quote_address": "0xeCad934bb9c88a191d809ad7CF7efA1946565607",
			"price": "317160124042885325.60297845221615686601",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "206616315377.443343228531710625",
			"liquidity_BNB": "707441327.573933797343054970508"
		},
		"0x2906F15927469090212Ef2d0643ec2eb63E7559F_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0x73633fca964Aa458407Fa945a653B70AE7291F7e",
			"base_name": "Hundred x BNB",
			"base_symbol": "100xBNB",
			"base_address": "0x2906F15927469090212Ef2d0643ec2eb63E7559F",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "3.16e-18",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "206568644203.7170077900463853705",
			"liquidity_BNB": "669593227.009662943099901822468"
		},
		"0x8D9BE1f85383EC96750E1e3952dC5668C54ACab6_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0xE24DA7547F7fC34F6508D8270F96576fB93b0290",
			"base_name": "Super Black",
			"base_symbol": "SB",
			"base_address": "0x8D9BE1f85383EC96750E1e3952dC5668C54ACab6",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "3.16e-18",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "206329528903.8798401184984436993",
			"liquidity_BNB": "714302762.587758045596758438647"
		},
		"0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c_0xbE89C5702F0d27829434495F78edA09E29913548": {
			"pair_address": "0xa2eb0218CC348A5eeEB5aa43804F251828812C0b",
			"base_name": "Wrapped BNB",
			"base_symbol": "WBNB",
			"base_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"quote_name": "SpacePanda",
			"quote_symbol": "SPACEPNDA",
			"quote_address": "0xbE89C5702F0d27829434495F78edA09E29913548",
			"price": "317068460138354921.58982990150411654849",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "206217106510.7080965659092976492",
			"liquidity_BNB": "706284160.041779466215430769646"
		},
		"0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c_0xc2BED90CceD762429ad7cdFD430677a870AFD3a7": {
			"pair_address": "0x6120759F5E33990642B0F24a5773f52b8dBeB0BF",
			"base_name": "Wrapped BNB",
			"base_symbol": "WBNB",
			"base_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"quote_name": "AquaInu",
			"quote_symbol": "AquaInu",
			"quote_address": "0xc2BED90CceD762429ad7cdFD430677a870AFD3a7",
			"price": "316861525692464301.63574453577137672586",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "206132309474.9896706992675363113",
			"liquidity_BNB": "678661812.466185696243647389274"
		},
		"0x7C3fF522b10Eb76bf0dA611c271a129636e727D6_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0xE3eAAc138F086d2350ADa004eD31DAa6Ba0F21A2",
			"base_name": "Hundred x Cash",
			"base_symbol": "100xCASH",
			"base_address": "0x7C3fF522b10Eb76bf0dA611c271a129636e727D6",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "3.15e-18",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "205282275085.0081452606348814869",
			"liquidity_BNB": "754069843.0933028731796485534846"
		},
		"0x1145AbF6f33Ba5aA739Ed65d4466DeCE16Dfdf0F_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0x519FaA2581Beb68e22dA95E98FB6d4D855a61332",
			"base_name": "EverPanda",
			"base_symbol": "EverPanda",
			"base_address": "0x1145AbF6f33Ba5aA739Ed65d4466DeCE16Dfdf0F",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "3.15e-18",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "204918290691.4010974461012214334",
			"liquidity_BNB": "749347054.048102949201863942125"
		},
		"0x5BB2440a9806cda8c4d1B0c4A6cE8675D7B1BbE1_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0xfEb1a315FAa7D13AE6818BA54f40148a8Def46cF",
			"base_name": "BabyBitcoin",
			"base_symbol": "BabyBTC",
			"base_address": "0x5BB2440a9806cda8c4d1B0c4A6cE8675D7B1BbE1",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "6.3e-19",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "204444667263.0150629149704691258",
			"liquidity_BNB": "541491762.3339712652304584601395"
		},
		"0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c_0xd384333Ff737B57C1d314Fb3003932d85e329e93": {
			"pair_address": "0xF28Dbc7D6deCCc11577278Fe323903ee1aE31780",
			"base_name": "Wrapped BNB",
			"base_symbol": "WBNB",
			"base_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"quote_name": "Space Black",
			"quote_symbol": "SPACEB",
			"quote_address": "0xd384333Ff737B57C1d314Fb3003932d85e329e93",
			"price": "317090715979128191.64172301397003366125",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "204373432626.055022040557948291",
			"liquidity_BNB": "670131721.5692374991593159586355"
		},
		"0xB2477fC8b118e25242C7cd705A441b65Eac8Dd41_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0xD875594dD41161341C2d03324b8d6682107980eF",
			"base_name": "AquaPanda",
			"base_symbol": "AquaPNDA",
			"base_address": "0xB2477fC8b118e25242C7cd705A441b65Eac8Dd41",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "3.16e-18",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "203765695218.0999536855730426088",
			"liquidity_BNB": "671148007.629979846413655060847"
		},
		"0x582Af9892E2d43620cE492Ef6A423f626AB98Ae1_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0xa13F6Dda82038b9c0778642d49D39036E7D3bB6f",
			"base_name": "SuperInu",
			"base_symbol": "SUPInu",
			"base_address": "0x582Af9892E2d43620cE492Ef6A423f626AB98Ae1",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "2.02e-18",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "203666848067.225282278734731484",
			"liquidity_BNB": "679328009.0143075365829737322904"
		},
		"0x46B148131d708F2bad36432aEf4d8F7b17532407_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0x253E6E6b3f7562B8c121816C9cC36120d8EE1548",
			"base_name": "FlokiSafe",
			"base_symbol": "fSAFE",
			"base_address": "0x46B148131d708F2bad36432aEf4d8F7b17532407",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "3.16e-18",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "203479962731.9923237983510351165",
			"liquidity_BNB": "666336718.254502910235558650137"
		},
		"0x567CFBACAC647ED8BecCEEA38FA51348875c7fb9_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0x8899eFe2Ce608EF20d05B6ADD8Fda89712da0594",
			"base_name": "Binance Bitcoin",
			"base_symbol": "BSCBTC",
			"base_address": "0x567CFBACAC647ED8BecCEEA38FA51348875c7fb9",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "3.15e-18",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "203252710845.0800507252450620849",
			"liquidity_BNB": "697043866.695255413417080685901"
		},
		"0x4F13bDD8823F68c1F7B384E5d54bD72e2F5cF27d_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0x71FbeC895D13F60E857d47d384525998C8dCa335",
			"base_name": "MiniCash",
			"base_symbol": "miniCASH",
			"base_address": "0x4F13bDD8823F68c1F7B384E5d54bD72e2F5cF27d",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "3.15e-18",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "203039762626.1424249641081520423",
			"liquidity_BNB": "669500620.706250351487151662616"
		},
		"0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c_0xd4bf81A1C6d75E3CCCee69321D3f73dcC1f787be": {
			"pair_address": "0x7Ed0478eF7f98DdB1BA18D906b3f8a838fDdbc87",
			"base_name": "Wrapped BNB",
			"base_symbol": "WBNB",
			"base_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"quote_name": "FEG Finance",
			"quote_symbol": "FEGFIN",
			"quote_address": "0xd4bf81A1C6d75E3CCCee69321D3f73dcC1f787be",
			"price": "316774953217304916.18441880476245770511",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "202691429226.1973125185942568678",
			"liquidity_BNB": "663813577.911885176391981031685"
		},
		"0x51e00a95748DBd2a3F47bC5c3b3E7B3F0fea666c_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0x1F34C2aB9151F28cb806A8c28aae6cc6f0db4890",
			"base_name": "DVGToken",
			"base_symbol": "DVG",
			"base_address": "0x51e00a95748DBd2a3F47bC5c3b3E7B3F0fea666c",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "9.397e-17",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "202373572990.7012912573577682073",
			"liquidity_BNB": "630488325.137824409421875692231"
		},
		"0x3040b6799fc6F2Dc259a115BC71726CE3E6D33c0_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0x5857e128fFFF6aB59F46004BC362DF68C4A1d783",
			"base_name": "ShibaCash",
			"base_symbol": "SHIBACASH",
			"base_address": "0x3040b6799fc6F2Dc259a115BC71726CE3E6D33c0",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "3.15e-18",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "202015521996.934776649277373652",
			"liquidity_BNB": "666879778.771200654347238730701"
		},
		"0x885682f327C9DB527C63a551Dd0691e383B776Ac_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0xdb5Aa686E8ab7105BBC50a7ef9ee73473fBa4fB3",
			"base_name": "Lite Shiba Token",
			"base_symbol": "LITESHIB",
			"base_address": "0x885682f327C9DB527C63a551Dd0691e383B776Ac",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "0",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "201854089031.4018638022481833348",
			"liquidity_BNB": "682916087.467054727186276668305"
		},
		"0x89380E89369690D988c7E9f397AF3d788CC5f185_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0xd1151A937Fb074EFd0ffac12Cc1bAdBa1b8fB5Df",
			"base_name": "Mini Finance",
			"base_symbol": "miniFIN",
			"base_address": "0x89380E89369690D988c7E9f397AF3d788CC5f185",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "3.16e-18",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "201033863869.922217830968099569",
			"liquidity_BNB": "700699234.918088407218622115991"
		},
		"0x1d8b1C0938De615B5Fb2925D53B4fDE78193416E_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0x559c2e452F558a51795c8a99d4942d9638713273",
			"base_name": "SuperCat",
			"base_symbol": "SUPCat",
			"base_address": "0x1d8b1C0938De615B5Fb2925D53B4fDE78193416E",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "3.16e-18",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "200777195313.9599778768187241477",
			"liquidity_BNB": "652053949.22787846846381527772"
		},
		"0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c_0xCdc46a14c7f8274DBcB87311A8c92BF5f8283322": {
			"pair_address": "0x89035762E352c42fA979A76771eA8798980a92f4",
			"base_name": "Wrapped BNB",
			"base_symbol": "WBNB",
			"base_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"quote_name": "Binance Loki",
			"quote_symbol": "BSCLOKI",
			"quote_address": "0xCdc46a14c7f8274DBcB87311A8c92BF5f8283322",
			"price": "317187908854511533.59483643398849855104",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "200174318891.0009523012298341574",
			"liquidity_BNB": "660801696.429894670242434027625"
		},
		"0x3479253110e4fbc30b5a978f5D44320bBB5D2D10_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0xb46983C6B83E2C41dB68d4a264fD3781Cd840036",
			"base_name": "FEGDoge",
			"base_symbol": "FEGDOGE",
			"base_address": "0x3479253110e4fbc30b5a978f5D44320bBB5D2D10",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "3.16e-18",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "200078346060.7026854905934029323",
			"liquidity_BNB": "649341194.86567013662737620632"
		},
		"0x0840f9eb77836d25162E0f8C683E9F579C8202e4_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0xc82F5Ba4f53ddd2C21Bef4B82506174b852eBD6F",
			"base_name": "SpaceBNB",
			"base_symbol": "SPACEBNB",
			"base_address": "0x0840f9eb77836d25162E0f8C683E9F579C8202e4",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "3.15e-18",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "199437429855.3283612067402523974",
			"liquidity_BNB": "650200348.078722606419868118032"
		},
		"0x50eD0Bcad1be73adfE642421DDa502f7753AaC85_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0xF0Bba9140a421Feb863D69a1F08e8a98EbFb2813",
			"base_name": "Hundred x Doge",
			"base_symbol": "100xDOGE",
			"base_address": "0x50eD0Bcad1be73adfE642421DDa502f7753AaC85",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "3.16e-18",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "199403675703.514712222150499256",
			"liquidity_BNB": "657519007.552436967644716769142"
		},
		"0x9f8E839A4cFB1EE283C54900d0C4553031453414_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0x946866fCE118e9083fD494aC6E054E386B158060",
			"base_name": "ShibaCash",
			"base_symbol": "SHIBACASH",
			"base_address": "0x9f8E839A4cFB1EE283C54900d0C4553031453414",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "3.15e-18",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "198966643449.6727918865063131997",
			"liquidity_BNB": "645744812.87404412319785787661"
		},
		"0x1f0Da2588053451e7b31483Ba44dBf7CfcB5A67d_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0xe8b14d89AD29a71656ffA4A2A0cF7EB032E5EaD1",
			"base_name": "MiniSafe",
			"base_symbol": "miniSAFE",
			"base_address": "0x1f0Da2588053451e7b31483Ba44dBf7CfcB5A67d",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "3.16e-18",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "198634129795.0391585750321288874",
			"liquidity_BNB": "694132133.691413942345773941617"
		},
		"0xaBfa9C225704f0a7130AcbE5C4145fe20944d996_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0xB4497AbDEd784458A559ECbF713a732a6b56AaA8",
			"base_name": "MiniMOON",
			"base_symbol": "miniMOON",
			"base_address": "0xaBfa9C225704f0a7130AcbE5C4145fe20944d996",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "3.16e-18",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "197385555405.8821185558428912647",
			"liquidity_BNB": "677373809.464704696932380986614"
		},
		"0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c_0xDF77C94D990334be1B1650Bc5c15a344780D1B26": {
			"pair_address": "0x522D6F244fa9e941Fe0bd92069405F09ed3D827d",
			"base_name": "Wrapped BNB",
			"base_symbol": "WBNB",
			"base_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"quote_name": "Shiba Black",
			"quote_symbol": "ShibB",
			"quote_address": "0xDF77C94D990334be1B1650Bc5c15a344780D1B26",
			"price": "317012662569835770.8365453511858256933",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "197044568922.8741644051771694367",
			"liquidity_BNB": "670161108.673772417519055095934"
		},
		"0x89D8A7694EAFb732b31f2571729B26a72E45167C_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0x3Da06C45A39241b0E1554ebFef8891FfFBe909d0",
			"base_name": "FlokiLoki",
			"base_symbol": "fLOKI",
			"base_address": "0x89D8A7694EAFb732b31f2571729B26a72E45167C",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "3.16e-18",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "196388295858.228063569485089096",
			"liquidity_BNB": "642566343.230437068361416445986"
		},
		"0x4c386A387C53d5c062fF846fB50d8A68bD38F8d0_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0x38Ff32706B0249a811E9d99b7FcECb69De6455b2",
			"base_name": "OnlyUpBNB",
			"base_symbol": "UpBNB",
			"base_address": "0x4c386A387C53d5c062fF846fB50d8A68bD38F8d0",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "3.16e-18",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "196333580065.0874912667457703954",
			"liquidity_BNB": "687751277.225159617295819997139"
		},
		"0x4dBD749F32917be0602487cff05b288eED77Ee3b_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0x384d1F5e73E705911DbdDC474307916e7122327E",
			"base_name": "NanoBitcoin",
			"base_symbol": "NanoBTC",
			"base_address": "0x4dBD749F32917be0602487cff05b288eED77Ee3b",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "3.15e-18",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "195633489638.451149496674681615",
			"liquidity_BNB": "666780197.360434204498746475362"
		},
		"0x2bC9E9384849dF9CB2B843C52B9ec0D35860b76A_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0xee2a6D993520dfcEA5e2D4b01D6bd005606d64D7",
			"base_name": "FlokiInu",
			"base_symbol": "fInu",
			"base_address": "0x2bC9E9384849dF9CB2B843C52B9ec0D35860b76A",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "3.16e-18",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "195335784982.5561756982952155229",
			"liquidity_BNB": "633689150.593317808789076005395"
		},
		"0x44aef696c274f520eb402092AA65c8b4257d11ac_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0x5b6df1603e9f082CAfD7f95D33D034099FA1636F",
			"base_name": "FlokiBNB",
			"base_symbol": "fBNB",
			"base_address": "0x44aef696c274f520eb402092AA65c8b4257d11ac",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "3.16e-18",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "195312108696.8356358138020518877",
			"liquidity_BNB": "682761633.769788195846800268935"
		},
		"0x57414a90602942B31C36a2C5B2d8a3a0efdaB905_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0xcE9f325756fbC0D8d35c3d79f143Ba8CbA9E8f36",
			"base_name": "OnlyUpStrongElon",
			"base_symbol": "UpSElon",
			"base_address": "0x57414a90602942B31C36a2C5B2d8a3a0efdaB905",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "2.02e-18",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "195205150313.2257683538795240766",
			"liquidity_BNB": "651786703.842166827843887926593"
		},
		"0x72a406051f6b9688ce3B8AfE19f014E266a9c2C3_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0x7608489Ac8F8fcE6fbfF3dcd521e17FAD03E4EA4",
			"base_name": "EverBitcoin",
			"base_symbol": "EverBTC",
			"base_address": "0x72a406051f6b9688ce3B8AfE19f014E266a9c2C3",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "3.16e-18",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "194891272508.9698737650970078964",
			"liquidity_BNB": "667927198.028439653884270827215"
		},
		"0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c_0xEc8610bfe8c9d26e34005fB6310124AAB046B24b": {
			"pair_address": "0x278a39059606fc101ee55Aa755ee17cFB872fFBa",
			"base_name": "Wrapped BNB",
			"base_symbol": "WBNB",
			"base_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"quote_name": "FlokiBNB",
			"quote_symbol": "FlokiBNB",
			"quote_address": "0xEc8610bfe8c9d26e34005fB6310124AAB046B24b",
			"price": "494518020331365004.33068982370963113725",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "194661770861.3262861299788621782",
			"liquidity_BNB": "646854849.490860738893391769845"
		},
		"0xB5cAAeEC00D73D5602912440Eb2a31691c305124_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0x5Fc5a62c1f55003909ed8C4f3beD83A89aFAA3A6",
			"base_name": "SpaceFEG",
			"base_symbol": "SPACEFEG",
			"base_address": "0xB5cAAeEC00D73D5602912440Eb2a31691c305124",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "2.02e-18",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "193664429429.453375987955479998",
			"liquidity_BNB": "647418342.386757127091424284359"
		},
		"0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c_0xcA2172f711c675ee1f97B5966ec7289D408EbC04": {
			"pair_address": "0x188EEf631449B5470B37A328Ace180Eb635ad858",
			"base_name": "Wrapped BNB",
			"base_symbol": "WBNB",
			"base_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"quote_name": "OnlyUpSafe",
			"quote_symbol": "UpSAFE",
			"quote_address": "0xcA2172f711c675ee1f97B5966ec7289D408EbC04",
			"price": "317153374356055016.95007657219309201673",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "193366868454.392213360029118443",
			"liquidity_BNB": "663195101.384221822583257515015"
		},
		"0x481e3a0d71338DD3e4573368f780d768E5e0603E_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0xD8c18AE87D503195962516330ea9079e7fA0D093",
			"base_name": "Hundred x Loki",
			"base_symbol": "100xLOKI",
			"base_address": "0x481e3a0d71338DD3e4573368f780d768E5e0603E",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "3.16e-18",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "192158271199.987890077949191122",
			"liquidity_BNB": "633001107.9508007426270558748955"
		},
		"0x2e835f4fa42590Aa87E5862c9334De9A9f4DaF57_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0xBCDF3dD66fBB21498c7C380D2D5d2360C869ef21",
			"base_name": "MiniBitcoin",
			"base_symbol": "miniBTC",
			"base_address": "0x2e835f4fa42590Aa87E5862c9334De9A9f4DaF57",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "3.16e-18",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "191880927790.5606420766774241923",
			"liquidity_BNB": "651163431.322564281033065100658"
		},
		"0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c_0xC657101B4b37ee62C61c16d6BD29a611e7bd4608": {
			"pair_address": "0x036ab992e6eEA2934c261B0b521865A761fFAB14",
			"base_name": "Wrapped BNB",
			"base_symbol": "WBNB",
			"base_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"quote_name": "MiniCash",
			"quote_symbol": "miniCASH",
			"quote_address": "0xC657101B4b37ee62C61c16d6BD29a611e7bd4608",
			"price": "635152222258726434.27308554360083916045",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "191787799226.9430830765315170602",
			"liquidity_BNB": "609497311.49310582873831509749"
		},
		"0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c_0xdC3766524b885775E1930d1d0e3F6C14dc27349D": {
			"pair_address": "0x5D90a220A679049A87e3A4e8674bce1F109Fe2b8",
			"base_name": "Wrapped BNB",
			"base_symbol": "WBNB",
			"base_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"quote_name": "OnlyUpStrongElon",
			"quote_symbol": "UpSElon",
			"quote_address": "0xdC3766524b885775E1930d1d0e3F6C14dc27349D",
			"price": "494463756224761268.87712976414988778323",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "191748386034.0073038980422115325",
			"liquidity_BNB": "643160648.043037033631273274443"
		},
		"0xacdfA1cd77F803A993A7F21Be90815D97f39279A_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0xe6b8f250BA050f884E5F36eE9aC29bf27434C2E9",
			"base_name": "Hundred x Rocket",
			"base_symbol": "100xRocket",
			"base_address": "0xacdfA1cd77F803A993A7F21Be90815D97f39279A",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "3.16e-18",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "191630946114.8104630708837939556",
			"liquidity_BNB": "700470269.190058179869691502281"
		},
		"0x64C3567ad1A3336AaBaF567B6f21d4E61D7eD34B_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0x3055aC42c598562e2b4624BcCA5dc54bc2704FFd",
			"base_name": "OnlyUpRocket",
			"base_symbol": "UpRocket",
			"base_address": "0x64C3567ad1A3336AaBaF567B6f21d4E61D7eD34B",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "2.02e-18",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "191309149838.88957916031379643",
			"liquidity_BNB": "636441269.044031214674559611576"
		},
		"0xb64d363A86daF26B5Be73434C28D4A86c0E70c5a_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0x6e1C3860b523eC59A0a529E203Bee00eA704Afbd",
			"base_name": "DogeOlympus",
			"base_symbol": "DOGEOLYMPUS 🌀️",
			"base_address": "0xb64d363A86daF26B5Be73434C28D4A86c0E70c5a",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "0",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "190781685767.672253182296308544",
			"liquidity_BNB": "474418494.2704551999000452098783"
		},
		"0x86CA1b6E8051804272c6318Aec9A18F169734608_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0xBCB1d0304F0139C31a57Dd15A335E3cd4086c818",
			"base_name": "Floki Black",
			"base_symbol": "FlokiB",
			"base_address": "0x86CA1b6E8051804272c6318Aec9A18F169734608",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "3.15e-18",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "190477787999.9422207874161426938",
			"liquidity_BNB": "633121538.724650932137018647998"
		},
		"0x55Beaece0Bf9c9b338E43dCc1386336C948D3c64_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0xEF5b59E86ee1943e3CC4E3264D6029FD2A68a03B",
			"base_name": "Cash",
			"base_symbol": "$CASH",
			"base_address": "0x55Beaece0Bf9c9b338E43dCc1386336C948D3c64",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "1.58e-18",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "189674145713.086258744256476888",
			"liquidity_BNB": "635559162.1618682802404258314486"
		},
		"0xafe808c763601A54111a809B34De0F2049eED201_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0x074401eDfEcda20fa532225966748c868cC21694",
			"base_name": "OnlyUpSushi",
			"base_symbol": "UpSushi",
			"base_address": "0xafe808c763601A54111a809B34De0F2049eED201",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "1.58e-18",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "189582637434.5856433057888737281",
			"liquidity_BNB": "626882385.490368855448759604111"
		},
		"0x9c9108a49C7aEB8DBCcB44fBb4C7AE2562eB07EE_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0xbfbDFc8EFba3467947BF57a36Dca742A4aFaE39B",
			"base_name": "OnlyUpCash",
			"base_symbol": "UpCASH",
			"base_address": "0x9c9108a49C7aEB8DBCcB44fBb4C7AE2562eB07EE",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "3.16e-18",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "189442092171.4503533625516760912",
			"liquidity_BNB": "631075005.0265346999955152667366"
		},
		"0x7E18f7A4F38FdbecfCF17a36a226D50eF48ebb82_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0x151a2775512775AD55083ec3793b82f84409EA34",
			"base_name": "ShibaPanda",
			"base_symbol": "ShibPNDA",
			"base_address": "0x7E18f7A4F38FdbecfCF17a36a226D50eF48ebb82",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "3.15e-18",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "189401109387.1242695197110555813",
			"liquidity_BNB": "643234888.034747199344298334996"
		},
		"0x2375c73c550FcEFB46Df00c9e7A6E33E02b092c6_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0x4a540EfbEb0a7d920fD419244510CC12EB666A88",
			"base_name": "ShibaPanda",
			"base_symbol": "SHIBAPNDA",
			"base_address": "0x2375c73c550FcEFB46Df00c9e7A6E33E02b092c6",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "3.15e-18",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "189264372378.533802327113752937",
			"liquidity_BNB": "642073071.5866665894326297217965"
		},
		"0x72a9E28f814af6806Bdc7EfEE9a3933E14542b6c_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0xBCfA7E41e46A3e894FAd64e60fa4b520b65C7464",
			"base_name": "BabyInu",
			"base_symbol": "bInu",
			"base_address": "0x72a9E28f814af6806Bdc7EfEE9a3933E14542b6c",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "3.16e-18",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "188444148229.7799839967251140298",
			"liquidity_BNB": "639432912.857130217329976772604"
		},
		"0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c_0xe79bFd3d6FdF740469eEb89EAa7599CBdD0BA69E": {
			"pair_address": "0x97BAC8Ba1006b487d2bb24b26f516f9f3b8d0313",
			"base_name": "Wrapped BNB",
			"base_symbol": "WBNB",
			"base_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"quote_name": "SuperCock",
			"quote_symbol": "SUPð",
			"quote_address": "0xe79bFd3d6FdF740469eEb89EAa7599CBdD0BA69E",
			"price": "494564589703206646.68565701396341631827",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "187652746866.0492908598182037502",
			"liquidity_BNB": "630405606.975052734479278015445"
		},
		"0x1Fb74A079ED9A66A17aC7109Eb50A5622f0f512A_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0x418d52853b92D6870efF1A5A95F1A5832fB1785d",
			"base_name": "FEGCock",
			"base_symbol": "FEGð",
			"base_address": "0x1Fb74A079ED9A66A17aC7109Eb50A5622f0f512A",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "2.02e-18",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "187319244787.1482617747791969286",
			"liquidity_BNB": "618112868.5160364408449567637064"
		},
		"0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c_0xf512dFAAb35986fA3b3E2CBF6534B5C54731C993": {
			"pair_address": "0x6F86A5d9db04C073883B8C34526531Ce99956Ed1",
			"base_name": "Wrapped BNB",
			"base_symbol": "WBNB",
			"base_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"quote_name": "MiniPanda",
			"quote_symbol": "miniPNDA",
			"quote_address": "0xf512dFAAb35986fA3b3E2CBF6534B5C54731C993",
			"price": "316965370213383952.26621651884841848491",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "186755921961.9299862668805816527",
			"liquidity_BNB": "634170078.809601673649271349724"
		},
		"0x077EBdC7e9063b94F00029DbA3486a9A0fd8F848_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0x0ca6d9500C3b35B69DB05b0e549A6b1Cc4c9C15F",
			"base_name": "SuperLoki",
			"base_symbol": "SUPLOKI",
			"base_address": "0x077EBdC7e9063b94F00029DbA3486a9A0fd8F848",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "3.16e-18",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "186745470078.844524592038213554",
			"liquidity_BNB": "655477133.8691233118085535523674"
		},
		"0x92fF0eE7f00D17948b5F74bF56dd16537aD19660_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0x0424825C15ca41ff49af8955E909090990696FF0",
			"base_name": "EverMOON",
			"base_symbol": "EverMOON",
			"base_address": "0x92fF0eE7f00D17948b5F74bF56dd16537aD19660",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "3.16e-18",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "186423329609.4920122620347729717",
			"liquidity_BNB": "633037084.145539133612300384165"
		},
		"0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c_0xbee899aAf833Bd4b0D611fbcA26A0DA57F315EF9": {
			"pair_address": "0x3E06bd9505c033216C33956220001C02844C374e",
			"base_name": "Wrapped BNB",
			"base_symbol": "WBNB",
			"base_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"quote_name": "WrappedFEG",
			"quote_symbol": "wFEG",
			"quote_address": "0xbee899aAf833Bd4b0D611fbcA26A0DA57F315EF9",
			"price": "316812273159721320.56577961100912183968",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "186125579612.6170290827057713667",
			"liquidity_BNB": "632695068.111806992690220495784"
		},
		"0xa1C5DAcf84DB15ddFc78348177D45Baf446f0eAB_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0x951d7e38a888e74cB6C0E3e9A3201a3CdbaD817d",
			"base_name": "AstroBabyShiba",
			"base_symbol": "ABSHIB",
			"base_address": "0xa1C5DAcf84DB15ddFc78348177D45Baf446f0eAB",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "0",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "185436469928.9966685638616961745",
			"liquidity_BNB": "612866603.00686344870666620608"
		},
		"0x1f4aF37805a8a021633CE3D0918035Ad79A613c3_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0x9a58e57C9D18c418Ee1f04153c895AcAE9468205",
			"base_name": "ShibaCash",
			"base_symbol": "SHIBACASH",
			"base_address": "0x1f4aF37805a8a021633CE3D0918035Ad79A613c3",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "3.15e-18",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "185167227200.623321396943399123",
			"liquidity_BNB": "628972267.98268434561415600231"
		},
		"0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c_0xc50d3ECCD911e48a1779d5a2EF9f96bDeC264871": {
			"pair_address": "0x56F86d00d300C3019a65D4844363a4464562F42a",
			"base_name": "Wrapped BNB",
			"base_symbol": "WBNB",
			"base_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"quote_name": "ShibaRocket",
			"quote_symbol": "ShibRocket",
			"quote_address": "0xc50d3ECCD911e48a1779d5a2EF9f96bDeC264871",
			"price": "494396969469593482.12511389900682441126",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "185099768480.8567024745327781307",
			"liquidity_BNB": "620485779.2743209591902576687244"
		},
		"0x723C34780ba4739eeb30Afc9ac07238687bDd563_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0x811d0cCF53f9240964Bd0C3F26600166d8f1dbf8",
			"base_name": "Baby Doge Pro",
			"base_symbol": "BabyDogePro",
			"base_address": "0x723C34780ba4739eeb30Afc9ac07238687bDd563",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "0",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "185022812380.1364813258862991212",
			"liquidity_BNB": "694233979.9138146838767258942665"
		},
		"0x0D36a6f45d7485EBed2B069dc18ac0589400eBCc_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0xC5FAf20db3bd60bc46F8564A897416B6edd9c1E1",
			"base_name": "Fat Cake Token ",
			"base_symbol": "FCake 🍰️",
			"base_address": "0x0D36a6f45d7485EBed2B069dc18ac0589400eBCc",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "0",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "184933935347.3984568117293234134",
			"liquidity_BNB": "443040533.6414804170079408747577"
		},
		"0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c_0xe20D66F518B7Ba530c37d7c3f352ee4F30029e9e": {
			"pair_address": "0xe83296Cad10eFEcFaf4f4f4F8801E079dde3ccC5",
			"base_name": "Wrapped BNB",
			"base_symbol": "WBNB",
			"base_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"quote_name": "ShibaRocket",
			"quote_symbol": "SHIBARocket",
			"quote_address": "0xe20D66F518B7Ba530c37d7c3f352ee4F30029e9e",
			"price": "635011323658528159.75013780375214427941",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "184403209158.3883358428425339724",
			"liquidity_BNB": "623238123.3070071718450430906405"
		},
		"0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c_0xfFc86FA6a71e68B5ee69232A28c480Cd4f3B2DFb": {
			"pair_address": "0x47a829FB6FE900903d322A6A036F8777C72A8d43",
			"base_name": "Wrapped BNB",
			"base_symbol": "WBNB",
			"base_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"quote_name": "Shiba Black",
			"quote_symbol": "SHIBAB",
			"quote_address": "0xfFc86FA6a71e68B5ee69232A28c480Cd4f3B2DFb",
			"price": "317163207684612609.9527559910069444679",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "182792646987.962852921749448065",
			"liquidity_BNB": "691739500.583791790152737336031"
		},
		"0x030C9bE2A6Ad834a7FaB080e182eF4607b0FC51e_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0xa95073BAD72375A9adC701e449f220C6c0A0dBF4",
			"base_name": "SuperInu",
			"base_symbol": "SUPInu",
			"base_address": "0x030C9bE2A6Ad834a7FaB080e182eF4607b0FC51e",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "3.16e-18",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "181773290658.42519832906468471",
			"liquidity_BNB": "645260944.551856002441685827711"
		},
		"0x5b250A7A207D63756977EEF1325C2181850F0e7E_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0x5FC6c35ea069dEa7448e0aA27be97276CBfc7D43",
			"base_name": "SuperRocket",
			"base_symbol": "SUPRocket",
			"base_address": "0x5b250A7A207D63756977EEF1325C2181850F0e7E",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "3.16e-18",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "181549271112.3557578340794986748",
			"liquidity_BNB": "642085834.451757557598288130966"
		},
		"0x1CbA429D1996331d3C27205Bfd3F6a42C2b8FB23_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0x83E8c052a876067E99EccF9f03c250Cc0B33d032",
			"base_name": "NFTs by BNB",
			"base_symbol": "NFTBNB",
			"base_address": "0x1CbA429D1996331d3C27205Bfd3F6a42C2b8FB23",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "6.3e-19",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "180695321026.0404379163193324936",
			"liquidity_BNB": "453285784.5936992128902691907754"
		},
		"0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c_0xC2722cD0dbA76042911941c4D7391DfCe83b5414": {
			"pair_address": "0x3914e65Db60db140411B6AF1C32Bf1230DDFE2DF",
			"base_name": "Wrapped BNB",
			"base_symbol": "WBNB",
			"base_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"quote_name": "Ass Fucker",
			"quote_symbol": "ASS 🍑️",
			"quote_address": "0xC2722cD0dbA76042911941c4D7391DfCe83b5414",
			"price": "9.8939043526447467391206542938971680600016158341e+26",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "180620973366.6377389698603144607",
			"liquidity_BNB": "453169091.4900314334354731638908"
		},
		"0x1051bC77DAA58b875D8A7A0246Ffd0E37d384b0A_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0x67978c3d1dCF556C7ebb87048d237250269C4F56",
			"base_name": "SuperRocket",
			"base_symbol": "SRocket",
			"base_address": "0x1051bC77DAA58b875D8A7A0246Ffd0E37d384b0A",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "3.15e-18",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "180543058155.8558810508451740945",
			"liquidity_BNB": "612797498.007069805258500425255"
		},
		"0x02C7F5DAd0B7e95C9fD23a685EC3580114CFa71b_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0x3a91046aFf740E4a89a5c8aFcA4309D9a2B470c0",
			"base_name": "FlokiEthereum",
			"base_symbol": "fETH",
			"base_address": "0x02C7F5DAd0B7e95C9fD23a685EC3580114CFa71b",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "3.16e-18",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "180395232427.0299382171868974897",
			"liquidity_BNB": "598971861.725449080771147365532"
		},
		"0x871d131f9aFf966B4947005513db4C21f30878eb_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0xa8f2168AA1528695Cf927784a9baFB205bB78b22",
			"base_name": "Hundred x Cock",
			"base_symbol": "100xð",
			"base_address": "0x871d131f9aFf966B4947005513db4C21f30878eb",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "2.02e-18",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "180184239844.185962339439271027",
			"liquidity_BNB": "604809433.839833721166500982701"
		},
		"0x03eF7d6152E242d3D1bb7686DebCB7e6C6A46aAd_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0xEE24C4023b7F3AC58e07EA8c9b307f71E5e352e0",
			"base_name": "SuperBoxer",
			"base_symbol": "SUPBOX",
			"base_address": "0x03eF7d6152E242d3D1bb7686DebCB7e6C6A46aAd",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "3.15e-18",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "180169077442.9493773180055832218",
			"liquidity_BNB": "630159807.797607899115271025596"
		},
		"0x1Be0A313c1eF8E4bf848fFfEeabF985E3252bCC7_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0xe13470560d24e0f839D63da9deb6eB45D50FC3A0",
			"base_name": "ShibaPanda",
			"base_symbol": "SHIBAPNDA",
			"base_address": "0x1Be0A313c1eF8E4bf848fFfEeabF985E3252bCC7",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "2.02e-18",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "179454872470.1241419049111191122",
			"liquidity_BNB": "603027991.640635706235799642695"
		},
		"0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c_0xc8049D7D22167371131d53698C53D837Ca062C8B": {
			"pair_address": "0x512564Ce122DF90038D2EE1B9a7ed7b202784652",
			"base_name": "Wrapped BNB",
			"base_symbol": "WBNB",
			"base_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"quote_name": "Orum.io",
			"quote_symbol": "ORUM",
			"quote_address": "0xc8049D7D22167371131d53698C53D837Ca062C8B",
			"price": "6.132380266399100285881530510555249528954735187e+25",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "178892624429.2845114494091238132",
			"liquidity_BNB": "570269415.580532397124499522694"
		},
		"0x09B250e8A63C353215DbC81b1D1A3c8F159eEb16_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0x7C6E7C999DD6A6cf9D55c54218DBfDc59e5D1F50",
			"base_name": "ShibaBoxer",
			"base_symbol": "ShibBOX",
			"base_address": "0x09B250e8A63C353215DbC81b1D1A3c8F159eEb16",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "3.15e-18",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "178490388945.0003445942496406755",
			"liquidity_BNB": "630200700.7698172462457407373555"
		},
		"0xB04E0f48A68261080C013B05850cd75f2E169Ed5_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0x3E8daD40Dda562ad294289DBEA3a39EEdbCFe8DB",
			"base_name": "MiniCat",
			"base_symbol": "miniCat",
			"base_address": "0xB04E0f48A68261080C013B05850cd75f2E169Ed5",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "3.16e-18",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "178215123845.6377674772646662346",
			"liquidity_BNB": "631048627.859787689544475936951"
		},
		"0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c_0xE84c281D6291Faa3F98A070A113da25e5280528b": {
			"pair_address": "0xEb120E8131f90C42D01F46B005A42BD105251AB1",
			"base_name": "Wrapped BNB",
			"base_symbol": "WBNB",
			"base_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"quote_name": "ShibaPanda",
			"quote_symbol": "SHIBAPNDA",
			"quote_address": "0xE84c281D6291Faa3F98A070A113da25e5280528b",
			"price": "317020246642327582.54072539067599927879",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "178107393439.7946820838456350963",
			"liquidity_BNB": "677696494.8448118366431557655015"
		},
		"0x6B0bA2919703C6A1712Bbe6C58825c9BbB8d3D68_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0xD873A8e02f8De342aBa78b748353b40b50AcC3B7",
			"base_name": "SpaceFEG",
			"base_symbol": "SPACEFEG",
			"base_address": "0x6B0bA2919703C6A1712Bbe6C58825c9BbB8d3D68",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "3.15e-18",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "178020067044.3541055154460567072",
			"liquidity_BNB": "628506614.06213200063979796134"
		},
		"0x2a110184876d312C2735ad821CBf6046eb7BD61d_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0xD1cdc42B70efdbDFBC760DceCc1a58b7B8647B4e",
			"base_name": "Hundred x  Finance",
			"base_symbol": "100xFIN",
			"base_address": "0x2a110184876d312C2735ad821CBf6046eb7BD61d",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "3.15e-18",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "177913678901.463469389476598772",
			"liquidity_BNB": "629814831.03844759645293716605"
		},
		"0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c_0xC8fB2283F7Ba13f8Ad5F15b3c2e2e0384a7B760f": {
			"pair_address": "0x545F283311bb2a10e1872Aee0f19C5b4864aeeB1",
			"base_name": "Wrapped BNB",
			"base_symbol": "WBNB",
			"base_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"quote_name": "NFTs x BabyDoge",
			"quote_symbol": "NFTxbDoge",
			"quote_address": "0xC8fB2283F7Ba13f8Ad5F15b3c2e2e0384a7B760f",
			"price": "1587087360336999273.81864899934515735069",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "177904936892.645726826691071721",
			"liquidity_BNB": "501073086.0181747758077523412366"
		},
		"0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c_0xDe82d2536427246BDC912914BBF9ba27538BC30c": {
			"pair_address": "0x1aF438b71bCAdE786815A79f56a5fEa82D4E22FE",
			"base_name": "Wrapped BNB",
			"base_symbol": "WBNB",
			"base_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"quote_name": "ShibaPanda",
			"quote_symbol": "ShibPanda",
			"quote_address": "0xDe82d2536427246BDC912914BBF9ba27538BC30c",
			"price": "316922981227340195.32723074437914608818",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "177077187344.5007943992857913718",
			"liquidity_BNB": "668687698.078449315471403429144"
		},
		"0x4E4482ff195EE3f9a4938A04b2a1Ba4810A41a42_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0x102c5E966A3146513d788209F81a11c390893651",
			"base_name": "NanoPanda",
			"base_symbol": "NanoPNDA",
			"base_address": "0x4E4482ff195EE3f9a4938A04b2a1Ba4810A41a42",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "3.16e-18",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "176327863039.8707638719459852865",
			"liquidity_BNB": "667793953.003355912817878281458"
		},
		"0x8fCBB3aD0Ef1fC16b3dE4933e4C636eA70d9C3DD_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0x4b10522cCCE4E1bde5A9Ccb8Ab7671162f2c4D02",
			"base_name": "WrappedMOON",
			"base_symbol": "wMOON",
			"base_address": "0x8fCBB3aD0Ef1fC16b3dE4933e4C636eA70d9C3DD",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "3.15e-18",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "176185180099.3879433609789283838",
			"liquidity_BNB": "578073541.275556984850695407974"
		},
		"0x547E7729240A5f26cF3856b4dCCBA3CEc04D5870_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0xBa7758BDcB890e28931fBf6a0e7f016Ccec01975",
			"base_name": "FlokiPanda",
			"base_symbol": "FlokiPanda",
			"base_address": "0x547E7729240A5f26cF3856b4dCCBA3CEc04D5870",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "3.16e-18",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "175704738518.2811161143409945812",
			"liquidity_BNB": "665836582.119991131169044351296"
		},
		"0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c_0xC1Ba8716627f1b1D7A509dE84BE4C32baad20526": {
			"pair_address": "0x60A1D3beD2A632038858EAD9c3d0D9b29EB04AB7",
			"base_name": "Wrapped BNB",
			"base_symbol": "WBNB",
			"base_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"quote_name": "Cat",
			"quote_symbol": "$Cat",
			"quote_address": "0xC1Ba8716627f1b1D7A509dE84BE4C32baad20526",
			"price": "316594764173432701.16288637708347867778",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "175328612666.3117327356138478",
			"liquidity_BNB": "620897767.6857938510867408730825"
		},
		"0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c_0xbC3eaD6a2c1a92D7F313fd56c34304645115f4bD": {
			"pair_address": "0x69f9e3DACc99C2677FA78734eDf2905528e644fe",
			"base_name": "Wrapped BNB",
			"base_symbol": "WBNB",
			"base_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"quote_name": "AquaInu",
			"quote_symbol": "AquaInu",
			"quote_address": "0xbC3eaD6a2c1a92D7F313fd56c34304645115f4bD",
			"price": "316973367305381317.12589948894158987493",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "175052449229.0233746517775345068",
			"liquidity_BNB": "667020457.578069561388101772266"
		},
		"0x0aFE66490472A46b8b9cCc670Ec6a736eA53aed7_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0xb0b5B9F6B519E433aa95baf24Be5Cda910fF44d8",
			"base_name": "ShibaBoxer",
			"base_symbol": "ShibBOX",
			"base_address": "0x0aFE66490472A46b8b9cCc670Ec6a736eA53aed7",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "3.16e-18",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "174945360615.1860681318622549975",
			"liquidity_BNB": "589609101.221263193160458008906"
		},
		"0xAEB5725007cEc4867eBebA59Deb129CD262F0632_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0x2c80d27Eb314A342FbB6b2c6D401d3461087563C",
			"base_name": "OnlyUpDoge",
			"base_symbol": "UpDOGE",
			"base_address": "0xAEB5725007cEc4867eBebA59Deb129CD262F0632",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "3.15e-18",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "174644013494.9324888110420188783",
			"liquidity_BNB": "658197613.6641642694120741102875"
		},
		"0x069E04729ec96Be82ccdDfc1f8677096a4E4B2aE_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0x9C079581A02f212eac733D2377C923328aD4a530",
			"base_name": "FlokiStrongElon",
			"base_symbol": "fSElon",
			"base_address": "0x069E04729ec96Be82ccdDfc1f8677096a4E4B2aE",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "2.03e-18",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "174539996244.1321435834422080749",
			"liquidity_BNB": "583625394.175144178062731533729"
		},
		"0x28F92682a0Df4313Ad29bf273848c3DBF87258Aa_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0x644bbCBd64763a9135e07dcBd6A5139Cc0B69708",
			"base_name": "MegaCock",
			"base_symbol": "Mð",
			"base_address": "0x28F92682a0Df4313Ad29bf273848c3DBF87258Aa",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "2.02e-18",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "174341521520.1574839589603662334",
			"liquidity_BNB": "574291709.4636861963431834004935"
		},
		"0xb895d001ecCf018499901E145e49C57D4BC5111E_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0x579Ed3B104D561d8d8bf8048B758982ca10820d5",
			"base_name": "FEGFEG",
			"base_symbol": "FEGFEG",
			"base_address": "0xb895d001ecCf018499901E145e49C57D4BC5111E",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "3.16e-18",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "174169646255.6834118364509597756",
			"liquidity_BNB": "618479079.861260507984850794797"
		},
		"0xAf4B29C2c65A35fD919Ee6141C5c548eE8745735_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0x59abC17664292CCdA3EfF01A5B390550A058B5Ee",
			"base_name": "StrongElon",
			"base_symbol": "$SElon",
			"base_address": "0xAf4B29C2c65A35fD919Ee6141C5c548eE8745735",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "3.15e-18",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "173229123763.1758594073781669428",
			"liquidity_BNB": "575808725.456648449663544951113"
		},
		"0x2eC12F8b141F327Dc4a02cfe428868b88e0a9463_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0xc97cc3aE4F8CfD2f424AdbeB14b6bAf2570A41b6",
			"base_name": "AquaBoxer",
			"base_symbol": "AquaBOX",
			"base_address": "0x2eC12F8b141F327Dc4a02cfe428868b88e0a9463",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "3.15e-18",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "172901586411.0931387693925845625",
			"liquidity_BNB": "573902350.296301753616957426551"
		},
		"0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c_0xE630f3FDcEc26828043befc3EFFF4187A9D39697": {
			"pair_address": "0xe80572E08B53ACD87d6EA4FBC23aDfaC2fBD4BE7",
			"base_name": "Wrapped BNB",
			"base_symbol": "WBNB",
			"base_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"quote_name": "FlokiSafe",
			"quote_symbol": "FlokiSAFE",
			"quote_address": "0xE630f3FDcEc26828043befc3EFFF4187A9D39697",
			"price": "316776360751940106.21986230216570420252",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "172525175742.6646451839946039164",
			"liquidity_BNB": "604465784.8677974017691074905"
		},
		"0xAa128e1dADDf3f643a4754A90dFD886A9a887D91_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0x319A00F28eA8a0bB3D6371990181Fdc281802a9b",
			"base_name": "BabyBNB",
			"base_symbol": "BabyBNB",
			"base_address": "0xAa128e1dADDf3f643a4754A90dFD886A9a887D91",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "3.15e-18",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "172156434871.2050598147061454778",
			"liquidity_BNB": "654123766.637011572006135592012"
		},
		"0xaD68685Af5010838a54E1bB282F940DE30f952eb_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0x7b4Cba65ddfD7B24c065935C06f92375D2106Bc1",
			"base_name": "BabyLoki",
			"base_symbol": "bLOKI",
			"base_address": "0xaD68685Af5010838a54E1bB282F940DE30f952eb",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "3.15e-18",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "172105346753.2800145915061476797",
			"liquidity_BNB": "566069576.707322527676907282686"
		},
		"0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c_0xEE85e70bE006ee2C4C8Eb324399A936Cec712711": {
			"pair_address": "0x5a664856d65A6DDfAfDad2EfA0AFFF0EBA92308C",
			"base_name": "Wrapped BNB",
			"base_symbol": "WBNB",
			"base_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"quote_name": "FEGMOON",
			"quote_symbol": "FEGMOON",
			"quote_address": "0xEE85e70bE006ee2C4C8Eb324399A936Cec712711",
			"price": "494555877700735681.16156919907359915126",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "171927037201.8085975302041931599",
			"liquidity_BNB": "575152169.595713738530959943057"
		},
		"0x23D39E2392DE50Ec87961f2A7213211CA51e206D_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0x042a0C563072093CCae73370B93E25C9155427D9",
			"base_name": "Aqua Cardano",
			"base_symbol": "AquaADA",
			"base_address": "0x23D39E2392DE50Ec87961f2A7213211CA51e206D",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "2.02e-18",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "171410118722.4137532145181475303",
			"liquidity_BNB": "569468435.2280011827363135918896"
		},
		"0x3Dd5047A60630de784F5612dBD95d811a91a5750_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0xc82cF977760Be3E777eF601eE2C2259999bc185b",
			"base_name": "Binance FEG",
			"base_symbol": "BSCFEG",
			"base_address": "0x3Dd5047A60630de784F5612dBD95d811a91a5750",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "3.16e-18",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "171251151370.6414617755557828537",
			"liquidity_BNB": "566098896.515709118001493820446"
		},
		"0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c_0xBd567f82ccBeA2B74A471C2c822136625d7cab49": {
			"pair_address": "0x9A673D11E2d26F3d3f0BA4c1a0CA33bA5410479b",
			"base_name": "Wrapped BNB",
			"base_symbol": "WBNB",
			"base_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"quote_name": "SpaceEthereum",
			"quote_symbol": "SPACEETH",
			"quote_address": "0xBd567f82ccBeA2B74A471C2c822136625d7cab49",
			"price": "317103980582061186.30014614479792960817",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "171249134548.1621144980561572072",
			"liquidity_BNB": "598957648.025093111162147955284"
		},
		"0x0AF1555BdD2858Da610857Baf358C29d296211A4_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0xBA32c4ef6332F40588222847E631d8Aa0495A31a",
			"base_name": "Floki Cardano",
			"base_symbol": "FlokiADA",
			"base_address": "0x0AF1555BdD2858Da610857Baf358C29d296211A4",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "2.02e-18",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "171021214815.7652875224261352066",
			"liquidity_BNB": "563064183.899518244412661338404"
		},
		"0x2228B40B28d77eA3771caBf7256Bd6eCe9F65852_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0x4127d203e471e43467164828c0E3edDCaDF04C1A",
			"base_name": "SuperDoge",
			"base_symbol": "SDOGE",
			"base_address": "0x2228B40B28d77eA3771caBf7256Bd6eCe9F65852",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "3.16e-18",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "170980894824.01967961525896352",
			"liquidity_BNB": "567431195.115643516021454668496"
		},
		"0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c_0xFA4f449F0932C0bB8Ead198E92f08fef9384bd3e": {
			"pair_address": "0xFe9C4F1f255B6Fd83873bd0c7D96635c1E6CFaD6",
			"base_name": "Wrapped BNB",
			"base_symbol": "WBNB",
			"base_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"quote_name": "EverInu",
			"quote_symbol": "EverInu",
			"quote_address": "0xFA4f449F0932C0bB8Ead198E92f08fef9384bd3e",
			"price": "317271551063964518.53567440937272320032",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "170891362440.0803278599580856216",
			"liquidity_BNB": "563005520.587551178474549298485"
		},
		"0x3B7bC32BC20828bC8546A2F0F549Cda7718F2F57_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0xa613BD504Cd78766DE28DE76d6B59C2d4a9aa08d",
			"base_name": "GoesUpHigherMOON",
			"base_symbol": "GUHMOON",
			"base_address": "0x3B7bC32BC20828bC8546A2F0F549Cda7718F2F57",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "3.16e-18",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "170696988665.1815216557886941535",
			"liquidity_BNB": "581011778.752951105925826021409"
		},
		"0x6bC648f84DEC7660066884Cfd515003B99382002_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0xd9A0dCCB1823E8A93b8d9c6cD9e040Fd87327216",
			"base_name": "SuperSafe",
			"base_symbol": "SSAFE",
			"base_address": "0x6bC648f84DEC7660066884Cfd515003B99382002",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "2.02e-18",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "170688347760.3411367923395529581",
			"liquidity_BNB": "571876564.044261810797188681296"
		},
		"0x1c762C903bBDE96c8fDdDaa51A386525cB588594_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0x1A962d5C6Cf8062770Fa8A43351e85168490D9B9",
			"base_name": "SuperApe",
			"base_symbol": "SUPAPE",
			"base_address": "0x1c762C903bBDE96c8fDdDaa51A386525cB588594",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "3.15e-18",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "170300061405.2805180529505036352",
			"liquidity_BNB": "574260751.493461972851520837918"
		},
		"0x2f681a3ff2C5842c49589fBF57C455678A5df6aD_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0xd37cA02c11a3D1BD0A7fa65514A3C72A7347fB3A",
			"base_name": "EverStrongElon",
			"base_symbol": "EverSElon",
			"base_address": "0x2f681a3ff2C5842c49589fBF57C455678A5df6aD",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "3.15e-18",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "170047081103.323305817119982242",
			"liquidity_BNB": "557534233.826783650971463106485"
		},
		"0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c_0xeBF9abaB5fef6ddBC3673846ebaf95EA862973Af": {
			"pair_address": "0xE370d1eBc18de5934dcA661cF2A343899C231498",
			"base_name": "Wrapped BNB",
			"base_symbol": "WBNB",
			"base_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"quote_name": "Aqua Cardano",
			"quote_symbol": "AquaADA",
			"quote_address": "0xeBF9abaB5fef6ddBC3673846ebaf95EA862973Af",
			"price": "317076286284966012.34304318664771861087",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "169868737308.9611866229663641895",
			"liquidity_BNB": "573421720.2509931724928693103246"
		},
		"0x518747b74AEB97742bfEBa7b36820D100B6Fd84F_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0x7e635b6F0957749fe18bD1F3C2B60B481E6b8bE0",
			"base_name": "SuperInu",
			"base_symbol": "SInu",
			"base_address": "0x518747b74AEB97742bfEBa7b36820D100B6Fd84F",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "3.15e-18",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "169555670838.4953953884374068504",
			"liquidity_BNB": "638100425.025173938191221032487"
		},
		"0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c_0xC9B5b0DBC042775f2d25dB0D9B76a8C077C37270": {
			"pair_address": "0x35876420F493cD0cffE92B4d5501ea1E175Ee410",
			"base_name": "Wrapped BNB",
			"base_symbol": "WBNB",
			"base_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"quote_name": "BabyPanda",
			"quote_symbol": "bPNDA",
			"quote_address": "0xC9B5b0DBC042775f2d25dB0D9B76a8C077C37270",
			"price": "316877199460469393.54948065213093414838",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "169535372355.9094302583457864921",
			"liquidity_BNB": "554408597.131057129256564729064"
		},
		"0x09c5C1B86a3248f866B584a48Bd12a88681E6bc6_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0xA98e248B7eD8F89098a8180Ed78E62B62f9C1379",
			"base_name": "AquaCat",
			"base_symbol": "AquaCat",
			"base_address": "0x09c5C1B86a3248f866B584a48Bd12a88681E6bc6",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "2.02e-18",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "169043648169.8871989897891950367",
			"liquidity_BNB": "552606256.63803188281696204123"
		},
		"0x942262cafbb45D638204ada42997AEd31CC14940_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0xC3FFB6a960d9d612B8081023dBA36072A9CA6B97",
			"base_name": "SpaceApe",
			"base_symbol": "SPACEAPE",
			"base_address": "0x942262cafbb45D638204ada42997AEd31CC14940",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "3.15e-18",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "168434285875.2531491031394251673",
			"liquidity_BNB": "566706952.195967449552893855072"
		},
		"0xa96b8c5CAC290ee2eBDE7c6455586EC785c088C5_0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"pair_address": "0xe14C424e130c376E3dbfAB04aE49A60075EbB304",
			"base_name": "Rocket",
			"base_symbol": "$Rocket",
			"base_address": "0xa96b8c5CAC290ee2eBDE7c6455586EC785c088C5",
			"quote_name": "Wrapped BNB",
			"quote_symbol": "WBNB",
			"quote_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"price": "3.16e-18",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "167857165904.3817406524752157886",
			"liquidity_BNB": "555827205.42568868100993801033"
		},
		"0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c_0xC4dA638B64c821AdB13e4F8aae03fA736FcBc22B": {
			"pair_address": "0xEC1b5f4B989D454c6CB4082Ef710437F87364e34",
			"base_name": "Wrapped BNB",
			"base_symbol": "WBNB",
			"base_address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"quote_name": " Finance",
			"quote_symbol": "$FIN",
			"quote_address": "0xC4dA638B64c821AdB13e4F8aae03fA736FcBc22B",
			"price": "316626624596404429.52048492083145703629",
			"base_volume": "0",
			"quote_volume": "0",
			"liquidity": "167711750621.140795650223887856",
			"liquidity_BNB": "638750985.537864591217601060048"
		}
	}
}