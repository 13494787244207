import React, { useRef, useEffect, useState, useMemo } from 'react'
import { Flex, Text, useMatchBreakpoints, Heading, ArrowForwardIcon, ArrowBackIcon  } from '@rimauswap-libs/uikit'
// import { useTranslation } from 'contexts/Localization'
import { formattedPercent, formatDollarAmount } from '../../../../utils'
// import { TokenImage } from '../../../../components/TokenImage';
import { TokenProps, SORT_FIELD, DesktopColumnSchema, MobileColumnSchema } from './types'
// import FormattedName from '../FormattedName'
import { DashGrid, DataText, Container, TableWrapper, StyledTable, TableHead, TableBody, TableContainer, ScrollButtonContainer } from '../tableStyle';


const TokenList: React.FC<{ tokens: TokenProps[]; itemMax?: number }> = ({ tokens = [], itemMax = 10 }) => {
    // page state
  const [page, setPage] = useState(1)
  const [maxPage, setMaxPage] = useState(1)
  const { isXs, isSm, isXl } = useMatchBreakpoints()
  const tableWrapperEl = useRef<HTMLDivElement>(null)
   
  const isMobile = !isXl;
  const tableSchema = isMobile ? MobileColumnSchema : DesktopColumnSchema;
  
  // const { t } = useTranslation()

  // sorting
  const [sortDirection, setSortDirection] = useState(true)
  const [sortedColumn, setSortedColumn] = useState(SORT_FIELD.NAME)

  useEffect(() => {
    setMaxPage(1) // edit this to do modular
    setPage(1)
  }, [tokens])

  const formattedTokens = useMemo(() => {
    // .filter((key) => {
    //   return !OVERVIEW_TOKEN_BLACKLIST.includes(key)
    // })
    
    return (
      tokens &&
      Object.keys(tokens).map((key) => tokens[key])
    )
  }, [tokens])

  useEffect(() => {
    if (tokens && formattedTokens) {
      let extraPages = 1
      if (formattedTokens.length % itemMax === 0) {
        extraPages = 0
      }
      setMaxPage(Math.floor(formattedTokens.length / itemMax) + extraPages)
    }
  }, [tokens, formattedTokens, itemMax])

  const filteredList = useMemo(() => {
    return (
      formattedTokens &&
      formattedTokens
        .sort((a, b) => {
          if (sortedColumn === SORT_FIELD.NAME) {
            return a[sortedColumn] > b[sortedColumn] ? (sortDirection ? -1 : 1) * 1 : (sortDirection ? -1 : 1) * -1
          }
          return parseFloat(a[sortedColumn]) > parseFloat(b[sortedColumn])
            ? (sortDirection ? -1 : 1) * 1
            : (sortDirection ? -1 : 1) * -1
        })
        .slice(itemMax * (page - 1), page * itemMax)
    )
  }, [formattedTokens, itemMax, page, sortDirection, sortedColumn])

  return (
    <Container>
      <TableContainer>
        <TableWrapper ref={tableWrapperEl}>
          <StyledTable>
            <TableHead>
              <tr >
                {
                  !isSm || !isXs?
                  <td key="table-head-number">
                    <Text key="table-head-number" fontSize="14px"> # </Text>
                  </td>
                  :null
                }
                
                {tableSchema.map((column) => {
                  return <td key={`table-head-${column.name}`} >
                    <Text 
                      key={`table-head-${column.name}`}
                      onClick={() => {
                        setSortedColumn(SORT_FIELD[column.sortKey.toUpperCase()])
                        setSortDirection(sortedColumn !== SORT_FIELD[column.sortKey.toUpperCase()] ? true : !sortDirection)
                      }}
                      fontSize="14px"
                    >
                      {column.name} {sortedColumn === SORT_FIELD[column.sortKey.toUpperCase()] ? (!sortDirection ? '↑' : '↓') : ''}
                    </Text>
                  </td>
                })}
              </tr>
            </TableHead>
            <TableBody>
                {
                  filteredList && filteredList.map((item, index) => {
                    return (
                      <DashGrid key={`row-${item.name}`} style={{textAlign:'center'}}>
                        {
                          !isSm || !isXs?
                            <DataText key={`cell-number-${item}`}><Flex flex="1" flexDirection="row" justifyContent="center" alignItems="center"> {(page - 1) * itemMax + index + 1}</Flex> </DataText>
                          :null
                        }
                        {
                          tableSchema.map((column) => {
                            return (
                              column.name === 'name'?
                                <DataText key={`cell-${column.name}`}>
                                  <Flex flex="1" flexDirection="row" alignItems="center">
                                    {/* <TokenImage token={item.address} height={32} width={32} mr="16px" /> */}
                                    <Flex flexDirection="column" alignItems="flex-start" ml="10px !important">
                                      <Heading fontSize="14px !important"> {item.name}</Heading>
                                      <Text style={{opacity:0.6, fontSize:12}} color='textSubtle'>{item.symbol}</Text>
                                    </Flex>
                                  </Flex>
                                </DataText>
                              :column.name === 'price'?
                                <DataText key={`cell-${column.name}`}> 
                                  <Flex flex="1" flexDirection="row" alignItems="center">
                                    {formatDollarAmount(item.price, 2)}
                                  </Flex>
                                 </DataText>
                              :column.name === 'priceBNB'?
                                <DataText key={`cell-${column.name}`}>
                                  <Flex flex="1" flexDirection="row" alignItems="center">
                                    {formattedPercent(item.priceBNB)} 
                                  </Flex>
                                </DataText>
                              :null
                            )
                          })
                        }
                      </DashGrid>
                      // <ListItem key={`row-${item}`} index={(page - 1) * itemMax + index + 1} item={item} />
                    )
                  })
                }
            </TableBody>
          </StyledTable>
        </TableWrapper>
        <ScrollButtonContainer>
          <Text onClick={() => setPage(page === 1 ? page : page - 1)}>
            <ArrowBackIcon width="25px" color={page === 1?'textDisabled':'success'} />
            {/* <Arrow faded={ page === 1 }> ← </Arrow> */}
          </Text>
          <Text fontSize="16px" ml="10px" mr="10px">{`Page ${page} of ${maxPage}`}</Text>
          <Text onClick={() => setPage(page === maxPage ? page : page + 1)}>
            <ArrowForwardIcon width="25px" color={page === maxPage?'textDisabled':'success'} />
            {/* <Arrow faded={ page === maxPage }>→</Arrow> */}
          </Text>
        </ScrollButtonContainer>
      </TableContainer>
    </Container>
  )
}

export default TokenList