export type PoolProps = {
  'pair_address': string,
  'base_name': string,
  'base_symbol': string,
  'base_address': string,
  'quote_name': string,
  'quote_symbol': string,
  'quote_address': string,
  'price': string,
  'base_volume': string,
  'quote_volume': string,
  'liquidity': string,
  'liquidity_BNB': string
}

export type ColumnsDefTypes = {
  label: string
  name: string
  sortable: boolean
  sortKey: string
}

export const SORT_FIELD = {
  NAME: 'name',
  PRICE: 'price',
  LIQUIDITY: 'liquidity',
}

// export const FIELD_TO_VALUE = {
//   [SORT_FIELD.LIQ]: 'trackedReserveUSD', // sort with tracked volume only
//   [SORT_FIELD.VOL]: 'oneDayVolumeUSD',
//   [SORT_FIELD.VOL_7DAYS]: 'oneWeekVolumeUSD',
//   [SORT_FIELD.FEES]: 'oneDayVolumeUSD',
// }

export const MobileColumnSchema: ColumnsDefTypes[] = [
  {
    name: 'name',
    sortable: false,
    label: 'Name',
    sortKey: 'NAME'
  },
  {
    name: 'price',
    sortable: true,
    label: 'Price',
    sortKey: 'PRICE'
  },
  {
    name: 'liquidity',
    sortable: true,
    label: 'Liquidity',
    sortKey: 'LIQUIDITY'
  },
  // {
  //   name: 'priceBNB',
  //   sortable: true,
  //   label: 'Price Change',
  //   sortKey: SORT_FIELD.CHANGE
  // }
]


export const DesktopColumnSchema: ColumnsDefTypes[] = [
  {
    name: 'name',
    sortable: false,
    label: 'Name',
    sortKey: 'NAME'
  },
  {
    name: 'price',
    sortable: true,
    label: 'Price',
    sortKey: 'PRICE'
  },
  {
    name: 'liquidity',
    sortable: true,
    label: 'Liquidity',
    sortKey: 'LIQUIDITY'
  },
  // {
  //   name: 'priceBNB',
  //   sortable: true,
  //   label: 'Price Change',
  //   sortKey: SORT_FIELD.CHANGE
  // }
]
