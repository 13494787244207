import React from 'react'
import { Text, Heading, Card, CardHeader, CardBody, Flex, Button } from '@rimauswap-libs/uikit'
import styled from 'styled-components'
import { useTranslation } from 'contexts/Localization'
import LiquidityChart from './components/Analytics/Liquidity'
import VolumeChart from './components/Analytics/Volume'
import TokenList from './components/TokenList'
import PoolList from './components/PoolList'
import { useGetInfoToken, useGetInfoPairs } from '../../hooks/infoApi';


const Container = styled.div`
  padding-top:30px;
  width:100%;
`

const LeftCard = styled(Card)`
  flex:1;
  margin:12px 0;
  ${({ theme }) => theme.mediaQueries.sm} {
    margin:12px;
  }
`

const RightCard = styled(Card)`
  flex:1;
  margin:12px 0;
  ${({ theme }) => theme.mediaQueries.sm} {
    margin:12px;
  }
`
const ScheduleInner = styled(Flex)`
  flex-direction: row;
  width:100%;
  & > div{
    flex:1;
  }
`

const Overview: React.FC = () => {
  const { t } = useTranslation();
  const tokens = useGetInfoToken();
  const pools = useGetInfoPairs();
  

  return (
    <Container>
      <Flex
        flexDirection={['column', null, null, 'row']}
        justifyContent="center"
        mb="20px"
      >
        <LeftCard>
          <CardHeader pb="0px !important" variant="transparent">
            <Heading fontSize="28px !important">{t('Farms & Staking')}</Heading>
          </CardHeader>
          <CardBody>
            <ScheduleInner>
              <Flex flexDirection="column" justifyContent="center" mb="20px">
                <img src="/images/icon.svg"  alt={t('Rimau Icon')} style={{width:60, marginBottom:10}} />
                <Text fontSize="14px" mb="10px">{t('Rimau to Harvest')}</Text>
                <Text fontSize="20px" mb="10px" fontWeight="600">0.000</Text>
                <Text fontSize="12px" mb="10px" style={{opacity:0.5}} >-$0.00</Text>
              </Flex>
              <Flex flexDirection="column" justifyContent="center" mb="20px">
                <img src="/images/icon.svg"  alt={t('Rimau Icon')} style={{width:60, marginBottom:10}} />
                <Text fontSize="14px" mb="10px">{t('Rimau in Wallet')}</Text>
                <Text fontSize="20px" mb="10px" fontWeight="600">100.159.9961</Text>
                <Text fontSize="12px" mb="10px" style={{opacity:0.5}} >-$7.877.71</Text>
              </Flex>
            </ScheduleInner>
            <Button width="100%" p="0" variant="primary"> {t('Harvest')}(0)</Button>
          </CardBody>
        </LeftCard>
        <RightCard>
          <CardHeader pb="0px !important" variant="transparent">
            <Heading fontSize="28px !important">{t('Rimua Stats')}</Heading>
          </CardHeader>
          <CardBody paddingTop={[0,0,0,50]}>
            <Flex flexDirection="row" justifyContent="space-between">
              <Text fontSize="14px" mb="10px">{t('Total Rimau Supply')}</Text>
              <Text fontSize="16px" mb="10px" fontWeight="600">1,007,160</Text>
            </Flex>
            <Flex flexDirection="row" justifyContent="space-between">
              <Text fontSize="14px" mb="10px">{t('Total Rimau Burned')}</Text>
              <Text fontSize="16px" mb="10px" fontWeight="600">0</Text>
            </Flex>
            <Flex flexDirection="row" justifyContent="space-between">
              <Text fontSize="14px" mb="10px">{t('New Rimua/block')}</Text>
              <Text fontSize="16px" mb="10px" fontWeight="600">0.0190</Text>
            </Flex>
          </CardBody>
        </RightCard>
      </Flex>
      <Flex
        flexDirection={['column', null, null, 'column']}
        justifyContent="center"
        mb="20px"
      >
        <Heading fontSize="30px !important" m="12px">{t('Rimau Info & Analytics')}</Heading>
        <Flex
          flexDirection={['column', null, null, 'row']}
          justifyContent="center"
        >
          <LeftCard>
            <LiquidityChart />
          </LeftCard>
          <RightCard>
            <VolumeChart />
          </RightCard>
        </Flex>
      </Flex>

      <Flex
        flexDirection={['column', null, null, 'column']}
        justifyContent="center"
        mb="20px"
      >
        <Heading fontSize="30px !important" m="12px">{t('Top Tokens')}</Heading>
        <Flex
          flexDirection={['row', null, null, 'row']}
          justifyContent="center"
        >
          <TokenList tokens={tokens}  />
        </Flex>
      </Flex>

      <Flex
        flexDirection={['column', null, null, 'column']}
        justifyContent="center"
        mb="20px"
      >
        <Heading fontSize="30px !important" m="12px">{t('Top Pools')}</Heading>
        <Flex
          flexDirection={['row', null, null, 'row']}
          justifyContent="center"
        >
          <PoolList pools={pools}  />
        </Flex>
      </Flex>
    </Container>
  )
}

export default Overview
