import React from 'react'
import { Heading, Flex } from '@rimauswap-libs/uikit'
import styled from 'styled-components'
import { useTranslation } from 'contexts/Localization'
import WatchList from './components/WatchList';

const Container = styled.div`
  padding-top:30px;
  width:100%;
`


const Pools: React.FC = () => {
  const { t } = useTranslation();
  return (
    <Container>
      <Flex
        flexDirection={['column', null, null, 'column']}
        justifyContent="center"
        mb="20px"
      >
        <Heading fontSize="30px !important" m="12px">{t('Your Watchlist')}</Heading>
        <Flex
          flexDirection={['row', null, null, 'row']}
          justifyContent="center"
        >
          <WatchList /> 
        </Flex>
      </Flex>
    </Container>
  )
}

export default Pools
