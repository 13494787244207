import styled from 'styled-components'

export const DashGrid = styled.tr`
  cursor: pointer;
  background:${({ theme }) => theme.colors.tableRow};
  border-bottom: 1px solid ${({ theme }) => `${theme.colors.textSubtle}1a`};
`


export const DataText = styled.td`
  align-items: flex-start;
  padding: 10px 0px;
  color: ${({ theme }) => theme.colors.textSubtle};
`

export const Container = styled.div`
  filter: ${({ theme }) => theme.card.dropShadow};
  width: 100%;
  background: ${({ theme }) => `${theme.card.background}70`};
  border-radius: 18px;
  margin: 16px 0px;
`

export const TableWrapper = styled.div`
  overflow: visible;

  &::-webkit-scrollbar {
    display: none;
  }
`

export const StyledTable = styled.table`
  border-collapse: collapse;
  font-size: 14px;
  border-radius: 4px;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
`

export const TableHead = styled.thead`
  & tr {
    td {
      font-size: 14px;
      vertical-align: middle;
      padding: 25px 0px;
      text-transform: capitalize;
      &:first-child{
        padding-left:25px;
      }
    }
  }
`

export const TableBody = styled.tbody`
  & tr {
    td {
      font-size: 16px;
      vertical-align: middle;
    }
  }
`

export const TableContainer = styled.div`
  position: relative;
`

export const ScrollButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  padding-top: 20px;
  padding-bottom: 20px;
`