import React from 'react'
import styled from 'styled-components'
import { useLocation, Link, useRouteMatch } from 'react-router-dom'
import { ButtonGroup, ButtonGroupItem } from '@rimauswap-libs/uikit'
// import { ButtonGroup, ButtonGroupItem } from '@rimauswap-libs/uikit/dist/components/ButtonMenu'
import { useTranslation } from 'contexts/Localization'


const InfoTabButtons: React.FC= () => {
  const { url } = useRouteMatch()
  const location = useLocation()
  const { t } = useTranslation()
  let activeIndex
  switch (location.pathname) {
    case '/info':
      activeIndex = 0
      break
    case '/info/pools':
      activeIndex = 1
      break
    case '/info/tokens':
      activeIndex = 2
      break
    default:
      activeIndex = 0
      break
  }

  return (
    <Wrapper>
      <ButtonGroup activeIndex={activeIndex} scale="sm">
        <ButtonGroupItem as={Link} to={`${url}`}>
          {t('Overview')}
        </ButtonGroupItem>
        <ButtonGroupItem as={Link} to={`${url}/pools`}>
          {t('Pools')}
        </ButtonGroupItem>
        <ButtonGroupItem as={Link} to={`${url}/tokens`}>
          {t('Tokens')}
        </ButtonGroupItem>
      </ButtonGroup>
    </Wrapper>
  )
}

export default InfoTabButtons;

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  a {
    padding-left: 12px;
    padding-right: 12px;
  }

  ${({ theme }) => theme.mediaQueries.sm} {
    margin-left: 16px;
  }
`
