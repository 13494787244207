import React from 'react'
import styled from 'styled-components'
import { useTranslation } from 'contexts/Localization'
import { Card, Text } from '@rimauswap-libs/uikit'

const WatchListContainer = styled(Card)`
  width:100%;
`
export default function WatchList() {
  const { t } = useTranslation();

  return (
    <WatchListContainer>
      <Text>{t('Saved pools will appear here')}</Text>
    </WatchListContainer> 
  )
}
