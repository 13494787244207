export type TokenProps = {
  name: string
  symbol: string
  price: string
  priceBNB: string
  address: string
}

export type ColumnsDefTypes = {
  label: string
  name: string
  sortable: boolean
  sortKey: string
}

export const SORT_FIELD = {
  NAME: 'name',
  PRICE: 'price',
  CHANGE: 'priceBNB',
}

export const MobileColumnSchema: ColumnsDefTypes[] = [
  {
    name: 'name',
    sortable: true,
    label: 'Name',
    sortKey: 'NAME'
  },
  {
    name: 'price',
    sortable: true,
    label: 'Price',
    sortKey: 'PRICE'
  },
  // {
  //   name: 'priceBNB',
  //   sortable: true,
  //   label: 'Price Change',
  //   sortKey: SORT_FIELD.CHANGE
  // }
]


export const DesktopColumnSchema: ColumnsDefTypes[] = [
  {
    name: 'name',
    sortable: true,
    label: 'Name',
    sortKey: 'NAME'
  },
  {
    name: 'price',
    sortable: true,
    label: 'Price',
    sortKey: 'PRICE'
  },
  // {
  //   name: 'priceBNB',
  //   sortable: true,
  //   label: 'Price Change',
  //   sortKey: SORT_FIELD.CHANGE
  // }
]
