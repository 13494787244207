export const Tokens = {
	"updated_at": 1629973735619,
	"data": {
		"0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c": {
			"name": "Wrapped BNB",
			"symbol": "WBNB",
			"price": "482.4411728522822431955504290298",
			"price_BNB": "1"
		},
		"0xCc88c8769e86D8E7B8726DEc92ca2Dbda9175787": {
			"name": "FOMOMOON",
			"symbol": "FOMOMOON",
			"price": "0.00000002434636569046027104570324912498",
			"price_BNB": "0.00000000004571667611946737207125643396864"
		},
		"0x34D5C5D5fD78Da13BA0aC15854C9405565ECF907": {
			"name": "Marlboro",
			"symbol": "Marlboro",
			"price": "0.04939684125277511202951014458827",
			"price_BNB": "0.0001705060773356424519540979393875"
		},
		"0xf3F219A4285771D00657C8fd06B69Af8c89EDF2A": {
			"name": "TankArmy",
			"symbol": "TANK",
			"price": "0.001264020028506849616700429398362",
			"price_BNB": "0.000002931028708307425363705180822325"
		},
		"0xBA537b27fD23908420a8b1169705A5FF9d77Abb6": {
			"name": "🙏 Help Addiction - Token for Charity 🙏",
			"symbol": "ADDICTED",
			"price": "0.000000294673098758641718546662699639",
			"price_BNB": "0.000000000506561723301440092329535509636"
		},
		"0xBb075aC9c55F4901c071d3352D00B21990976542": {
			"name": "Boost",
			"symbol": "Boost",
			"price": "0.00149320610860046935822255703029",
			"price_BNB": "0.00000435023792169770930751038385231"
		},
		"0xb92ae8D7a63170CdA049e079e2b3419f2496C29c": {
			"name": "HotFood",
			"symbol": "HD",
			"price": "6.61239995320287451330567143239",
			"price_BNB": "0.01956414769574896120166222605853"
		},
		"0x5aD8aB066CD086d0184d658FACa2A0522c57386e": {
			"name": "maidcoin.org",
			"symbol": "MAID",
			"price": "0.003712036801863937339374314999932",
			"price_BNB": "0.00000869755895969347696243644274193"
		},
		"0x35674807881aff2920D6D553cD45346Fdc1CfA90": {
			"name": "Polka Dogelon",
			"symbol": "PDOGELON",
			"price": "0.000003346191137635685396622950943528",
			"price_BNB": "0.0000000077998890041090223641268194669"
		},
		"0xd590D6D4f173885a9A3db2E97Ab9f58F80fa6631": {
			"name": "GAMEOLOGYv2",
			"symbol": "GMYX",
			"price": "0.000002610668964450553566083323622775",
			"price_BNB": "0.00000000774816348024814269022804701588"
		},
		"0x7B74C77AA1E7F70cb8C1d01c2FEC66336995deD4": {
			"name": "CONNECTX",
			"symbol": "CX",
			"price": "0.6262619343082149159991143588",
			"price_BNB": "0.001798362474950199680189220183487"
		},
		"0xdA3155A436c964dA63D1e943593801AE9b513A75": {
			"name": "Tiger Shiba Doge",
			"symbol": "TGSDOGE",
			"price": "0.0000002376589343009204200112858374704",
			"price_BNB": "0.0000000008035535379100662643694703577725"
		},
		"0xF0a8D2eB72De9F0980e6D64A1a9EA86abC296d3D": {
			"name": "PlanChain.io",
			"symbol": "PLAN",
			"price": "0.00000021039561798721051946469419963",
			"price_BNB": "0.0000000006691284832668808885563328361435"
		},
		"0x8b5A1aE5309299a634702b46B7790240Bf7931fB": {
			"name": "Yoshix NFT Game",
			"symbol": "YSX",
			"price": "0.000688404901671185387923867455939",
			"price_BNB": "0.000001791283786190176269012220650857"
		},
		"0xA6A0567Ae25283E9fC4C42a063340424b794d7CE": {
			"name": "CryptoPunk NFT BSC",
			"symbol": "👨‍🎤CRPK",
			"price": "0.0002285003715497166783506271548571",
			"price_BNB": "0.000000605599477420475759572698573034"
		},
		"0x47f2EE75Dd5bDEa39bf2a1B2495b59A1Dca619Df": {
			"name": "Sappchat.com",
			"symbol": "APP",
			"price": "0.0000000261361060974101854860266364789",
			"price_BNB": "0.0000000000776415514379345145305369915817"
		},
		"0x55d398326f99059fF775485246999027B3197955": {
			"name": "Tether USD",
			"symbol": "USDT",
			"price": "0.9990482803642679714881629204455",
			"price_BNB": "0.002070818861619351647822391429314"
		},
		"0x58472700522605db6505ED6AEa866F57d9873c44": {
			"name": "SweetDOGE",
			"symbol": "SweetDOGE",
			"price": "0.00000001560623993747135227059285124135",
			"price_BNB": "0.0000000000331333051138828956493467970494"
		},
		"0x5cB25d67e574D730d98dcF66D11e00eEAC2408d9": {
			"name": "Cosmic",
			"symbol": "COS",
			"price": "0.00000693519925177075542906713140302",
			"price_BNB": "0.00000002230866381458921706011770361293"
		},
		"0x8143dA62F4A06311eEa8854E6C5965a167ECBDDD": {
			"name": "Mecha Doge",
			"symbol": "MECHADOGE",
			"price": "0.00000000766860780940352405758299766097",
			"price_BNB": "0.00000000002746508485854471285029690825111"
		},
		"0x28c96d92AB0C3B991aFFA45E3Af0111937f79ef0": {
			"name": "Green Akita Inu",
			"symbol": "GAKITA",
			"price": "0.0000000076261877621508569638852068674",
			"price_BNB": "0.0000000000245648224113381809429723141077"
		},
		"0x66b6468486FeADf0e1A56885b953C6778d1f9f20": {
			"name": "Blue Akita Inu",
			"symbol": "BLUEAKITA",
			"price": "0.00000000738291697954717052697355012666",
			"price_BNB": "0.00000000002436296083813180494683325438418"
		},
		"0xDc0A57625567115f890F424B451970AaDfE17Fc7": {
			"name": "TopDoge",
			"symbol": "TopDoge",
			"price": "0.0003101824532514482659215722004318",
			"price_BNB": "0.000001086277674752511126063970590088"
		},
		"0xa4bdB640038b9eb264d7bcC3bF47DBf12192f9f8": {
			"name": "Ryoshis DogeCoin",
			"symbol": "RYOSHIDOGE",
			"price": "0.000000005793788182651972744982144837194",
			"price_BNB": "0.00000000001392472559379150166336654082822"
		},
		"0x653f1e96130Bda6e2a82ECc640D6F6742D1C3119": {
			"name": "Waifu Labs",
			"symbol": "WaifuLabs",
			"price": "0.002433545579165104220686769265305",
			"price_BNB": "0.00000825904850615937854489282926039"
		},
		"0xa1231c9354733C04A433890A32146fF4EEbFe14C": {
			"name": "Polka DogeMoon",
			"symbol": "PDOGEMOON",
			"price": "0.00000000452160207465920162622682382286",
			"price_BNB": "0.00000000001056700187733632766523097795473"
		},
		"0xA399009E2993DA85A63bc63C093a5dD555291f6B": {
			"name": "Orica Doge",
			"symbol": "ORIDOGE",
			"price": "0.00000000447071546235245133023528716715",
			"price_BNB": "0.00000000001059830310942973152465449238974"
		},
		"0x18e00741463D23671286F84ad19b0452a17688C8": {
			"name": "Blue Doge Coin",
			"symbol": "BLUEDOGE",
			"price": "0.00000000439594524330161664408162955258",
			"price_BNB": "0.00000000001449757818939656511104816011091"
		},
		"0x30fA6EeE6314028CfCd129302774C14972697c1E": {
			"name": "Doge Monkey NFT",
			"symbol": "DOGEMONKEY",
			"price": "0.000000004205126334052006447574437011386",
			"price_BNB": "0.00000000001059122021028439796584750576685"
		},
		"0x703b859625b7eED45f93a558eE9E1eFb8D43bF94": {
			"name": "BiragonNFT",
			"symbol": "BIRA",
			"price": "0.000000004198075464382275959607613301255",
			"price_BNB": "0.00000000000826499896693931083119979686989"
		},
		"0x441477e0f1f61521dEce2462Af808A9c689c579C": {
			"name": "Shiba Monster",
			"symbol": "SHIBMON",
			"price": "0.000000004171731676921656327601661743295",
			"price_BNB": "0.00000000000830276653465480690342983914226"
		},
		"0x6D9B3ef219273EFD20219F662cf3F22Ff06cdb29": {
			"name": "Bina DogeMoon",
			"symbol": "BINADOGE",
			"price": "0.00000000406603980262782472551019562211",
			"price_BNB": "0.000000000008269310721457646217459061014"
		},
		"0xa0A5aFf1Faa5eE9bc491cC411afBe7A850B5EEa2": {
			"name": "MelodyMuse Token",
			"symbol": "MELOMUSE",
			"price": "0.000000004048603441158990834157956611997",
			"price_BNB": "0.00000000001110844647690621731457826599224"
		},
		"0xf45570923787Cca30e3b23f4256c82c67e7481b9": {
			"name": "Floki Doge Elon",
			"symbol": "FLOKIDOGE",
			"price": "0.00000000400633967556384074075630554139",
			"price_BNB": "0.00000000000819269979612307716615785683392"
		},
		"0x71BA32EB1f88c40652a1f8f14C0880f2CB86528F": {
			"name": "Corgi Shiba Musk",
			"symbol": "SHIBMUSK",
			"price": "0.00000000397957156800190635213706871429",
			"price_BNB": "0.00000000000821324879152027182902997970319"
		},
		"0xb58fCa9B6757B811bbc9eae6216CB26De1871f21": {
			"name": "Floki Akita NFT",
			"symbol": "FLAIKITA",
			"price": "0.0000000039148116651346436945031426367",
			"price_BNB": "0.00000000000807756912898158378710816223388"
		},
		"0x9d2738fa3d3D6621218cA1254bcbC8020A0B23Dd": {
			"name": "Alice Doge NFT",
			"symbol": "ALDOGE",
			"price": "0.00000000371607947718487166247655306867",
			"price_BNB": "0.00000000000825457029005283762891406920141"
		},
		"0x6d4D626db4fD6FeD0dc0D8ea8a1B6DbdFdAf9B30": {
			"name": "COBRA",
			"symbol": "COBRA",
			"price": "183.261533062408828583914815747",
			"price_BNB": "0.3015210724671763131789540994963"
		},
		"0x5c2321aEE02b8D380202DcAF368097FDEAe46F2A": {
			"name": "Rise Doge NFT",
			"symbol": "RDOGE",
			"price": "0.000000003468166000894924262429858510813",
			"price_BNB": "0.00000000000775141281761261754947709286686"
		},
		"0xeB109f8a504Db88a6a1C2141dC2F97DdE86823b8": {
			"name": "Polka Doge Token",
			"symbol": "PKDOGET",
			"price": "0.000000003411895841235370126563364222847",
			"price_BNB": "0.00000000000775860736414021699739008160452"
		},
		"0x43BC90EAC5877E84490df228420B85446705C35A": {
			"name": "Axie Infinity BSC",
			"symbol": "BAXS",
			"price": "0.003339405383114742033009433737155",
			"price_BNB": "0.00000873662884230644763040388087568"
		},
		"0x57B62cEb9FE91794B794AA62E494C7c12Ac0Ed9b": {
			"name": "StepHero",
			"symbol": "HERO",
			"price": "0.00000000312351236277752228722632110314",
			"price_BNB": "0.00000000000776098185490307674257920974521"
		},
		"0x47e10659F54fb21E7e3E0Ef387aB6872Fb46316B": {
			"name": "Floki",
			"symbol": "FLOKI",
			"price": "0.0000000241559215018953339917986931986",
			"price_BNB": "0.00000000005272382678352478757590594084324"
		},
		"0x89Cf8aC9c202AF7D19630Ec7Eb384F25985fA4d7": {
			"name": "Polytopia",
			"symbol": "POLY",
			"price": "0",
			"price_BNB": "0"
		},
		"0xB0ec269433F719564724d7AbEB83cBfDaa1F19Ea": {
			"name": "Alien BSC Token",
			"symbol": "AlienBSC",
			"price": "0.000000002947854440786278653644206184786",
			"price_BNB": "0.0000000000076120471159573964901891028655"
		},
		"0x8B6B65195C67C536b6FB331433e1488E83f32460": {
			"name": "Polka SuShi Moon",
			"symbol": "PKSUSHI",
			"price": "0.000000002905288693061283139231897263994",
			"price_BNB": "0.00000000000775100995983355345762906749477"
		},
		"0xb568A9BdC9f026244b9cebE2B331C144dA33E68F": {
			"name": "Nexo DogeCoin",
			"symbol": "NEXODOGE",
			"price": "0.00000000286170902078060664533908293913",
			"price_BNB": "0.00000000000778851485503812571486843655666"
		},
		"0x6F6d6CC9e6f46E525Aaf2D35a58f454c4A4a8856": {
			"name": "Yoshi Doge",
			"symbol": "YOSHIDOGE",
			"price": "0.0000000028054350686359666475852878862",
			"price_BNB": "0.00000000000868806175762873723108063759673"
		},
		"0xC7dC5f0015936134bcE40D1e9Be61b6e8E6c0325": {
			"name": "Dogecoin 3.0",
			"symbol": "DOGE3",
			"price": "0.000000002726302564526042833111821825317",
			"price_BNB": "0.00000000000776910698560957675948778582016"
		},
		"0xB2bC20374FaC51259a271b7769958CE09B2B2C07": {
			"name": "Super CateMoon",
			"symbol": "CATEMOON",
			"price": "0.00000000272471957028903331681494379902",
			"price_BNB": "0.00000000000781565991491151758117693158142"
		},
		"0x24BC8C85D29eE2C69991A692b87dd396B31796Ff": {
			"name": "NeoDOGE Coin",
			"symbol": "NeoDOGE",
			"price": "0.000000002722502332756260546695634690252",
			"price_BNB": "0.00000000000775658576010446496029282152853"
		},
		"0xD5Fe3d1392d359653D951a3999769B905E03F2EF": {
			"name": "Germ Network",
			"symbol": "GERM",
			"price": "0.00000000272190149572890345267907391853",
			"price_BNB": "0.00000000000760645054571557867335984060212"
		},
		"0x6C4d7d614534b7Ec4c3F9A4d4613403187037D97": {
			"name": "Ape Akita Token",
			"symbol": "AKITA",
			"price": "0.000000002634237245474209222535849154595",
			"price_BNB": "0.00000000000761364242553174836110707416416"
		},
		"0x182E5ba6Df3e6769Ff3C95dAaa618Ee2b205Cc6E": {
			"name": "Bobby Finke",
			"symbol": "FINKE",
			"price": "0.000000002506666641871178183314234159254",
			"price_BNB": "0.00000000000797271619851697627843361660685"
		},
		"0x91D15Ab3B5f47f157C7F543230e42bde3e38351A": {
			"name": "Dino Shiba Inu",
			"symbol": "DINOSHIB",
			"price": "0.00000000228039007678051000573392027795",
			"price_BNB": "0.00000000000785107425393801150226630153235"
		},
		"0xD9C535cf9ab165Ed8cA37fd345787Ac2BF819Bf9": {
			"name": "Truebit Chain",
			"symbol": "TRU-CHAIN",
			"price": "0.00000000227105578828370733629551680929",
			"price_BNB": "0.00000000000686916313196244967084077375479"
		},
		"0xB340D5C82a9de96a0b841d5653549dc2a104e3bc": {
			"name": "Crypto Emory",
			"symbol": "EMORY",
			"price": "0.00000000208504288005168010773937246391",
			"price_BNB": "0.00000000000675978444534469390993774065532"
		},
		"0x9bB7722756daBe175F3A70b4a7e569274636e38a": {
			"name": "Nikita Mars Inu",
			"symbol": "NIKITA",
			"price": "0.00000002082925941618505982625823639744",
			"price_BNB": "0.0000000000646638091520958852941803405746"
		},
		"0xa582CB9d6033aEC03A0eC959FF91AbD29DcADE56": {
			"name": "Baby Rune",
			"symbol": "BabyRUNE",
			"price": "0.000000002074054160195916949346759630806",
			"price_BNB": "0.00000000000664537551319939315708385659921"
		},
		"0xd6371ea36f68A90b757453763690c0895c4fCfe1": {
			"name": "Luna Doge",
			"symbol": "LUNADOGE",
			"price": "0.00000000207300770289748809496447684813",
			"price_BNB": "0.00000000000659050939350731008459947021869"
		},
		"0x46E267Bc62FFad6552AB613E21ad56fa1fc0F96F": {
			"name": "Crypto Cake BSC",
			"symbol": "CCAKEBSC",
			"price": "0.000000001736064173679900816399130685531",
			"price_BNB": "0.000000000005379870044890634289606050480326"
		},
		"0xE640ab4c80d289eED4434A96B0DEC8c2CF7906D8": {
			"name": "BinaCake BSC",
			"symbol": "BinaCAKEBSC",
			"price": "0.000000001724205746680521328511422982967",
			"price_BNB": "0.000000000005402170401432341194441607938247"
		},
		"0x5b41395467d484B377143408D7BBbEb14B1937BC": {
			"name": "Bella Kishu Doge",
			"symbol": "KISHUDOGE",
			"price": "0.000000001687925079109051296974069615954",
			"price_BNB": "0.00000000000541092689992389614488207268127"
		},
		"0xF0700d3EEaa5D5e33E9D6573ed5a237687500b19": {
			"name": "Blue Shiba Token",
			"symbol": "BLSHIB",
			"price": "0.000000001542505660175921852774026722336",
			"price_BNB": "0.0000000000052533968576366658841262625925"
		},
		"0x66651364c5435e73a962da59Ec259c249b56A3EB": {
			"name": "Corgi Rise",
			"symbol": "CORGIRISE",
			"price": "0.000000001432157161825063452752457547359",
			"price_BNB": "0.000000000005083325279186689562208977263965"
		},
		"0x3E1E7119a413E64571b0A4BB74Cca6AC8f916D05": {
			"name": "POLYMER",
			"symbol": "POLY",
			"price": "0.000700686515659896016139770625206",
			"price_BNB": "0.0000020466591301942898851788466907"
		},
		"0xaeBE7F94deB8e4C87D4822100445C02299061bC1": {
			"name": "StealthElonSafeCommunityMoon",
			"symbol": "StealthElonSafeCommunityMoon",
			"price": "0.000628928058333783607993046017735",
			"price_BNB": "0.000001000399479656243454944916016255"
		},
		"0x5CFBFF1BE0abE5C6942AF67C2f31348dF1C0f08c": {
			"name": "Anon Akita Inu",
			"symbol": "AnonAKITA",
			"price": "0.000000000983298085642698603005024288053",
			"price_BNB": "0.00000000000371939304122600510758630800026"
		},
		"0xd34ee06A6E3773059ca5B91107094f9f9d8A040e": {
			"name": "YAY.games",
			"symbol": "YAY",
			"price": "0.0000000009269155665063282583780387597615",
			"price_BNB": "0.000000000001881254063285412304413863021918"
		},
		"0x45CDcE2375f988DC1A358D5f763463daC8E435A9": {
			"name": "Poly Akita",
			"symbol": "POLYAKITA",
			"price": "0.000000000889652610191106113032525903998",
			"price_BNB": "0.000000000002845091550932015900959883787276"
		},
		"0xd83bd1715FF628a797E85B8AFc5AD51f2Ed00Cdd": {
			"name": "Shiba Rise Mars",
			"symbol": "SHIBRISE",
			"price": "0.00000000080104393407894050077959790819",
			"price_BNB": "0.00000000000284727108424675126260977573623"
		},
		"0x461192C88DAACBc5958bc52617Bdaecc1FdD80bc": {
			"name": "CoffeCat",
			"symbol": "COFC",
			"price": "0.0003378341966705715634020753174965",
			"price_BNB": "0.000001043164258976542385575790589407"
		},
		"0xfF79649cAdE0AF00a9577C3dB2B77E8BB18b1f99": {
			"name": "SargeCoin",
			"symbol": "SARGE",
			"price": "0.00000000064145949516297089982101526284",
			"price_BNB": "0.00000000000241591471090009399149850103544"
		},
		"0x3E83EaB545e5B41c7B9BB169Af7eba034F1c4f60": {
			"name": "Fuck Israel",
			"symbol": "Fuck Israel",
			"price": "0.00301818653028633171019753776171",
			"price_BNB": "0.000010024453825045950266"
		},
		"0xd3c84Fe12279cdf461a4121aCAAD1E3deFd13e4b": {
			"name": "DAKURA",
			"symbol": "DKR",
			"price": "0.00002983924159811075543695135177573",
			"price_BNB": "0.0000000957183331297925829690882631308"
		},
		"0x64e130d7c40fa8C1B65Fbdf49F22df2cB1a5555F": {
			"name": "Ever Akita Inu",
			"symbol": "EverAKITA",
			"price": "0.0000000005879474589565094851016138875475",
			"price_BNB": "0.000000000002253358301255513893618036397378"
		},
		"0x59F276a70490422dD555eeeb0C16F2493fF8eC00": {
			"name": "BestBuy.finance",
			"symbol": "BEST",
			"price": "0.000000000515153072648155655738261297458",
			"price_BNB": "0.000000000001375871548861660785637691607817"
		},
		"0x45e6ad1444e63a88535D32fDF0AAf26809EA935D": {
			"name": "Dogcake Finance",
			"symbol": "DCAKE",
			"price": "0.000000000482402989609409815063489872694",
			"price_BNB": "0.000000000000957439776423772528235523531459"
		},
		"0xBBd64F409ed768BEa0D85c7B52a54269b91f4041": {
			"name": "QueenDex Token",
			"symbol": "QUEENDEX",
			"price": "0.0000000004349339821824169689357611147525",
			"price_BNB": "0.00000000000091088333269642283105352989244"
		},
		"0xe56f999669DE1DD507dBDe6FaC1334f66F96670D": {
			"name": "NFT LaunchPad",
			"symbol": "NFTPAD",
			"price": "0.0000000004282669002600371886797667613417",
			"price_BNB": "0.0000000000008969883739449527830365990072686"
		},
		"0x41F22f8b5E6b8d685b391b1ceeDE36F793a96Ab6": {
			"name": "101Doge",
			"symbol": "101DOGE",
			"price": "0.0000000004211415167161671910008594167276",
			"price_BNB": "0.000000000000872651255383489232952114929047"
		},
		"0x2889a76bCa61B72B63Ce318E75c58fF4F658a647": {
			"name": "Doge Roblox NFT",
			"symbol": "DOGERBL",
			"price": "0.0000000004209342519185994787088511315608",
			"price_BNB": "0.000000000001053341921725587470670621377072"
		},
		"0x519Ac0E8cad648D667a74c34d0505E338bE9B008": {
			"name": "Ryoshis DogeCoin",
			"symbol": "RYOSHIDOGE",
			"price": "0.0000000004184446111340734741262100616053",
			"price_BNB": "0.000000000001027232785504122718984576746936"
		},
		"0xe04C2E062D5FBe184F929D7dd3E03D2BCdBf1e47": {
			"name": "Doge Guild Games",
			"symbol": "DOGEGG",
			"price": "0.0000000004183815006883294978839044078523",
			"price_BNB": "0.000000000001042855561255626357101265235028"
		},
		"0x9082A354b1E9cA61c93e843DF2Ef36Ea53a076E5": {
			"name": "DogeWin Token",
			"symbol": "DOGEWIN",
			"price": "0.0000000004091910691608374792806821297628",
			"price_BNB": "0.000000000001020737270459277003265353511085"
		},
		"0x36cD824F403C94CBF0Bc773EB16ec44bBe2388Ca": {
			"name": "Multigame Rewards Token",
			"symbol": "MULTI",
			"price": "0.0000000004012370184153455067187526957533",
			"price_BNB": "0.000000000000893616583198744327072629624632"
		},
		"0x7157489406d24a41466219B42f1B9cbd98943905": {
			"name": "Corgi Doge NFT",
			"symbol": "CORGIDOGE",
			"price": "0.00000000040018983569495969133166390292",
			"price_BNB": "0.000000000000821798922313646985973797376675"
		},
		"0x9bd547Cd5382aB3fAC467bfAdAf0fDbA67Ff73e0": {
			"name": "Doge MetaVerse 1Layer",
			"symbol": "METADOGE",
			"price": "0.0000000003963173270181666417697936041287",
			"price_BNB": "0.000000000000876449595513393720821653885771"
		},
		"0x0c5cE2b5793a1836A716B98863CFB63e6381505b": {
			"name": "Chocolate Cake",
			"symbol": "COLACAKE",
			"price": "0.0000000003894180695401725890972976612774",
			"price_BNB": "0.000000000000919108185582459047222461738711"
		},
		"0x41d514f9748c264782aaF041D80B6b68172BF234": {
			"name": "PolkaFantasy Token",
			"symbol": "POLKAFAN",
			"price": "0.0000000003827975437230295771127593802105",
			"price_BNB": "0.000000000000860502408089737372431825662204"
		},
		"0x5D153bE4e8aBd9Ac1d4ae01b0De338335B2Ed162": {
			"name": "Rangers PUBG Token",
			"symbol": "RPG",
			"price": "0.0000000003812733011733075574135953449766",
			"price_BNB": "0.000000000000792107508155967081235186764565"
		},
		"0xEdf01772b17316cad4Ab83E451606220E01da7a2": {
			"name": "DogeVault Token",
			"symbol": "DOGEVAULT",
			"price": "0.0000000003773967998137312453424385500873",
			"price_BNB": "0.000000000000880518819494500220272438114544"
		},
		"0x3b5D9E529BD8A25aFe6AF706AbB18ef5d3f5c87F": {
			"name": "BinaDoge",
			"symbol": "BINADOGE",
			"price": "0.0000000003756713252336216127374177315348",
			"price_BNB": "0.000000000001236027537573459102627113293102"
		},
		"0xcE56278568005185eafcd87118945272A0CF2558": {
			"name": "Rise Cake",
			"symbol": "RiseCAKE",
			"price": "0.0000000003736387884828538732739157082937",
			"price_BNB": "0.000000000001122243774896376360662051889438"
		},
		"0x7eC2edA8B38489fD6cdA7F9eFA826AC4c9CbF588": {
			"name": "DogeToys Token",
			"symbol": "DOGETOY",
			"price": "0.000000000373593805162331226438360491324",
			"price_BNB": "0.000000000000934396772071968347931191254454"
		},
		"0x6bBe1B6fF989b040856120A66D0AbCeF001E66fe": {
			"name": "DaikokutenSama 7LuckyGods",
			"symbol": "DKKS",
			"price": "0.0000000003670042614690201649203222878034",
			"price_BNB": "0.000000000000920638469849486223396384941179"
		},
		"0x7250EC91121bEe74CA0E3CF58Fbd61F3b72bD417": {
			"name": "DeRaceNFT",
			"symbol": "DERC",
			"price": "0.000000000366635213077782986283814226711",
			"price_BNB": "0.000000000000827555967249810547674195680068"
		},
		"0xb18947ca2724840EB181E17a3E79662d859a986b": {
			"name": "Volka Doge",
			"symbol": "VOLKADOGE",
			"price": "0.0000000003557586630777450636000073737854",
			"price_BNB": "0.000000000000885721223373171878520778379039"
		},
		"0x909601FB5D9e021F559f0AcDD90D2738a49F6cEB": {
			"name": "HalfPizza Cake",
			"symbol": "HALFCAKE",
			"price": "0.000000000347459525742161580920501301176",
			"price_BNB": "0.000000000000876963102423010853479623548053"
		},
		"0x486fFeef815A14804CcE346F5c60a4cC7369eD5e": {
			"name": "PORNHUB TOKEN",
			"symbol": "PORNHUB",
			"price": "0.00001632303639576508967266093344031",
			"price_BNB": "0.0000000527796716530337112921256343822"
		},
		"0x4aBdcC312A858Ae0a918ab461DC5802612Cc471A": {
			"name": "Decubate",
			"symbol": "DCB",
			"price": "0.01618502419187539018831120063897",
			"price_BNB": "0.00005000199751994997799910859072266"
		},
		"0xC666bB69965f934B86a57cCb4123e8530BA12BC3": {
			"name": "Virtual Rise",
			"symbol": "VRISE",
			"price": "0.0000000003204441401116460053208829168322",
			"price_BNB": "0.000000000001176308620353267907749191295042"
		},
		"0x04aF6bb8a1A4377BEb2419E927fbb66477836a68": {
			"name": "Povo.finance",
			"symbol": "POVO",
			"price": "0.0000000003137066741352969380859682537754",
			"price_BNB": "0.000000000000699462749322740734415796556698"
		},
		"0x4DDBdA62516E323573041bc31bf42e52B8832d57": {
			"name": "Energy Monster NFT",
			"symbol": "EGMON",
			"price": "0.000000000296326666053883126856396105127",
			"price_BNB": "0.000000000000775654682185999134306734525643"
		},
		"0x72F8BaaF423A9f40a4Dff933939eF74feF3d5372": {
			"name": "BinaShiba",
			"symbol": "BINASHIB",
			"price": "0.000000000289277353634668499092956249517",
			"price_BNB": "0.000000000000954773650753003200317297525244"
		},
		"0xc61C804F792dde3bD20a137731FF57Fe79592D2A": {
			"name": "AliceFarming Play2Earn",
			"symbol": "ALICEFARM",
			"price": "0.000000000282274323991447181894491323397",
			"price_BNB": "0.0000000000006696271233759695969354855729034"
		},
		"0xeD3e3aa53e952912EDb2DB1E698F0ffb42110685": {
			"name": "Mecha Shiba Inu",
			"symbol": "MECHASHIB",
			"price": "0.0000000002781603963185757285959774374762",
			"price_BNB": "0.000000000000989624580873898312517080857628"
		},
		"0xa4Ac5A958B02ae1F2D2F636a515e2d2081F621C9": {
			"name": "Mac Donald Token",
			"symbol": "MAC",
			"price": "0.0000000002748818386192725244510529717736",
			"price_BNB": "0.0000000000007752087391535769585929243291134"
		},
		"0x4c791555D9835fA0B54CaFCa39A03BAA4298B20B": {
			"name": "Evo Corgi Doge",
			"symbol": "EVODOGE",
			"price": "0.0000000002735477145683697187792060448745",
			"price_BNB": "0.00000000000094582393201641169507722372569"
		},
		"0x9F6182243C8fAA8fF08b19Ea54052DEc487924A9": {
			"name": "Kusa Coin",
			"symbol": "KUSA",
			"price": "0.0000000002732604577979103054231812837812",
			"price_BNB": "0.00000000000094949668920247474791271968899"
		},
		"0xe74a4Fa9bFAcfa87CB7cCceDd76ECdAa255dc97c": {
			"name": "Theta DOGE",
			"symbol": "DOGETHETA",
			"price": "0.0000000002728764452396502793871460215804",
			"price_BNB": "0.0000000000007784585530303067209063049816756"
		},
		"0xD2ecFdEe8fef5e258C05814049487Cdc197029F6": {
			"name": "Ares Corgi Doge",
			"symbol": "ARESCORGI",
			"price": "0.0000000002516388702485971119291310481575",
			"price_BNB": "0.0000000000008579942613214278465526722711"
		},
		"0x850791628aD65eE1E7D5dD60a724BEb7f4D710F6": {
			"name": "Seda Corgi Doge",
			"symbol": "SEDADOGE",
			"price": "0.0000000002465402236843637070328440230445",
			"price_BNB": "0.000000000000855358526096007781594220350281"
		},
		"0xB58d3472353EdffB1a2B17dfEd3d73C9CE0C5fD6": {
			"name": "Ultra Shit",
			"symbol": "UltraShit",
			"price": "0.00001201251534160667193940348480341",
			"price_BNB": "0.0000000395640888269906298144892521441"
		},
		"0x9C640BAc5896C3D4F8cb45792FCE466c820D107f": {
			"name": "Supper Cake",
			"symbol": "SUPPERCAKE",
			"price": "0.0000000002268268433556778282531870647808",
			"price_BNB": "0.000000000000727920086160873548480594420382"
		},
		"0xC1d3CFC0811a2a7a14549d9A86dDd579E0976436": {
			"name": "GunnyGaming NFT",
			"symbol": "GUNNY",
			"price": "0.0000000002240214022597966113869808465629",
			"price_BNB": "0.000000000000578927487848427434360798533923"
		},
		"0xD9129d4394F2FB512bd47aB40fE4cA7CA34b257F": {
			"name": "Easy Corgi Doge",
			"symbol": "EASYDOGE",
			"price": "0.0000000002174696711948920041577656307562",
			"price_BNB": "0.000000000000732276839701906976266430228567"
		},
		"0x35a15e9B1392a745FB7943debF1F9F7EF9599391": {
			"name": "FOHO.io",
			"symbol": "FOHO",
			"price": "0.0000000002159749502302828303276077071767",
			"price_BNB": "0.0000000000006805460226008004746722486970885"
		},
		"0x4de7F1f0d8F5b6795f1a7f016bea714D3f3d83FB": {
			"name": "BornBadBoys",
			"symbol": "BOY",
			"price": "0.0000000002135125990623820668322795875222",
			"price_BNB": "0.00000000000072376645521636807186488385051"
		},
		"0x6A208be9C67Ce857fE155b4f6F7f16B10554E014": {
			"name": "Baby Shepherd Doge",
			"symbol": "BSDOGE",
			"price": "0.0000000002119519788516322151103837334566",
			"price_BNB": "0.000000000000654447976881649021300266486565"
		},
		"0xFB0734e92EdB936883f78a02a169c3C3CA8c93f1": {
			"name": "Casini Token",
			"symbol": "CSN",
			"price": "0.0000000002065485362197516682889382255542",
			"price_BNB": "0.00000000000065770442817804923797187781132"
		},
		"0x9013eA902ae7d9Eb0508a3c037b415310d658fdF": {
			"name": "SteakBank Finance",
			"symbol": "SBF",
			"price": "0.0000000002053122097658028327658856033172",
			"price_BNB": "0.000000000000576093884986155615607290533795"
		},
		"0x3AC47281542aF6462638608e98c241b05CaC55CD": {
			"name": "Doge Inu Daddy",
			"symbol": "🐶DINU",
			"price": "0.0000000002032672619482707834002165472513",
			"price_BNB": "0.000000000000499025218616892402129862036538"
		},
		"0x23fCfbb268FdeC1eCD16B6887533b20003dd671f": {
			"name": "Money Hound Doge",
			"symbol": "MONEYDOGE",
			"price": "0.0000000001871558321313805122123449823315",
			"price_BNB": "0.000000000000464069213005347315385531657178"
		},
		"0xBc0A2eceA89Ecb5b05A207C34fD0B47a1cB6A3D7": {
			"name": "Venus Shiba Inu",
			"symbol": "VSHI",
			"price": "0.0000000001785236388776324773247425531353",
			"price_BNB": "0.000000000000593663034022104331894791278121"
		},
		"0x5Da0495B10B7789f0e0cd66427aE8b7382218000": {
			"name": "BattleSeal",
			"symbol": "SEAL",
			"price": "0.00000000083254353797005375200149208938",
			"price_BNB": "0.000000000001854346075312408478124043341908"
		},
		"0x5CFf7a56F863e8c99738D031355E81f7BE423255": {
			"name": "BabyPepe",
			"symbol": "BABYPEPE",
			"price": "0.0000830302970317752295376246011388",
			"price_BNB": "0.0000002234240283722372365391198709046"
		},
		"0xAAE4ee70b370b8490C2776Ba9A1BD0CC6e078E6A": {
			"name": "Jungle Doge Coin",
			"symbol": "JungleDoge",
			"price": "0.0000000001633376416455130200063539208284",
			"price_BNB": "0.000000000000385361679540257860669870137262"
		},
		"0x06dbD1eC39c008AB0FE2AE72917156C95176A6A9": {
			"name": "Jungle Doge Token",
			"symbol": "JungleDoge",
			"price": "0.0000000001599950124059133955073467234077",
			"price_BNB": "0.0000000000003861118445472732298614292294782"
		},
		"0xbCc8C619eBa684b167B0c657AC5187CA2D8eA1dc": {
			"name": "Elite Doge Token",
			"symbol": "EDOGE",
			"price": "0.0000000001587807954345172035031409446246",
			"price_BNB": "0.0000000000003877029970332531503530818351004"
		},
		"0xB535779CFD889A9612a7cbEE5a74Ff6A6BB7C03B": {
			"name": "SnowDoge",
			"symbol": "SNOWDOGE",
			"price": "0.0000000001580651658534457561497902131327",
			"price_BNB": "0.000000000000390053095714324562000467812575"
		},
		"0xD3459bAa98f85a0a2f5D9e2B8e47b41C2A6c2bb6": {
			"name": "Doge Infinity",
			"symbol": "DOGEINF",
			"price": "0.0000000001509464406336425392183250128765",
			"price_BNB": "0.0000000000003741088112606326831744298090966"
		},
		"0x6DaE5174c4092B0f013064a72c42b0CdAaE87723": {
			"name": "JunkYardDogs",
			"symbol": "JYDOGE",
			"price": "0.0000000001563083489039543234456147701669",
			"price_BNB": "0.0000000000003511754658144551878282366697407"
		},
		"0xac4BAA2279099567ec66ED906CA5e81348E4e794": {
			"name": "TrueDeck",
			"symbol": "TDP",
			"price": "0.000000000151784913394335883965927561198",
			"price_BNB": "0.0000000000003742678983426218462735028657782"
		},
		"0xB20CC4cBb7BF4F15E0aD6b92D0d2f20eaa48F4E9": {
			"name": "CryptoMarginswap",
			"symbol": "CMFI",
			"price": "0.0000000001500029181403655344846248754269",
			"price_BNB": "0.0000000000003019956928199592187331413960155"
		},
		"0x12471225F9C485e19bbdbCE71490d36eB13CA8c6": {
			"name": "Baby BNBSafeMars",
			"symbol": "BNBSafeMars",
			"price": "0.0000000001496630032149304320889416817712",
			"price_BNB": "0.000000000000499320680512250305816024039818"
		},
		"0x91da6C8d681a8f6D885315f197519E430474DBF4": {
			"name": "Folgory Token",
			"symbol": "FLG",
			"price": "0.0000000001495645566550226608490023100722",
			"price_BNB": "0.000000000000367677119638451102801883581202"
		},
		"0x3ce2E01b923aBDd9867b057aEE6D4f1f275E26dB": {
			"name": "Simba Doge",
			"symbol": "SDOGE",
			"price": "0.0000000001494649225336756293378307734188",
			"price_BNB": "0.000000000000474102674005916789501358855594"
		},
		"0x30352FBff08380b2fFA19795EBe50a1Edbd6641a": {
			"name": "Dog Shield",
			"symbol": "DOGSHI",
			"price": "0.0000000001477308104676485756594990034403",
			"price_BNB": "0.0000000000003251878172765709760137899469245"
		},
		"0xBff670131627e94dD4B6fD4eE7361CC767113970": {
			"name": "Crazy Defense Heroes",
			"symbol": "TOWER",
			"price": "0.0000000001475863908773456141715229923221",
			"price_BNB": "0.000000000000295845729794420939295040836139"
		},
		"0xb0e9F71CB259babB7c499eC14629653ea94E61f6": {
			"name": "RooCoin",
			"symbol": "ROO",
			"price": "0.0000000001471295350300554491042396646775",
			"price_BNB": "0.000000000000373188341390719494659040033218"
		},
		"0xe0750Bd282A5B787Ac1423FC9108fA7AEd95758c": {
			"name": "Doge Proof",
			"symbol": "DOGEPROOF",
			"price": "0.0000000001470785888077847524574089144961",
			"price_BNB": "0.000000000000329628664761045193931195955486"
		},
		"0x38bfceFdee88496A9A73e191ABC3ff19C4B0E1f5": {
			"name": "DOGE NFT",
			"symbol": "DOGENFT",
			"price": "0.0000000001449791946231071453535132751318",
			"price_BNB": "0.000000000000343919019910211426586468523989"
		},
		"0xc34498F42E8bb0c644fE447a0070C8b093962464": {
			"name": "KryptoBoomer Token",
			"symbol": "KBOOMER",
			"price": "0.000000000143729454664618639094766669084",
			"price_BNB": "0.0000000000002958437688160643120529518402184"
		},
		"0xf04B81EEDAa6D8b4314c58D31500EdD210519C69": {
			"name": "Doge Passport",
			"symbol": "DOGEPP",
			"price": "0.0000000001417782554496868458742203652623",
			"price_BNB": "0.0000000000003306758915375678250904178698878"
		},
		"0xe20E75D279b3f46948451C96D458bFe91043D4AD": {
			"name": "PolkaUniswap Token",
			"symbol": "PUNFI",
			"price": "0.000000000141178524225451340386317865838",
			"price_BNB": "0.000000000000283444853586574463530783750057"
		},
		"0xE8ff76Ac7691F4b6F4b2C468378E89Ac4515F169": {
			"name": "DOGEYES",
			"symbol": "DOGEYES",
			"price": "0.0000000001391934841827829720750581084074",
			"price_BNB": "0.0000000000003244492018641860793830917348252"
		},
		"0x188214A11e931c0Ab807f9ccfaEE72F9d57BD688": {
			"name": "LonelyDoge",
			"symbol": "LOD",
			"price": "0.0000000001386659106879104053429804826308",
			"price_BNB": "0.000000000000309360235403848530411756214763"
		},
		"0xa0D2d109F70aE7412e21406E00129bbb439f0Ddc": {
			"name": "Doge Success",
			"symbol": "DOGES",
			"price": "0.0000000001367272985618046775275310757046",
			"price_BNB": "0.000000000000321957329112059534189966711361"
		},
		"0xba4230A9d8e9674061B7bCC9830be1294b0c2D2B": {
			"name": "PiplCoin",
			"symbol": "PIPL",
			"price": "0.000000000134848793550701927181930102618",
			"price_BNB": "0.000000000000323065094593343646388640280008"
		},
		"0x5724f4b24f0D528F23AF31E7ff651fcB973e2b25": {
			"name": "Doge Bond 007",
			"symbol": "DBOND",
			"price": "0.0000000001335878750304363671620369142913",
			"price_BNB": "0.000000000000272670286678380815400826206014"
		},
		"0x60832780A29f0eF02AA06b7279aD4f9bd00039e8": {
			"name": "EXchange Doge",
			"symbol": "EXDOGE",
			"price": "0.0000000001331252811747217670440949066807",
			"price_BNB": "0.000000000000332827933069849625810302048526"
		},
		"0xC1669031f0Fa97CC75cf3eB5724b6AE9b78c5392": {
			"name": "CryptoCakeInu Token",
			"symbol": "CakeINU",
			"price": "0.0000000001330277020496701759975698659492",
			"price_BNB": "0.000000000000269163218052741263413259577945"
		},
		"0xaf3C53F02c8f9a9f19D9D38fF18fd65501372E70": {
			"name": "Super Jungle Doge",
			"symbol": "SuperDOGE",
			"price": "0.0000000001330201595482845545149775052436",
			"price_BNB": "0.000000000000334281799263546227611519724569"
		},
		"0x0A022A9379BAa8D2120e8A93A872de9A9f50e3F4": {
			"name": "SCC DIGforIT",
			"symbol": "SCC",
			"price": "0.00000000013282157529768751668222541698",
			"price_BNB": "0.000000000000279777450103310040734914426041"
		},
		"0xE827Bcbb4Fa3AA8636145950b8F8be56bcD1Aa5f": {
			"name": "OK Boomer Token",
			"symbol": "OKBOOMER",
			"price": "0.0000000001327271149648260647564873507523",
			"price_BNB": "0.000000000000295558865483285548888083541318"
		},
		"0x8eb755A4D9949257C01069d2cBe7d78840B0CC62": {
			"name": "MoonCast Token",
			"symbol": "MOONCAST",
			"price": "0.0000000001316759538563473900973916901947",
			"price_BNB": "0.0000000000002751977510174558823756454910045"
		},
		"0x72c8b8bb2109A67eaA93C4Bc07da87EC0007c3c7": {
			"name": "LTCDOGE",
			"symbol": "LTCDOGE",
			"price": "0.0000000001314727669835376831722882401632",
			"price_BNB": "0.0000000000003221429188501815541400805246466"
		},
		"0x5a79D877D4435f1a7c631345fc0Ecf8ED761f1BC": {
			"name": "SEED DOGE",
			"symbol": "SEEDOGE",
			"price": "0.0000000001302037241940881509387950256764",
			"price_BNB": "0.0000000000002759834367672350193130199373596"
		},
		"0xD6b0d0EEF1C05EEd156B3cF7329773194ec60AF2": {
			"name": "Hero Floki",
			"symbol": "HLOKI",
			"price": "0.000000000129360503988098963255927613143",
			"price_BNB": "0.0000000000003287115151221284108180511973805"
		},
		"0x3347f13968d78417930E9a6372Ee047c34AcCAA4": {
			"name": "specialDoge",
			"symbol": "SPEDOGE",
			"price": "0.0000000001285128134494699265828908189508",
			"price_BNB": "0.0000000000003198966597357626636840101919508"
		},
		"0xD52d57834aFeA39c92f1fD1038c925B7D3004E51": {
			"name": "Option Doge",
			"symbol": "OPTIONDOGE",
			"price": "0.0000000001243294617103568416710395472618",
			"price_BNB": "0.000000000000323552623139165777179286493257"
		},
		"0x28f7A5155B63e08BDC68992613Ee708E94CA4f07": {
			"name": "Marine Doge",
			"symbol": "MARDOGE",
			"price": "0.0000000001160622200480578695074829413182",
			"price_BNB": "0.0000000000002636423405973883499118065672474"
		},
		"0xBa6B349cbd21308648db4b34c5e753a733A81aFB": {
			"name": "Venus BNB",
			"symbol": "VBNB",
			"price": "0.0000000001155479980544649215400636751569",
			"price_BNB": "0.000000000000379274230082837623030367499706"
		},
		"0xcD5d3302F89d8602D01d894355e9AadFFb6DEd1d": {
			"name": "MOONLIGHT SONATA",
			"symbol": "MOONLIGHT",
			"price": "0.000005565605224451497555964152283117",
			"price_BNB": "0.000000018030685359150038404"
		},
		"0x9eDeA699a38CB68CB04A5A4f520650178ce983fA": {
			"name": "Rocket1H Token",
			"symbol": "ROCKET1H",
			"price": "0.0000000000899748019968227858117721854533",
			"price_BNB": "0.000000000000226535429357429834222240984001"
		},
		"0x91d17E20c67Bcfccabb389C0474bA159b34f1290": {
			"name": "TicTalk",
			"symbol": "TIC",
			"price": "0.0000000000839490379268162371204339618333",
			"price_BNB": "0.0000000000002381499364635202023202721753882"
		},
		"0xCE80ceAb33BA1f95f6F9927F67b8B66da7a8c26b": {
			"name": "Tratok Token",
			"symbol": "TRAT",
			"price": "0.0000000000833344969841523851776117860849",
			"price_BNB": "0.0000000000002098128319533229709073875017555"
		},
		"0x58220169Fc25Fa5Ce4a0a3B65DC46d2538c1540D": {
			"name": "OptionRoom Doge",
			"symbol": "ROOMDOGE",
			"price": "0.0000000000827020452415444276547484811979",
			"price_BNB": "0.0000000000002079272709751543745942136654311"
		},
		"0xF95d2C5E66ec87EA48D400Ca4CB42DEB8c43c3d0": {
			"name": "OptionRoom",
			"symbol": "ROOM",
			"price": "0.00000000008256580159271331734332944353195",
			"price_BNB": "0.0000000000002097280344297061297881277265062"
		},
		"0x889104053309C78362279D0Aa7673119919d8c1B": {
			"name": "Alpaca Baby Token",
			"symbol": "APACA",
			"price": "0.000000000082463242102224566093420241184",
			"price_BNB": "0.0000000000002135990270420423047354147024288"
		},
		"0xe56e4A608e458603EcF54C461cA49e60E867d5e3": {
			"name": "Razor Network",
			"symbol": "RAZOR",
			"price": "0.00000000008214893288464047476961860784675",
			"price_BNB": "0.000000000000247302677500570156350230257225"
		},
		"0x943aBb09997a2AFfEa3974B0dab8eaC1F866A1ca": {
			"name": "BBSCoin",
			"symbol": "BBS",
			"price": "0.0000000000813409005169112317675027082738",
			"price_BNB": "0.000000000000211360647758215038413549709255"
		},
		"0x806e918685266E1cE148a83808b7162B80D87cbE": {
			"name": "NFTXSwap",
			"symbol": "NFTX",
			"price": "0.0000000000810236711139354380588778466219",
			"price_BNB": "0.0000000000002352477332155695891255974459915"
		},
		"0x951fAac64e748E15997959B4595de0303BEA3Cbc": {
			"name": "Crust Network",
			"symbol": "CRU",
			"price": "0.0000000000799856295299191642794576838203",
			"price_BNB": "0.000000000000243025749609644876928298467955"
		},
		"0xA44F2689805C4D5742bddA74D2E7018185F147E9": {
			"name": "Koto Doge",
			"symbol": "KDOGE",
			"price": "0.0000000000791389123167319258279361481191",
			"price_BNB": "0.0000000000002151908980453068740533697823243"
		},
		"0x81ED36178bd7E96EbC3dCae076bA8857c54e4381": {
			"name": "Darwinia Network",
			"symbol": "RING",
			"price": "0.0000000000785702529125163527285877804037",
			"price_BNB": "0.000000000000235360808325940690779396667931"
		},
		"0x8DFCa562c6FCFe2aF90e68258314352aFC293EE0": {
			"name": "DogeCash",
			"symbol": "DOGECASH",
			"price": "0.0000000000774569476492704133167184332991",
			"price_BNB": "0.000000000000219917268435019207698277350083"
		},
		"0xaCccA1F4B1D7c57358C2f770D0c28443d7aEb0D9": {
			"name": "CyberDog",
			"symbol": "CYBERDOG",
			"price": "0.0000000000770824786829476236058051196247",
			"price_BNB": "0.0000000000001987870517047203145148052286204"
		},
		"0x3037C5CAA1684Df70DDbECDCaF7898167311ff10": {
			"name": "SwapRoom",
			"symbol": "ROOM",
			"price": "0.0000000000759627563605683335169002670058",
			"price_BNB": "0.000000000000190542625338923933031594902597"
		},
		"0x93f3ABB72B773920FE3452E6571A61c3D8E8F375": {
			"name": "Exchange Token",
			"symbol": "EXC",
			"price": "0.0000000000750973082629377262313728584621",
			"price_BNB": "0.0000000000001963255851204828906966151964734"
		},
		"0xF9C49A7C4ACd050064D31829d1C716A251890e5B": {
			"name": "Whale Token",
			"symbol": "WHALE",
			"price": "0.000000000073958163987364813544693505286",
			"price_BNB": "0.0000000000002306338594831213042173051531897"
		},
		"0xf1e999ECf1453a171438B4EF4c6Cc2E38c5d6cB7": {
			"name": "Hegic",
			"symbol": "HEGIC",
			"price": "0.0000000000725682197343813994058164046894",
			"price_BNB": "0.000000000000215531992668281251979567375823"
		},
		"0xcbE921fE4688862B84f1EDB2b1829F31E5908fd5": {
			"name": "Mobius Network ",
			"symbol": "MOBI",
			"price": "0.0000000000725130979676317880190138423026",
			"price_BNB": "0.0000000000002137929148551975814032298546086"
		},
		"0x2CAcC8Ab7E2F5602a7F3EAF3Beb4b6C9aE9111a1": {
			"name": "DogeWarrior Token",
			"symbol": "DOGEWARIOR",
			"price": "0.0000000000720747368935181904991250726366",
			"price_BNB": "0.0000000000002049373350905103316193012326879"
		},
		"0x950f8054FA19EdA8Ebd26AcFdf82F4b3f6d2450c": {
			"name": "Bosco Doge Coin",
			"symbol": "BCDOGE",
			"price": "0.000000000071400758541810359695113222421",
			"price_BNB": "0.000000000000230436199456047817925116007723"
		},
		"0xdC22f3c6A82939dF1A747ca323357b40E42e8767": {
			"name": "Binance Akita",
			"symbol": "BinanceAKITA",
			"price": "0.0000000000701911440350898212508357356332",
			"price_BNB": "0.000000000000230599373076137902909201095992"
		},
		"0x13e43Dc74903fF56B89D1199e68f2Ed0f7ddEFF4": {
			"name": "Raze Network Token",
			"symbol": "RAZE",
			"price": "0.0000000000700246549176344669399858265808",
			"price_BNB": "0.0000000000002183956805954008343998176577236"
		},
		"0x9dbF636Cc8A71C5c66e15aa0DaD15996928D15F7": {
			"name": "LocalCoinSwap Token",
			"symbol": "LCS",
			"price": "0.0000000000690227395635069301420291522655",
			"price_BNB": "0.0000000000002137927638108428985762499486546"
		},
		"0xef551b543502317b5E5d74632d6C98a57523B0D4": {
			"name": "LABS Group Swap",
			"symbol": "LABS",
			"price": "0.0000000000671631730242910538162688134971",
			"price_BNB": "0.0000000000001895893276220875846308227769308"
		},
		"0x5289c37299722C3d086924f7D24ABbC55f74F1e9": {
			"name": "Feathercoin Swap",
			"symbol": "FTC",
			"price": "0.0000000000662607055585052090208292954233",
			"price_BNB": "0.0000000000001875256777958437662009472015856"
		},
		"0xB888132d2946318E8Aa971A8CEf7eDfB18CF46aA": {
			"name": "Stafi",
			"symbol": "FIS",
			"price": "0.0000000000646117698219427445225450806575",
			"price_BNB": "0.000000000000191398254679750509735758627486"
		},
		"0x82AB77f59076562B1788c3f801B4a185fd3Eb588": {
			"name": "ZECBACK",
			"symbol": "ZECBACK",
			"price": "0.003213400956859790653100593975123",
			"price_BNB": "0.00001062289039804452525486909368272"
		},
		"0x88a5cC2c9e6805209FFF69ac2E52629E66BB9141": {
			"name": "ShibaBack",
			"symbol": "ShibaBack",
			"price": "0.0003092195060571692847708096242703",
			"price_BNB": "0.000001031603928601785782987825427923"
		},
		"0x99B100D770B041B1044Ef3e3cB7C145E4B51f143": {
			"name": "Doge Network",
			"symbol": "DOGENET",
			"price": "0.00000000005764235135470673926371104932186",
			"price_BNB": "0.0000000000001855058247426451823753543809001"
		},
		"0x7D7F552E4dC434B9636c1b7f78E33f713a62AF71": {
			"name": "Swap Shield Network",
			"symbol": "SWAPSHIELD",
			"price": "0.00000000005722688937844102929221044515218",
			"price_BNB": "0.0000000000001823847402762740620857597882518"
		},
		"0xE3C9F28A15719c365Be3530bCa86871e54E4Dcd0": {
			"name": "NFT Revolution Finance",
			"symbol": "RVF",
			"price": "0.00000000005662480028689734503832822861853",
			"price_BNB": "0.0000000000001718935595679521083715049337705"
		},
		"0xc021d00C8cCA744756b61d29482DEE69966767CD": {
			"name": "Mini Splintershards Token",
			"symbol": "MiniSPS",
			"price": "0.0000000000542569606953596579464858649522",
			"price_BNB": "0.0000000000001741493818824064252823701199207"
		},
		"0xF74f5158626d64aF338E4fB11E109a3ee1d67ba3": {
			"name": "Baby Pampther",
			"symbol": "BABYPAMPTHER",
			"price": "0.0000000000536173863511156675781956701709",
			"price_BNB": "0.0000000000001560911876483979667781249544516"
		},
		"0xA25Afd16a875CA49c17B7E3915D0293C45B47d73": {
			"name": "Shield Corgi",
			"symbol": "SHIELDCORGI",
			"price": "0.0000000000528241372433052247675894912502",
			"price_BNB": "0.0000000000001679655699849637157102634691628"
		},
		"0xf4Eae1CC1a3b7e6Fb5Dbe34B0E9Afacb19d354b8": {
			"name": "Cake KLend Token",
			"symbol": "CakeKLT",
			"price": "0.00000000005261436529553985554852258208366",
			"price_BNB": "0.0000000000001533827596098428771350896115572"
		},
		"0x9AFad676e86D6da8412d44e3bcbdA09E89DEBd7F": {
			"name": "Mini Pizza Swap",
			"symbol": "MiniPIZZA",
			"price": "0.00000000005231352621315432341400151887735",
			"price_BNB": "0.0000000000001508697918983434493451026391037"
		},
		"0x991102966942Da0Ad96D1b3260ccC2a9c9b2AFc0": {
			"name": "Chain DashSports",
			"symbol": "CDASS",
			"price": "0.00000000005101724214338593775100766428794",
			"price_BNB": "0.0000000000001618873138388191036085183235776"
		},
		"0x2a787b2044E066A46B172DCc396FeBdb4428b06a": {
			"name": "Swole Doge",
			"symbol": "SWOLEDOG",
			"price": "0.00000000005084879501282511441205394731313",
			"price_BNB": "0.0000000000001541933685570791520680046427139"
		},
		"0xe451376E825dd1836AfeAD71F72ACDf4Ed808495": {
			"name": "Spaceswap Cocktail",
			"symbol": "SsCOCKTAIL",
			"price": "0.0000000000507712909411683785413372191441",
			"price_BNB": "0.0000000000001535695939988257478989474320585"
		},
		"0xCc17Fd7E02D1BD9062e751b516FA2154F9aE217d": {
			"name": "Mini Dot Token",
			"symbol": "MiniDOT",
			"price": "0.0000000000503301904727175470149132041382",
			"price_BNB": "0.0000000000001590991618105691038386523019552"
		},
		"0xf174caBb24C35e5033c2c856af4C12d6fa457b84": {
			"name": "Cake Shake Token",
			"symbol": "CakeSHAKE",
			"price": "0.000000000049951717131954187331344107868",
			"price_BNB": "0.0000000000001521304273085212483633263391964"
		},
		"0x7636c8dCcF3cD2e01Af2bDD5c5E30a8f0F7fe6DE": {
			"name": "Baby Cow Boy",
			"symbol": "BCB",
			"price": "0.0000000000495777492729290047924182707385",
			"price_BNB": "0.0000000000001488348511995097885340917723514"
		},
		"0xae48E925d16632541C423e0030464435530364b8": {
			"name": "Cake Dot Token",
			"symbol": "CaKeDOT",
			"price": "0.0000000000493385404319642882010113475776",
			"price_BNB": "0.0000000000001589316567476086759386502180597"
		},
		"0x7a65e33f304aebC5eC07480d7e295FA2A10F00d3": {
			"name": "ShibaShield Finance",
			"symbol": "SHIBSHIELD",
			"price": "0.0000000000490861062315925141158603854299",
			"price_BNB": "0.000000000000156693418992076937140220187493"
		},
		"0x9C89EE6FAb0c0C0178F1F95aA86C39d3697DfcCB": {
			"name": "SPF Finance ",
			"symbol": "SPFFinance ",
			"price": "0.04658670332502644298279252399103",
			"price_BNB": "0.000131171849252182539372091552723"
		},
		"0x803D13dd1aA2c74f0E131C022Bff57fe3f1F5462": {
			"name": "Mini Fibo Token",
			"symbol": "MiniFIBO",
			"price": "0.00000000004621001321908168089250485033003",
			"price_BNB": "0.0000000000001477329783172306262195206619549"
		},
		"0x7Ca32819af24A9b2309A2E41Db365759d93F8FcF": {
			"name": "Mini APWars Token",
			"symbol": "MGOLD",
			"price": "0.0000000000455820195662852304159404835209",
			"price_BNB": "0.000000000000144340459633776948465532036022"
		},
		"0x5f0D41DCa92230Bf69f4d1A223cb6c6d2A4d8aa1": {
			"name": "Cake Arena Token",
			"symbol": "ARENA",
			"price": "0.00000000004516657441913664671947127944763",
			"price_BNB": "0.000000000000145295920366174362121551637487"
		},
		"0x49b972261d76B400Fe9042953b9425999A3ae3Bf": {
			"name": "DogeGive10x",
			"symbol": "DogeGive10x",
			"price": "0.0000219082723484959748382538292342",
			"price_BNB": "0.0000000765030592350010487491390260461"
		},
		"0x56593b29364da1C94ddF661b32A2CEfFFaDEA08D": {
			"name": "PolkaBunny",
			"symbol": "PKBUNNY",
			"price": "0.0000002162822983770259852777597564421",
			"price_BNB": "0.000000000475355448529276080655517919319"
		},
		"0x346Cfc17f8bEb98E0cb90d76DEfeeFB616E62221": {
			"name": "Teddly Finance",
			"symbol": "TED",
			"price": "0.0000000000425364413289651717205621735117",
			"price_BNB": "0.0000000000001466070826243318851084352489007"
		},
		"0x6E7408f929287560D8eAE56120eD57a258e9D9D8": {
			"name": "Mini PanCake Token",
			"symbol": "MMPCAKE",
			"price": "0.00000000003933014828433615611711851538645",
			"price_BNB": "0.0000000000001235446654449070163468580653904"
		},
		"0x1152B350B582198F43F48c45498eA1F728Cf0A87": {
			"name": "MiniDogeInu",
			"symbol": "MINIDOGEINU",
			"price": "0.0000000196282062036630306161743427921",
			"price_BNB": "0.00000000006232125345137730427651776515415"
		},
		"0x1ca4153B105AA30eab666E9ff883dee5A567bbED": {
			"name": "MiniDot",
			"symbol": "MDOT",
			"price": "0.00000000003907377147259712470416577275492",
			"price_BNB": "0.0000000000001248654465980476684891284574576"
		},
		"0xF19EaEC046C3ce43Ab7b90ae3b9580986Bc7F6C4": {
			"name": "SushiSwap Cake",
			"symbol": "SHUSHICAKE",
			"price": "0.0000000000390144737262983454600700479161",
			"price_BNB": "0.0000000000001218634376734948707513258935455"
		},
		"0xE5c9F5966F70255D7CC75d103F51e3A67832Bb2d": {
			"name": "krypto The SuperDog Coin",
			"symbol": "KRSDOG",
			"price": "0.0000000000386256992599640696215374306699",
			"price_BNB": "0.000000000000120230151395058864191973787209"
		},
		"0x3F93676e782D1098D615845c8E67f1D3209D1282": {
			"name": "MiniMatic",
			"symbol": "MINIMATIC",
			"price": "0.0000000000382063438869801163340809222675",
			"price_BNB": "0.000000000000125101962069650386727925210954"
		},
		"0x22122F3B64428956477701a396b207E7E119C503": {
			"name": "CAKITA",
			"symbol": "CAKITA",
			"price": "0.001859717516110236755740382732248",
			"price_BNB": "0.000003088674368260225971493304391447"
		},
		"0x21518063371E1893B9d18AD5Ed7D979607bFeed2": {
			"name": "Paw Patrol Dog Coin",
			"symbol": "PPDOG",
			"price": "0.00000000003448229555611879548597838315733",
			"price_BNB": "0.0000000000001155498227505150669188721290822"
		},
		"0x807f5C2C51eD9dba2df9985f854aCbbC3dF17677": {
			"name": "Big Dog Token",
			"symbol": "BIGDOG",
			"price": "0.00000000003360781154894143698903689255657",
			"price_BNB": "0.0000000000001111110821038339004615444462084"
		},
		"0x626A56C69982FfAD28948BfF91A203643Dec067A": {
			"name": "Citizen Finance Shiba",
			"symbol": "CIFISHIB",
			"price": "0.0000000000328148443212554700730658186435",
			"price_BNB": "0.00000000000011079679469327706097035378829095"
		},
		"0xc3ef914e2b5C3aF7CC426f16d57b5B06daf509E5": {
			"name": "PolkaMonster Game",
			"symbol": "PKMon",
			"price": "0.0000000001380121410151634513473011480261",
			"price_BNB": "0.0000000000003616516733824337580597445384968"
		},
		"0x0838DC6B6FE31d570e508700FdB1812988E5a139": {
			"name": "SafeDogeBabyMoonRisePumpInu",
			"symbol": "MEME",
			"price": "0.0001318117948330090627019967853556",
			"price_BNB": "0.0000004134553213410901900459035392156"
		},
		"0xD3C8Fa27B3470183beEbd69592D1BbfF520C2464": {
			"name": "Baby Bull",
			"symbol": "BabyBull",
			"price": "0.000927779313626288174055485326073",
			"price_BNB": "0.000002960798376315759791202278927315"
		},
		"0xDc1Bf87cc9693830A68531E050c8d2eC3D482F5D": {
			"name": "Raku Doge Coin",
			"symbol": "RakuDoge",
			"price": "0.00000000001985237156112673560041964403653",
			"price_BNB": "0.0000000000000460204662060553901063469484074"
		},
		"0xF828c5AD6d23d4cA33D6E25280d8AE36B91bf96D": {
			"name": "Foxi Equilibrium Network",
			"symbol": "FOXINET",
			"price": "0.0000000000175034139508294274817962698533",
			"price_BNB": "0.00000000000005428424257194695067636801332324"
		},
		"0x650917741d8505cA7059f00d06CDC0CAb87b75E8": {
			"name": "MINIUNCLE",
			"symbol": "MINIUNCLE",
			"price": "0.00000851589824825161913080364580381",
			"price_BNB": "0.0000000276144712459505371434634077054"
		},
		"0xeF83808c91683aA366356c2C85b8226B3E2A5B66": {
			"name": "Sunny Baby Token",
			"symbol": "SunnyBaby",
			"price": "0.0000000000746810218152742742650621124206",
			"price_BNB": "0.0000000000002184878944362163212153381580622"
		},
		"0xC846359C19DBf507F55A7F371f2AC44A79216Ca6": {
			"name": "Golden Shiba Inu",
			"symbol": "GSHIBA",
			"price": "0.0000000723584155193345983375467937611",
			"price_BNB": "0.000000000254824573158847168959051770363"
		},
		"0x3069a53bE1e3691f5cB957fcF0C307a2C81AaC6A": {
			"name": "DOGEBALL",
			"symbol": "BALL",
			"price": "0.000006574442007898426463560944643324",
			"price_BNB": "0.00000002170791054002766683042630317742"
		},
		"0x06443AaD8bF655e1EF8C7d7a036C421749Fe2cde": {
			"name": "SaveYourAss",
			"symbol": "SYA",
			"price": "0.000000637652522398308718866677644071",
			"price_BNB": "0.000000001004164742516421145139982756537"
		},
		"0x93C048036e327D5e7F4AFF226626eB2133310e47": {
			"name": "Planet Wolf",
			"symbol": "PLANETWOLF",
			"price": "0.000000006191471807575321424108583700404",
			"price_BNB": "0.00000000001978727272429800292327746756174"
		},
		"0x933E9b56Cee51e60247C48834b0F6521D7D9b17f": {
			"name": "Bake Bank",
			"symbol": "BakeBank",
			"price": "0.0000000000595077699571197020051588223277",
			"price_BNB": "0.0000000000001742923514261398342359449112071"
		},
		"0x60fC8f2D01D883cCad21A42d1aDD0cE2091a803b": {
			"name": "Starship Inu",
			"symbol": "STARINU",
			"price": "0.5274533885032814870596322929536",
			"price_BNB": "0.001774618515946549104982402840155"
		},
		"0xB4D80B8F4F8D532E3048FA2C343784eEfC91Fd36": {
			"name": "Binance Super Coin",
			"symbol": "SuperCoin",
			"price": "0.00000000005107321799675581614571383186412",
			"price_BNB": "0.0000000000001894966601296259950790050999372"
		},
		"0xaC513A6a2a58BaeA12b5C0F779E6C742f4730402": {
			"name": "Binance-Peg Pamptherdot Token",
			"symbol": "PAMPTHERDOT",
			"price": "0.0000000000098267303859403332205336311763",
			"price_BNB": "0.00000000000002873384073346699088504150261856"
		},
		"0xB1470fcDB0a4141Bb56784d52460174710bbeEe4": {
			"name": "Wonder Land",
			"symbol": "WONDERLAND",
			"price": "0.0000000004806839253147402764069855483176",
			"price_BNB": "0.000000000001444383936637097237367547022221"
		},
		"0xBc01F2a1942a3061fe9356483792DB39Be67EDB1": {
			"name": "CakeRefund",
			"symbol": "CAKEREFUND",
			"price": "0.00427928525520093869822592554264",
			"price_BNB": "0.0000158870160442642104275190205752"
		},
		"0xdA8A6d7372F5Ba3979C367813c097F465490A551": {
			"name": "XRP Monster",
			"symbol": "XRPMON",
			"price": "0.0000000417806012404195588388393120952",
			"price_BNB": "0.0000000000817692246922709707600774441491"
		},
		"0xbba3F3d65B21B9977AA61687Bd6674E13F5eAE8d": {
			"name": "ETH Monster",
			"symbol": "ETHMON",
			"price": "0.00000004075276944605604401014866070894",
			"price_BNB": "0.0000000000793985574389055995914844499572"
		},
		"0xa426122A0AA2D072F35949aF8a23830580394aD9": {
			"name": "PolkaMonster SafeMoon",
			"symbol": "PMSAFEMOON",
			"price": "0.0000000403718152902637654392347655748",
			"price_BNB": "0.0000000000819169588843343838123012622403"
		},
		"0xCa60A098FB7e2202fB1552af6C4E048c62Aa777a": {
			"name": "Bunny Monster",
			"symbol": "BUNNYMON",
			"price": "0.00000004019333022842413795868349849996",
			"price_BNB": "0.0000000000833414840483123356353913513904"
		},
		"0xE34484c2DD0b2b43bE1cED3268EE13AbCd7B972A": {
			"name": "Floki Shiba Monster",
			"symbol": "FSHIBMON",
			"price": "0.00000003997038297333178258644077025256",
			"price_BNB": "0.0000000000810976405139878093348358000008"
		},
		"0xAA0950923B05F7C581D0212c2874C93e13D478B0": {
			"name": "PolkaMonster Rabbit",
			"symbol": "PMRABBIT",
			"price": "0.00000003978429135758287714080089066405",
			"price_BNB": "0.00000000007983771416126343323736942288775"
		},
		"0xe7221f68881d264541Bc0BcB4A9bAF41cfd7ACA6": {
			"name": "Shiba Monster",
			"symbol": "SHIBMON",
			"price": "0.00000003958377477680928782791269188067",
			"price_BNB": "0.0000000000826519634909952274805184017054"
		},
		"0x024E5033816e3089E9D5606d83Eb0648Ae81f651": {
			"name": "PolkaCorgi",
			"symbol": "PKCORGI",
			"price": "0.00000003934655488895685957274592780943",
			"price_BNB": "0.0000000000817123208031283836740139090677"
		},
		"0x47eec2d8f4f5de839aE5a7A928D07DD9c7a67dC7": {
			"name": "PolkaCake",
			"symbol": "PKCAKE",
			"price": "0.0000000390096432750064194657071706881",
			"price_BNB": "0.0000000000813718745307219417571632582472"
		},
		"0x113724bF08Da4272FFCA1365323dB6e49F50E61b": {
			"name": "Moon EverDoge",
			"symbol": "MOONED",
			"price": "0.0000379761339458278475120314199384",
			"price_BNB": "0.0000001275050850425558795800811210123"
		},
		"0xBc73063C34D039ba56E9C22245ACdEf16329E4F7": {
			"name": "BNB Monster",
			"symbol": "BNBMON",
			"price": "0.00000003758222534825615665244883764587",
			"price_BNB": "0.0000000000781705905612443562110661801948"
		},
		"0x8e4e61a74eb7D87aFD6154e6b5342D56dD84400F": {
			"name": "PolkaMonster Bunny",
			"symbol": "PMBUNNY",
			"price": "0.00000003706499768338470127574795062592",
			"price_BNB": "0.0000000000822986053158870389732700104995"
		},
		"0xC9ec88A4175CE34D08C63C6d8661f45D59Fa2A3E": {
			"name": "SolarDoge",
			"symbol": "SolarDoge",
			"price": "0.00003677640546460309579722929340415",
			"price_BNB": "0.0000001181363887873546614130857985867"
		},
		"0xE7D502b72BE5379989Bb8f4463A2B8388294A3d6": {
			"name": "PolkaBunny",
			"symbol": "PKBUNNY",
			"price": "0.00000003578592783979844719593828144803",
			"price_BNB": "0.0000000000794810670332055178682168046979"
		},
		"0xC97701c9b90EC23715d8b7A24c55c63565eb1E9d": {
			"name": "Crazy Lizard Army",
			"symbol": "CLA",
			"price": "0.0000000003563729092102029117491167070664",
			"price_BNB": "0.000000000001034843423795188529663121223017"
		},
		"0x99E4F52C0af4E86bB9C19D07a42D0C9CB89a5f19": {
			"name": "EARNDOGE",
			"symbol": "EARNDOGE",
			"price": "0.0000355979200453967790361888145739",
			"price_BNB": "0.000000121145590753886954726519843061"
		},
		"0x444e937045c2C732E67801085D0cEB1867bb87eb": {
			"name": "Cyber Doge",
			"symbol": "CYBERDOGE",
			"price": "0.0000000003549055752440356184636708029116",
			"price_BNB": "0.000000000001115595285191411221183083337108"
		},
		"0x184196BBd55a746b1209034C1637dc972CEa7850": {
			"name": "SpaceSwap Memes",
			"symbol": "SWAPMEMES",
			"price": "0.0000000003547263193551640384410080695207",
			"price_BNB": "0.000000000001073355909990527070564673757194"
		},
		"0xd8D1c3964aD47FAB5616c0657Ae411da4FE146Bd": {
			"name": "ENZYME xFactor",
			"symbol": "ENZY",
			"price": "0.000000000354055510527741982366619420908",
			"price_BNB": "0.000000000001079828336040384852601469053752"
		},
		"0x02420fcD708eCF8990394fa6bA6Bd10d10A7F6aD": {
			"name": "Floppa",
			"symbol": "FLOPPA",
			"price": "0.00003426873187848780901971179874747",
			"price_BNB": "0.0000000942137915878417130511798531458"
		},
		"0xF62C9A666C61F0BEce2BAD59163c00CEc2759a29": {
			"name": "Bake Capital Holding",
			"symbol": "CAPITALBAKE",
			"price": "0.000000000338231866976271197275664174917",
			"price_BNB": "0.000000000001056489089900852690879061958677"
		},
		"0x574BfCc13cc043Ead4747631FB23079848231165": {
			"name": "BlackHoleSwap",
			"symbol": "SERCURE",
			"price": "0.00000000331427881716759997753880223084",
			"price_BNB": "0.00000000000997685550814821417480179144457"
		},
		"0x0A2875CedD8304a66E8f0d2bF41daE1544668C5A": {
			"name": "BSC Red Fox",
			"symbol": "BSCREDFOX",
			"price": "0.0000000003207500268544423490393869893434",
			"price_BNB": "0.000000000001002332962933424072987976211647"
		},
		"0xC4dACb39f28e53b911AC754Ba5C841b7a6BC7759": {
			"name": "YOYO Baby",
			"symbol": "YOYOEGG",
			"price": "0.0000000003111050428740645147569088871013",
			"price_BNB": "0.000000000000967590051341668452021734420187"
		},
		"0x9D7964B418Cc1D386A7682Ca88DF0434960F689C": {
			"name": "Splinter DogeCoin",
			"symbol": "SPSDOGE",
			"price": "0.0000000003107790911009035803317028729627",
			"price_BNB": "0.000000000001003016527550790693016871988068"
		},
		"0x93ee4E9E8b32aa1B5e670B5554FA37C68a2fa353": {
			"name": "Ero Finance",
			"symbol": "ERO",
			"price": "29.06746776406788182643230538109",
			"price_BNB": "0.101523459802823290793800101014"
		},
		"0xbDD721f39cC09CBe6b9392961Fcb21FDDd6A1965": {
			"name": "PUSSYHUNTER",
			"symbol": "PUSSYHUNTER",
			"price": "0.00000002857808838369392681164154256166",
			"price_BNB": "0.00000000005036019543286174433272386487354"
		},
		"0xb32D461653a86648C71dfeF8Ee082A26a86F58B2": {
			"name": "Lazy SheepDoge",
			"symbol": "LAZYDOGE",
			"price": "0.0000000002687965931118176607874086937214",
			"price_BNB": "0.000000000000894376362410222908828695615466"
		},
		"0xd9A138ECAf025F396Ef3A05d8fa278BFCf8bdA99": {
			"name": "The City Doge",
			"symbol": "CITYDOGE",
			"price": "0.0000000002602064520522381074261766923535",
			"price_BNB": "0.000000000000900630999057894378994549640573"
		},
		"0xEaD3157654b35CDBea754A49a22436F5FEA49bEC": {
			"name": "Pepe Doge",
			"symbol": "PPDOGE",
			"price": "0.000000000244230193418188045353284728342",
			"price_BNB": "0.000000000000815818484173276571590656849232"
		},
		"0x7b4981E07bf6A1A4897C60FdC0D69Cb73f9F9F01": {
			"name": "VirtualRise Token",
			"symbol": "VRISE",
			"price": "0.0000000002366724642199277768253654576818",
			"price_BNB": "0.0000000000008049466193610818643704692601596"
		},
		"0x69a7C8cACB32773900D3B4cF6501C54B12d51b0E": {
			"name": "Lucky Pandas",
			"symbol": "LUCKYDAS",
			"price": "0.0000000002160335421658962774307190115973",
			"price_BNB": "0.000000000000725323702138687433760276301475"
		},
		"0xabaaEE31c954736d40B8dEf498c311606394482D": {
			"name": "Animeta NFT",
			"symbol": "ANIMETA",
			"price": "0.0000000002079250004245315632256007679224",
			"price_BNB": "0.000000000000623163490242161461311983121907"
		},
		"0x811795710cf9a29cDB821f76fB1400C554Db8cc7": {
			"name": "SabatonSAFU",
			"symbol": "SABATON",
			"price": "0.00000001947453868208697980472147608512",
			"price_BNB": "0.0000000000310658004376166325302312919774"
		},
		"0x760eD470347738C4918A3bF83da432C02366306b": {
			"name": "BABY TRX",
			"symbol": "BTRX",
			"price": "0.00001761916302937366665256956410285",
			"price_BNB": "0.00000005301226721857757869105402892753"
		},
		"0xb4e3E1deFB07BC516b1482b6917142e153c34aE0": {
			"name": "Candy Baby Doge",
			"symbol": "CBD",
			"price": "0.0000160101283220485323997355761088",
			"price_BNB": "0.0000000534637683918540922005782036586"
		},
		"0xd864C65b0175Dd8cD75C253B530e673356Cc773F": {
			"name": "Babe Doge Mars",
			"symbol": "BabeDOGE",
			"price": "0.0000000001561561925599760699833664480614",
			"price_BNB": "0.0000000000004970380300311342534588666501004"
		},
		"0x010E5D4364C346d95f5E117800133674B37B145D": {
			"name": "BigBabyDoge",
			"symbol": "BigBabyDoge",
			"price": "0.00001498782190717720042072007521714",
			"price_BNB": "0.00000005108572140670961986074060422715"
		},
		"0x4c0a643140E3a1Cc82305a568D2661B97e5e65b8": {
			"name": "https://www.smoke-high.com",
			"symbol": "SmokeHigh",
			"price": "0.00000000004164794760728914875205675652524",
			"price_BNB": "0.0000000000001354825119465991993655191437028"
		},
		"0xE1d0f12f50e5801456e0Bd78383Bc2370d90254D": {
			"name": "Cybrrrtruck",
			"symbol": "Cybrrrtruck",
			"price": "0.00001232962630065971512820002393961",
			"price_BNB": "0.00000004036903359920954926535667930523"
		},
		"0x970c577D8bb1fbE627887A19231B7908dF74edAc": {
			"name": "MetaBunny Token",
			"symbol": "METABUNNY",
			"price": "0.0000000001195116253744702303371255519644",
			"price_BNB": "0.0000000000003935782798267949897888494152972"
		},
		"0x72291976C3427028c83167611d1528eF4aE21C1B": {
			"name": "SHIBA Rising",
			"symbol": "SHIBRISE",
			"price": "0.0000000001160883559795505516872274666799",
			"price_BNB": "0.0000000000003804118795366159538161198843757"
		},
		"0x18eF448297d9518737D6e371517c0aDE67152A9B": {
			"name": "LAMAFINANCE",
			"symbol": "LMF",
			"price": "0.00000000001140715023288347403546091133584",
			"price_BNB": "0.0000000000000404914464857746094662444479376"
		},
		"0x72F570208be2590888B5435204961cFF52218180": {
			"name": "Bondly Token",
			"symbol": "BONDLY",
			"price": "0.0000108786612926452610027776242823",
			"price_BNB": "0.0000000351357941445459798557095548353"
		},
		"0x66E2db6aB335Fa53FE06a551bA0aBd71C95F75ec": {
			"name": "ALPHABET INC",
			"symbol": "ALPHABETINC",
			"price": "0.000000000108305014576885154547443190485",
			"price_BNB": "0.0000000000003065916542300745368191489190348"
		},
		"0xFD08F5017Bd18fc6280b172F77A8236f0993faa9": {
			"name": "Cybrrrtruck",
			"symbol": "Cybrrrtruck",
			"price": "0.0000097404035064925214357755668458",
			"price_BNB": "0.0000000318283226172684100094288324053"
		},
		"0xc1F11B22261a156b0D04fef99b8B4F5Ee639A217": {
			"name": "Baby Cattle Doge",
			"symbol": "BABYCATTLE",
			"price": "0.0000000001639739445248854716366940618457",
			"price_BNB": "0.000000000000495054239753665070124450700607"
		},
		"0xB4B09b3AD180C8432b99b90DEA13Fb52784Ae893": {
			"name": "Extra Ethereum Token",
			"symbol": "xETH",
			"price": "0.000000000080422220110254339306754485101",
			"price_BNB": "0.0000000000002305680538115565105343920271203"
		},
		"0xA3Fd97D990003f32c3FFd860F919333Ee3ac2385": {
			"name": "CryptoDoge Skill",
			"symbol": "DOGESKILL",
			"price": "0.0000000000798464585242991371331324291943",
			"price_BNB": "0.000000000000237223351821789631642001276028"
		},
		"0x15453B6c42e563f7293B9Ef0Bb54fcA1303E33Ce": {
			"name": "DeFi Scale Network",
			"symbol": "DEFISCALE",
			"price": "0.000000000737537448133047056446132901912",
			"price_BNB": "0.0000000000022387388365159526969597276817"
		},
		"0x65f093536AbAB32137f02a354380A358756889e7": {
			"name": "XCross Chain",
			"symbol": "XCHAIN",
			"price": "0.0000000000730457608484387138376944462259",
			"price_BNB": "0.0000000000002240775995635280913363328861956"
		},
		"0xCE6c3FD4A858b5E08bAC94BF20A11A6CC7eC3201": {
			"name": "Baby CryptoZoon",
			"symbol": "BABYZOON",
			"price": "0.000000000068392537609151540880454499148",
			"price_BNB": "0.0000000000002193522815019678521968742509867"
		},
		"0x279B777B703270bc6cdbe659d6fb469fF800C491": {
			"name": "ToastedDoge",
			"symbol": "TSD",
			"price": "0.00000683819879661363453276640335569",
			"price_BNB": "0.000000022835587340856382836171139378"
		},
		"0xB13e98F9d13c6c8ED782C76750C194C26250Ca96": {
			"name": "Buny Fury Festival",
			"symbol": "FURRY",
			"price": "0.0000000000665266057536240463212897605615",
			"price_BNB": "0.000000000000201487782687576929229688145666"
		},
		"0xCa3fC14e0A7443569a053105aF27E3aD2b3A3AC9": {
			"name": "robotarena.io",
			"symbol": "🤖ARRO",
			"price": "0.001423811280654511824783615698622",
			"price_BNB": "0.000003529728929131573232197498068455"
		},
		"0xfd03b8aE6C598c060Be092554e42B9376B505971": {
			"name": "YOLO DOGGY Coin",
			"symbol": "YOLODOGGY",
			"price": "0.00000000006523305716313160006000043423745",
			"price_BNB": "0.0000000000002028029640401605495335912792042"
		},
		"0x6Ff753f58797c7c6d6e61cBD504B3832EfEA22Fa": {
			"name": "Captain SHIBA",
			"symbol": "CAPTAINSHIB",
			"price": "0.0000000000624299844211793853889361516237",
			"price_BNB": "0.0000000000002058292009400711721320213232567"
		},
		"0x9f52fF779446a5d52457CE4f88c51E97a025A01c": {
			"name": "Bobby Finke Fan",
			"symbol": "BOBBYFINKE",
			"price": "0.0000000000607359616822053009553696344641",
			"price_BNB": "0.0000000000001935834209321244785726050749626"
		},
		"0x60C2D7a35Ee313AeF16451a6B4dd57880D3F389d": {
			"name": "Baby Kutta Inu",
			"symbol": "BABYKUTTA",
			"price": "0.0000000001103600454706669420055777647067",
			"price_BNB": "0.0000000000003321302169764186880597242246426"
		},
		"0x511a179Cb872B9b77e3FEE392BBac0095aa8E1C8": {
			"name": "BabyDucky Token",
			"symbol": "DUCKY",
			"price": "0.00000000005362683202669771282890546912407",
			"price_BNB": "0.0000000000001665842633294823846636206578083"
		},
		"0xc7514e357C3Feb59B6a72222C641625cb027193f": {
			"name": "Nerd Coin",
			"symbol": "NERD",
			"price": "0.0000000530266952296440789566424114485",
			"price_BNB": "0.00000000008731400245657470861006417091165"
		},
		"0xb7CBedC796B4a0f071462da518F5Bf68cB97285a": {
			"name": "Bunny Hunter",
			"symbol": "HUNTER",
			"price": "0.0000000000523081014000927670465447547342",
			"price_BNB": "0.000000000000168538592596668296935819276542"
		},
		"0x71650DDaCD0285Ab7e69F66083675dDC5834d542": {
			"name": "JapaneseChin Inu",
			"symbol": "JPCHIN",
			"price": "0.00000000004763816963991229781793145892054",
			"price_BNB": "0.000000000000151284941427778667566177009954"
		},
		"0x37Ec3a6E2E226805C2bd27485F15cd62a1E39173": {
			"name": "t.me/CaptainUnderpantsBSC",
			"symbol": "CUP",
			"price": "0.000000000004669463979903682934404577626316",
			"price_BNB": "0.00000000000001130777017919882626741832103649"
		},
		"0xFfbA998357872E146bcd1345c6E24C5F6472551b": {
			"name": "WorldCup Airdrop Token",
			"symbol": "WCUP",
			"price": "0.00000000004648920261367714519486245425965",
			"price_BNB": "0.000000000000143283613544013435103960913833"
		},
		"0xF8FEdC38c68fE3b813c9C2f3117959AE2D8BdBCB": {
			"name": "Lazy Cats Token",
			"symbol": "LAZYCAT",
			"price": "0.00000000004538429165755301947925296863148",
			"price_BNB": "0.0000000000001600155309211633820705677544709"
		},
		"0x8aaFb1F03fA4BBEf66fb83E376474F1bf3f9e790": {
			"name": "YooShiGame",
			"symbol": "YooShiGame",
			"price": "0.00004441756019936708144680566753765",
			"price_BNB": "0.0000001461633663796991581856482505398"
		},
		"0x330130EE5566c2FF9dE40F612F3Cc388A535aA20": {
			"name": "Puppy DOGE",
			"symbol": "PUPPYDOGE",
			"price": "0.00000000004406603905202647833780030403846",
			"price_BNB": "0.0000000000001459399548401105123031571221882"
		},
		"0xF4540cF57bf80b42108478557446E180231F302e": {
			"name": "Mini Terrier Inu",
			"symbol": "TERI",
			"price": "0.00000000004367584766048145961603846735787",
			"price_BNB": "0.0000000000001370125280322108655734968387509"
		},
		"0xD15db375ca260a1593999072B8FECd850f51fe27": {
			"name": "SpaceSwap Cola",
			"symbol": "COLA",
			"price": "0.000000000434860782227975523544049525839",
			"price_BNB": "0.000000000001325289969504793349985551285575"
		},
		"0xa297e15802Fd5D3163813B24fD0C419e73a87C7A": {
			"name": "DuckDuck Token",
			"symbol": "DUCK",
			"price": "0.00000000004311501657165943280698151505216",
			"price_BNB": "0.0000000000001330673087139821217754750332716"
		},
		"0xd1d3bbbE080cC86Df671BFe2d3Fe8757373D6537": {
			"name": "TosaKen Warrior Inu",
			"symbol": "TOSA",
			"price": "0.0000000000430482314164103929262464929192",
			"price_BNB": "0.0000000000001358618480399459771911563204801"
		},
		"0xDc102aAd412c547B3cf2F0f3C5BF62e36feA7c82": {
			"name": "Safe Doge Space",
			"symbol": "SAFESPACEDDOGE",
			"price": "0.00000000004028349777512338074849978160576",
			"price_BNB": "0.0000000000001189519781455631142083697363942"
		},
		"0x9637B0778581ba4505A8493D7FfA1fDa4Af2DE6F": {
			"name": "Retro Doge Coin",
			"symbol": "RETRODOGE",
			"price": "0.0000000003910170274002329978675325800975",
			"price_BNB": "0.000000000001227595381658943971675012320795"
		},
		"0x01f1bacA39B2E366DaE708Fddb3E670d7d110184": {
			"name": "F98 DEFI",
			"symbol": "F98",
			"price": "0.00003725435312882008278594441451214",
			"price_BNB": "0.0000001251941671926018245150023068171"
		},
		"0x1ed296e2869f0d2C25cdDf4eC20599C862Bed4bd": {
			"name": "Nigga",
			"symbol": "NIGGA",
			"price": "0.000372185491222033867980161674306",
			"price_BNB": "0.000001059973767957085839554474197837"
		},
		"0x6EE5a44bA1A8A9A208Ca5Da0e629f721e736EBDF": {
			"name": "F98 Token",
			"symbol": "F98",
			"price": "0.00003717977562504387797852154062375",
			"price_BNB": "0.0000001247981625653135827544665758255"
		},
		"0xea0B489b7AeE88C358d3ed9232FeF789C8154003": {
			"name": "Flash Oracle",
			"symbol": "FORCL",
			"price": "0.00000000370969677284616003514138893547",
			"price_BNB": "0.00000000001048907554930563633820445297598"
		},
		"0x3f121f685883275d22E057736B760a38A9e4A945": {
			"name": "CeloBNB",
			"symbol": "CBNB",
			"price": "0.000000003699995606583223120624860942074",
			"price_BNB": "0.00000000000806834425644162361187244173552"
		},
		"0x8B55ED1107aEc6D07B71b2A14647d8610A2EFEb8": {
			"name": "SpaceSwap BankETH",
			"symbol": "SWAPBANKE",
			"price": "0.0000000003630306695869686823514546838222",
			"price_BNB": "0.000000000001100945425917252399230271048325"
		},
		"0x9fc1401C9245faF08416c3682267bB7866c031bD": {
			"name": "@NemoCommunity",
			"symbol": "NEMO",
			"price": "0.00000000000000000003627839896665862270073378859016",
			"price_BNB": "0.00000000000000000000006"
		},
		"0x69FE1EC74D3d4A94a62520d31CFbDb6bfA4A7FB5": {
			"name": "XRP BSC",
			"symbol": "XRPBSC",
			"price": "0.000000003587774560095357935783683678374",
			"price_BNB": "0.00000000001111837855001434073497973223806"
		},
		"0xb60F5174c4aEfF1f08fa2E653b7aD918A5eE744b": {
			"name": "Goldy.Finance",
			"symbol": "GOLDY",
			"price": "0.00000000357478018076381657593254641838",
			"price_BNB": "0.00000000001006497585931243050401009436413"
		},
		"0xF474075274A239Af5D675212B2a5D1979F029525": {
			"name": "Capital Cake",
			"symbol": "CAKECAPITAL",
			"price": "0.0000000003510155017851498226505902273218",
			"price_BNB": "0.000000000001056407845292504765918880204932"
		},
		"0x142ED88EE6D50a99Ac5FdB771D003a20Bc206857": {
			"name": "Baby ElonTech",
			"symbol": "BabyETCH",
			"price": "0.000000003495673619127627290515854044942",
			"price_BNB": "0.00000000000812802255997339607599172095652"
		},
		"0x5373e4d0BA236d1acE059729d9DcA32e6900EBda": {
			"name": "Dior Dogecoin",
			"symbol": "DIORDOGE",
			"price": "0.00000000348636753594097820486680628628",
			"price_BNB": "0.00000000000761661308438488683512689641789"
		},
		"0x7819768cA0fa25E204d0b9f1B721859B80Bb5648": {
			"name": "NanoCake Token",
			"symbol": "nCAKE",
			"price": "0.00000000337839253275917604193557744538",
			"price_BNB": "0.00000000001015012134849442995253137803979"
		},
		"0x065d750e46AFfAC336DC8aA51AB3cEEc5eD35066": {
			"name": "LilDogeX",
			"symbol": "LDX",
			"price": "0.0003336062176648087733721759127212",
			"price_BNB": "0.000001102414826929689460171396751005"
		},
		"0xa428F9F503D10b66BC9559dbf4B3cf8c3a1E197C": {
			"name": "PolyCenter.io",
			"symbol": "POLYC",
			"price": "0.00003319582572774134143542659060747",
			"price_BNB": "0.0000001055269198450951595959329229496"
		},
		"0xEd790fB587C7F0efA0E60f27cf1d3b423d480f72": {
			"name": "BaByForeverDoge",
			"symbol": "BFDOGE",
			"price": "0.00003315899979205303481331290863018",
			"price_BNB": "0.0000001092526376937521332194387330745"
		},
		"0xc0D75911B1Ce967c795f584A4039d9Fc2EE47f78": {
			"name": "UNIPANCAKE",
			"symbol": "UNIPANCAKE",
			"price": "3359.667975246941706523978423695",
			"price_BNB": "10.20815857040816325688338122904"
		},
		"0x6f3dECb1AcD04386ec95C06796A45Da49837835F": {
			"name": "Uncle Doge",
			"symbol": "UncleDoge",
			"price": "0.0000000000329083722143824983442346774932",
			"price_BNB": "0.0000000000001045034689294658703554038515466"
		},
		"0x8C0d3a6C477BF7A784Ab7e2CD1d149d3fC46c37f": {
			"name": "Waves Doge",
			"symbol": "WDOGE",
			"price": "0.000000003259874896884577360140599406453",
			"price_BNB": "0.00000000000990022607779268868633389920135"
		},
		"0x653C29af85fd5d5817cF01EeaEc1f26ce4a28efB": {
			"name": "F98 Token",
			"symbol": "F98",
			"price": "0.0000325170246856389956765204933083",
			"price_BNB": "0.0000001088569374118579359749991206982"
		},
		"0x70475D1031a89778dc50202723912832EE2B2FE5": {
			"name": "BSC x MegaTron",
			"symbol": "BSCTRON",
			"price": "0.000000000322366197644953472516570650892",
			"price_BNB": "0.000000000000952038979362921270342946987832"
		},
		"0xB685996f08677399E1C5B619aBD4bF16E713012F": {
			"name": "The Shiba Town",
			"symbol": "SHIBTOWN",
			"price": "0.00000000032102685298337692371621959415",
			"price_BNB": "0.000000000001076956324595373968763401186497"
		},
		"0xe676C695dB2FAE12C2106Bc25c5638C1784Fcf17": {
			"name": "Celo Dogecoin",
			"symbol": "CELODOGE",
			"price": "0.000000003198988302485719102027334718268",
			"price_BNB": "0.00000000000784132011110571169461424831716"
		},
		"0xB9a644590a37ee10bE77788866756a588472AfE8": {
			"name": "Doge Coin",
			"symbol": "DOGE",
			"price": "0.0000317005299882807993635040318893",
			"price_BNB": "0.0000001046656742919363103460848697755"
		},
		"0xd916B4Fe454CFFE15b2521d911DBcE17383be01A": {
			"name": "Super Corgi Doge",
			"symbol": "SuperCORGI",
			"price": "0.000000003121898525633126521671078947687",
			"price_BNB": "0.0000000000080519465185191245048924498239"
		},
		"0xf5f4a6b3167DfF0Fdd1529d433dDF99424f7670B": {
			"name": "Bezos Dogecoin",
			"symbol": "BEZOSDOGE",
			"price": "0.000000003120806435782421960877431881357",
			"price_BNB": "0.00000000000774170302190867200849491639575"
		},
		"0xE436841046F58f33190AD2D27deCDbA96B17d6E6": {
			"name": "Titan Dogecoin",
			"symbol": "TITANDOGE",
			"price": "0.000000003102526999015639864667629675365",
			"price_BNB": "0.00000000000768414419471637052561672629087"
		},
		"0xdfE9c8211c5d7781bD02F82E35e902CE7199c460": {
			"name": "NFT Polkamon",
			"symbol": "NFTPMON",
			"price": "0.000000003074280513655071276424692987788",
			"price_BNB": "0.00000000000804202295666109452794767594063"
		},
		"0x75b85D379eF369c0C7ffA3a7B6C8e354912e4756": {
			"name": "White Dogecoin",
			"symbol": "WHITEDOGE",
			"price": "0.00000000306138481708331048691128699699",
			"price_BNB": "0.00000000000765668921333055886365687501981"
		},
		"0xfd7686952dBb01A13bb45763d535E0954B434aC9": {
			"name": "XRPBABY",
			"symbol": "XRPBABY",
			"price": "0.000000003016671966649785198380749018817",
			"price_BNB": "0.00000000000874690417815490295350209031167"
		},
		"0x914E15A28eA147C7708d66E4ad289FDAd736f82a": {
			"name": "Digi Dogecoin",
			"symbol": "DIGIDOGE",
			"price": "0.00000000297552031240429488244785117538",
			"price_BNB": "0.00000000000731940804806321301551205941247"
		},
		"0xBeC77c7821A7a0B27f4ae405ea6d95b43732EB98": {
			"name": "Captain America BULLDOGE",
			"symbol": "BULLDOGE",
			"price": "0.000000000297468710071205124891578893574",
			"price_BNB": "0.000000000000973934314573099222064371136511"
		},
		"0x9c6bE9ba9d8F99A2f09D7e06FF3A7d3eaf198C74": {
			"name": "Waves Dogecoin",
			"symbol": "WAVESDOGE",
			"price": "0.000000002957486875040291219012661275118",
			"price_BNB": "0.00000000000765908337107065410573045564896"
		},
		"0x64Ae61C985EFf3f4F9A3E09A93A43Ff67fA9DCB8": {
			"name": "Ero Finance",
			"symbol": "ERO",
			"price": "2.906447648826086609561791926135",
			"price_BNB": "0.01015234598028232907939023939205"
		},
		"0x57ef7e7aabB9B2301072402e7D2DDB38D55Bda65": {
			"name": "Quantum Moon",
			"symbol": "QMM",
			"price": "0.000000000290197985735910489569828557978",
			"price_BNB": "0.0000000000008798251690277007418334095656055"
		},
		"0x59B5E08fac61C95B251632D49fC195A0DcFbc813": {
			"name": "Extreme Network",
			"symbol": "EXTR",
			"price": "0.00000000288756078736233479946998923189",
			"price_BNB": "0.00000000000873516276394223445331327274226"
		},
		"0x9a721f73Fc960456cF3635116e0154a0E8716D46": {
			"name": "CANVA",
			"symbol": "CANVA",
			"price": "0.001136426826270466638985843207448",
			"price_BNB": "0.000003480799045862068965517240918228"
		},
		"0x85dEB5cC15141787076C4CBEF6320741D8F22858": {
			"name": "BakeryCake Token",
			"symbol": "BakeryCake",
			"price": "0.00000000283725988342218708658582444393",
			"price_BNB": "0.00000000000750341696437885408643104671275"
		},
		"0xf198353295F6E5BA2334B0fb40889be2Eb87e44f": {
			"name": "NFT Apex Legends",
			"symbol": "NFTAPEX",
			"price": "0.00000000281811592150938917649656876255",
			"price_BNB": "0.00000000000854554367498186540552972940325"
		},
		"0xD9d3173C0fDB0C651a554F6477eD19707560b615": {
			"name": "NFT Binamars",
			"symbol": "NFTBMARS",
			"price": "0.00000000281191790555276489565985048056",
			"price_BNB": "0.00000000000747822118240934061608270733779"
		},
		"0x6D4D1D0A09a967207EC86261a7249Ce7F2C1F17d": {
			"name": "Doge Diamond",
			"symbol": "DODI",
			"price": "0.000000002768564020394767177968926127733",
			"price_BNB": "0.0000000000077407716267153589128043500059"
		},
		"0x5577353cBedE89fE74E2402f11ed51b49a94CAe4": {
			"name": "BankCake Token",
			"symbol": "BANKCAKE",
			"price": "0.000000002768360418296887918818248468104",
			"price_BNB": "0.00000000000812028008732252964611594369598"
		},
		"0x972E416f48d214BFB9A13b9F0d2d968d1b1198c5": {
			"name": "Monero BSC",
			"symbol": "XMRBSC",
			"price": "0.00000000270822433405239956345742185103",
			"price_BNB": "0.00000000000798075322743973859379548821968"
		},
		"0xA94f188c732a2B4501ddbD142a7c9Eeb56e0b5F2": {
			"name": "SuperDODO",
			"symbol": "SuperDODO",
			"price": "0.00000000270378626220891677177877383084",
			"price_BNB": "0.00000000000771008525019273615798601776904"
		},
		"0xE9cAb0A8b44e5deb86F290557155FC37479B4720": {
			"name": "CrowdStrike Coin",
			"symbol": "CRWD",
			"price": "0.00000000269168409330728399114301638201",
			"price_BNB": "0.00000000000801621062307364834535873873264"
		},
		"0xfD73846f6B27cC89FAC19954dF8bdcC47aB8a8b5": {
			"name": "DCOL SWAP",
			"symbol": "DCOLSWAP",
			"price": "0.000514613817386159282336688351527",
			"price_BNB": "0.000001474554479398947088384209340803"
		},
		"0x92Ea293874E97fbC4974e39D47933D1C8eeDf12F": {
			"name": "MinaDOGE",
			"symbol": "MinaDOGE",
			"price": "0.000000002652928650207959953420445169965",
			"price_BNB": "0.00000000000746593373541419568746250589479"
		},
		"0xdef7BAffE4C2aa18AF200b5aE92AE9353525e459": {
			"name": "NanoPUG",
			"symbol": "NanoPUG",
			"price": "0.00002640781007430995284714452887543",
			"price_BNB": "0.0000000867760732710978896537597002415"
		},
		"0xA954275D51ed24B5e4990bC0BF79F4d9F472bc4f": {
			"name": "BSC Banking",
			"symbol": "BSCBANK",
			"price": "0.000000000260234205891760485098938782939",
			"price_BNB": "0.000000000000802272921932346177910248032513"
		},
		"0x8f1ed9f4E852a3A85aa454D463aBC3a113f62b42": {
			"name": "SuperMaker",
			"symbol": "SuperMKR",
			"price": "0.000000002600371135726870693635565477425",
			"price_BNB": "0.00000000000739306461114098648754294524962"
		},
		"0xEFa04a4DF756DD2c38C4b27f63C334587Ec4E525": {
			"name": "BTCPump",
			"symbol": "BTCPUMP",
			"price": "0.0001292994157454530462986075463067",
			"price_BNB": "0.0000003699698186209211859219140714713"
		},
		"0xFc6F5db15a20Ed19B04eCaa1D572fFd415AA6b4e": {
			"name": "Uniswap BSC",
			"symbol": "UNIBSC",
			"price": "0.00000000256584007750639028296732030696",
			"price_BNB": "0.000000000007186276695467421755761133871714"
		},
		"0x7e0deFAE6CB1D094F54386Fc49aA90B14BD9905e": {
			"name": "Ape",
			"symbol": "$APE",
			"price": "3.151914735404859067698938568588",
			"price_BNB": "0.0092192266112128035954660920639"
		},
		"0xD5429389A2F02D7Ee9995607e7F1AbD27002c1b5": {
			"name": "SuperSushi",
			"symbol": "SUPSushi",
			"price": "3.14291407295290953928041344815",
			"price_BNB": "0.00921084005820994427881658128574"
		},
		"0x72087e581FAdc3bb97d044878Adb655A1f3c00E7": {
			"name": "Safe Hoppy Doge",
			"symbol": "HOPPY",
			"price": "0.000000000251084674516523659642748200304",
			"price_BNB": "0.000000000000826226966876406646355282608543"
		},
		"0x902E9BF462cd7361B6aAbE07C0b354F20F40e4D9": {
			"name": "Space Cardano",
			"symbol": "SPACEADA",
			"price": "3.09669725001742946504995056603",
			"price_BNB": "0.00915491104801023394472375124291"
		},
		"0xC47eDe0bA2D4DC0BF64F6C146f4dBb4c59Fb469f": {
			"name": "NFTNFTs",
			"symbol": "NFTNFTS",
			"price": "3.088924073497188390293252774247",
			"price_BNB": "0.009137942378731371881837259806"
		},
		"0xA5282021d4d39d0b06B3f2E1B95198458C18BDC7": {
			"name": "BNB Rocket Doge",
			"symbol": "BROCKETDOGE",
			"price": "0.0000000002466021871940044829331153802373",
			"price_BNB": "0.000000000000805094510391980445459819288605"
		},
		"0x6E114F787993d35a9D45cC42880653633cc2D1e1": {
			"name": "BabyDoge",
			"symbol": "$bDoge",
			"price": "3.040184758541847740974269521423",
			"price_BNB": "0.00896009913922104164793225361673"
		},
		"0xad80fC0c0f90D2aa5a933F88d7Fc720919cf44Bb": {
			"name": "MiniStrongElon",
			"symbol": "miniSElon",
			"price": "3.024396770105702177094237956157",
			"price_BNB": "0.00883535679821718937883295405329"
		},
		"0x62C953eb4D1Eaf5bC910c336ac598356Fc9fD6c6": {
			"name": "Mask Doge Coin",
			"symbol": "MDOGE",
			"price": "0.000000002396108792633427682548178030098",
			"price_BNB": "0.00000000000696806892464189996205077156292"
		},
		"0xcc80564a259941e65c14c273819cA161e5369BFA": {
			"name": "BSC BullPerks",
			"symbol": "BSCBULL",
			"price": "0.0000000002355007032839257023962101862085",
			"price_BNB": "0.000000000000727907632550011569876801458741"
		},
		"0xbB332bEf98c4603FDA83eb85EE59d985d63eA8F3": {
			"name": "Akita BSC",
			"symbol": "AKITABSC",
			"price": "0.000000002332532755007221278717673120774",
			"price_BNB": "0.00000000000728882406363401864901840217509"
		},
		"0x3E889D88183742bAa4c70c51fE924B7F75D9bf20": {
			"name": "Doge ProMax",
			"symbol": "DOGEPROMAX",
			"price": "0.000000002280463277468410654428063271338",
			"price_BNB": "0.000000000005911437124202436617804455236627"
		},
		"0xA8961A49f201bc7ecCd915614B4Ec766591C0e6A": {
			"name": "ShibaEthereum",
			"symbol": "SHIBAETH",
			"price": "2.843445893726894451661342087922",
			"price_BNB": "0.00826494911062783293729512405762"
		},
		"0xd2F42C1E062Bd2738ABbE115310a8B390cEDA7e2": {
			"name": "WrappedMOON",
			"symbol": "wMOON",
			"price": "2.798751808361964503062086807555",
			"price_BNB": "0.00815912621928589253494776036455"
		},
		"0xF82EEbA7f949960AC153414566581B17CD18147b": {
			"name": "American Cake",
			"symbol": "UCAKE",
			"price": "0.000000002209295067567812385948381903187",
			"price_BNB": "0.000000000005566566850295285876068175479003"
		},
		"0x73c52503B9D56427Be967F08F52e47a3c2358736": {
			"name": "Hundred x Inu",
			"symbol": "100xInu",
			"price": "2.75987345325547136601083887805",
			"price_BNB": "0.00812891045904718959647415606246"
		},
		"0x93Ac1C2c33558f688B65b05dAA4feC1cDB7CAFE1": {
			"name": "Astro Bull",
			"symbol": "NFTASTRO",
			"price": "0.0000000002203884946487706264278751597708",
			"price_BNB": "0.000000000000658673010348753545568723120277"
		},
		"0xE89c66F5bD4F4Aa6Ef0373365f8cBa7A7ba7B473": {
			"name": "Hundred x Boxer",
			"symbol": "100xBOX",
			"price": "2.671934787229752498063782522717",
			"price_BNB": "0.00694150577371424530936837196345"
		},
		"0x8C0646E3c04e46b3F4dF350Ed9A3614782694960": {
			"name": "AquaFEG",
			"symbol": "AquaFEG",
			"price": "2.64498955702506562867921615684",
			"price_BNB": "0.006916927900486759976271708671"
		},
		"0xf089993308b99a8DDDABBc045e956D91dACB93b5": {
			"name": "BabyMOON",
			"symbol": "BabyMOON",
			"price": "2.644126539811939120394245794287",
			"price_BNB": "0.00656711223778702795510738919037"
		},
		"0xcA3DD236F78Eb1cd7776f5d4A734B640f039D3E9": {
			"name": "Ripple",
			"symbol": "$XRP",
			"price": "2.63149653126537155214214019871",
			"price_BNB": "0.00687140011251858785836717241379"
		},
		"0x38217Ebe801dd97A14eb309324CbD8110ABA2a86": {
			"name": "Hundred x Ethereum",
			"symbol": "100xETH",
			"price": "2.605780967967816892079734821006",
			"price_BNB": "0.00754644102616761017347391192712"
		},
		"0xFd2B1c5B842580b07bd131f31D0a39b20D89b61B": {
			"name": "The Asia Doge",
			"symbol": "ASIADOGE",
			"price": "0.0000000002069080966522617466097108710468",
			"price_BNB": "0.00000000000073171306718668469622956360998"
		},
		"0x8C2cD4777BB1A04cA8c0E2129E7FF270Bd6Ecc30": {
			"name": "FlokiDoge",
			"symbol": "FlokiDOGE",
			"price": "2.578692239075551587393428709603",
			"price_BNB": "0.00673270129782653812111029094662"
		},
		"0xc01A70FC7a523ce0A8B55AaFB8E4f86653647046": {
			"name": "RoyalPanda",
			"symbol": "RoyalPNDA",
			"price": "2.53239582189774758898911924604",
			"price_BNB": "0.00656583640176787801640730605804"
		},
		"0xaa9D0abB06f6284CB0d5B4c74d1606082342f307": {
			"name": "SuperArt",
			"symbol": "SUPART",
			"price": "2.509691591903642302813797698428",
			"price_BNB": "0.00654652912999029426490393829499"
		},
		"0x21cF74c5fc432619975ba8e39Df91C4B02fAbDF3": {
			"name": "Hundred x Cat",
			"symbol": "100xCat",
			"price": "2.505537320222380748449719989732",
			"price_BNB": "0.00737342295129163950181365473757"
		},
		"0xb549Daf57F3b5A9e7f2424F95D511cB742d965Bc": {
			"name": "BabySafe",
			"symbol": "BabySAFE",
			"price": "2.46091276142042654908264077496",
			"price_BNB": "0.00729842639372732410227001943631"
		},
		"0xA3C25E89215bEBA31D127Ea25bE92cBd9d3C92Bf": {
			"name": "FlokiPanda",
			"symbol": "FlokiPNDA",
			"price": "2.451964185100158599255068650135",
			"price_BNB": "0.007246882554250468092011010374656"
		},
		"0x8ea8df2BECa5C7486f937Fc4a6871dD18055673a": {
			"name": "EverMOON",
			"symbol": "EverMOON",
			"price": "2.406517309343843151426343517534",
			"price_BNB": "0.00716641057340642270618013048192"
		},
		"0x5e3D1E67619e978FeF428244b3477771803D6521": {
			"name": "Impossible Decentrailized Incubator Access",
			"symbol": "IDIA",
			"price": "0.0413629359185479320374699511335",
			"price_BNB": "0.0001038211206441772766137180451765"
		},
		"0x45534D4dCc9a336375a61A0cc06CA5F5E861Ad1d": {
			"name": "WrappedBabyDoge",
			"symbol": "wbDoge",
			"price": "2.401904121540023236282094282436",
			"price_BNB": "0.00713756316553725971961005557125"
		},
		"0x4f4a0377a4E93C1De98C30EC9d054D6937ec2c66": {
			"name": "OnlyUpSushi",
			"symbol": "UpSushi",
			"price": "2.387289132040707853762040365145",
			"price_BNB": "0.0071967122996901999935755699152"
		},
		"0x728f3efE0a8B53d375B1A1bcCd3502F677962cd6": {
			"name": "NFTs x LiteCoin",
			"symbol": "NFTxLTC",
			"price": "2.3861161187737495123225700088",
			"price_BNB": "0.00718425779844244457007627604445"
		},
		"0xd4E505Aeab8A88bD28ABD754D59c4cD22A2C1220": {
			"name": "SuperSafe",
			"symbol": "SUPSAFE",
			"price": "2.289808148708845922505256184057",
			"price_BNB": "0.0069041482914788456638629712698"
		},
		"0xBD407ace25C9E65987BAe2acA5a1019De9956b35": {
			"name": "SpaceBabyDoge",
			"symbol": "SPACEbDoge",
			"price": "2.260086567279702858393298053508",
			"price_BNB": "0.00664012009534813841716136499659"
		},
		"0x82d386464C86982695E02A4c7DC2d8B55DCCBF6F": {
			"name": "Chubby BabyDoge",
			"symbol": "CBDoge",
			"price": "0.00001791010155217191963415680400851",
			"price_BNB": "0.00000005765875424964892775980958718264"
		},
		"0x9f7099c96DFfE2B5EddE98dDB2ECDF0aE9BA338a": {
			"name": "MiniShibaInu",
			"symbol": "MiniSHIBA",
			"price": "0.00000001770145356480659723353428500065",
			"price_BNB": "0.00000000005168924805845665159703778374353"
		},
		"0xe69a7349e0eACA7ED7986FB8590b11106DA53534": {
			"name": "Mini Doge Cash",
			"symbol": "MDC",
			"price": "0.00001743146322653622106060943015529",
			"price_BNB": "0.0000000558116989626835821962853938358"
		},
		"0x8FE9eEf046682B8A6E3DE5b5fA19A896aBc2212C": {
			"name": "ShibaBNB",
			"symbol": "ShibBNB",
			"price": "2.12373791575090145109333887184",
			"price_BNB": "0.00637597649651156884947032611528"
		},
		"0x20E974eafD211Fa4f37dfbd189ad7fdEc41a513b": {
			"name": "XRPPay Gateway",
			"symbol": "XRPPAY",
			"price": "0.0000000001689208906068414863003399553924",
			"price_BNB": "0.0000000000005045260429171487941587395134776"
		},
		"0x8655aF16a6463F01f8cDD1DCd7D7cDC338d83EDb": {
			"name": "SuperSushi",
			"symbol": "SUPSushi",
			"price": "2.0666870718918924290655581974",
			"price_BNB": "0.005220194302124478234591953718266"
		},
		"0x7F5E1fDB2058085F255eaA80a985Bf8a2Fa47dF8": {
			"name": "FlokiStrongElon",
			"symbol": "FlokiSElon",
			"price": "2.027626843652589140426681408332",
			"price_BNB": "0.005084040326941414002882452945243"
		},
		"0xFa4934A2c8FB944b650DD578F1e1aB0DDCC541A6": {
			"name": "EverNFTArt",
			"symbol": "EverNFTA",
			"price": "1.973039914526412474255479211986",
			"price_BNB": "0.006127485468700218971518612064715"
		},
		"0x9ca7917699f33BE092E3c3Ec08ae73a3DfaFC8F4": {
			"name": "NanoNFTArt",
			"symbol": "NanoNFTA",
			"price": "1.962796041132652611128189091689",
			"price_BNB": "0.00501800713162346256629669443172"
		},
		"0xb6b6dd144cACA3e8E1087fB3A0F98C60740ad918": {
			"name": "WrappedNFTArt",
			"symbol": "wNFTA",
			"price": "1.958796569520017411965005996262",
			"price_BNB": "0.00492016577120351084259811826373"
		},
		"0x3E2fa145517Fbe8b1E0f781b834A707c8Ba72292": {
			"name": "OnlyUpSafe",
			"symbol": "UpSAFE",
			"price": "1.958581655157959062269386773218",
			"price_BNB": "0.00606948760570410194078496000013"
		},
		"0x9F79B041AdA5e7f51145f81b0582db506c649257": {
			"name": "FEG Finance",
			"symbol": "FEGFIN",
			"price": "1.936294374282773847926179397834",
			"price_BNB": "0.00492413622578160912901428879718"
		},
		"0x6e3f40378eDE9778F455fda4E670750F70b52220": {
			"name": "FlokiNFTArt",
			"symbol": "FlokiNFTA",
			"price": "1.93471948311019246970561033488",
			"price_BNB": "0.004862637396753624696067196500333"
		},
		"0x21665f22511C38F94d8856ce007C9156Fbf76d62": {
			"name": "MiniBoxer",
			"symbol": "miniBOX",
			"price": "1.933279049175591630734652361584",
			"price_BNB": "0.004891109277003571774181257248624"
		},
		"0xf988270a52202b67b327963f43D9f647E40c8fac": {
			"name": "SuperSushi",
			"symbol": "SSushi",
			"price": "1.9332475652687711912507780011",
			"price_BNB": "0.00601174888904088364650040328782"
		},
		"0x49181Ecac151B262796a03E76Cdec7FaeBc6dcF5": {
			"name": "NFTDoge",
			"symbol": "NFTDOGE",
			"price": "1.92861638474141567965169703943",
			"price_BNB": "0.004904646429071138424224375831715"
		},
		"0x12D9573eC609E9ca646564Ae6c364442687C0b5C": {
			"name": "FEGSafe",
			"symbol": "FEGSAFE",
			"price": "1.918682203231354737627551612316",
			"price_BNB": "0.005197740881487580513711223239577"
		},
		"0x6e808385857E6F72C66DB046256442945c44A1D3": {
			"name": "FlokiFEG",
			"symbol": "fFEG",
			"price": "1.917788175763619431830517145625",
			"price_BNB": "0.004734066292910055272962747021655"
		},
		"0x0505Bb9e1945F16707d2c4B60374bA2d68e514c6": {
			"name": "EverPanda",
			"symbol": "EverPanda",
			"price": "1.914933015565633310765752431008",
			"price_BNB": "0.004817846732509369515168985425517"
		},
		"0x488c378E5C4BE70d24155Bd1f55717980557a791": {
			"name": "NanoStrongElon",
			"symbol": "NanoSElon",
			"price": "1.912892805270413647422698128879",
			"price_BNB": "0.005170988203219496840449231142034"
		},
		"0xaB8BF38cD9F5f0CC93c1Ab176Fe077bFBF913109": {
			"name": "Binance Ape",
			"symbol": "BSCAPE",
			"price": "1.912591548980798820243283651977",
			"price_BNB": "0.005198946704368235634200492319654"
		},
		"0xEE418Fe597526E8e6B6fB2630c941AA2Ff902e54": {
			"name": "RoyalCash",
			"symbol": "RoyalCASH",
			"price": "1.912445418544152486856357647857",
			"price_BNB": "0.004806906230458049363926883002336"
		},
		"0x2D090f31A3Dc6D2d980c0ccb2567B0032a1bac1d": {
			"name": "OnlyUpPanda",
			"symbol": "UpPanda",
			"price": "1.908888967302679336455457371927",
			"price_BNB": "0.00520081871283598885144292451413"
		},
		"0x1fC9EaA575eF929640133a0569F909f86a14531D": {
			"name": "EverNFTArt",
			"symbol": "EverNFTA",
			"price": "1.902441584664637985656118907382",
			"price_BNB": "0.004779097064814657393960120727104"
		},
		"0xc84B821FAeec5822D1b2cc9c71bdf7078a7C1f0d": {
			"name": "ShibaEthereum",
			"symbol": "SHIBAETH",
			"price": "1.894541162533309348556389286016",
			"price_BNB": "0.00475543943762561484041931963931"
		},
		"0x00306A4316dD29e21087515e5Fe11005A11e4d5d": {
			"name": "SuperDoge",
			"symbol": "SDOGE",
			"price": "1.878796528368894931863799333053",
			"price_BNB": "0.00510252228735333795159962992335"
		},
		"0x6391b8e7bB2BC744d3Df93456FBD74b7AC10c182": {
			"name": "BabyElon",
			"symbol": "BABYELON",
			"price": "0.000000000004967547070434703963051375565326",
			"price_BNB": "0.00000000000001627253953819852603804256026687"
		},
		"0xC366850FDBf95633A5F9Fd7FF6ead93ef1f233F7": {
			"name": "FEGNFTs",
			"symbol": "FEGNFTS",
			"price": "1.841411221958386761750161754107",
			"price_BNB": "0.0050528047324551848138599744672"
		},
		"0x6Ced3747414c564E4FEfe94Bb4292AC1a5B50408": {
			"name": "NFTs x Sushi",
			"symbol": "NFTxSushi",
			"price": "1.832208807592527630113775496855",
			"price_BNB": "0.004992418830371885882177162978156"
		},
		"0x37Ba9EE739428E0e68D14ccEbD8527413d1023c6": {
			"name": "NanoLoki",
			"symbol": "NanoLOKI",
			"price": "1.821251890134637148096198121817",
			"price_BNB": "0.00494020162657593865129048070859"
		},
		"0xbF1f1C39429dB06e95FDBc4b216FFAc0E8D0938B": {
			"name": "Shiba Cardano",
			"symbol": "SHIBAADA",
			"price": "1.819939417232854597227312817042",
			"price_BNB": "0.00569564205851382149708705365574"
		},
		"0x2F0fAaC9229d68caA0A76abE83C4C069a8297752": {
			"name": "BabyInu",
			"symbol": "bInu",
			"price": "1.786834725978212018767607885462",
			"price_BNB": "0.004846578889996832091802019170855"
		},
		"0x768c4eE206eb5114dF8D98639B04EECB60055043": {
			"name": "FlokiLiteCoin",
			"symbol": "fLTC",
			"price": "1.766303316828110387752674352862",
			"price_BNB": "0.004836472199254757287958298752444"
		},
		"0x6505E477A38610c4B5006b7979A1E86D64dadFEa": {
			"name": "NFTs x FEG",
			"symbol": "NFTxFEG",
			"price": "1.743668883185277617970693168322",
			"price_BNB": "0.004688098774177782194513598964623"
		},
		"0x21Cf7AF880c1e73e9eeE63Bc45fDa9d6c5f9a942": {
			"name": "EverSushi",
			"symbol": "EverSushi",
			"price": "1.73975863943904885161976274384",
			"price_BNB": "0.00465072642130043513001219551639"
		},
		"0x64f39aBCDcEF4c00738e347761693EF6954f6A30": {
			"name": "NanoDoggy.Finnance",
			"symbol": "NANODOGGY",
			"price": "0.0000000001382979308526614072007615292617",
			"price_BNB": "0.0000000000004017074189036919344747767362114"
		},
		"0xB33dC55Ecf41Efb7018BADa3686E2252223b4237": {
			"name": "Mini Black",
			"symbol": "miniB",
			"price": "1.717334117072930922249750658925",
			"price_BNB": "0.005390644381404771626259798045055"
		},
		"0x970B72E21d987bfc250875fBf6a911dDa4104470": {
			"name": "NanoMOON",
			"symbol": "NanoMOON",
			"price": "1.706995830726606895546256111122",
			"price_BNB": "0.005307075383809010319096554322874"
		},
		"0xD310d280a15B463433f581C88c3774A3bB0673dB": {
			"name": "NFTs x Cash",
			"symbol": "NFTxCASH",
			"price": "1.70219110232894916422980924378",
			"price_BNB": "0.0052931442671451565582666896744"
		},
		"0x491f9299efeC6e42e631663bBBfdcF6a5e52dB18": {
			"name": "ShibaSushi",
			"symbol": "SHIBASushi",
			"price": "1.696331506802231630078883024093",
			"price_BNB": "0.00461981469769076556521834667038"
		},
		"0xDA49eC493144a97DF3A34fBF65ffEc9f19643607": {
			"name": "NFTs by Safe",
			"symbol": "NFTSAFE",
			"price": "1.653420899388982266401573756513",
			"price_BNB": "0.004646015589692073634281066139146"
		},
		"0x58f247bFb429643e4e43FdE5279736efACE31A13": {
			"name": "BabyNFTArt",
			"symbol": "bNFTA",
			"price": "1.648827030353008165062009058543",
			"price_BNB": "0.004624095049967133646564772958067"
		},
		"0xC218a1756986d98DF391dbe87D6e295985c39965": {
			"name": "ShibaArt",
			"symbol": "SHIBAART",
			"price": "1.636176588272419876246503079019",
			"price_BNB": "0.00449853524242703437783939007843"
		},
		"0x5B83360BbE51c8b1230FCcA6CC43729590b35911": {
			"name": "ShibaCat",
			"symbol": "SHIBACat",
			"price": "1.631958712625409551875117655653",
			"price_BNB": "0.004604361488783586944647959805074"
		},
		"0x4211E5259AC22BD3B273F1b09f00F9918Ab999Fa": {
			"name": "SuperSafe",
			"symbol": "SSAFE",
			"price": "1.624424734726076843056405388956",
			"price_BNB": "0.0045397188689431720799330001861"
		},
		"0xE4171495DFC5535f1BD4942155B3D7AAa2bb492D": {
			"name": "MiniPanda",
			"symbol": "miniPNDA",
			"price": "1.618491811043726982221514295415",
			"price_BNB": "0.005040949071376151602704440596304"
		},
		"0xE7D7F404DeB357C82C8468b65856319c91d96066": {
			"name": "baby Shark",
			"symbol": "BBS",
			"price": "0.0000001294040859851688921485618059872",
			"price_BNB": "0.0000000002042447264286183783598176225809"
		},
		"0x57fc9cA698feb030BA9a393413F3cbA66f832240": {
			"name": "KingDoge",
			"symbol": "KingDoge",
			"price": "0.00001284888174014677762685947854023",
			"price_BNB": "0.0000000436209283790601984027371919785"
		},
		"0x11Bb292D005A5dEd259a6809d2193a464c94E4F8": {
			"name": "Hundred x Boxer",
			"symbol": "100xBOX",
			"price": "1.598805435468589321461029478934",
			"price_BNB": "0.004988144248625255665124566405685"
		},
		"0x8b4Cd1438fbC91417e068DC5617F91Cc01e391b1": {
			"name": "Polka Cake Swap",
			"symbol": "PolkaCake",
			"price": "0.0000000001259419470419244835968772787295",
			"price_BNB": "0.0000000000003116240088013737584874933085754"
		},
		"0x644c6f34b7653e19C38FcF10C9BaBB55CA1a57b3": {
			"name": "DogeKool Token",
			"symbol": "DOGEKOOL",
			"price": "0.0000000001253367480476561742550653150464",
			"price_BNB": "0.000000000000355340130308208783917224925311"
		},
		"0x699152A585F928873f1A1dd821DC5931279C93F5": {
			"name": "SONIC INU",
			"symbol": "SONIC",
			"price": "0.00001248505769635128180732452496449",
			"price_BNB": "0.0000000410139346134890336267520426699"
		},
		"0x555209Ce5Fea24AD1575613C4ca16673bC8E0335": {
			"name": "OnlyUpApe",
			"symbol": "UpAPE",
			"price": "1.552408934759221337079480824975",
			"price_BNB": "0.00470375946449705216912764541531"
		},
		"0xd8aE3b736D7c67eBc5A256eAdFcb3DE45AE87D5B": {
			"name": "NFTs x StrongElon",
			"symbol": "NFTxSElon",
			"price": "1.5496333540180444003037712398",
			"price_BNB": "0.004951605938846084805755889374015"
		},
		"0xAac0c34B1Bd96739b6fFE0040BE2Fb67feA8eC64": {
			"name": "NFTs x BNB",
			"symbol": "NFTxBNB",
			"price": "1.542928368615960755523643413362",
			"price_BNB": "0.00489221921369437982889483995755"
		},
		"0xfD7943a4674A49166A610D46CDeDee0986e108C7": {
			"name": "SpaceNFTs",
			"symbol": "SPACENFTS",
			"price": "1.541898327641885538187531955835",
			"price_BNB": "0.004942316066840715632354813618156"
		},
		"0xa28EF6440073dFC218bCeA6C0955d607A83D983F": {
			"name": "Floki Finance",
			"symbol": "fFIN",
			"price": "1.528010210435598741749589621043",
			"price_BNB": "0.004864235935926516906070550217146"
		},
		"0x789A41c785D033D9C8DF1255653cf69Cc4244414": {
			"name": "OnlyUpApe",
			"symbol": "UpAPE",
			"price": "1.514213186424272420316741354259",
			"price_BNB": "0.00476807652164610741605411955821"
		},
		"0xf3DBDA558fA5F5F2779a9A1c4E03f4aA56147197": {
			"name": "SpaceEthereum",
			"symbol": "SPACEETH",
			"price": "1.505134550414152566970707393768",
			"price_BNB": "0.00482838161887141376320622628576"
		},
		"0x35dE455fB5bDde7f537f4c8A798176c47d1651fe": {
			"name": "Baby Finance",
			"symbol": "BabyFIN",
			"price": "1.501929364541397172718246185109",
			"price_BNB": "0.00481598958651453754759827692213"
		},
		"0xF340afF5D9eA5f0f1CF4D1c131CC0928e17b21C1": {
			"name": "MiniArt",
			"symbol": "miniART",
			"price": "1.501034299616000401945630584464",
			"price_BNB": "0.00424958753684758535108281398497"
		},
		"0x7731980dBfE6c3Bc3a40c02B72742571d02D74f0": {
			"name": "BabyArt",
			"symbol": "BabyART",
			"price": "1.488111807256895353513164443169",
			"price_BNB": "0.00422403348131704129306943710892"
		},
		"0xd6cB5D9204de038c02073bA9e8Ac873981f4c1B0": {
			"name": "ShibaInu",
			"symbol": "SHIBAInu",
			"price": "1.473408039082292394756184518647",
			"price_BNB": "0.00472920691800364938852435541045"
		},
		"0xb026e4e7bb4a74f3bACCc0037B6408a610600072": {
			"name": "FlokiBoxer",
			"symbol": "FlokiBOX",
			"price": "1.46783740174261420644453402676",
			"price_BNB": "0.00416635136486266731265273590927"
		},
		"0x4aA6A5E3B23612Ffce2B31894a85c0Df164a0937": {
			"name": "NFTs x Cat",
			"symbol": "NFTxCat",
			"price": "1.452125950312015092703143470824",
			"price_BNB": "0.00465780145243936875249309944774"
		},
		"0x568964d4F60498f51D4603d1D422c474E066A44e": {
			"name": "ShibaCash",
			"symbol": "SHIBACASH",
			"price": "1.443578883522166474245884166975",
			"price_BNB": "0.00463244854376589954860613225919"
		},
		"0xaa091A3131AeDbF8a3541530fB50A2D394DF33dd": {
			"name": "RocketBoys",
			"symbol": "RocketBoys",
			"price": "0.00001151405077973898925812360718706",
			"price_BNB": "0.0000000378510675757256508541430713187"
		},
		"0x96cC2e87f7c79adbD141aCA1Fe51994D384e9717": {
			"name": "BabyCat",
			"symbol": "BabyCat",
			"price": "1.432691337361862200957835928874",
			"price_BNB": "0.004595366263555798589972584034504"
		},
		"0x4A3eEe191E89EBdD6ce9E4bFA54FdF5E17F9643a": {
			"name": "NFTs by BabyDoge",
			"symbol": "NFTbDoge",
			"price": "1.425034774004342747668150437659",
			"price_BNB": "0.004567782016536917597801027456305"
		},
		"0x3c8D527C6E104E148929fae107948930a002bb20": {
			"name": "BabyRipple",
			"symbol": "bXRP",
			"price": "1.412525870245283461447151503568",
			"price_BNB": "0.00400746172662987763586403093751"
		},
		"0xD92D9a37dd9D178B2181091c8b98e64a934EB48F": {
			"name": "EverBabyDoge",
			"symbol": "EverbDoge",
			"price": "1.400446723094043570140131496172",
			"price_BNB": "0.00382754956691327371231648262298"
		},
		"0x25447873750578bD92464B45b865e30f723993D2": {
			"name": "SpaceSushi",
			"symbol": "SPACESushi",
			"price": "1.40011039351157454371122952974",
			"price_BNB": "0.004001560326916594534709498613486"
		},
		"0x25cD807d4CE5e2570A172Ba2c70B6545722Ea599": {
			"name": "SuperStrongElon",
			"symbol": "SSElon",
			"price": "1.394719100141580353122064625353",
			"price_BNB": "0.00396919945073326373136275897929"
		},
		"0xF59D7a78D138e4d013A1b6B07760383fE1A58E58": {
			"name": "FEGPanda",
			"symbol": "FEGPanda",
			"price": "1.39354578018519995885932083431",
			"price_BNB": "0.00393028745331161621507568087243"
		},
		"0xF368e5cf4533B5419C42751c8b6886037CbDB7c6": {
			"name": "Floki Cardano",
			"symbol": "fADA",
			"price": "1.390329060993070710318954142325",
			"price_BNB": "0.00447322136242622165785548451431"
		},
		"0xd2184b7DDf1781c18696b28493b012eD43B45013": {
			"name": "MiniBabyDoge",
			"symbol": "minibDoge",
			"price": "1.376080603658798410538324018246",
			"price_BNB": "0.0044249141763299864622281228251"
		},
		"0xA45754c9A72134869eC3F1B0eB1140855f442006": {
			"name": "OnlyUpNFTArt",
			"symbol": "UpNFTA",
			"price": "1.369374026270513992715121923242",
			"price_BNB": "0.00389811790863459254875747280645"
		},
		"0xf3da67A595BD9aF7087e545E141e0634E236e92F": {
			"name": "WrappedStrongElon",
			"symbol": "wSElon",
			"price": "1.364951175589452827054388659772",
			"price_BNB": "0.00439853721366754831976204330663"
		},
		"0x24289f29338A5FCbDd64481ddE7Dc306380C6118": {
			"name": "NFTs by FEG",
			"symbol": "NFTFEG",
			"price": "1.364549059642628667713729824208",
			"price_BNB": "0.003850190589150571178008295225222"
		},
		"0xBC1387B119FFa568506F8b5CC96ea7Dd40f63F04": {
			"name": "RoyalArt",
			"symbol": "RoyalART",
			"price": "1.35672734036729573861807564116",
			"price_BNB": "0.00383354209691051050436258476758"
		},
		"0xF8087A2c874dE99a480F4085D31ce1649e5794e2": {
			"name": "SpaceEthereum",
			"symbol": "SPACEETH",
			"price": "1.353653115972539601886662483874",
			"price_BNB": "0.003820021534069841415396065801134"
		},
		"0x6bA8d2E9865601631ae093580A144C9b88135fd3": {
			"name": "SuperCash",
			"symbol": "SCASH",
			"price": "1.349002820477742710616106895919",
			"price_BNB": "0.00382889742632024898187144922705"
		},
		"0xc67C1c80910AF8C99d07Daa43Aa447d53fA9C81A": {
			"name": "Binance Loki",
			"symbol": "BSCLOKI",
			"price": "1.34081569748245989605303319871",
			"price_BNB": "0.00379794558159162594764709942018"
		},
		"0xE8551fB0118769eC389B7DF5Eac43F11d14769ca": {
			"name": "RoyalDoge",
			"symbol": "RoyalDOGE",
			"price": "1.340752785087389615292776286535",
			"price_BNB": "0.00381544084653642565659038013271"
		},
		"0xA81D37bFC75769a875ac373bB5b109c3d6212ff7": {
			"name": "NanoDoge",
			"symbol": "NanoDOGE",
			"price": "1.334780671561757194474927636859",
			"price_BNB": "0.00376501116784415956505881120368"
		},
		"0xef9f735426d514e7300aA4364A7ea41F06108154": {
			"name": "EverDoge",
			"symbol": "EverDOGE",
			"price": "1.326853641413525671905003893445",
			"price_BNB": "0.00380861435022908290940274084892"
		},
		"0x930B9D0C7612Dd71c1A95806818292Db05E9Ce7b": {
			"name": "Hundred x Cat",
			"symbol": "100xCat",
			"price": "1.319418779634151325721958424378",
			"price_BNB": "0.003715679192521107256270023520607"
		},
		"0x762F7D245CD97F11F35d5f3f5B015c02b04411E2": {
			"name": "SpaceBabyDoge",
			"symbol": "SPACEbDoge",
			"price": "1.3146421902941996538884661828",
			"price_BNB": "0.004249326112704979096183160437434"
		},
		"0x6879cDeA540Eb323dea59227b3138CE6e602DD1b": {
			"name": "EverBitcoin",
			"symbol": "EverBTC",
			"price": "1.30775917997379834331604834065",
			"price_BNB": "0.003682637926848452781413193821608"
		},
		"0x03289Be4f12A296f5B7067a72c78d0B2881C1135": {
			"name": "BabyCat",
			"symbol": "bCat",
			"price": "1.289123639423323936946962229406",
			"price_BNB": "0.003234776374656378226804939511077"
		},
		"0x1C325655dd47D3d0ACACDD3ba8964bBafc37Ece1": {
			"name": "Hundred x Art",
			"symbol": "100xART",
			"price": "1.273505160632573135783836212283",
			"price_BNB": "0.003209862664659437637194825889457"
		},
		"0xf53431960226F553208861580a396CAa28f08f9e": {
			"name": "SpaceBitcoin",
			"symbol": "SPACEBTC",
			"price": "1.264963903236192300256035438527",
			"price_BNB": "0.003212513633882182980237179662432"
		},
		"0x8c7A29DcC4e2e06223C50B91484A59466c15De11": {
			"name": "FlokiSafe",
			"symbol": "fSAFE",
			"price": "1.250369284840006582321244737319",
			"price_BNB": "0.00415488947051493030553826266208"
		},
		"0x1C595d2dDDa1FEa54A7B5f3F907B30eb505A4666": {
			"name": "Baby Nikita",
			"symbol": "BNIKITA",
			"price": "0.0000000001985474706497088076180392935842",
			"price_BNB": "0.000000000000625581502388269743429020924684"
		},
		"0x5A95E6dBc71d389e53ac2bF983D2Ed36ADd1a7Fe": {
			"name": "BabyBNB",
			"symbol": "bBNB",
			"price": "1.23764282087702197382292631277",
			"price_BNB": "0.004099420698836722211268112345886"
		},
		"0x6fD084069751c9C7d4554bd8f45088EB87651f0b": {
			"name": "SuperSushi",
			"symbol": "SUPSushi",
			"price": "1.19123575298465758045041818724",
			"price_BNB": "0.003088614832907804933423343788266"
		},
		"0x03c36AF840A87C35D8f005Ec285cf2732910eA13": {
			"name": "Ever Black",
			"symbol": "EverB",
			"price": "1.181110819517529527745751094054",
			"price_BNB": "0.00391581375708838743663226041215"
		},
		"0xA6f2Ac0CAF296173c43AF624157c08b06078f6B8": {
			"name": "Boxer",
			"symbol": "$BOX",
			"price": "1.175862430235321707949898237334",
			"price_BNB": "0.003033148175260800093166353301047"
		},
		"0xA13b092714596e8292Fe6cC33c8be30cA22f9112": {
			"name": "AquaInu",
			"symbol": "AquaInu",
			"price": "1.168330369398514504892031682699",
			"price_BNB": "0.003015927953395670723246398650178"
		},
		"0xD6F6EDe422194C84b5E203aeF31A5a0691A242f3": {
			"name": "EverFEG",
			"symbol": "EverFEG",
			"price": "1.150296905837030120477033183784",
			"price_BNB": "0.002976091860152265298563927920213"
		},
		"0x5bf0fd8006F2189270BAFb7150380240c2c848A4": {
			"name": "timeantiques",
			"symbol": "TAQ",
			"price": "0.0000000104937350498064631930064366753",
			"price_BNB": "0.00000000002738193042033148529192110230162"
		},
		"0xBD6D511B546C10cD05816EbC0F1FFfD71128bD39": {
			"name": "ShibaSushi",
			"symbol": "SHIBASushi",
			"price": "1.117366744286729650222315055318",
			"price_BNB": "0.00290900780356026680239512706986"
		},
		"0xEa62DE278297A049e82298C94bD7C4998099e2E7": {
			"name": "SuperMOON",
			"symbol": "SUPMOON",
			"price": "1.110303356431330957776713058596",
			"price_BNB": "0.00333444878702975561846854344262"
		},
		"0x3f846AB30D233166d17E12ac027CCD5b622F783e": {
			"name": "WrappedApe",
			"symbol": "wAPE",
			"price": "1.108898455297643243533791703584",
			"price_BNB": "0.002872926934926554916754108381474"
		},
		"0x9Ccd07fEDce7640B64D02348A2a886113B7bb072": {
			"name": "Aqua Finance",
			"symbol": "AquaFIN",
			"price": "1.102766498608404729646545969231",
			"price_BNB": "0.00285798674434731003680448256072"
		},
		"0xF3031e5709876f844B78370fb0c449895f896846": {
			"name": "FEGSafe",
			"symbol": "FEGSAFE",
			"price": "1.097918116410819355061811607021",
			"price_BNB": "0.002899014794244423534970074040082"
		},
		"0x7163635149dcFcC5a0F2D3052F4400dA9158f9d9": {
			"name": "ShibaArt",
			"symbol": "SHIBAART",
			"price": "1.097366647976514515591077689144",
			"price_BNB": "0.002859581266048743875133605064762"
		},
		"0xb415d4Fe902b09eF5E06E7e7C788f784ebcC30fB": {
			"name": "BabyDoge",
			"symbol": "BabyDOGE",
			"price": "1.091799567680046396768757749398",
			"price_BNB": "0.002800874989516107835980254035937"
		},
		"0x299135e880346fC2DA4e81f77B072C31749573aC": {
			"name": "Ever Finance",
			"symbol": "EverFIN",
			"price": "1.087416285150999156362027475493",
			"price_BNB": "0.0028634451526611112677530036282"
		},
		"0x83fc23c016bBD676d9C94Dfc98760a66FbF48132": {
			"name": "BabyLoki",
			"symbol": "bLOKI",
			"price": "1.084638024668764025358985250509",
			"price_BNB": "0.002872420587368178561706235149452"
		},
		"0x4762cCF25CeAc01220F873b110D4751015E43eDC": {
			"name": "BabyPanda",
			"symbol": "bPanda",
			"price": "1.084295164232414690613181809609",
			"price_BNB": "0.002837064554288393236723266536904"
		},
		"0x7d49fAB3A0c2f05Eb03C12b93b220b395f4B3603": {
			"name": "FEGRipple",
			"symbol": "FEGXRP",
			"price": "1.077926707513015641291012743444",
			"price_BNB": "0.002786188996330899178768564111465"
		},
		"0x47A5d483463cAcFd08ae3dC509Ae7a2bd169880E": {
			"name": "FEGCash",
			"symbol": "FEGCASH",
			"price": "1.07202363264799929680584174408",
			"price_BNB": "0.002780436040622535438257648614137"
		},
		"0xc2d191596DD704b3b024263BE7CC55fF127c7a8F": {
			"name": "RoyalBitcoin",
			"symbol": "RoyalBTC",
			"price": "1.071436976123912197384093079542",
			"price_BNB": "0.0032051308221559362695530645403"
		},
		"0x3d5225f1Af7C64E4E107B4e87a3943C75576CCF2": {
			"name": "EverFEG",
			"symbol": "EverFEG",
			"price": "1.07099175040017151627604367455",
			"price_BNB": "0.002808315912177878574350735321664"
		},
		"0xfcf1b248CFE8C3970D8B62E61d7CF8973c75e8F6": {
			"name": "EverRipple",
			"symbol": "EverXRP",
			"price": "1.066361411433738862016544830433",
			"price_BNB": "0.002820601916479854086955051774773"
		},
		"0xC6B638F42b2504d5A99CC08ae0fD4D54E4509B03": {
			"name": "AquaRocket",
			"symbol": "AquaRocket",
			"price": "1.064945548906835723093547773543",
			"price_BNB": "0.002744728286455445225498248672372"
		},
		"0xF0fA8202B2778CDe9f3c0e3da7af333024509EAa": {
			"name": "NFTs by  Cardano",
			"symbol": "NFTADA",
			"price": "1.059413420505194860671714352756",
			"price_BNB": "0.002803440279104032502574182871616"
		},
		"0x88ABf023ee4D9bbf84F088F0aC8402B532809c9C": {
			"name": "MiniLoki",
			"symbol": "miniLOKI",
			"price": "1.059092596283544774352390525658",
			"price_BNB": "0.002823958560406709981190405684534"
		},
		"0xd87FA6b0AbbEDCD79Bd3E37Fe24339AEE3681381": {
			"name": "WrappedPanda",
			"symbol": "wPanda",
			"price": "1.051912991148113296941084510218",
			"price_BNB": "0.00276770557829150105096442972562"
		},
		"0xB72EaBC89D77010185770b56268C8a133476EcA9": {
			"name": "FlokiLoki",
			"symbol": "FlokiLOKI",
			"price": "1.050360709655893555150223582045",
			"price_BNB": "0.00277894163768951769773683796423"
		},
		"0x1aFE52D6eB012C67b5e07EBCFC86CCcE8162EC9b": {
			"name": "RoyalArt",
			"symbol": "RoyalART",
			"price": "1.047741230186356237107519175585",
			"price_BNB": "0.002794583141245418530125873676724"
		},
		"0x90aB34d4EF68a1a295996A3504Fc0b55b0fa79E2": {
			"name": "SuperSafe",
			"symbol": "SSAFE",
			"price": "1.044515100095589598426305886348",
			"price_BNB": "0.00349226534210462038736256136054"
		},
		"0x0e26b393219425a188f8fB74fB3e23A0a9BdE259": {
			"name": "SpaceRocket",
			"symbol": "SPACERocket",
			"price": "1.04385349238224358918915639108",
			"price_BNB": "0.002810375556514046922268353847917"
		},
		"0x3bd5Fa83195C4fC0BFAC83d43250b6cE7b74cebF": {
			"name": "FlokiSushi",
			"symbol": "FlokiSushi",
			"price": "1.040677755330673150593752555763",
			"price_BNB": "0.00272653289493969532877297187274"
		},
		"0x35634E1C6Db74987944B570007055f3e3B3614EB": {
			"name": "ShibaArt",
			"symbol": "ShibART",
			"price": "1.040612708786228603719657109848",
			"price_BNB": "0.00267740560802472977832241744765"
		},
		"0x216AB1d1CabEdac2357564D37540cb3e667b86aF": {
			"name": "OnlyUpBoxer",
			"symbol": "UpBOX",
			"price": "1.039875301815705740650222499856",
			"price_BNB": "0.00279028669729326813563440558566"
		},
		"0x1BA3129bDC06e7D117a6Dc025Ca1D2E122AA0c3a": {
			"name": "NFTs x Ripple",
			"symbol": "NFTxXRP",
			"price": "1.039463896325023774029522212153",
			"price_BNB": "0.002789565802400530455598470715517"
		},
		"0x19B98f4E7a3270733AD825Ef689A28884EA4128a": {
			"name": "RoyalMOON",
			"symbol": "RoyalMOON",
			"price": "1.035222378662792523739681293578",
			"price_BNB": "0.002728677242398087451455917391523"
		},
		"0xA75909aBaf5bFB43c54819379A34fC01b0c1aE7b": {
			"name": "NanoApe",
			"symbol": "NanoAPE",
			"price": "1.034126982182786726546675749448",
			"price_BNB": "0.003469959635515950071313663453438"
		},
		"0x99F45C3e41daCFa796f0b99C1ed6e175D061966c": {
			"name": "FlokiCat",
			"symbol": "FlokiCat",
			"price": "1.033674846779815974968953869466",
			"price_BNB": "0.002751856341498149739893563468302"
		},
		"0x15dcf856E965777E47A32Bf52D80a193d3Dc6EfA": {
			"name": "RoyalRocket",
			"symbol": "RoyalRocket",
			"price": "1.02745219093435644943700680247",
			"price_BNB": "0.002708148851493922490975506578066"
		},
		"0x18935e0085af35c0baF53B6a9a7fa977e580E2c8": {
			"name": "NFTBoxer",
			"symbol": "NFTBOX",
			"price": "1.024310556485507162252836154768",
			"price_BNB": "0.00269787617339445662746051316866"
		},
		"0x52261060390a5b132036202B1c0E5d2Aed6c8769": {
			"name": "Duke Doge",
			"symbol": "DukeDoge",
			"price": "0.0000000000816476513484010967859346550149",
			"price_BNB": "0.0000000000002602850894639462487893379082336"
		},
		"0x3A28606c995a0F51998714B3D238Ca1f4f4Bc676": {
			"name": "BabySafe",
			"symbol": "BabySAFE",
			"price": "1.01259625213405769372713066609",
			"price_BNB": "0.00338802737663086083448579046221"
		},
		"0xC8d4101Bf1eF5BC899ADd47b3b1b7460B82abCED": {
			"name": "MOONSOON",
			"symbol": "MOSO",
			"price": "0.0008617721485831983028473407711534",
			"price_BNB": "0.000002039342209225200662489019516597"
		},
		"0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56": {
			"name": "BUSD Token",
			"symbol": "BUSD",
			"price": "1.000449764079119181089098306106",
			"price_BNB": "0.00207372384526029045150100798951"
		},
		"0x7900a48bf830d272983C59cA6729BEC1384bbc78": {
			"name": "SuperBabyDoge",
			"symbol": "SUPbDoge",
			"price": "1.012434166628839118947554459548",
			"price_BNB": "0.002677402310307344612187510746662"
		},
		"0x474D39a0aa895B6b24903536552B8447c6503b03": {
			"name": "FEGFEG",
			"symbol": "FEGFEG",
			"price": "1.011866377148854424021147947811",
			"price_BNB": "0.00272622875311433294685517686647"
		},
		"0xb1016834A26352f913375660B27e19d6D6a3e31b": {
			"name": "BabyMOON",
			"symbol": "BabyMOON",
			"price": "1.009735615492062395177515590663",
			"price_BNB": "0.003037035434695321281011305781043"
		},
		"0x76A315D54d221e7AC34Dd11eD517803EdC7e1773": {
			"name": "ShibaEthereum",
			"symbol": "SHIBAETH",
			"price": "1.004796081142157645677109891403",
			"price_BNB": "0.003364949006718161553259049065513"
		},
		"0xf2C08B1526Ec2d79cF13d1db80C9a5017086141b": {
			"name": "Hundred x FEG",
			"symbol": "100xFEG",
			"price": "0.997522069206782291721351681028",
			"price_BNB": "0.002674385423684620561753991097495"
		},
		"0xDb7c9df60269465b98337a146F7A1485172E43e2": {
			"name": "LokiDoge",
			"symbol": "LDOGE",
			"price": "0.0000000000796793071659587507255433584157",
			"price_BNB": "0.000000000000253724541807271300070623795769"
		},
		"0xA2CcD7F0577E9073049b1E79Dbc8Bd1B5ac7c19E": {
			"name": "DRAGON BABY",
			"symbol": "DRAGONBABY",
			"price": "0.0000000000790661746102804107218787541635",
			"price_BNB": "0.0000000000002534029399296126393194049488995"
		},
		"0xA31E96167B9eE647143e4a0e8B0c7dc320BFeF6C": {
			"name": "DogeFire Token",
			"symbol": "DOGEFIRE",
			"price": "0.0000000000789024497451887216307856343977",
			"price_BNB": "0.000000000000231222523606270546827626486302"
		},
		"0x8dDD535879AA7332e99C9808E595D7c8E0e52fF3": {
			"name": "EverMOON",
			"symbol": "EverMOON",
			"price": "0.9208769757709567451829651613935",
			"price_BNB": "0.003109121456416599967343415319243"
		},
		"0x646ad1869f3337a86f645E38179f08692b4525e6": {
			"name": "TEST",
			"symbol": "TEST",
			"price": "0.0072674841860132964383817081606",
			"price_BNB": "0.0000184130347866565487620297670152"
		},
		"0x5975c15C9c463eD51bae1db8FACF902151D86754": {
			"name": "MiniDogePRO",
			"symbol": "MiniDogePRO",
			"price": "0.000007193141067696354740780862445024",
			"price_BNB": "0.00000002354775557965965001474026972293"
		},
		"0x3f1fe75bBe61F79c16724d7ebA04BF52f9Ee3962": {
			"name": "FEG Black",
			"symbol": "FEGB",
			"price": "0.88475471391726694144459968846",
			"price_BNB": "0.002935533798349128673390756717097"
		},
		"0xc5F35da9EC5D5892437aeca58D7517a6CEDe500A": {
			"name": "Floki Cardano",
			"symbol": "FlokiADA",
			"price": "0.851768422008023060298166235311",
			"price_BNB": "0.002850691917051798987030039299564"
		},
		"0x1261C79C78C848769E73EF73CCF05C35dA7C3B6B": {
			"name": "MiniApe",
			"symbol": "miniAPE",
			"price": "0.816987029300366259866369355004",
			"price_BNB": "0.002828476095986055261788916713636"
		},
		"0x437aF57E817f8ac09f34eA6C6ecc10bb90d0aBe4": {
			"name": "ShibaRipple",
			"symbol": "SHIBAXRP",
			"price": "0.810957724534315610314020382669",
			"price_BNB": "0.002717442808899536897803092083734"
		},
		"0x3aF223E3625c808BCE66B51Fa5739B6A027b40AF": {
			"name": "OnlyUpRipple",
			"symbol": "UpXRP",
			"price": "0.80581473914904631695956529253",
			"price_BNB": "0.002700271868689102283729106565773"
		},
		"0x38e83Ce878A797DC62b5b88AefEF8b8CDe157246": {
			"name": "ShibaRocket",
			"symbol": "SHIBARocket",
			"price": "0.804325732371169972920755245294",
			"price_BNB": "0.002789917018499577519882921511844"
		},
		"0x39FecA765ae9c86C4f3b9E4E9A01d42c0C23ca00": {
			"name": "BabyPanda",
			"symbol": "bPanda",
			"price": "0.801750653757765663838834692335",
			"price_BNB": "0.00269126495988710064022686180566"
		},
		"0x1639B43cFf1e7E90A961996C75602D9ae0557F1B": {
			"name": "OnlyUpPanda",
			"symbol": "UpPanda",
			"price": "0.793096819759272362919586172698",
			"price_BNB": "0.002657908834014929134394406798686"
		},
		"0xB0158E0A788D0A88c4D67E0e93ba675607DbE76C": {
			"name": "EverMOON",
			"symbol": "EverMOON",
			"price": "0.792132465555609588649002971127",
			"price_BNB": "0.002639909368749840818992748583063"
		},
		"0x7cfe0eBB989A7509c1D5f8aE0c479D74f878C55C": {
			"name": "NanoLoki",
			"symbol": "NanoLOKI",
			"price": "0.780886287660555162082416639844",
			"price_BNB": "0.00259676465026383427994998107663"
		},
		"0x69C6cafE63a8908B186Fc08C29b519166503af90": {
			"name": "Shiba Finance",
			"symbol": "ShibFIN",
			"price": "0.775582574072185317134273905338",
			"price_BNB": "0.002564328986876792118816755674583"
		},
		"0x968eC6E0eAF42d917909B790C7751F660283500c": {
			"name": "Ever Black",
			"symbol": "EverB",
			"price": "0.770676391780262258649409007227",
			"price_BNB": "0.00266767447536572152033627302159"
		},
		"0x044c234d3DD7B6Edd2BA5faE9C9017032C184d4B": {
			"name": "FlokiApe",
			"symbol": "fAPE",
			"price": "0.7674725492104000221703815044546",
			"price_BNB": "0.00255449460503125670702181773864"
		},
		"0xF3f3CEc219A1206d468BfBa32895414F326D38A4": {
			"name": "MiniInu",
			"symbol": "miniInu",
			"price": "0.7651588494551488886100299758005",
			"price_BNB": "0.002533222596796959507815653704502"
		},
		"0x48247A6E0e1dD99a9d9ab4456AEdbfb313f5bc6c": {
			"name": "SpaceBitcoin",
			"symbol": "SPACEBTC",
			"price": "0.7649101340288975442296316696354",
			"price_BNB": "0.002565995663429048996114195495097"
		},
		"0xc7B0e512981C5a8534EEBd1823e71b4e56767035": {
			"name": "BabyMOON",
			"symbol": "bMOON",
			"price": "0.762247083467706941641435367056",
			"price_BNB": "0.00252710867622384831119057732178"
		},
		"0x59dF10D87A9BEC26e00DE497f410207A35C77A88": {
			"name": "SuperMOON",
			"symbol": "SMOON",
			"price": "0.761437887699632418014676649502",
			"price_BNB": "0.00256388985147207606951554574782"
		},
		"0x6140a3E62387d9B44384ECD6eb417F49D64Ab73F": {
			"name": "SpaceBoxer",
			"symbol": "SPACEBOX",
			"price": "0.742681740586425392215663294166",
			"price_BNB": "0.00261839619062557740951845773324"
		},
		"0x895b854682d681F3e8f9bb5A8eEC7a9eAEF5e240": {
			"name": "Cash",
			"symbol": "$CASH",
			"price": "0.735910437693160961723503291959",
			"price_BNB": "0.00259382574247045595298366538149"
		},
		"0xA683BdC67225590f5cA0B0d4157D740e3233a540": {
			"name": "EverEthereum",
			"symbol": "EverETH",
			"price": "0.735908384788643095607592718262",
			"price_BNB": "0.002603940013636903706878956119912"
		},
		"0x0E00189Ab107a3a66d33396C989DAB4beFFDdfAd": {
			"name": "NanoRocket",
			"symbol": "NanoRocket",
			"price": "0.733095181367484664218607709376",
			"price_BNB": "0.002418922693763455250424485102052"
		},
		"0x0888e9AbEA3701d163F9CD5e99dcb84B08388E74": {
			"name": "SuperStrongElon",
			"symbol": "SUPSElon",
			"price": "0.7325946629520753686480544291234",
			"price_BNB": "0.002423968021238978321594503567988"
		},
		"0xb9549157d8a42E8163A55eF14648619F536ae4e3": {
			"name": "FlokiFEG",
			"symbol": "FlokiFEG",
			"price": "0.722184286819380302206392949884",
			"price_BNB": "0.002505609831130192758954183831808"
		},
		"0x287e9Dc874587258afEA086C9d11Ae342e2561ec": {
			"name": "Super Cardano",
			"symbol": "SUPADA",
			"price": "0.721469688366790985132408681581",
			"price_BNB": "0.002460602921537984837457999039746"
		},
		"0xF4242FDffD0Fd9B7d3A08c04ca3137e88F86e0B8": {
			"name": "ShibaPanda",
			"symbol": "ShibPNDA",
			"price": "0.7091147213486054003680725609505",
			"price_BNB": "0.00236198232095279075013551429577"
		},
		"0x6C51D9155a2239EbAbDB04Ca149Fe2C8a838ffeE": {
			"name": "OnlyUpMOON",
			"symbol": "UpMOON",
			"price": "0.7072334840198311366338245151645",
			"price_BNB": "0.00236033347383382721840178803808"
		},
		"0x44Fa06BB3807e020b6448cF3617b58a22cC150CF": {
			"name": "SuperBoxer",
			"symbol": "SUPBOX",
			"price": "0.7035139432821322774074519370855",
			"price_BNB": "0.00233654138308448994358311687362"
		},
		"0xF21133d2f680e73750F1f1D87845Ce4F5DB57f81": {
			"name": "BabySafe",
			"symbol": "BabySAFE",
			"price": "0.701993913252833487870576912749",
			"price_BNB": "0.0024804845836036312408913290529"
		},
		"0xae906bB98F3281503E06646390c225A207Efc4B7": {
			"name": "EverApe",
			"symbol": "EverAPE",
			"price": "0.6821400284158819750839626259645",
			"price_BNB": "0.00227026467250992243364857018063"
		},
		"0x1c1bB6cd5a8B9EFA043164205410ad6B21a98B52": {
			"name": "Hundred x Loki",
			"symbol": "100xLOKI",
			"price": "0.679052321657652229997688345844",
			"price_BNB": "0.002290981894817828883566076751543"
		},
		"0x9c2C3C471EBe902ec3E8147153f602f44A019f3c": {
			"name": "AquaInu",
			"symbol": "AquaInu",
			"price": "0.673911454644981025390755072276",
			"price_BNB": "0.00225227818647619923561766833618"
		},
		"0x13f72492e80245E9f358575E279b932BF083499E": {
			"name": "FlokiLoki",
			"symbol": "FlokiLOKI",
			"price": "0.673532955487466012836540843972",
			"price_BNB": "0.002278216002468222962009050118235"
		},
		"0xeA3B19D5eD4d5f53eE7D8185c2A26d846845Ea25": {
			"name": "Binance  Finance",
			"symbol": "BSCFIN",
			"price": "0.6599098664959378882811368160395",
			"price_BNB": "0.002208161849440696205591038317158"
		},
		"0x9ab48095a89229b97ed9af461cdF08169b5DBcF7": {
			"name": "SpacePanda",
			"symbol": "SPACEPanda",
			"price": "0.6516354796779104022140499612135",
			"price_BNB": "0.002254966911247856722305793658636"
		},
		"0x134F984f75D83bF7f36179a4591D27532Ffe14B5": {
			"name": "FlokiApe",
			"symbol": "FlokiAPE",
			"price": "0.645802290593248052171760231472",
			"price_BNB": "0.002225113552299236567470534605973"
		},
		"0x9ceC4823d8a0D4BC73062ddD404Ec85eeF0cDfAC": {
			"name": "ShibaEthereum",
			"symbol": "ShibETH",
			"price": "0.632158999547451049109209049722",
			"price_BNB": "0.002113654762978990717176712361657"
		},
		"0x24E61403F170dd837D65f699300EC652Bb36C653": {
			"name": "FlokiMOON",
			"symbol": "FlokiMOON",
			"price": "0.624775778847572857257430765961",
			"price_BNB": "0.002112190601677935301867083746383"
		},
		"0x7e6D2C67fA6Bba5058025823988bf0D33333D1e0": {
			"name": "BabyBabyDoge",
			"symbol": "BabybDoge",
			"price": "0.621677865475132470168198619927",
			"price_BNB": "0.001751533739697632863849772532981"
		},
		"0x28B71b5308956E59813620CA664F488B402E30b5": {
			"name": "Rocket",
			"symbol": "$Rocket",
			"price": "0.613222826372435944662433325595",
			"price_BNB": "0.002049898105836688397127008201256"
		},
		"0xA521B91bd39d7f79E1Ed96857f65aA0e0e60d567": {
			"name": "SpaceStrongElon",
			"symbol": "SPACESElon",
			"price": "0.609949269599690552075327108348",
			"price_BNB": "0.002037036204949779045314185292396"
		},
		"0x5F7D1de2466DF884957E4396a38D37510EDF5B10": {
			"name": "AquaRocket",
			"symbol": "AquaRocket",
			"price": "0.607188644612997531414286982002",
			"price_BNB": "0.002085116707064004127531584417836"
		},
		"0xF359fd97A9C31Bb7fbc682DeE85C289D5ecAcc69": {
			"name": "ShibaDoge",
			"symbol": "ShibDOGE",
			"price": "0.60711385796035776837784087495",
			"price_BNB": "0.002151431970504784270194482125675"
		},
		"0xF19c25A99b2445B55290B82E8366F5FD415B942A": {
			"name": "Space Finance",
			"symbol": "SPACEFIN",
			"price": "0.599793771044290950404775290817",
			"price_BNB": "0.002002621865215086743173516672976"
		},
		"0x2bb90EE1d3E43F4Ed1c96681A64f71bffC6aB9C3": {
			"name": "EverFEG",
			"symbol": "EverFEG",
			"price": "0.5950223157307099673913886965256",
			"price_BNB": "0.002049946032367174796402970469574"
		},
		"0x9BF1452f51E45115290cB0a00dD77e65c8107851": {
			"name": "The NFT",
			"symbol": "NFT",
			"price": "0.001104111695262825230362958932051",
			"price_BNB": "0.000002891804639671197354595364651265"
		},
		"0x7f84b183265B1F10edCE4cfDbCF33FcEbd3B7625": {
			"name": "BabyDoge",
			"symbol": "bDOGE",
			"price": "0.5688936740528264098843083927385",
			"price_BNB": "0.001965883340277837262558822127866"
		},
		"0xf4a256f01A699DA828596A16Aa70583da4876Eea": {
			"name": "greendoge.biz",
			"symbol": "GREENDOGE",
			"price": "0.00701570001142316861295623361071",
			"price_BNB": "0.0000187303698831492738451124111165"
		},
		"0x6Dc39BBec51E5b4dE9b350f0D7fC1d35977626a6": {
			"name": "BabyDoge",
			"symbol": "BabyDOGE",
			"price": "0.548205858236383606643070267152",
			"price_BNB": "0.001534591773898241285551142235063"
		},
		"0x6185Af5D61581FaB6561a6b3F3Cd07562B9F1D49": {
			"name": "SpaceLoki",
			"symbol": "SPACELOKI",
			"price": "0.545754525745568274685844237976",
			"price_BNB": "0.001699196039996302300097339685"
		},
		"0xAdC15CB5e572f3E9148856Fcf9a65AdeEa030789": {
			"name": "WrappedInu",
			"symbol": "wInu",
			"price": "0.544069217450175646921536531607",
			"price_BNB": "0.001695751164124371298071641005732"
		},
		"0x7642319F0dE6e91e0a520f49f0C6EEFbFf1Fc97e": {
			"name": "WrappedApe",
			"symbol": "wAPE",
			"price": "0.540676726508279410316485188358",
			"price_BNB": "0.001682222044744230449516973986429"
		},
		"0x6481B92C289bb2E2fecC6B04fD414E02643b554d": {
			"name": "ShibaCat",
			"symbol": "SHIBACat",
			"price": "0.540351949624504494271139427117",
			"price_BNB": "0.001680328081929805260946133736468"
		},
		"0x44e163D5Fe0Fa7b01D99A208F731f28e2bDB7462": {
			"name": "Floki Black",
			"symbol": "fB",
			"price": "0.53906125122798081687118958492",
			"price_BNB": "0.001863066884399826111327809224421"
		},
		"0xbb6E04dCDbA541677Ea43A9815E6E060E842fC86": {
			"name": "FlokiLoki",
			"symbol": "FlokiLOKI",
			"price": "0.5365982967353144255880507064996",
			"price_BNB": "0.001686177194348312848846178190358"
		},
		"0xD7257A7581A90EBCA09f305DFd2dFc2F338F20bC": {
			"name": "FlokiMOON",
			"symbol": "fMOON",
			"price": "0.5352106325565911298592669207376",
			"price_BNB": "0.001820245567235222162593139600788"
		},
		"0x6864818aFFE5DaA214edA84cf77bD07D0f3f99F1": {
			"name": "SuperRocket",
			"symbol": "SUPRocket",
			"price": "0.535012794230231958797304061149",
			"price_BNB": "0.00166524781274174406891401540433"
		},
		"0xd0eb8d9E6543AcD585E32EA6C7Cd5d2108aEB6be": {
			"name": "Ever Black",
			"symbol": "EverB",
			"price": "0.534595781442357753685826806366",
			"price_BNB": "0.001844972479210184991313648468624"
		},
		"0xDff9cB3E8F00D21b7340E9a800e73AeBF247cD01": {
			"name": "ShibaSushi",
			"symbol": "ShibSushi",
			"price": "0.534354637453439319085201523387",
			"price_BNB": "0.001702586649606842631413041835425"
		},
		"0xf42CEB53eae58E4AE32c4C6ac464C29A00cac9f1": {
			"name": "FlokiMOON",
			"symbol": "fMOON",
			"price": "0.534351485454616532793351950569",
			"price_BNB": "0.001727106571539443599123083102541"
		},
		"0x6Ea915538b2Fb50Bc61d4e3CdEa4976547CED821": {
			"name": "ShibaMOON",
			"symbol": "SHIBAMOON",
			"price": "0.5340007758508502519455178075894",
			"price_BNB": "0.001828428691171549688142301257314"
		},
		"0x65a1f5391C9FF6117D8D62F2BDF42285dab24842": {
			"name": "Shiba Finance",
			"symbol": "SHIBAFIN",
			"price": "0.533298120931972823697285457532",
			"price_BNB": "0.001654963043418508715606059674687"
		},
		"0x644D5E145feB7fBd875a9f23E96767CbCE91b3D0": {
			"name": "SpaceStrongElon",
			"symbol": "SPACESElon",
			"price": "0.531527650174030483668175255984",
			"price_BNB": "0.001814952361115645421413797937483"
		},
		"0x04dF2246AF13F84019E956F663a99dBFe8b8cff1": {
			"name": "SuperRipple",
			"symbol": "SUPXRP",
			"price": "0.530919036005537640246039863451",
			"price_BNB": "0.001718301211034478534774974483318"
		},
		"0x802ea9f86514bd254eB5176f0C059B8d4F382096": {
			"name": "BabyDoge",
			"symbol": "bDOGE",
			"price": "0.5308717141381965505794474444505",
			"price_BNB": "0.001839589112044190825119437119726"
		},
		"0x9F45967cf1d6D77b885156DBF3D0bC8509a7a663": {
			"name": "ShibaRocket",
			"symbol": "SHIBARocket",
			"price": "0.522420218920668089220569604754",
			"price_BNB": "0.00169004134686081758813520241526"
		},
		"0x81aD7b207720B38c09Abb3bf1c7277e639df17Ac": {
			"name": "WrappedInu",
			"symbol": "wInu",
			"price": "0.5183267849885342572640791950294",
			"price_BNB": "0.001675052089799472764165881186648"
		},
		"0x7763C8b57e5629B5342dAc915ae4B03F8A049537": {
			"name": "ShibaBoxer",
			"symbol": "ShibBOX",
			"price": "0.516329265312270391779062503972",
			"price_BNB": "0.001755749443568103529073291829794"
		},
		"0x8d57C0dC71D0ee029c49073d5DB975958a08cFBc": {
			"name": "FlokiInu",
			"symbol": "fInu",
			"price": "0.512710226437055075505185770597",
			"price_BNB": "0.001743666943996326599509402945967"
		},
		"0x377bcB16ca6Cf1A7aB156A1D7062998886068FA2": {
			"name": "FlokiDoge",
			"symbol": "fDOGE",
			"price": "0.511311311264098049277277882222",
			"price_BNB": "0.001730523262635010171459915740604"
		},
		"0x6cF9e567e7361052f6874af2D9E4fC5FdD20ef3d": {
			"name": "Hundred x MOON",
			"symbol": "100xMOON",
			"price": "0.504022716239520051587797253792",
			"price_BNB": "0.001698282300720013361210582314614"
		},
		"0x6BEdF0481e61FBDeB8CC22F1335c1454E79B51e9": {
			"name": "BabyMOON",
			"symbol": "bMOON",
			"price": "0.500432602569460726051663476646",
			"price_BNB": "0.001684506335043625473956399489766"
		},
		"0x012dF622CaA0A18Ddfbf2e6BB8F23ce72eaadD02": {
			"name": "DIGIMONGO",
			"symbol": "DIGIMONGO",
			"price": "0.000000000005922711187928934910972621528895",
			"price_BNB": "0.00000000000001810888764560616410851726943628"
		},
		"0x8c37f797f6768Fe54037ad7C099b1a928e96Ced7": {
			"name": "ShibaDoge",
			"symbol": "ShibDOGE",
			"price": "0.4929956788499393269335043605003",
			"price_BNB": "0.001650569212015423609933446225511"
		},
		"0x816B44dB612b6B8B3143f789A07C78203245C677": {
			"name": "ShibaLiteCoin",
			"symbol": "SHIBALTC",
			"price": "0.4893306801663309933100904487306",
			"price_BNB": "0.001580475079556670365978356329315"
		},
		"0xE70712fA650D13eb3416a03619b8eb22F0E8Bf0e": {
			"name": "AquaLoki",
			"symbol": "AquaLOKI",
			"price": "0.4872629118227879256513050427932",
			"price_BNB": "0.001526622149510875126869649947562"
		},
		"0x9FE01Fe488628011691c48C69EC2CE0c1d8129E2": {
			"name": "FEGMOON",
			"symbol": "FEGMOON",
			"price": "0.486368147529170270624682030597",
			"price_BNB": "0.001631872554045024594706606904008"
		},
		"0xFF16712F7D2d0a9efDFC84d56440BfA9490A5cD9": {
			"name": "Porn Token",
			"symbol": "PORN",
			"price": "0.00003885742040358459426847625501774",
			"price_BNB": "0.0000001290664619204607615034827572431"
		},
		"0x41152924fd55A826870F457d596fC3B5767Ff02d": {
			"name": "Super Finance",
			"symbol": "SFIN",
			"price": "0.4828864818963545758512993108544",
			"price_BNB": "0.001567538031822729683674779518785"
		},
		"0xEfd94554B61C70181c70d50A239144511d3d479A": {
			"name": "Aqua Black",
			"symbol": "AquaB",
			"price": "0.4809441666902917467798724967008",
			"price_BNB": "0.001506806145758408421725110040151"
		},
		"0x5e84e163591d1D1D9D39f0ddD93CA3699F74BCa9": {
			"name": "RoyalBoxer",
			"symbol": "RoyalBOX",
			"price": "0.4804159051536254172029543124596",
			"price_BNB": "0.001606628678107150708911906855758"
		},
		"0x4aE475342e8b27951A11F77Ba60c6B263F79468d": {
			"name": "Nano Finance",
			"symbol": "NanoFIN",
			"price": "0.4794041911251278871428172174943",
			"price_BNB": "0.001522156017467254018892070731564"
		},
		"0xc8B070ED2D5984C83AcC568b42f3EA0761020597": {
			"name": "BabyRocket",
			"symbol": "BabyRocket",
			"price": "0.4785061448888466019529936236736",
			"price_BNB": "0.001571216373082838663455091441916"
		},
		"0xD16FfFD0ae8C7f85cD4416cAF18EC04Fd224b524": {
			"name": "RoyalPanda",
			"symbol": "RoyalPNDA",
			"price": "0.477872628447779724554387069112",
			"price_BNB": "0.001580398811306820505836010894652"
		},
		"0x0ce08Ec6F8aF09d9AF326F95243a43FdDA807D89": {
			"name": "AquaPanda",
			"symbol": "AquaPNDA",
			"price": "0.4762203080893062004966873138682",
			"price_BNB": "0.001604610127826215323419598320661"
		},
		"0x0A4a77a02Fe0EE857CE0f0785D6D4b5F636336F3": {
			"name": "Binance Safe",
			"symbol": "BSCSAFE",
			"price": "0.4758776885778335352153664701484",
			"price_BNB": "0.001605963638890809537464092667244"
		},
		"0x8918F1Cf5B0667ABa923FC3FafF404DF896994c1": {
			"name": "SingerDoge",
			"symbol": "SingerDoge",
			"price": "0.0000380447576318905328770783796893",
			"price_BNB": "0.0000001223523921037277303422963869137"
		},
		"0x7aff55DA0691405725b75A493fBb12007641CdE4": {
			"name": "Hundred x Panda",
			"symbol": "100xPNDA",
			"price": "0.475365311822569056604492108044",
			"price_BNB": "0.001590718528796254436582903314912"
		},
		"0xFe8e1eA3CBBF98870883b98B7DcA7F5deB1b8cA2": {
			"name": "SpaceBNB",
			"symbol": "SPACEBNB",
			"price": "0.4743454480496141594478230606528",
			"price_BNB": "0.001570666816069557964066148434278"
		},
		"0x7310206cB22E12B4839b7B700759aCA8A58E60E2": {
			"name": "SuperEthereum",
			"symbol": "SETH",
			"price": "0.473322160801538947728200304635",
			"price_BNB": "0.001573974314250883030053763044456"
		},
		"0x9191191aecDfdD0DDf8f5724E1C6de38C3CBAFF9": {
			"name": "BNB RAIN",
			"symbol": "BRAIN",
			"price": "0.00000000037750008243085513703920641385",
			"price_BNB": "0.0000000000007540927029445612593512119398105"
		},
		"0x557BA5Fb3fDD4291697879789fd465aCEC455471": {
			"name": "AquaPanda",
			"symbol": "AquaPNDA",
			"price": "0.4709176466447138550386506264787",
			"price_BNB": "0.001587978985976811390827270110006"
		},
		"0x0a90841DA1667a66F925Aa63772854b65EC0D51F": {
			"name": "RoyalMOON",
			"symbol": "RoyalMOON",
			"price": "0.469615984281191319037061605687",
			"price_BNB": "0.001561568865710771749481412767899"
		},
		"0xf6E6800731B45f9c36cb4b569A17f2E20ec5fBad": {
			"name": "EverEthereum",
			"symbol": "EverETH",
			"price": "0.469566673420587990515679380914",
			"price_BNB": "0.001489895388695264713892185843806"
		},
		"0x5c303Ae355f47ea4Dd94956b6Bfec8C971045B8a": {
			"name": "Binance Panda",
			"symbol": "BSCPanda",
			"price": "0.4689974735030852116289729906078",
			"price_BNB": "0.001558183307197124848896733671535"
		},
		"0xc54708110D29Af641Cf5cc5Bd6884Cafd84c706F": {
			"name": "WrappedPanda",
			"symbol": "wPanda",
			"price": "0.468313718560354916400351203845",
			"price_BNB": "0.001576766689647401861111261021399"
		},
		"0x66242B3DFe70b0e88eA8e45e5e37a478925A6245": {
			"name": "Binance Panda",
			"symbol": "BSCPanda",
			"price": "0.4647933830791257445961408196534",
			"price_BNB": "0.001561732372529975052146951211712"
		},
		"0xf3c769012718f38D3555abac3bC6304C29bE908B": {
			"name": "Son Of Gold",
			"symbol": "SOG 🔆 ️",
			"price": "0.0000000003706161606201979541218383974892",
			"price_BNB": "0.0000000000007735486909197183928683202120905"
		},
		"0x2de258C75cCD9CE3329a7aa684Da9E65FB05D4B7": {
			"name": "SuperApe",
			"symbol": "SAPE",
			"price": "0.4628784691939927800078171582696",
			"price_BNB": "0.001473675985257690497482709573412"
		},
		"0x73201ba782558ff8Cd800190e0c793b1a0f77466": {
			"name": "Electric Star",
			"symbol": "E-Star",
			"price": "0.000000000369499554571894339624117073165",
			"price_BNB": "0.000000000000765867126628359546288292682098"
		},
		"0x1F6AEa41a5702eb497CEe200c3250eC62cD5C2ba": {
			"name": "AquaEthereum",
			"symbol": "AquaETH",
			"price": "0.4608083732719082768910152077236",
			"price_BNB": "0.001469121705658609424701738585366"
		},
		"0x4CF465db8DE3598498F4536A4080700eD10aa42e": {
			"name": "BabySafe",
			"symbol": "BabySAFE",
			"price": "0.4596123931649155914732066385792",
			"price_BNB": "0.001591362869444450804177712486914"
		},
		"0xADC7316883469908282970De0DA71b411A0001A0": {
			"name": "ShibaPanda",
			"symbol": "ShibPNDA",
			"price": "0.458086157795359114858398008415",
			"price_BNB": "0.001536069669249500928138200904454"
		},
		"0x8F927B226594D3EA8E990B92Db7101aD86039f5B": {
			"name": "FlokiCash",
			"symbol": "FlokiCASH",
			"price": "0.4577635033589784977138888112472",
			"price_BNB": "0.001710065845685694951684548185661"
		},
		"0x82eA421d5Fb0E84798760285A3A033cFCab3BA2a": {
			"name": "AquaStrongElon",
			"symbol": "AquaSElon",
			"price": "0.455021849384528595516824690846",
			"price_BNB": "0.001511920064639496041895400739538"
		},
		"0x4eA38FDC811414e35e91F18c18276b363a37B249": {
			"name": "OnlyUp Cardano",
			"symbol": "UpADA",
			"price": "0.4533148813675273343262063339478",
			"price_BNB": "0.001705617034598084875329412439038"
		},
		"0x8A20A9467E6c1ca5f96Ad1548D38230D845dE92F": {
			"name": "NanoSafe",
			"symbol": "NanoSAFE",
			"price": "0.4527404105834445991127855219346",
			"price_BNB": "0.001684448493192637485620396648393"
		},
		"0x4CaE84beB773717cb3076E16D07F96033a115d1A": {
			"name": "Hundred x Safe",
			"symbol": "100xSAFE",
			"price": "0.452686159573951487806637606618",
			"price_BNB": "0.00151368429305805750065701254264"
		},
		"0x65D6D7934503C4222801061bf8A380B51a8fbeDa": {
			"name": "FlokiLiteCoin",
			"symbol": "fLTC",
			"price": "0.4524002730893360626804445275684",
			"price_BNB": "0.001440665490229049101856670316541"
		},
		"0xb657bfe3A04BbAe207bAADb6E024CFAb9C09864F": {
			"name": " Light Protocol",
			"symbol": "Light 💡️",
			"price": "0.0000000003617149291734121006609222093944",
			"price_BNB": "0.0000000000007600576762207540403691130856154"
		},
		"0x95F086e0Fcfd2d3014fEa8dac2c185d9d3464509": {
			"name": "Ever Black",
			"symbol": "EverB",
			"price": "0.4513274496041718450872638643622",
			"price_BNB": "0.001667229278981746674335246235259"
		},
		"0x978F5258A919aCDB20cEDB13c38458BB1c5296B0": {
			"name": "FEGMOON",
			"symbol": "FEGMOON",
			"price": "0.4491396570422038047188891201225",
			"price_BNB": "0.001662549934348200438277073601193"
		},
		"0x64446CD879BeB6fD3a53788A22cF30d6B0f165c9": {
			"name": "Hundred x Cat",
			"symbol": "100xCat",
			"price": "0.4490689861008303883435128551223",
			"price_BNB": "0.001684913756145795235629623162762"
		},
		"0x61cC90F586440C5F80900E1a6BBB691483948897": {
			"name": "Shiba Finance",
			"symbol": "ShibFIN",
			"price": "0.4479639099089137593071136680854",
			"price_BNB": "0.00166073862873046018908341319496"
		},
		"0xc870a7241C641570F2Ce6C5AbFc159c575B93BC5": {
			"name": "Floki Black",
			"symbol": "FlokiB",
			"price": "0.446311032762414794533348765605",
			"price_BNB": "0.001493368374753196801561611538298"
		},
		"0x7b5C0b1ce1176DbfeD1326EC5142Ad89E4EC4498": {
			"name": "FlokiLoki",
			"symbol": "FlokiLOKI",
			"price": "0.4462829181051830331658045339886",
			"price_BNB": "0.001492381833182515143803645101918"
		},
		"0x47B8B2F0682622aB901EAc7d8560B0D36cb1BfE4": {
			"name": "FlokiBNB",
			"symbol": "FlokiBNB",
			"price": "0.4450855578088316620700247349717",
			"price_BNB": "0.001489236214064588843296726138823"
		},
		"0xA01000C52b234a92563BA61e5649b7C76E1ba0f3": {
			"name": "ROCKS",
			"symbol": "ROCKS",
			"price": "0.3509149874623559563014080233434",
			"price_BNB": "0.001325520471055345055670954325268"
		},
		"0x4D50cF2c92eec9243b7070CCde7A68B03123Ce5F": {
			"name": "SuperInu",
			"symbol": "SUPInu",
			"price": "0.4383012365426724621004090935504",
			"price_BNB": "0.00146781337293289061421943862223"
		},
		"0xBf593922598195Ec51Eb62F117475846b7e249E7": {
			"name": "AquaDoge",
			"symbol": "AquaDOGE",
			"price": "0.4379426144528195569746330047244",
			"price_BNB": "0.001465833914953929921726399028017"
		},
		"0x722f367C9Ed5BeFBCCd3Ffe356A2eF78C5D17794": {
			"name": "NanoMOON",
			"symbol": "NanoMOON",
			"price": "0.435264008285537423187104804183",
			"price_BNB": "0.001445739454110096370032029672508"
		},
		"0xbe86f587f13c783075b1C209673e8d9146c127F2": {
			"name": "SuperMOON",
			"symbol": "SMOON",
			"price": "0.433519858199448958970548684615",
			"price_BNB": "0.001451012672696237525033356408504"
		},
		"0x58D67239f43886f5B7B95943926000062d8fdd0C": {
			"name": "NanoLoki",
			"symbol": "NanoLOKI",
			"price": "0.4267950242432787231807426036605",
			"price_BNB": "0.001430263794298324834074371268776"
		},
		"0x64e7708B0d4B8B19A92E0e60568e8282C53F270D": {
			"name": "WrappedBabyDoge",
			"symbol": "wbDoge",
			"price": "0.4262161335456758777915799157586",
			"price_BNB": "0.001105187950410252679693788813482"
		},
		"0x5C554A9942c64E1378b62009017788243ec39dD0": {
			"name": "Rocket Horse",
			"symbol": "ROCKET",
			"price": "0.00000000003396366790118733605205282498013",
			"price_BNB": "0.0000000000001298137184173721624169075105538"
		},
		"0x4DF7fd1f96A7cBC1e6f110351fedD21C5186a64e": {
			"name": "MiniRocket",
			"symbol": "miniRocket",
			"price": "0.4235010830084868769769422154363",
			"price_BNB": "0.001576063783821791491658864057908"
		},
		"0x27e913c4C6E04c16Fc8f63765aeB31ae57d7B2EF": {
			"name": "Hundred x Ape",
			"symbol": "100xAPE",
			"price": "0.418553998855847118626849244353",
			"price_BNB": "0.001289652041624488973990073167374"
		},
		"0x9fFE675aa37A9E2C987D4D72fd48BC5480A1ba87": {
			"name": "Hundred x Panda",
			"symbol": "100xPNDA",
			"price": "0.4149044924525438760677980600413",
			"price_BNB": "0.001536602080368816395717878381863"
		},
		"0x9A32A5cFbbB9fF86B852391a20B6328Cc500214C": {
			"name": "SuperLoki",
			"symbol": "SUPLOKI",
			"price": "0.413928923993350209713028420832",
			"price_BNB": "0.001377305503235750281044608097023"
		},
		"0x9C387240376883BFB9689A16f8A334c341400A62": {
			"name": "AquaMOON",
			"symbol": "AquaMOON",
			"price": "0.4106857966952580484490708126507",
			"price_BNB": "0.001530394676436447590673485213546"
		},
		"0x86947FC7CA6957faCA609F975695126F43000af1": {
			"name": "OnlyUp Finance",
			"symbol": "UpFIN",
			"price": "0.4106247327365404522949506653088",
			"price_BNB": "0.001279401716634896404635377080034"
		},
		"0xC8CC454b6a7b7f776B7fF9EBb41367e2b095e8A6": {
			"name": "OnlyUp Cardano",
			"symbol": "UpADA",
			"price": "0.4099556008711803279211755571685",
			"price_BNB": "0.001368231007994457902521694819901"
		},
		"0x630fd7b6dc159fFF77f0e337E3ED63f421C5F3F7": {
			"name": "FlokiRocket",
			"symbol": "FlokiRocket",
			"price": "0.4086385777985591976586111750647",
			"price_BNB": "0.001358188461486928167508944598031"
		},
		"0xA6C94E128f860a59e69F75D9C77dAa9db96Ee9Fc": {
			"name": "Binance Inu",
			"symbol": "BSCInu",
			"price": "0.408438621904816367947742249655",
			"price_BNB": "0.001516554816188727316935455691288"
		},
		"0x7A7306c24280911c117Ba2147759BF87571e02Ff": {
			"name": "Binance Panda",
			"symbol": "BSCPNDA",
			"price": "0.4070936231290658626626747436917",
			"price_BNB": "0.001266909322176633504625400290512"
		},
		"0xbB90FeDaCaCD2eb66df2A84B1CFE0dB8a5E832c3": {
			"name": "FEGFEG",
			"symbol": "FEGFEG",
			"price": "0.4044946893052197632451165452706",
			"price_BNB": "0.00151775902328752562532865283163"
		},
		"0x7ca246472e85db2e0915106BC46d9ecAE1aeE8e1": {
			"name": "Kudo Solid Co",
			"symbol": "KSC️",
			"price": "0.0000000003230752702863491848952821023563",
			"price_BNB": "0.0000000000006441959662498981568994523074865"
		},
		"0x4a51B798C1FcdF28Eb32563C8b7E135b49A928a7": {
			"name": "WrappedInu",
			"symbol": "wInu",
			"price": "0.403772707775912199115325506876",
			"price_BNB": "0.001514641106157163086541389179418"
		},
		"0x95F0DA3077B287Cd717AF89eF0FDc9Fe4Bb675DD": {
			"name": "BabyCat",
			"symbol": "BabyCat",
			"price": "0.4037606460151775976144503523265",
			"price_BNB": "0.001508340793409827633146985417652"
		},
		"0x493ca45Af3AF855f402d4B07309250AfC2F8e1B1": {
			"name": "WrappedEthereum",
			"symbol": "wETH",
			"price": "0.4034737369680340574269356975903",
			"price_BNB": "0.001282629539681008126336860610566"
		},
		"0x8A8480d88E9446B2543e7E62AAEAbE6dbADdf4BE": {
			"name": "SpaceBoxer",
			"symbol": "SPACEBOX",
			"price": "0.402861019532169614612277942846",
			"price_BNB": "0.001513690018533136978144526465465"
		},
		"0x2F740C78bf2846645BE16BcF241A62b124c04EA6": {
			"name": "BabyRocket",
			"symbol": "bRocket",
			"price": "0.4022155095113306296365929774587",
			"price_BNB": "0.00134537593752748487244586855525"
		},
		"0x32Ade1Ff6DBeF28d60455966ad67dAAA86117a21": {
			"name": "Lite Corgi Doge",
			"symbol": "LITECORGI",
			"price": "0.0000000003217556241127797104886805241632",
			"price_BNB": "0.000000000001076872586057238553983248866201"
		},
		"0x1162962b1b2d95cb79439B62A85420DE336B4280": {
			"name": "Hundred x Ethereum",
			"symbol": "100xETH",
			"price": "0.401721356891203308125678887592",
			"price_BNB": "0.001493899525105690044507599180735"
		},
		"0x1652b02C51fDa8B119305E11BF4390A12A4889F4": {
			"name": "FEGCash",
			"symbol": "FEGCASH",
			"price": "0.4016243337430641964441389971557",
			"price_BNB": "0.00150393615735942871570709791182"
		},
		"0x97fdb5889221aA9c06551884D155764FeB1c6472": {
			"name": "MiniBoxer",
			"symbol": "miniBOX",
			"price": "0.399822794296059003291386650146",
			"price_BNB": "0.001340715386917429655265502702171"
		},
		"0xFEFa113aae595EE9028C134e38F29aA50F22f230": {
			"name": "Binance  Black",
			"symbol": "BSCB",
			"price": "0.399287741868390419278077840879",
			"price_BNB": "0.001339613539694600019993343631328"
		},
		"0x2B40fA990619034Cf1A0441f61DE763CD1454e24": {
			"name": "WrappedRocket",
			"symbol": "wRocket",
			"price": "0.3988831852314730653634822892676",
			"price_BNB": "0.001283292186949387093490845862096"
		},
		"0x3B709e9fFE9e416Afc67E68797EA8B8238D5846E": {
			"name": "FlokiMOON",
			"symbol": "fMOON",
			"price": "0.398347074712023276806717420684",
			"price_BNB": "0.00149592477773943521414012048025"
		},
		"0x426Be9252a51F03BFD85BC5315E6CD3d429933D9": {
			"name": "Hundred x Loki",
			"symbol": "100xLOKI",
			"price": "0.3972553205905434163901116478557",
			"price_BNB": "0.001270176211822240925647900047575"
		},
		"0xeA83bC67c44696a5671728DDB76d7F636636C4e3": {
			"name": "AquaPanda",
			"symbol": "AquaPNDA",
			"price": "0.3965339708919690510582250140022",
			"price_BNB": "0.001329653479276701553403193263103"
		},
		"0x00d1aFF5109107E7af5cf807241Ee93cf4cdf446": {
			"name": "FEGSafe",
			"symbol": "FEGSAFE",
			"price": "0.3941693949800499231516032374",
			"price_BNB": "0.001317004679249068394781460404475"
		},
		"0x92E48dF7771d36FBa862a019dAf2a3E41dd80f75": {
			"name": "FlokiEthereum",
			"symbol": "FlokiETH",
			"price": "0.3937619767296232267782193617732",
			"price_BNB": "0.001455854670603153833723529264072"
		},
		"0x38b75f3c9A3528A608D5a692861c4b25bA41d1F0": {
			"name": "Rocket",
			"symbol": "$Rocket",
			"price": "0.3935289953679444569770661486716",
			"price_BNB": "0.001304233162247036746127093168984"
		},
		"0x642F1dc649a1a76DB8dE9aA9D3651fdEe1c3D1cb": {
			"name": "MANEMOON",
			"symbol": "MNM",
			"price": "0.0000000000000314414349342054488809836444166",
			"price_BNB": "0.00000000000000007939181443782406131584454566536"
		},
		"0xD72828831a16478D729a03E52B476360177241EB": {
			"name": "Hundred x Ape",
			"symbol": "100xAPE",
			"price": "0.3927769497745090368067223773414",
			"price_BNB": "0.001314940281501221271489918147273"
		},
		"0x8a4B4709B8Ebe5fC4dEFFD81E49e5D7c071F38b4": {
			"name": " BabySharkBTC",
			"symbol": "https://t.me/BabySharkBTC",
			"price": "0.003126422793327118610258916958013",
			"price_BNB": "0.00001033220847975514002138247983853"
		},
		"0x63916E7848f419208AedB1273cDa8f6c9B4AB6de": {
			"name": "Mini Black",
			"symbol": "miniB",
			"price": "0.3885924331121371509874054545418",
			"price_BNB": "0.001284564644783644308225991185244"
		},
		"0xef1Aee6fDA598fFabE23CAeBD251676D8694437a": {
			"name": "Cream Doge Coin",
			"symbol": "CREAMDOGE",
			"price": "0.0000000003108737307992296176284043103064",
			"price_BNB": "0.00000000000095933699063323796432041215959"
		},
		"0xfdb790Ac07f251873350CC4cc7AD27cD23415563": {
			"name": "Mars Doge",
			"symbol": "Mars Doge",
			"price": "0.00003105452368983743917988238601923",
			"price_BNB": "0.0000001016418540915297497063094276058"
		},
		"0x247ebCC2660c91C4D9D11f71cdEC543dff82201a": {
			"name": "Lite Kishu Token",
			"symbol": "LITEKISHU",
			"price": "0.00000000031024036172081492199117253786",
			"price_BNB": "0.000000000001038090431271172591457956521851"
		},
		"0x32961ad121c7e576F960CBD72331EdB4E306B088": {
			"name": "Shiba Finance",
			"symbol": "ShibFIN",
			"price": "0.38713342218935724387523014287",
			"price_BNB": "0.001445499035122488863924006861259"
		},
		"0x11FD5070567313166E3a6F675f84fAC2D9F8680c": {
			"name": "Flow Corgi Doge",
			"symbol": "FLOWCORGI",
			"price": "0.00000000030913181083691601361212879832",
			"price_BNB": "0.000000000001022984750834330256836317624714"
		},
		"0xc3A2D9A2D8F07399A47fb3d3C53cbbcA51b1A630": {
			"name": "OnlyUpSafe",
			"symbol": "UpSAFE",
			"price": "0.3848465191873039375754731553222",
			"price_BNB": "0.001458435070804100204824811660143"
		},
		"0xc368a0e29904f7F7dc7959B53c8395374F7103B7": {
			"name": "FlokiMOON",
			"symbol": "fMOON",
			"price": "0.38484404613969985575368744782",
			"price_BNB": "0.001449186246199063417211614971194"
		},
		"0x13Cb7Cd9Dcf351280ea058bE9B44A4473903e5f7": {
			"name": "Hundred x Inu",
			"symbol": "100xInu",
			"price": "0.3845073192913840378571375419085",
			"price_BNB": "0.001454301586931080686096116351586"
		},
		"0x845F6561009F4D1d483D06062776C3884EDe0E2e": {
			"name": "OnlyUpInu",
			"symbol": "UpInu",
			"price": "0.383657118471316698794681345695",
			"price_BNB": "0.00145532707896042038903401490684"
		},
		"0x6d6Ed7E2B47D0a49c62e7683C5C4ddE66086B6dB": {
			"name": "EverMOON",
			"symbol": "EverMOON",
			"price": "0.383556664269650258133596486406",
			"price_BNB": "0.001452274254035606174108807016295"
		},
		"0x576c109188e497A5Ca274cB959aa3B60908538Fc": {
			"name": "RoyalSushi",
			"symbol": "RoyalSushi",
			"price": "0.3827795396905117316093176147676",
			"price_BNB": "0.001223986267670557538014353388727"
		},
		"0xCd639FFfbcAa89010BAAe41B39Bf14b74beD8fEe": {
			"name": "BabyCat",
			"symbol": "bCat",
			"price": "0.381997367970759214839857960928",
			"price_BNB": "0.001451280708330807918295273489946"
		},
		"0xF0D75f4B9BfcD43F214bda41bf25badF6545c89B": {
			"name": "DekoFinance.live (BSC)",
			"symbol": "DEKO",
			"price": "0.0000003054933481274355542927430654286",
			"price_BNB": "0.000000001288032599186552403744725571488"
		},
		"0xC7143EE587868051eaB403964413606A299A0690": {
			"name": "Lite Bunny Token",
			"symbol": "LITEBUNNY",
			"price": "0.000000000305367999180772351833358055521",
			"price_BNB": "0.000000000000984767394441048678979715370973"
		},
		"0xc999e4188CA9E2777f6Dfa4C6F6171732Ba08045": {
			"name": "SuperDoge",
			"symbol": "SDOGE",
			"price": "0.381570418620921289238819518696",
			"price_BNB": "0.001458458710289446997788034533926"
		},
		"0x5FF53777671d471872134BE8a855e8AB08ea4eAA": {
			"name": "Baby Finance",
			"symbol": "BabyFIN",
			"price": "0.380600927872508675213941192245",
			"price_BNB": "0.001225330918692549874148448422348"
		},
		"0x3Fc1aa5DEB604ae254B95D289Aa76e0ABe2beAF9": {
			"name": "FlokiMOON",
			"symbol": "fMOON",
			"price": "0.3793616583389054464443546888687",
			"price_BNB": "0.00127622384848616367501452033515"
		},
		"0x6EEA3114adbD2183Ad2119506e06DfA40181d818": {
			"name": "MiniMOON",
			"symbol": "miniMOON",
			"price": "0.3781947570260768921050558555446",
			"price_BNB": "0.00126779921706874850978110403096"
		},
		"0xcf9AdC4CCdB888d253cfaB6122719718A040797d": {
			"name": "Binance Inu",
			"symbol": "BSCInu",
			"price": "0.3777988056415607162628965507493",
			"price_BNB": "0.00126822639402677829365027450761"
		},
		"0xA7c327B51328f68d2468695918b1DB17e08a99bD": {
			"name": "FlokiDoge",
			"symbol": "FlokiDOGE",
			"price": "0.3763863748109544753035739857045",
			"price_BNB": "0.001205189360317042663149455066557"
		},
		"0x5D057F1cfA6F857B036Ef43ec6A6DfbcAb32A830": {
			"name": "AquaPanda",
			"symbol": "AquaPNDA",
			"price": "0.376346545648039009031495978702",
			"price_BNB": "0.001424874679011414942215751861233"
		},
		"0x504E7Ebc8C8b9D9422A6aBA220C8b6BA23e34B42": {
			"name": "SuperInu",
			"symbol": "SUPInu",
			"price": "0.3752394977466748456950567475244",
			"price_BNB": "0.001231969531577171816446471961498"
		},
		"0xf009958134897Ecc7A5D1dFBE82A364EE30E134d": {
			"name": "ShibaMOON",
			"symbol": "SHIBAMOON",
			"price": "0.3748880442969203219514271699393",
			"price_BNB": "0.001170346820103594654841598209948"
		},
		"0xF576Eb1ac7A20e12794AE3cBe4a718A4bD9393ec": {
			"name": "Flow Akita Inu",
			"symbol": "FLOWAKITA",
			"price": "0.000000000298364553476859845847927807157",
			"price_BNB": "0.000000000000989900599809197304399466447233"
		},
		"0x54b657B7d1a4e0d0fD01c0892dACC1604Add0FA3": {
			"name": " Black",
			"symbol": "$B",
			"price": "0.3711184265886601426566957175508",
			"price_BNB": "0.001420311095897179970109446642197"
		},
		"0xEb32Ca0d96602176F177178a8c3A37FD557B6e5f": {
			"name": "SpaceEthereum",
			"symbol": "SPACEETH",
			"price": "0.370907494783912976544839570534",
			"price_BNB": "0.001186980311627917969176924354636"
		},
		"0xD8739655d0E27c3010162F0A6ef97DbD37Ead6F3": {
			"name": "AquaPanda",
			"symbol": "AquaPNDA",
			"price": "0.3705982308801593638403991209402",
			"price_BNB": "0.001225229336964846160639200606361"
		},
		"0x0C11BAB4CB1024cdfc80041751bc9a8E8f79837f": {
			"name": "EverInu",
			"symbol": "EverInu",
			"price": "0.3704558337601041059130818050206",
			"price_BNB": "0.001411288993914951990703280237731"
		},
		"0xb3e12D2FfB421b8bcb77D29e922DCba3CF11fF82": {
			"name": "Super Cardano",
			"symbol": "SUPADA",
			"price": "0.3704518422589215128151152156152",
			"price_BNB": "0.00122422855440561229760917843229"
		},
		"0x06108b5e6D377Ef47D0122b47103877Cd2040e75": {
			"name": "Floki Finance",
			"symbol": "FlokiFIN",
			"price": "0.3698383913776887533391462532156",
			"price_BNB": "0.001234282510691213615800090687623"
		},
		"0x5b1F903792eC2D07A746B55F8498FdD8C6d2D81a": {
			"name": "Aqua Black",
			"symbol": "AquaB",
			"price": "0.3691304807498937988450604997963",
			"price_BNB": "0.001231424397368208287099163774183"
		},
		"0xE067Bb88e913aEcac8aCF29De09eD73FF8A42A6e": {
			"name": "SpaceCat",
			"symbol": "SPACECat",
			"price": "0.3651987806774431096343938955767",
			"price_BNB": "0.001214315552407280239177596234748"
		},
		"0x34c285dE991E80EfC76b5c1465d046F50d8664B6": {
			"name": " Ocean Protocol",
			"symbol": "OCEAN 🌀️",
			"price": "0.0000000002914657587600690993964704975926",
			"price_BNB": "0.000000000000588515198711085162826503639581"
		},
		"0x79cA029860544446c586Ca45ff1bBAA0942cD8e4": {
			"name": "Ero Finance",
			"symbol": "ERO",
			"price": "2.912422489262955631440302890747",
			"price_BNB": "0.01015037359374999999999789500283"
		},
		"0x1af954dA5576E4622626349d8cA06c71eA0661de": {
			"name": "Ero Finance",
			"symbol": "ERO",
			"price": "2.910442657429905208495619829835",
			"price_BNB": "0.01015037359374999999999789500283"
		},
		"0x7Eb02Be15f2ef17B2bAac0804015b85279d3374B": {
			"name": "Ero Finance",
			"symbol": "ERO",
			"price": "2.910245663152982172018463178343",
			"price_BNB": "0.01015037359374999999999789500283"
		},
		"0x8Ed893deA02e79277fE881c89F4Cb959824caEa8": {
			"name": "ShibaSafe",
			"symbol": "ShibSAFE",
			"price": "0.3630014306430856723407954425395",
			"price_BNB": "0.00136841935379705508446246824006"
		},
		"0x399AD4D78b154D229a5d4693A1aa17f7F9e67Fb2": {
			"name": "Ero Finance",
			"symbol": "ERO",
			"price": "0.2902756522600577031539739557162",
			"price_BNB": "0.001015234598028232907938819353392"
		},
		"0x39d54dA3a6bc30D1d53DbccfA8ee9a7e1c019075": {
			"name": "FlokiLoki",
			"symbol": "FlokiLOKI",
			"price": "0.362497905217334942302025796478",
			"price_BNB": "0.001368694635403885837977804249175"
		},
		"0xa25f00c333F127b19CDf70092E0A33B1741490C7": {
			"name": "Hundred x  Black",
			"symbol": "100xB",
			"price": "0.3613422417382292828491220407927",
			"price_BNB": "0.001370739982787369734054552685465"
		},
		"0xBd13De1aab1220b037763bcC8db735f7BFA57107": {
			"name": "FlokiCat",
			"symbol": "FlokiCat",
			"price": "0.360878870811313544867749699239",
			"price_BNB": "0.001203783255880996528307401794124"
		},
		"0xa90B85907f7372FD73Ba2cFe6C718Da89663d487": {
			"name": "Binance MOON",
			"symbol": "BSCMOON",
			"price": "0.3601318783301027333835013947446",
			"price_BNB": "0.001198958522186625432003441399149"
		},
		"0x83202C20d4F3a4f29aa8b2063A47a3494922535B": {
			"name": "Panda",
			"symbol": "$PNDA",
			"price": "0.3594682901186743963783303056067",
			"price_BNB": "0.001195976002191024628121813865583"
		},
		"0xf675B1E4fc0b561bE1f5A90155601898f1F28e4b": {
			"name": "Baby Black",
			"symbol": "BabyB",
			"price": "0.358513648787952530376760098936",
			"price_BNB": "0.001189112192997009492519428627869"
		},
		"0x403006d29eD2DFB4a85c128162149B019bFCFa60": {
			"name": "OnlyUpDoge",
			"symbol": "UpDOGE",
			"price": "0.3551545669513481669551780340996",
			"price_BNB": "0.00118045016735252489878262356094"
		},
		"0x9408D0B4d5C744544c7651E69f5F5CF0FcBA71b8": {
			"name": "Mini Cardano",
			"symbol": "miniADA",
			"price": "0.3551407773169783016424526993444",
			"price_BNB": "0.001177498047655853458021251488884"
		},
		"0x550C8897DfEEb5Fa37943F13829ce2D6D5f6C65d": {
			"name": "BabyInu",
			"symbol": "BabyInu",
			"price": "0.353604803543448487265713584375",
			"price_BNB": "0.001339389661116069929456390515463"
		},
		"0xA4Aa8c96dc0C08CbbC79c80DbC47f81FC6F8395C": {
			"name": "MiniInu",
			"symbol": "miniInu",
			"price": "0.352626928595504228253418023092",
			"price_BNB": "0.001346371454412769116766991078733"
		},
		"0x5E38CB27083E7fA247FCF82db3A53c920eDD0333": {
			"name": "ShibaApe",
			"symbol": "ShibAPE",
			"price": "0.342363202990688532091857996855",
			"price_BNB": "0.001142382288667804237622670326528"
		},
		"0x6e9730EcFfBed43fD876A264C982e254ef05a0DE": {
			"name": "Nord Token",
			"symbol": "NORD",
			"price": "0.273101173291650846973052646697",
			"price_BNB": "0.000879195219352205374374903362328"
		},
		"0x11Ecc9b7f1347974c3262524e2b9C0B5df655952": {
			"name": "AquaInu",
			"symbol": "AquaInu",
			"price": "0.3401580150604741624498336640354",
			"price_BNB": "0.001122477305166036217997116452808"
		},
		"0x5b038286768aC7A2F08dFFCc6DA41E1e53135afa": {
			"name": "OnlyUpLoki",
			"symbol": "UpLOKI",
			"price": "0.336962983252871428352890029642",
			"price_BNB": "0.001131172989594670621109772391098"
		},
		"0xAA2633EB2be44A96f531640A8770cEF46711125E": {
			"name": "BabyBitcoin",
			"symbol": "bBTC",
			"price": "0.3361262003724881039607568608235",
			"price_BNB": "0.001095591296278874983527682543703"
		},
		"0x19Dc812322A981b32127175552535740316265A4": {
			"name": "FlokiPanda",
			"symbol": "FlokiPNDA",
			"price": "0.335417189172766016674522233496",
			"price_BNB": "0.001110713730607057151033753232706"
		},
		"0xb6d96Fcb503F9242f86E4F9434614D56992e60dc": {
			"name": "BabyLoki",
			"symbol": "BabyLOKI",
			"price": "0.3336781893796552447715898443166",
			"price_BNB": "0.001037711011096339610291590157174"
		},
		"0x1AE7450B6a2d58B52E44f2Fa9C30D4D3caBD7Cc0": {
			"name": "MiniStrongElon",
			"symbol": "miniSElon",
			"price": "0.333505507410512329559365683403",
			"price_BNB": "0.001094536264997894756073158464704"
		},
		"0xf7DFd0cc76D4DE8FCE1eDc9c553b7311D909BE6B": {
			"name": "ShibaFEG",
			"symbol": "SHIBAFEG",
			"price": "0.332537856545752129842643624834",
			"price_BNB": "0.001091557053919340486420669104727"
		},
		"0x91C727da48617dD00D1dF6D787014F913a8ffdd9": {
			"name": "Mozart Finance",
			"symbol": "Mozart 🎻️",
			"price": "0.0000000002654211378925167346923529075526",
			"price_BNB": "0.0000000000006595707759143810793052568500764"
		},
		"0xDfd7BAa3903583E6ff8eC110723D274E980df271": {
			"name": "MiniPanda",
			"symbol": "miniPNDA",
			"price": "0.3314480862448987846268394485207",
			"price_BNB": "0.001078906471654506000610300463886"
		},
		"0x56d69890C7666287Aa2f78BAE63D72dF82027a04": {
			"name": "Ever Finance",
			"symbol": "EverFIN",
			"price": "0.3307020885180000960565975272915",
			"price_BNB": "0.001086505099732619341777803889453"
		},
		"0xe4be71052Ff356260d8321260f64c4f131B81350": {
			"name": "WrappedInu",
			"symbol": "wInu",
			"price": "0.330548437710697273289791730235",
			"price_BNB": "0.001097614756810082497355547527009"
		},
		"0x28Eb9fE1f17633093b03eb3d862EC648C93Ea26E": {
			"name": "AquaInu",
			"symbol": "AquaInu",
			"price": "0.3299601636149426426898958682945",
			"price_BNB": "0.001083168359273718913586149720655"
		},
		"0xD27Fb367D6A4012148a0760D0AaD3847FBB7D08C": {
			"name": "FlokiMOON",
			"symbol": "FlokiMOON",
			"price": "0.3271880213348883603369606058805",
			"price_BNB": "0.00124380734281590170151115821526"
		},
		"0x80065b58B0eE4a069be48A5B6146CE29BdA69C26": {
			"name": "MiniPanda",
			"symbol": "miniPNDA",
			"price": "0.3270702295768799730241317987637",
			"price_BNB": "0.001245312510598117047849580156085"
		},
		"0x69294418324E081eC823CAc603abf678238f5164": {
			"name": "Ass Fucker inu",
			"symbol": "Ass 🍑️",
			"price": "0.0000000002614159932224885806805674883315",
			"price_BNB": "0.000000000000609663301621073395617063244852"
		},
		"0xd3914274407d3E7Ce295f7B763a6E8efcB3303b2": {
			"name": "SpaceMOON",
			"symbol": "SPACEMOON",
			"price": "0.3251970983178237751101420336543",
			"price_BNB": "0.00106504255148249854723143743608"
		},
		"0x0F94D648F458Eb676446574f697D70f682a74D1d": {
			"name": "Hundred x Doge",
			"symbol": "100xDOGE",
			"price": "0.324752727354165526481566029028",
			"price_BNB": "0.001065485416099597032905794510049"
		},
		"0x98225bb02eC95c1EC7575326A20eaac314856594": {
			"name": "MiniSafe",
			"symbol": "miniSAFE",
			"price": "0.3237842296697399677768801003564",
			"price_BNB": "0.001061400204571299328485784201402"
		},
		"0xC8A1cc36Ea7512Cd30dc6551B36E6DE6c5eB2D58": {
			"name": "ShibaRocket",
			"symbol": "SHIBARocket",
			"price": "0.322874093921052329793703537297",
			"price_BNB": "0.001057171630224293494138143436184"
		},
		"0x43536e4c0C3B6B0c1777dC12bC0Bde730560795E": {
			"name": "SpaceCash",
			"symbol": "SPACECASH",
			"price": "0.3227569081007099398327534663506",
			"price_BNB": "0.001070018809769495243947370451506"
		},
		"0x019adAE921DFBC60ee0fA058AeB4F9BF95a84386": {
			"name": "Super Finance",
			"symbol": "SFIN",
			"price": "0.3227118735005917533857653287918",
			"price_BNB": "0.001080662643037118954519440079852"
		},
		"0x3b7d78baE8f9EF467884Bc81a0BF5c99c9A47Dc5": {
			"name": "Binance Inu",
			"symbol": "BSCInu",
			"price": "0.3215374488953349101347825730432",
			"price_BNB": "0.00106448494397025633826161577454"
		},
		"0xCCfB76baa005F813b7c53295F4A86337a358e029": {
			"name": "NanoSafe",
			"symbol": "NanoSAFE",
			"price": "0.3215291225971389583434406308476",
			"price_BNB": "0.001234987396255172020604376526575"
		},
		"0x5fBbE9Ea1CAEe2Fd4028f6289F6d190009fC71bD": {
			"name": "BabyMOON",
			"symbol": "bMOON",
			"price": "0.3215009786630595060335718555457",
			"price_BNB": "0.00122952205045316093301908159568"
		},
		"0xb046f1bBe91Ca1365F2641C986A820133A460916": {
			"name": "ShibaFEG",
			"symbol": "ShibFEG",
			"price": "0.3213578365350558708044130366598",
			"price_BNB": "0.001058033885695939633339189429368"
		},
		"0xBe4dB1ec86F92d3E4ed627FC9D383eE8253F2600": {
			"name": "SpaceMOON",
			"symbol": "SPACEMOON",
			"price": "0.320886495734489890799607411917",
			"price_BNB": "0.001062692234191958295942814413732"
		},
		"0x00e86B9A2cFF00e8c0b1e4436f89e586D8d06e53": {
			"name": "FlokiBNB",
			"symbol": "fBNB",
			"price": "0.320439187097720971367021832173",
			"price_BNB": "0.001049344696755765695886040183744"
		},
		"0xc5f6fb0dBA52a3aE1d79183650783730B5155582": {
			"name": "Dash Corgi Doge",
			"symbol": "DASHCORGI",
			"price": "0.000000000256271038348279007206867600733",
			"price_BNB": "0.0000000000009046116574376345853919142753155"
		},
		"0x2010525c4BC41Df91BC0945D25294A2B90BC1256": {
			"name": "FEGBNB",
			"symbol": "FEGBNB",
			"price": "0.319038586679128669596849461177",
			"price_BNB": "0.001070466368531882871933037828397"
		},
		"0xE797EB4fb0dCc92608B5028B1CfFA8Ed59e38A16": {
			"name": "ShibaMOON",
			"symbol": "SHIBAMOON",
			"price": "0.317919641865678510166101697789",
			"price_BNB": "0.001121090925818322122693339756127"
		},
		"0xd18b4479c1A6708E4D015621881a8af1d75F1513": {
			"name": "FlokiRocket",
			"symbol": "FlokiRocket",
			"price": "0.3151710187903504700447072791705",
			"price_BNB": "0.001040878921546670932916000951064"
		},
		"0x6a09a8f4b0E185985ec72F9DefE20822d4C1021a": {
			"name": "EverPanda",
			"symbol": "EverPanda",
			"price": "0.3149232298767815724715702504612",
			"price_BNB": "0.00103388107218853621004918635013"
		},
		"0x07BD2980ABf45349E460Dd8d3380c97F3aB8932d": {
			"name": "WrappedCat",
			"symbol": "wCat",
			"price": "0.314805938628442067688175040937",
			"price_BNB": "0.00123243941662156520929463681626"
		},
		"0x896bEB516a42068229f098388387fa6EEF4364A4": {
			"name": "AquaRocket",
			"symbol": "AquaRocket",
			"price": "0.310800520719754218315067113372",
			"price_BNB": "0.001026074798298885246428724863351"
		},
		"0xD7A39b6F6aA1Ab550D609D9a4b67Ad043b727F0B": {
			"name": "MiniSafe",
			"symbol": "miniSAFE",
			"price": "0.3086845055058484543809434064495",
			"price_BNB": "0.001018354986526963814962328359732"
		},
		"0xBB193399Fa1d2Cc654f54b64ddB8305126f641Dd": {
			"name": "NanoSushi",
			"symbol": "NanoSushi",
			"price": "0.308245931847886611219002858768",
			"price_BNB": "0.001036151314753973042295321820038"
		},
		"0x7bc56B6EFd397f75988779e763ECd70c0C6012C1": {
			"name": "BabyCake",
			"symbol": "BABYCAKE 👶🏻️",
			"price": "0.0000000002455995483281601184994757140805",
			"price_BNB": "0.000000000000618298503767578711573079381722"
		},
		"0x54dbE7A5b82041b284a7162841DaB236Fa578A39": {
			"name": "MiniPanda",
			"symbol": "miniPNDA",
			"price": "0.3069556584565759379394681304934",
			"price_BNB": "0.001183981996087528082989270126012"
		},
		"0x9A98C85bD1aeFf1d4268da377864a3C716494a90": {
			"name": "FEG",
			"symbol": "$FEG",
			"price": "0.3066971689462242306738464237206",
			"price_BNB": "0.001003964024907577231635376838502"
		},
		"0x9743211F12941aC1A27DDA2fD12ffc629EdEf75e": {
			"name": "ShibaPanda",
			"symbol": "SHIBAPNDA",
			"price": "0.3054668667783653133069327642785",
			"price_BNB": "0.00114597289237839796428491617139"
		},
		"0xFc9DEac799E8Cde587171E73BB42111064Dbcd42": {
			"name": "ShibaBNB",
			"symbol": "ShibBNB",
			"price": "0.305424057456691296162564495082",
			"price_BNB": "0.00114499193226966124294855433267"
		},
		"0x08b84bdEF03F4af3700e80a9dA5E230A8e5762F5": {
			"name": "Mini Hippo Token",
			"symbol": "MiniHIP",
			"price": "0.0000000002433428593823074278534009035454",
			"price_BNB": "0.000000000000797664180848956727710478091253"
		},
		"0x89B3EBb4A601D28Fe0d564b130360Db92066Cc27": {
			"name": "Hundred x FEG",
			"symbol": "100xFEG",
			"price": "0.303564329324157759986178633983",
			"price_BNB": "0.001007338874681066340648288227104"
		},
		"0xC402e98745F2b45BF356c98a210de26Fb116A12D": {
			"name": "SuperDoge",
			"symbol": "SUPDOGE",
			"price": "0.3019573394879932548452469098193",
			"price_BNB": "0.001006162740226771449886674259777"
		},
		"0x32fC99acA8CAaF452a015C6989F096F882D758B3": {
			"name": "Mini Shiba Pro",
			"symbol": "MINISHIBAPRO",
			"price": "0.0000000002413073323229253779109404740263",
			"price_BNB": "0.000000000000807906875636463815922574092134"
		},
		"0x7588074261C167794898F1412603e6BAA3a4256e": {
			"name": "WrappedStrongElon",
			"symbol": "wSElon",
			"price": "0.300626002441005629715931045494",
			"price_BNB": "0.001019622232932648459006773081493"
		},
		"0xae3Dec98F91fF2D0fD8c1f81E8Bb9b7f5d83fae6": {
			"name": "AquaBNB",
			"symbol": "AquaBNB",
			"price": "0.2997519727872669967819392689675",
			"price_BNB": "0.001013749489273689025774506760533"
		},
		"0xFE52f74017baD79040d4203286531223d8d5D613": {
			"name": "MiniRocket",
			"symbol": "miniRocket",
			"price": "0.2994234709104530461589753156568",
			"price_BNB": "0.000990360443320776661421420434159"
		},
		"0x535b44ED84bD1F5D89f9B078e708793B7cd376De": {
			"name": "FlokiDoge",
			"symbol": "FlokiDOGE",
			"price": "0.299281187894767721078320959638",
			"price_BNB": "0.001133940395347840323801579824129"
		},
		"0xE8266ED175C927c57e0Bd29064d2E284e2a6fdC9": {
			"name": "FlokiDoge",
			"symbol": "fDOGE",
			"price": "0.2991979073245937864232128493746",
			"price_BNB": "0.001012523169389766890131333588484"
		},
		"0xbA4b23C8a0665C9407d9604D3F81E8Da41796e4E": {
			"name": "WrappedRocket",
			"symbol": "wRocket",
			"price": "0.2986084567009657108985793257238",
			"price_BNB": "0.00113155061531918982951305775895"
		},
		"0xf713D0222109090001e031ac52Cd5c6fbb57BC09": {
			"name": "SpaceLoki",
			"symbol": "SPACELOKI",
			"price": "0.29776879892467154777800552977",
			"price_BNB": "0.000984267887634701282822019521228"
		},
		"0x65bf986dA8a974f5e530CfF60eD502dce3f2c4E8": {
			"name": "BabyFEG",
			"symbol": "bFEG",
			"price": "0.295960327979852547625069002148",
			"price_BNB": "0.000992176139735143643928791685058"
		},
		"0x17B68B67e2A6b952E681206ddE06324B5fAa48E5": {
			"name": "WrappedMOON",
			"symbol": "wMOON",
			"price": "0.2957732675764578633941438422406",
			"price_BNB": "0.0007667640835344663515002484268384"
		},
		"0x28aa84C51f886d8d6385EF259fF95f0EAC081076": {
			"name": "EverBoxer",
			"symbol": "EverBOX",
			"price": "0.2952880100396737408231497218237",
			"price_BNB": "0.001119194928494628549701388760591"
		},
		"0x3E727B12ECf413C1903d4Ab6d2B59BDf429cFaC2": {
			"name": "FlokiDoge",
			"symbol": "FlokiDOGE",
			"price": "0.2948695071011227714546137761495",
			"price_BNB": "0.001106974941491491677529096968772"
		},
		"0x934b3342e33b50F3966DA81CE907a69F18c1897B": {
			"name": "SuperFEG",
			"symbol": "SUPFEG",
			"price": "0.2947939589102503931131132358673",
			"price_BNB": "0.000975890863692650088921922802294"
		},
		"0x5F13B2c5A8bde01DFc5148781d3803C5dbC4024D": {
			"name": "SuperApe",
			"symbol": "SUPAPE",
			"price": "0.2942206367641802691511950743865",
			"price_BNB": "0.001111616505196100940582062242618"
		},
		"0x1B933C9124Ab3EB6644A39Ea835393EF60260CA9": {
			"name": "Rocket",
			"symbol": "$Rocket",
			"price": "0.2937400015453365430748069128756",
			"price_BNB": "0.001110282047667602749006285173452"
		},
		"0xE819f1cCF908AB4848d9E24649EB8fE7B14A57fd": {
			"name": "File Doge Coin",
			"symbol": "FILDOGE",
			"price": "0.0000000002349537895342217248886852736586",
			"price_BNB": "0.000000000000800780023093329218993921149054"
		},
		"0xE16b35f03CC16b4549dBDd0514302061CFeEE53e": {
			"name": "Nano Cardano",
			"symbol": "NanoADA",
			"price": "0.2930675333067605989141711315277",
			"price_BNB": "0.001109873877389860466661760262639"
		},
		"0x8dA0AE17E42E5b2F579Dc8e25F4302043616466B": {
			"name": "OnlyUp Finance",
			"symbol": "UpFIN",
			"price": "0.292845054363956205029987025347",
			"price_BNB": "0.000961205758806716546133109009206"
		},
		"0xDb14535d0D9c0234aB82a8aF9F6B83FdA4808B41": {
			"name": "Binance MOON",
			"symbol": "BSCMOON",
			"price": "0.2922764349522237230934070791",
			"price_BNB": "0.001103195518577120371597525725214"
		},
		"0xfDbf2f8b57721eEAc91143825c661fA5E985BA70": {
			"name": "WrappedRocket",
			"symbol": "wRocket",
			"price": "0.291595190736543762983479375278",
			"price_BNB": "0.000952742213924829807646211852363"
		},
		"0xC2F5bFE3b50167fC03BE819bf6c7CA624Cc2642A": {
			"name": "Floki Cardano",
			"symbol": "FlokiADA",
			"price": "0.290839114137395518265106529238",
			"price_BNB": "0.001087775992283058617481703932447"
		},
		"0x68444269870d789D302DD03c67A02731a548c3A9": {
			"name": "FlokiStrongElon",
			"symbol": "fSElon",
			"price": "0.290032336675913113263275270634",
			"price_BNB": "0.0009482683283235329530554292644125"
		},
		"0x8fE68464D89adE45184Fd0a1fde01D433C26D69b": {
			"name": "SpaceDoge",
			"symbol": "SPACEDOGE",
			"price": "0.289375149354052946820724250005",
			"price_BNB": "0.000961770593421439957144014156493"
		},
		"0xF72bbeDb260941E45A2749D36CA88186cA0886d9": {
			"name": "Hundred x Ethereum",
			"symbol": "100xETH",
			"price": "0.2887385276850069751406584259753",
			"price_BNB": "0.000943451014217072311333738443887"
		},
		"0x5B4d10714405f1CDB6b74d1cBc71736D1A9B3324": {
			"name": "NanoRocket",
			"symbol": "NanoRocket",
			"price": "0.288314393458049484117239639963",
			"price_BNB": "0.001089665732357970965790072308388"
		},
		"0x9DeE116036B55beCA1C645acCddFc7f355535EcC": {
			"name": "OnlyUpDoge",
			"symbol": "UpDOGE",
			"price": "0.287259136284369485675656213144",
			"price_BNB": "0.000970808074720496736909770565747"
		},
		"0xea644f2af65989Fa2dEb52Dd0dfa7265Ff127526": {
			"name": "SuperLoki",
			"symbol": "SLOKI",
			"price": "0.2868581914529918526585445606915",
			"price_BNB": "0.000936887050789191094828933165275"
		},
		"0x6487D77eeab5F034fE984B72FAa9d3EC04153321": {
			"name": "WrappedEthereum",
			"symbol": "wETH",
			"price": "0.2868054054199788902530291688373",
			"price_BNB": "0.0009383648265349000665062166174535"
		},
		"0xdc4658bdf0B5568145AfB5C47389e3D205863789": {
			"name": "Super Finance",
			"symbol": "SUPFIN",
			"price": "0.2865127744623442411559884566986",
			"price_BNB": "0.000940633042057154965152488178469"
		},
		"0xbd411Fd8114D9A638DC0D70C1B02425764395C59": {
			"name": "SuperApe",
			"symbol": "SAPE",
			"price": "0.286416896201693220917556798279",
			"price_BNB": "0.000940291615120621879519218049668"
		},
		"0xB071Cf159409bB52E7b4493e9889b9249Ece5A1F": {
			"name": "SpaceBNB",
			"symbol": "SPACEBNB",
			"price": "0.286226822696550756618116404573",
			"price_BNB": "0.000940305624734747697499905670729"
		},
		"0xA92678b3509Bb031100f068517E8dA2eba85F99c": {
			"name": "EverMOON",
			"symbol": "EverMOON",
			"price": "0.285564844269580987780579858844",
			"price_BNB": "0.000935440081891201947626713070419"
		},
		"0x8E0FeEE83e35269420Cb136BF9e7447C83760F18": {
			"name": "BabyFEG",
			"symbol": "bFEG",
			"price": "0.285281317033292395855677805903",
			"price_BNB": "0.0009663293381161909942608110698535"
		},
		"0x661A1A9768Ac46D1dcEea82e9beCc10EE81cC249": {
			"name": "SuperCat",
			"symbol": "SCat",
			"price": "0.2849422756250740999056621761504",
			"price_BNB": "0.001089546132289555119784485632349"
		},
		"0x11696BDc2D6Fbef3f5f35a4CDCc1eba4a831fC3C": {
			"name": "FEGPanda",
			"symbol": "FEGPNDA",
			"price": "0.282258073927188718067367186148",
			"price_BNB": "0.0009239023178977116768297083332084"
		},
		"0x668A2F170cC21a7A1C47AaEc38B0e6D4622ed4b9": {
			"name": "Super Bunny Token",
			"symbol": "SBUNNY",
			"price": "0.0000000002257268896286070786275490964034",
			"price_BNB": "0.000000000000699494048320974661687833984075"
		},
		"0xc47e8f21D5fAe6D00ECa228DCF0af08b8EFBea12": {
			"name": "MiniSafe",
			"symbol": "miniSAFE",
			"price": "0.2812514416052642515882125076952",
			"price_BNB": "0.000920480681217935507766996697745"
		},
		"0xfD5B7cf1363507D9f47fa24940df70fc64AB08fF": {
			"name": "SpaceInu",
			"symbol": "SPACEInu",
			"price": "0.2807960417410926583618462565216",
			"price_BNB": "0.000919445366813686294331250170685"
		},
		"0xe68FbD7E6e2AA8401Fa770Fa54C683E31469779F": {
			"name": "ShibaPanda",
			"symbol": "ShibPNDA",
			"price": "0.2805131218134045164160380871424",
			"price_BNB": "0.000928689073063874312566380786854"
		},
		"0x9407eb1172424CbDbB6775EA0DbC312f36a36E51": {
			"name": "FEGMOON",
			"symbol": "FEGMOON",
			"price": "0.2798832857593358104220649593927",
			"price_BNB": "0.00091706280710118090625096122031"
		},
		"0xF0C1b4d524c0b640c5ff098Ea9a89739fb6fbeBA": {
			"name": "Space Cardano",
			"symbol": "SPACEADA",
			"price": "0.279100885555850710497047787723",
			"price_BNB": "0.00106998645733213620655280657448"
		},
		"0xf72B067c9fa744A4a57189d398ba1322A0BC0a73": {
			"name": "ShibaPanda",
			"symbol": "ShibPNDA",
			"price": "0.277329392411877933265970993343",
			"price_BNB": "0.000933002107994138741173295086322"
		},
		"0x57b51564EfF8760129B20829cC4f62cEa6aBE86D": {
			"name": "NanoPanda",
			"symbol": "NanoPanda",
			"price": "0.2771073174030194355483474341753",
			"price_BNB": "0.001060214709590614015504697559462"
		},
		"0xe815436B7896eCc6063129534cDe7b6D6F181bB4": {
			"name": "Mecha Akita Inu",
			"symbol": "MECHAKITA",
			"price": "0.00000000022158934642064523456936227077",
			"price_BNB": "0.000000000000792744934471687849076426444633"
		},
		"0x130Ec47a1aA556839826a03Af4d23F17a6B7C21A": {
			"name": "Binance Boxer",
			"symbol": "BSCBOX",
			"price": "0.27621212764809252289406315202",
			"price_BNB": "0.0009062122106840275596652823938394"
		},
		"0x48b8355FeEBa52D4ebC933Ef84cc25C3d035EfDA": {
			"name": "Hundred x Doge",
			"symbol": "100xDOGE",
			"price": "0.275475949508730088657203843863",
			"price_BNB": "0.000904718483186938975178253621751"
		},
		"0xea8107C7aa3aebb949d0E1A152a4190348A71E5D": {
			"name": "FlokiBoxer",
			"symbol": "fBOX",
			"price": "0.2747961910564449728449942417952",
			"price_BNB": "0.00090200184243920208456014444411"
		},
		"0x4823B96A9BaB6dD17B46e441C614c100283b99ed": {
			"name": "Panda",
			"symbol": "$Panda",
			"price": "0.274790211862720739931337276383",
			"price_BNB": "0.000899619483660912077441067477133"
		},
		"0xD7c950e1AacA955cE459b13e04aB727D90ecb2B6": {
			"name": "AquaMOON",
			"symbol": "AquaMOON",
			"price": "0.2731148586587474871856545356637",
			"price_BNB": "0.000937315641632448837986293030714"
		},
		"0x800e7350ebB59C757B4895649Fc062097B74814B": {
			"name": "BabyPanda",
			"symbol": "BabyPanda",
			"price": "0.2727828190573108260292098818935",
			"price_BNB": "0.000924524968185399698862054810483"
		},
		"0x3c3051662f7B0624C14F04E27AA3684Dd8048eb5": {
			"name": "Binance Panda",
			"symbol": "BSCPanda",
			"price": "0.2727784652700818693692684665607",
			"price_BNB": "0.000921036528338978548973093382478"
		},
		"0x9997144dA758bECe0843C14722f83D7302599BaA": {
			"name": "FlokiLoki",
			"symbol": "fLOKI",
			"price": "0.2705660186945730337184940865553",
			"price_BNB": "0.000914225689378409975535533714523"
		},
		"0xE7A44Fa1E3FE02D457A8c613357e2f04d78b1CA6": {
			"name": "SuperApe",
			"symbol": "SUPAPE",
			"price": "0.2678135798860642589722329160623",
			"price_BNB": "0.0009050882113787827498334258837414"
		},
		"0xb82aCdBd7d7684efF3BDF77A78ac8336617c04Ae": {
			"name": "NanoApe",
			"symbol": "NanoAPE",
			"price": "0.266776048762591345468938165514",
			"price_BNB": "0.000909764538333350224662536755497"
		},
		"0xf69Ea0060518938D4D127ea5a82A03AD2373dcbe": {
			"name": "BabyPanda",
			"symbol": "bPanda",
			"price": "0.2666698730647202341486359187837",
			"price_BNB": "0.000900619713592849355376566604984"
		},
		"0x909942c310AF3130d9940fABd661DDc7252b3DD2": {
			"name": "FEG",
			"symbol": "$FEG",
			"price": "0.2660876862876096635791122779928",
			"price_BNB": "0.000899442211042776585332272602769"
		},
		"0x1b63005E96Af2850E3A56EdbC8cE0FDEF3aA0d64": {
			"name": "AquaMOON",
			"symbol": "AquaMOON",
			"price": "0.2660397546915984951267169938723",
			"price_BNB": "0.0009140210969739334150796146334006"
		},
		"0x79839dae8d0a30685272F5FCff5864c7a3BACD7c": {
			"name": "WrappedLoki",
			"symbol": "wLOKI",
			"price": "0.265586916113890180283445832981",
			"price_BNB": "0.000903681792789573663264262998354"
		},
		"0xBC3B5413e7E21F774626EA28668177532DF9E620": {
			"name": "AquaApe",
			"symbol": "AquaAPE",
			"price": "0.2646429609019724837641971595255",
			"price_BNB": "0.000903360315216990604986349177486"
		},
		"0x572962B2FE8dfC33681cEd1767CC722D9f1DE8b8": {
			"name": "MiniMOON",
			"symbol": "miniMOON",
			"price": "0.264497301473357720287314787697",
			"price_BNB": "0.000904006164361005658330301995488"
		},
		"0xF56eA6877C2D05396Db31393855C18d6719d4080": {
			"name": "ShibaInu",
			"symbol": "SHIBAInu",
			"price": "0.2634415953001565202233791748998",
			"price_BNB": "0.000897907920826245651154525448358"
		},
		"0x115a8557b90BeFF577d0B265E6A952d0977e5e51": {
			"name": "Hundred x Doge",
			"symbol": "100xDOGE",
			"price": "0.2631592111649248254123135512643",
			"price_BNB": "0.000861413686812991431499207864409"
		},
		"0x9F18ad2b81F0ab68bf1eD8Ba68758bE57C593e51": {
			"name": "Hundred x Cash",
			"symbol": "100xCASH",
			"price": "0.2630087279225119737819129600352",
			"price_BNB": "0.000903008823804326334325262191099"
		},
		"0xB25A3c0E95F3aB117a5a85a6FCa1783f7655Ff22": {
			"name": "WrappedStrongElon",
			"symbol": "wSElon",
			"price": "0.2630032283160685987687356100658",
			"price_BNB": "0.000860018286180475873447813463068"
		},
		"0x873863FB6338D0D78BACf13911E83843c5Df5337": {
			"name": "NanoBNB",
			"symbol": "NanoBNB",
			"price": "0.2625903692031522861689161962344",
			"price_BNB": "0.000853273021647743953852674840803"
		},
		"0xcCbb595fca34748654f5aFF8D384282aaab6980E": {
			"name": "Aqua Black",
			"symbol": "AquaB",
			"price": "0.2624428370183911308992611631653",
			"price_BNB": "0.0009299370013594534306099121487224"
		},
		"0xE1785bD4d0166C3783970335907a98Df4e13f18B": {
			"name": "My Doge Coin",
			"symbol": "MYDOGE",
			"price": "0.0000000002099520353198618162030300088466",
			"price_BNB": "0.000000000000661155897708452532372769582122"
		},
		"0xD005B2F7348C26d8cB836a4A65699DE46b1dD401": {
			"name": "MiniCat",
			"symbol": "miniCat",
			"price": "0.2622299327873852114145874038284",
			"price_BNB": "0.000857948716593299531304173295755"
		},
		"0x1DDB7b5a76e5975d63D07eE1461305E270A80Bfb": {
			"name": "WrappedSafe",
			"symbol": "wSAFE",
			"price": "0.261916994536047591231817478753",
			"price_BNB": "0.00092738385882586362121663616503"
		},
		"0xf84ae9E4f5Ea99fbA1bBA3144f6BEA21eD54cf6b": {
			"name": "NanoBoxer",
			"symbol": "NanoBOX",
			"price": "0.26134070320776911052003153491",
			"price_BNB": "0.00098066751479015826565081718182"
		},
		"0x8CB98E1cA11B84E83C31b2155e96B1b1bc898F01": {
			"name": "Binance BNB",
			"symbol": "BSCBNB",
			"price": "0.2610590688249207805135952861787",
			"price_BNB": "0.000926299365127436557650015817426"
		},
		"0xcb5527E1e0661BEF8e11f2A4cd51e7231d4Af9D4": {
			"name": "NanoPanda",
			"symbol": "NanoPNDA",
			"price": "0.2609875526625385405565123531272",
			"price_BNB": "0.000855465209168855593623444930837"
		},
		"0x6Eb268Da347e6edF8A2529213344e0109830678F": {
			"name": "FEGBoxer",
			"symbol": "FEGBOX",
			"price": "0.26091951653278294748088943091",
			"price_BNB": "0.0008546816273667384881003036292204"
		},
		"0x21F64D66b21D950bC7684359A8e55093E6B699eC": {
			"name": "AquaBNB",
			"symbol": "AquaBNB",
			"price": "0.2605538322367256354401087856813",
			"price_BNB": "0.0009734367700095338321632543256864"
		},
		"0x3a45a0781cC27E65f5CA28cF006a5623168F9786": {
			"name": "Pro Shiba Inu",
			"symbol": "PROSHIB",
			"price": "0.0000000002080893605448508326848207737292",
			"price_BNB": "0.000000000000694092600606766365841210292614"
		},
		"0x497052BB5f93F3624FEc84D1F3fDC70c4fE266b8": {
			"name": "Hundred x  Cardano",
			"symbol": "100xADA",
			"price": "0.260075717841662624699568939726",
			"price_BNB": "0.000969860557908419199226377615528"
		},
		"0x2d7120E784A617DA4c97cC1108EE764ecC2A4CE3": {
			"name": "BabyPanda",
			"symbol": "BabyPanda",
			"price": "0.2599264815063633163013781889945",
			"price_BNB": "0.00084706353889091116794582808107"
		},
		"0xb0e5954c982967527E145b3221a804bC33615212": {
			"name": "My Corgi Doge",
			"symbol": "MYCORGI",
			"price": "0.000000000207839175993170750939009763838",
			"price_BNB": "0.00000000000067452679313140581586857529436"
		},
		"0x059e9922f3fDBED3dDcF7D92c24ba87B50442736": {
			"name": "Binance MOON",
			"symbol": "BSCMOON",
			"price": "0.2594102361042317383111758801426",
			"price_BNB": "0.000949777119395427671198756159154"
		},
		"0xcA201A477beb70c42049bf6Ed7b6E810dd55e7E7": {
			"name": "Hundred x MOON",
			"symbol": "100xMOON",
			"price": "0.2593480844409150595597620514394",
			"price_BNB": "0.000954469595540252297876668795297"
		},
		"0xD6B320ED8088f7F2255f66a28f378957F5d875c4": {
			"name": " Black",
			"symbol": "$B",
			"price": "0.259265436000982772505913135797",
			"price_BNB": "0.000849407756935165701533331160772"
		},
		"0x453ed4430617504277EC6B51aa677cE4423749d7": {
			"name": "Aqua Black",
			"symbol": "AquaB",
			"price": "0.259059921751312916003311450022",
			"price_BNB": "0.000849108433427012425919839901364"
		},
		"0xB7F618Fd2BfB4D50fAe83750d31B2b67E367c309": {
			"name": "MiniBoxer",
			"symbol": "miniBOX",
			"price": "0.2587494232996702466378464775642",
			"price_BNB": "0.000883857916034842158352806824744"
		},
		"0x04cbB1cC7a8244304B24C59f4fF85eB78f13df9A": {
			"name": "AquaBNB",
			"symbol": "AquaBNB",
			"price": "0.258554587721825073531375672265",
			"price_BNB": "0.000938314365934450962174656320338"
		},
		"0xeCad934bb9c88a191d809ad7CF7efA1946565607": {
			"name": "ShibaSafe",
			"symbol": "SHIBASAFE",
			"price": "0.258270378819290362258378306105",
			"price_BNB": "0.000884301606730174291728914315634"
		},
		"0x2906F15927469090212Ef2d0643ec2eb63E7559F": {
			"name": "Hundred x BNB",
			"symbol": "100xBNB",
			"price": "0.2582107894102330225029710466177",
			"price_BNB": "0.000836991482402339012230820691835"
		},
		"0x8D9BE1f85383EC96750E1e3952dC5668C54ACab6": {
			"name": "Super Black",
			"symbol": "SB",
			"price": "0.2579118958165794047406486721964",
			"price_BNB": "0.0008928784002209025573059938420585"
		},
		"0xbE89C5702F0d27829434495F78edA09E29913548": {
			"name": "SpacePanda",
			"symbol": "SPACEPNDA",
			"price": "0.257771367750819017568304907642",
			"price_BNB": "0.0008828551473505140618097403645574"
		},
		"0xc2BED90CceD762429ad7cdFD430677a870AFD3a7": {
			"name": "AquaInu",
			"symbol": "AquaInu",
			"price": "0.2576653711474798799095517879752",
			"price_BNB": "0.000848327213904999536008019549092"
		},
		"0x7C3fF522b10Eb76bf0dA611c271a129636e727D6": {
			"name": "Hundred x Cash",
			"symbol": "100xCASH",
			"price": "0.256602829029938001351126557104",
			"price_BNB": "0.000942587249404632613190232071855"
		},
		"0x1145AbF6f33Ba5aA739Ed65d4466DeCE16Dfdf0F": {
			"name": "EverPanda",
			"symbol": "EverPanda",
			"price": "0.256147848519770097414701688451",
			"price_BNB": "0.0009366837632766956444496072726554"
		},
		"0x5BB2440a9806cda8c4d1B0c4A6cE8675D7B1BbE1": {
			"name": "BabyBitcoin",
			"symbol": "BabyBTC",
			"price": "0.2555558262846170530635441481157",
			"price_BNB": "0.000676864682273888304490361682675"
		},
		"0xd384333Ff737B57C1d314Fb3003932d85e329e93": {
			"name": "Space Black",
			"symbol": "SPACEB",
			"price": "0.2554667751271717287123142216886",
			"price_BNB": "0.000837664600628171290910992140794"
		},
		"0xB2477fC8b118e25242C7cd705A441b65Eac8Dd41": {
			"name": "AquaPanda",
			"symbol": "AquaPNDA",
			"price": "0.254707103407726655228551270761",
			"price_BNB": "0.000838934958106305967913251631058"
		},
		"0x582Af9892E2d43620cE492Ef6A423f626AB98Ae1": {
			"name": "SuperInu",
			"symbol": "SUPInu",
			"price": "0.4073336804321779906595963158055",
			"price_BNB": "0.00135865596565389790218531134458"
		},
		"0x46B148131d708F2bad36432aEf4d8F7b17532407": {
			"name": "FlokiSafe",
			"symbol": "fSAFE",
			"price": "0.2543499377766446738997304446637",
			"price_BNB": "0.000832920846607169910877238032672"
		},
		"0x567CFBACAC647ED8BecCEEA38FA51348875c7fb9": {
			"name": "Binance Bitcoin",
			"symbol": "BSCBTC",
			"price": "0.254065873293694189225932353002",
			"price_BNB": "0.000871304781026639872622692521127"
		},
		"0x4F13bDD8823F68c1F7B384E5d54bD72e2F5cF27d": {
			"name": "MiniCash",
			"symbol": "miniCASH",
			"price": "0.2537996877203290179857477232856",
			"price_BNB": "0.0008368757245677296806072281932704"
		},
		"0xd4bf81A1C6d75E3CCCee69321D3f73dcC1f787be": {
			"name": "FEG Finance",
			"symbol": "FEGFIN",
			"price": "0.2533642709247055225628374438753",
			"price_BNB": "0.000829766921273587478031834455857"
		},
		"0x51e00a95748DBd2a3F47bC5c3b3E7B3F0fea666c": {
			"name": "DVGToken",
			"symbol": "DVG",
			"price": "0.202373546421146879800933621418",
			"price_BNB": "0.000630488242361236473668178256231"
		},
		"0x3040b6799fc6F2Dc259a115BC71726CE3E6D33c0": {
			"name": "ShibaCash",
			"symbol": "SHIBACASH",
			"price": "0.2525193869813373674441586102954",
			"price_BNB": "0.000833599672247505017956806689627"
		},
		"0x885682f327C9DB527C63a551Dd0691e383B776Ac": {
			"name": "Lite Shiba Token",
			"symbol": "LITESHIB",
			"price": "0.0000000002018540570055507982825617001081",
			"price_BNB": "0.000000000000682915979116666521451658562305"
		},
		"0x89380E89369690D988c7E9f397AF3d788CC5f185": {
			"name": "Mini Finance",
			"symbol": "miniFIN",
			"price": "0.251292314769131179549652464456",
			"price_BNB": "0.0008758739911274719944398526974886"
		},
		"0x1d8b1C0938De615B5Fb2925D53B4fDE78193416E": {
			"name": "SuperCat",
			"symbol": "SUPCat",
			"price": "0.250971478537931304849614020283",
			"price_BNB": "0.00081506738585684151700349390465"
		},
		"0xCdc46a14c7f8274DBcB87311A8c92BF5f8283322": {
			"name": "Binance Loki",
			"symbol": "BSCLOKI",
			"price": "0.250217883174509282560950451362",
			"price_BNB": "0.000826002069570404639370708884532"
		},
		"0x3479253110e4fbc30b5a978f5D44320bBB5D2D10": {
			"name": "FEGDoge",
			"symbol": "FEGDOGE",
			"price": "0.2500979169966277889322299885757",
			"price_BNB": "0.00081167644302064822434468351665"
		},
		"0x0840f9eb77836d25162E0f8C683E9F579C8202e4": {
			"name": "SpaceBNB",
			"symbol": "SPACEBNB",
			"price": "0.2492967718125677348193152700993",
			"price_BNB": "0.00081275038454424203954143659004"
		},
		"0x50eD0Bcad1be73adfE642421DDa502f7753AaC85": {
			"name": "Hundred x Doge",
			"symbol": "100xDOGE",
			"price": "0.2492545792029604420928101999417",
			"price_BNB": "0.000821898708573014072153323057678"
		},
		"0x9f8E839A4cFB1EE283C54900d0C4553031453414": {
			"name": "ShibaCash",
			"symbol": "SHIBACASH",
			"price": "0.2487082887872660658762100276202",
			"price_BNB": "0.0008071809657068473206399432045124"
		},
		"0x1f0Da2588053451e7b31483Ba44dBf7CfcB5A67d": {
			"name": "MiniSafe",
			"symbol": "miniSAFE",
			"price": "0.2482926472856908634615744060516",
			"price_BNB": "0.000867665114842769616489009685771"
		},
		"0xaBfa9C225704f0a7130AcbE5C4145fe20944d996": {
			"name": "MiniMOON",
			"symbol": "miniMOON",
			"price": "0.2467319292132521643267924860874",
			"price_BNB": "0.000846717210203599260953971790768"
		},
		"0xDF77C94D990334be1B1650Bc5c15a344780D1B26": {
			"name": "Shiba Black",
			"symbol": "ShibB",
			"price": "0.2463056960580735910172080906238",
			"price_BNB": "0.000837701334501395112218044178667"
		},
		"0x89D8A7694EAFb732b31f2571729B26a72E45167C": {
			"name": "FlokiLoki",
			"symbol": "fLOKI",
			"price": "0.2454853544508079847395325473035",
			"price_BNB": "0.000803207878742202243458117526234"
		},
		"0x4c386A387C53d5c062fF846fB50d8A68bD38F8d0": {
			"name": "OnlyUpBNB",
			"symbol": "UpBNB",
			"price": "0.2454169602265910417525255710987",
			"price_BNB": "0.000859689044495593591217857547673"
		},
		"0x4dBD749F32917be0602487cff05b288eED77Ee3b": {
			"name": "NanoBitcoin",
			"symbol": "NanoBTC",
			"price": "0.2445418470221761827174858860523",
			"price_BNB": "0.000833475195487611775625665402952"
		},
		"0x2bC9E9384849dF9CB2B843C52B9ec0D35860b76A": {
			"name": "FlokiInu",
			"symbol": "fInu",
			"price": "0.2441697158328625380333014940812",
			"price_BNB": "0.000792111388297622457905570330492"
		},
		"0x44aef696c274f520eb402092AA65c8b4257d11ac": {
			"name": "FlokiBNB",
			"symbol": "fBNB",
			"price": "0.2441401210452544580283300996734",
			"price_BNB": "0.000853451990385030423309398367418"
		},
		"0x57414a90602942B31C36a2C5B2d8a3a0efdaB905": {
			"name": "OnlyUpStrongElon",
			"symbol": "UpSElon",
			"price": "0.3904102852698596071743390307627",
			"price_BNB": "0.001303573356408932264182318775186"
		},
		"0x72a406051f6b9688ce3B8AfE19f014E266a9c2C3": {
			"name": "EverBitcoin",
			"symbol": "EverBTC",
			"price": "0.2436140756744099070115657774915",
			"price_BNB": "0.000834908946258780477888772775269"
		},
		"0xEc8610bfe8c9d26e34005fB6310124AAB046B24b": {
			"name": "FlokiBNB",
			"symbol": "FlokiBNB",
			"price": "0.3893235263496866142343185486962",
			"price_BNB": "0.00129370964789784642103107741169"
		},
		"0xB5cAAeEC00D73D5602912440Eb2a31691c305124": {
			"name": "SpaceFEG",
			"symbol": "SPACEFEG",
			"price": "0.3873288435737288799025235665836",
			"price_BNB": "0.001294836633675310261955675874717"
		},
		"0xcA2172f711c675ee1f97B5966ec7289D408EbC04": {
			"name": "OnlyUpSafe",
			"symbol": "UpSAFE",
			"price": "0.241708570679925829461659231025",
			"price_BNB": "0.000828993825668316753187234335019"
		},
		"0x481e3a0d71338DD3e4573368f780d768E5e0603E": {
			"name": "Hundred x Loki",
			"symbol": "100xLOKI",
			"price": "0.2401978238487614781351282097978",
			"price_BNB": "0.00079125133502786669285608874737"
		},
		"0x2e835f4fa42590Aa87E5862c9334De9A9f4DaF57": {
			"name": "MiniBitcoin",
			"symbol": "miniBTC",
			"price": "0.2398511448227293662948316988915",
			"price_BNB": "0.000813954238536347948680544733323"
		},
		"0xC657101B4b37ee62C61c16d6BD29a611e7bd4608": {
			"name": "MiniCash",
			"symbol": "miniCASH",
			"price": "0.2397347381491996013986263404083",
			"price_BNB": "0.000761871604775752078425409830611"
		},
		"0xdC3766524b885775E1930d1d0e3F6C14dc27349D": {
			"name": "OnlyUpStrongElon",
			"symbol": "UpSElon",
			"price": "0.383496756880866471355339803852",
			"price_BNB": "0.001286321245145483434982182104885"
		},
		"0xacdfA1cd77F803A993A7F21Be90815D97f39279A": {
			"name": "Hundred x Rocket",
			"symbol": "100xRocket",
			"price": "0.239538668278432544490785113077",
			"price_BNB": "0.0008755877839787685215592059678516"
		},
		"0x64C3567ad1A3336AaBaF567B6f21d4E61D7eD34B": {
			"name": "OnlyUpRocket",
			"symbol": "UpRocket",
			"price": "0.382618284436136059384449147404",
			"price_BNB": "0.001272882487382643036851094553152"
		},
		"0xb64d363A86daF26B5Be73434C28D4A86c0E70c5a": {
			"name": "DogeOlympus",
			"symbol": "DOGEOLYMPUS 🌀️",
			"price": "0.000000000190781639491931490934855843742",
			"price_BNB": "0.000000000000474418379196164052214578911879"
		},
		"0x86CA1b6E8051804272c6318Aec9A18F169734608": {
			"name": "Floki Black",
			"symbol": "FlokiB",
			"price": "0.2380972199884982009746348337543",
			"price_BNB": "0.000791401873509920339332587731247"
		},
		"0x55Beaece0Bf9c9b338E43dCc1386336C948D3c64": {
			"name": "Cash",
			"symbol": "$CASH",
			"price": "0.2370926715912339181283059155353",
			"price_BNB": "0.000794448917351033860320867960562"
		},
		"0xafe808c763601A54111a809B34De0F2049eED201": {
			"name": "OnlyUpSushi",
			"symbol": "UpSushi",
			"price": "0.2369782861636627486341961608841",
			"price_BNB": "0.00078360294671475418108780216264"
		},
		"0x9c9108a49C7aEB8DBCcB44fBb4C7AE2562eB07EE": {
			"name": "OnlyUpCash",
			"symbol": "UpCASH",
			"price": "0.2368026002486794800469411311966",
			"price_BNB": "0.0007888437064292150967941354221706"
		},
		"0x7E18f7A4F38FdbecfCF17a36a226D50eF48ebb82": {
			"name": "ShibaPanda",
			"symbol": "ShibPNDA",
			"price": "0.2367513719303314085538169277578",
			"price_BNB": "0.000804043559768252142399985994995"
		},
		"0x2375c73c550FcEFB46Df00c9e7A6E33E02b092c6": {
			"name": "ShibaPanda",
			"symbol": "SHIBAPNDA",
			"price": "0.2365804506656409927761205635817",
			"price_BNB": "0.000802591289249294210003470432245"
		},
		"0x72a9E28f814af6806Bdc7EfEE9a3933E14542b6c": {
			"name": "BabyInu",
			"symbol": "bInu",
			"price": "0.2355551705002981429364443528588",
			"price_BNB": "0.000799291090896080778349968843256"
		},
		"0xe79bFd3d6FdF740469eEb89EAa7599CBdD0BA69E": {
			"name": "SuperCock",
			"symbol": "SUPð",
			"price": "0.375305478721263295372867769218",
			"price_BNB": "0.001260811163522307912406860864891"
		},
		"0x1Fb74A079ED9A66A17aC7109Eb50A5622f0f512A": {
			"name": "FEGCock",
			"symbol": "FEGð",
			"price": "0.3746384744369472424985817283193",
			"price_BNB": "0.001236225687082104332160906415412"
		},
		"0xf512dFAAb35986fA3b3E2CBF6534B5C54731C993": {
			"name": "MiniPanda",
			"symbol": "miniPNDA",
			"price": "0.2334448877436173193036093906696",
			"price_BNB": "0.000792712548565110501916558114655"
		},
		"0x077EBdC7e9063b94F00029DbA3486a9A0fd8F848": {
			"name": "SuperLoki",
			"symbol": "SUPLOKI",
			"price": "0.2334318231276976117357071630749",
			"price_BNB": "0.000819346366543651034123470244209"
		},
		"0x92fF0eE7f00D17948b5F74bF56dd16537aD19660": {
			"name": "EverMOON",
			"symbol": "EverMOON",
			"price": "0.233029147312012493895559683246",
			"price_BNB": "0.000791296305265683299333859987706"
		},
		"0xbee899aAf833Bd4b0D611fbcA26A0DA57F315EF9": {
			"name": "WrappedFEG",
			"symbol": "wFEG",
			"price": "0.2326569598359982938054793940205",
			"price_BNB": "0.00079086878523893375637278787348"
		},
		"0xa1C5DAcf84DB15ddFc78348177D45Baf446f0eAB": {
			"name": "AstroBabyShiba",
			"symbol": "ABSHIB",
			"price": "0.0000000001854364368511102243334550535858",
			"price_BNB": "0.00000000000061286649368461466191456156108"
		},
		"0x1f4aF37805a8a021633CE3D0918035Ad79A613c3": {
			"name": "ShibaCash",
			"symbol": "SHIBACASH",
			"price": "0.2314590193600837402628130115981",
			"price_BNB": "0.000786215285247139292851064150387"
		},
		"0xc50d3ECCD911e48a1779d5a2EF9f96bDeC264871": {
			"name": "ShibaRocket",
			"symbol": "ShibRocket",
			"price": "0.3701995220346789364669419468464",
			"price_BNB": "0.001240971508510693786702159633449"
		},
		"0x723C34780ba4739eeb30Afc9ac07238687bDd563": {
			"name": "Baby Doge Pro",
			"symbol": "BabyDogePro",
			"price": "0.0000000001850227775951344847229071471414",
			"price_BNB": "0.0000000000006942338493951501648401736582665"
		},
		"0x0D36a6f45d7485EBed2B069dc18ac0589400eBCc": {
			"name": "Fat Cake Token ",
			"symbol": "FCake 🍰️",
			"price": "0.0000000001849338911842785192754717303937",
			"price_BNB": "0.000000000000443040427841254040794556936807"
		},
		"0xe20D66F518B7Ba530c37d7c3f352ee4F30029e9e": {
			"name": "ShibaRocket",
			"symbol": "SHIBARocket",
			"price": "0.2305040010974641459357553080472",
			"price_BNB": "0.0007790476191515073900311656470505"
		},
		"0xfFc86FA6a71e68B5ee69232A28c480Cd4f3B2DFb": {
			"name": "Shiba Black",
			"symbol": "SHIBAB",
			"price": "0.228490794954689777399853517405",
			"price_BNB": "0.0008646743235812920390689521737886"
		},
		"0x030C9bE2A6Ad834a7FaB080e182eF4607b0FC51e": {
			"name": "SuperInu",
			"symbol": "SUPInu",
			"price": "0.227216599128526974982595014525",
			"price_BNB": "0.000806576130302002503935600529638"
		},
		"0x5b250A7A207D63756977EEF1325C2181850F0e7E": {
			"name": "SuperRocket",
			"symbol": "SUPRocket",
			"price": "0.226936574677111768939223824202",
			"price_BNB": "0.000802607242796361096756506202457"
		},
		"0x1CbA429D1996331d3C27205Bfd3F6a42C2b8FB23": {
			"name": "NFTs by BNB",
			"symbol": "NFTBNB",
			"price": "0.2258691437577933512921325284546",
			"price_BNB": "0.0005666072118657887012586939772195"
		},
		"0xC2722cD0dbA76042911941c4D7391DfCe83b5414": {
			"name": "Ass Fucker",
			"symbol": "ASS 🍑️",
			"price": "0.0000000001806207957873911522382558134258",
			"price_BNB": "0.000000000000453168645952482037981010134998"
		},
		"0x1051bC77DAA58b875D8A7A0246Ffd0E37d384b0A": {
			"name": "SuperRocket",
			"symbol": "SRocket",
			"price": "0.2256788082362302792008439091474",
			"price_BNB": "0.000765996823433632676549223450319"
		},
		"0x02C7F5DAd0B7e95C9fD23a685EC3580114CFa71b": {
			"name": "FlokiEthereum",
			"symbol": "fETH",
			"price": "0.22549402591399989041919155265",
			"price_BNB": "0.000748714778614279275867586639415"
		},
		"0x871d131f9aFf966B4947005513db4C21f30878eb": {
			"name": "Hundred x Cock",
			"symbol": "100xð",
			"price": "0.360368464970940888144850625844",
			"price_BNB": "0.001209618818278892210934979391402"
		},
		"0x03eF7d6152E242d3D1bb7686DebCB7e6C6A46aAd": {
			"name": "SuperBoxer",
			"symbol": "SUPBOX",
			"price": "0.2252113325690233948322666563497",
			"price_BNB": "0.000787699709959816458698076230745"
		},
		"0x1Be0A313c1eF8E4bf848fFfEeabF985E3252bCC7": {
			"name": "ShibaPanda",
			"symbol": "SHIBAPNDA",
			"price": "0.358909730264187154182297450528",
			"price_BNB": "0.00120605593396483020150444297739"
		},
		"0xc8049D7D22167371131d53698C53D837Ca062C8B": {
			"name": "Orum.io",
			"symbol": "ORUM",
			"price": "0.0000000001788925942162449762745362355014",
			"price_BNB": "0.000000000000570269319268163714499067106693"
		},
		"0x09B250e8A63C353215DbC81b1D1A3c8F159eEb16": {
			"name": "ShibaBoxer",
			"symbol": "ShibBOX",
			"price": "0.2231129720828471534743129126338",
			"price_BNB": "0.000787750826184669090887530097944"
		},
		"0xB04E0f48A68261080C013B05850cd75f2E169Ed5": {
			"name": "MiniCat",
			"symbol": "miniCat",
			"price": "0.2227688907345566334036388381623",
			"price_BNB": "0.000788810734994924005001254031189"
		},
		"0xE84c281D6291Faa3F98A070A113da25e5280528b": {
			"name": "ShibaPanda",
			"symbol": "SHIBAPNDA",
			"price": "0.2226342282312281082791398153862",
			"price_BNB": "0.0008471205669279771060817447043776"
		},
		"0x6B0bA2919703C6A1712Bbe6C58825c9BbB8d3D68": {
			"name": "SpaceFEG",
			"symbol": "SPACEFEG",
			"price": "0.222525069722863861559683184939",
			"price_BNB": "0.000785633217858585308502856527925"
		},
		"0x2a110184876d312C2735ad821CBf6046eb7BD61d": {
			"name": "Hundred x  Finance",
			"symbol": "100xFIN",
			"price": "0.222392084571151289892838010111",
			"price_BNB": "0.000787268489040926998144368022563"
		},
		"0xC8fB2283F7Ba13f8Ad5F15b3c2e2e0384a7B760f": {
			"name": "NFTs x BabyDoge",
			"symbol": "NFTxbDoge",
			"price": "0.2223811640713339008873990977234",
			"price_BNB": "0.000626341337681807833810476092796"
		},
		"0xDe82d2536427246BDC912914BBF9ba27538BC30c": {
			"name": "ShibaPanda",
			"symbol": "ShibPanda",
			"price": "0.2213464705979424982188483998023",
			"price_BNB": "0.000835859571306455446739337361431"
		},
		"0x4E4482ff195EE3f9a4938A04b2a1Ba4810A41a42": {
			"name": "NanoPanda",
			"symbol": "NanoPNDA",
			"price": "0.2204098152558392125586209031972",
			"price_BNB": "0.000834742389959971519005672248072"
		},
		"0x8fCBB3aD0Ef1fC16b3dE4933e4C636eA70d9C3DD": {
			"name": "WrappedMOON",
			"symbol": "wMOON",
			"price": "0.2202314605936096751342578930115",
			"price_BNB": "0.0007225918789186428926206535137175"
		},
		"0x547E7729240A5f26cF3856b4dCCBA3CEc04D5870": {
			"name": "FlokiPanda",
			"symbol": "FlokiPanda",
			"price": "0.2196309096380903105295213767167",
			"price_BNB": "0.0008322956764544820557285503978704"
		},
		"0xC1Ba8716627f1b1D7A509dE84BE4C32baad20526": {
			"name": "Cat",
			"symbol": "$Cat",
			"price": "0.2191607518691294257337062836026",
			"price_BNB": "0.000776122160156856379806530980103"
		},
		"0xbC3eaD6a2c1a92D7F313fd56c34304645115f4bD": {
			"name": "AquaInu",
			"symbol": "AquaInu",
			"price": "0.2188155480932009684883293704066",
			"price_BNB": "0.000833775520749035125130266780333"
		},
		"0x0aFE66490472A46b8b9cCc670Ec6a736eA53aed7": {
			"name": "ShibaBoxer",
			"symbol": "ShibBOX",
			"price": "0.2186816864776299740170378114044",
			"price_BNB": "0.000737011328361188730946811203633"
		},
		"0xAEB5725007cEc4867eBebA59Deb129CD262F0632": {
			"name": "OnlyUpDoge",
			"symbol": "UpDOGE",
			"price": "0.2183050033681875301936766120892",
			"price_BNB": "0.0008227469661996610896586732366095"
		},
		"0x069E04729ec96Be82ccdDfc1f8677096a4E4B2aE": {
			"name": "FlokiStrongElon",
			"symbol": "fSElon",
			"price": "0.349079977962359596006001509628",
			"price_BNB": "0.001167250739778688530539025797458"
		},
		"0x28F92682a0Df4313Ad29bf273848c3DBF87258Aa": {
			"name": "MegaCock",
			"symbol": "Mð",
			"price": "0.348683028424178692818202174693",
			"price_BNB": "0.001148583370780919304569312626987"
		},
		"0xb895d001ecCf018499901E145e49C57D4BC5111E": {
			"name": "FEGFEG",
			"symbol": "FEGFEG",
			"price": "0.2177120439235264756965030677358",
			"price_BNB": "0.000773098800481390699221700274746"
		},
		"0xAf4B29C2c65A35fD919Ee6141C5c548eE8745735": {
			"name": "StrongElon",
			"symbol": "$SElon",
			"price": "0.2165363903871780536669308337655",
			"price_BNB": "0.0007197608592321981915457496113915"
		},
		"0x2eC12F8b141F327Dc4a02cfe428868b88e0a9463": {
			"name": "AquaBoxer",
			"symbol": "AquaBOX",
			"price": "0.2161269687004611882238949059046",
			"price_BNB": "0.000717377890360709549553815331939"
		},
		"0xE630f3FDcEc26828043befc3EFFF4187A9D39697": {
			"name": "FlokiSafe",
			"symbol": "FlokiSAFE",
			"price": "0.2156564557563274164912618822135",
			"price_BNB": "0.000755582182307084768467680874376"
		},
		"0xAa128e1dADDf3f643a4754A90dFD886A9a887D91": {
			"name": "BabyBNB",
			"symbol": "BabyBNB",
			"price": "0.2151955302390000545005097440768",
			"price_BNB": "0.000817654657571722029702199995015"
		},
		"0xaD68685Af5010838a54E1bB282F940DE30f952eb": {
			"name": "BabyLoki",
			"symbol": "bLOKI",
			"price": "0.2151316690985053641784173055388",
			"price_BNB": "0.0007075869237084571532626507746074"
		},
		"0xEE85e70bE006ee2C4C8Eb324399A936Cec712711": {
			"name": "FEGMOON",
			"symbol": "FEGMOON",
			"price": "0.3438540600051390544454616473972",
			"price_BNB": "0.001150304291023808955662604938115"
		},
		"0x23D39E2392DE50Ec87961f2A7213211CA51e206D": {
			"name": "Aqua Cardano",
			"symbol": "AquaADA",
			"price": "0.342820223010398850486299717169",
			"price_BNB": "0.00113893682250112338063541738778"
		},
		"0x3Dd5047A60630de784F5612dBD95d811a91a5750": {
			"name": "Binance FEG",
			"symbol": "BSCFEG",
			"price": "0.2140639249292778907124810145944",
			"price_BNB": "0.000707623573426441812873662485558"
		},
		"0xBd567f82ccBeA2B74A471C2c822136625d7cab49": {
			"name": "SpaceEthereum",
			"symbol": "SPACEETH",
			"price": "0.214061404309947362607450502758",
			"price_BNB": "0.000748697011501542921565813397855"
		},
		"0x0AF1555BdD2858Da610857Baf358C29d296211A4": {
			"name": "Floki Cardano",
			"symbol": "FlokiADA",
			"price": "0.3420424151469500710861490172363",
			"price_BNB": "0.001126128320110517205564116880808"
		},
		"0x2228B40B28d77eA3771caBf7256Bd6eCe9F65852": {
			"name": "SuperDoge",
			"symbol": "SDOGE",
			"price": "0.2137261042931447694992579873947",
			"price_BNB": "0.00070928894664687947216128580312"
		},
		"0xFA4f449F0932C0bB8Ead198E92f08fef9384bd3e": {
			"name": "EverInu",
			"symbol": "EverInu",
			"price": "0.2136141887723520964526250308064",
			"price_BNB": "0.000703756853696069056610491504356"
		},
		"0x3B7bC32BC20828bC8546A2F0F549Cda7718F2F57": {
			"name": "GoesUpHigherMOON",
			"symbol": "GUHMOON",
			"price": "0.2133712217840000724246663937994",
			"price_BNB": "0.00072626467562693296842601981926"
		},
		"0x6bC648f84DEC7660066884Cfd515003B99382002": {
			"name": "SuperSafe",
			"symbol": "SSAFE",
			"price": "0.341376681183596142280607113352",
			"price_BNB": "0.001143753080053355065807166778591"
		},
		"0x1c762C903bBDE96c8fDdDaa51A386525cB588594": {
			"name": "SuperApe",
			"symbol": "SUPAPE",
			"price": "0.2128750626638824077919644276553",
			"price_BNB": "0.000717825891845441389174667278647"
		},
		"0x2f681a3ff2C5842c49589fBF57C455678A5df6aD": {
			"name": "EverStrongElon",
			"symbol": "EverSElon",
			"price": "0.2125588370989861195955893610511",
			"price_BNB": "0.000696917745463019722787643965606"
		},
		"0xeBF9abaB5fef6ddBC3673846ebaf95EA862973Af": {
			"name": "Aqua Cardano",
			"symbol": "AquaADA",
			"price": "0.2123359075690550150085867558127",
			"price_BNB": "0.000716777102827620978437990359156"
		},
		"0x518747b74AEB97742bfEBa7b36820D100B6Fd84F": {
			"name": "SuperInu",
			"symbol": "SInu",
			"price": "0.2119445752366306711826652403852",
			"price_BNB": "0.0007976254811854342191457954281086"
		},
		"0xC9B5b0DBC042775f2d25dB0D9B76a8C077C37270": {
			"name": "BabyPanda",
			"symbol": "bPNDA",
			"price": "0.2119192011621526078520821459441",
			"price_BNB": "0.000693010699706927682691432852579"
		},
		"0x09c5C1B86a3248f866B584a48Bd12a88681E6bc6": {
			"name": "AquaCat",
			"symbol": "AquaCat",
			"price": "0.3380872818922513034082609319977",
			"price_BNB": "0.001105212466046891055406219074461"
		},
		"0x942262cafbb45D638204ada42997AEd31CC14940": {
			"name": "SpaceApe",
			"symbol": "SPACEAPE",
			"price": "0.2105428433130204983303595571797",
			"price_BNB": "0.000708383643036689105231574378841"
		},
		"0xa96b8c5CAC290ee2eBDE7c6455586EC785c088C5": {
			"name": "Rocket",
			"symbol": "$Rocket",
			"price": "0.209821443257654340739355684137",
			"price_BNB": "0.0006947839600170585113812628141614"
		},
		"0xC4dA638B64c821AdB13e4F8aae03fA736FcBc22B": {
			"name": " Finance",
			"symbol": "$FIN",
			"price": "0.2096396751079509586427017782706",
			"price_BNB": "0.0007984386817685616914021137088095"
		}
	}
}