import { MenuEntry } from '@rimauswap-libs/uikit'
import { ContextApi } from 'contexts/Localization/types'

const socials: (t: ContextApi['t']) => MenuEntry[] = (t) => [
  
  {
    label: t("Telegram"),
    icon: "TelegramIcon",
    items: [
      {
        label: `${t('Community')} (${t('EN')})`,
        href: "https://telegram.rimauswap.finance/",
      },
      {
        label:`${t('Community')} (${t('BM')})`,
        href: "https://t.me/RimauSwapBM/",
      },
      {
        label:`${t('Community')} (${t('ZH')})`,
        href: "https://t.me/RimauSwapZH/",
      },
      {
        label: t("Announcement"),
        href: "https://telegramann.rimauswap.finance/",
      }
    ],
  },
  {
    label: t("Twitter"),
    icon: "TwitterIcon",
    href: "https://twitter.rimauswap.finance/",
  },
  {
    label: t("Facebook"),
    icon: "FacebookIcon",
    href: "https://page.rimauswap.finance/",
  },
  {
    label: t("Medium"),
    icon: "MediumIcon",
    href: "https://medium.com/@rimauswap/",
  },
  {
    label: t("Docs"),
    icon: "DocsIcon",
    items: [
      {
        label: t("BM"),
        href: "https://docs-bm.rimauswap.finance/",
      },
      {
        label: t("EN"),
        href: "https://docs-en.rimauswap.finance/",
      },
      {
        label: t("ZH"),
        href: "https://docs-zh.rimauswap.finance/",
      },
    ],
  },
  {
    label: t("Whitepaper"),
    icon: "WhitepaperIcon",
    items: [
      {
        label: t("BM"),
        href: "https://www.whitepaper-bm.rimauswap.finance/",
      },
      {
        label: t("EN"),
        href: "https://www.whitepaper-en.rimauswap.finance/",
      },
      {
        label: t("ZH"),
        href: "https://whitepaper-zh.rimauswap.finance/",
      },
    ],
  },
  
  
];
export default socials;
